import { computed } from "vue";
import { useStore } from "vuex";
import { vuexName, VuexNames } from "@/store/utils";
import { ExposureItemMedia } from "@/store/lesson/state";
export const useLessonStore = () => {
  const { getters, commit } = useStore();
  return {
    visibleTargetTags: computed<string[]>(() => getters[vuexName(VuexNames.LESSON.GETTERS.GET_VISIBLE_TARGET_TAGS)]),
    currentSelectedSlide: computed<ExposureItemMedia | undefined>(() => getters["lesson/currentExposureItemMedia"]),
    setVisibleTargetTagsToEmpty: () => commit(vuexName(VuexNames.LESSON.COMMITS.SET_VISIBLE_TARGET_TAGS), []),
  };
};
