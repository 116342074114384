import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, Fragment as _Fragment } from "vue"
import _imports_0 from '@/assets/icon-select.png'


const _hoisted_1 = {
  key: 0,
  class: "absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
}
const _hoisted_2 = { class: "annotation-view-container__image" }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = ["src"]
const _hoisted_5 = ["src"]
const _hoisted_6 = ["id", "muted", "data-slide-id", "volume"]
const _hoisted_7 = ["src"]
const _hoisted_8 = ["id", "poster", "muted", "data-slide-id", "volume"]
const _hoisted_9 = ["src"]
const _hoisted_10 = {
  class: "annotation-view-container__canvas",
  id: "canvasOnStudent",
  ref: "canvasRef"
}
const _hoisted_11 = {
  key: 1,
  class: "focus-word"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CustomSpinner = _resolveComponent("CustomSpinner")!
  const _component_CropImage = _resolveComponent("CropImage")!
  const _component_vue_pdf_embed = _resolveComponent("vue-pdf-embed")!
  const _component_Alert = _resolveComponent("Alert")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(["annotation-view-container", {
      whiteboard: _ctx.isWbVisible,
      'pointer-events-none': _ctx.student?.isPalette !== true,
    }]),
      ref: "containerRef"
    }, [
      (_ctx.isBoardImageFetching)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_CustomSpinner, { class: "h-8 w-8" })
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_2, [
        ((_ctx.isPointerMode && !_ctx.studentOneAndOneId) || (_ctx.isPointerMode && _ctx.student.id == _ctx.studentOneAndOneId))
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "cursor",
              style: _normalizeStyle([_ctx.pointerStyle])
            }, _cache[5] || (_cache[5] = [
              _createElementVNode("img", {
                src: _imports_0,
                alt: ""
              }, null, -1)
            ]), 4))
          : _createCommentVNode("", true),
        (_ctx.mediaTypeId === undefined && _ctx.image?.url)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              (_ctx.isCropImage)
                ? (_openBlock(), _createBlock(_component_CropImage, {
                    class: "annotation-img",
                    imageUrl: _ctx.boardImageUrl,
                    metadata: _ctx.image.metaData,
                    canvasImage: _ctx.image,
                    onImgLoad: _ctx.onLPSlideLoaded,
                    onImgError: _ctx.onErrorLoadSlide,
                    ref: "imgCropRef",
                    key: _ctx.reloadKey
                  }, null, 8, ["imageUrl", "metadata", "canvasImage", "onImgLoad", "onImgError"]))
                : (_openBlock(), _createElementBlock("img", {
                    crossorigin: "anonymous",
                    ref: "imgRef",
                    class: "annotation-img",
                    src: _ctx.boardImageUrl,
                    onLoad: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onLPSlideLoaded && _ctx.onLPSlideLoaded(...args))),
                    onError: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onErrorLoadSlide && _ctx.onErrorLoadSlide(...args))),
                    alt: "",
                    key: _ctx.reloadKey
                  }, null, 40, _hoisted_4))
            ]))
          : (_ctx.mediaTypeId === _ctx.MediaType.pdf && _ctx.isValidUrl)
            ? (_openBlock(), _createElementBlock("div", {
                key: 2,
                style: _normalizeStyle({
          'z-index': _ctx.isWbVisible ? 0 : 9000,
        }),
                class: "pdf-content"
              }, [
                _createVNode(_component_vue_pdf_embed, {
                  source: _ctx.image.url,
                  class: "pdf-config nice-scroll",
                  ref: "pdfRef",
                  onRendered: _ctx.onPdfRendered
                }, null, 8, ["source", "onRendered"])
              ], 4))
            : (_ctx.mediaTypeId === _ctx.MediaType.mp3 && _ctx.isValidUrl)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 3,
                  style: _normalizeStyle({
          'z-index': _ctx.isWbVisible ? 0 : 9000,
        }),
                  class: "audio-content"
                }, [
                  (_ctx.boardImageUrl)
                    ? (_openBlock(), _createElementBlock("img", {
                        crossorigin: "anonymous",
                        ref: "imgRef",
                        src: _ctx.boardImageUrl,
                        class: "annotation-img",
                        onLoad: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onLPSlideLoaded && _ctx.onLPSlideLoaded(...args))),
                        onError: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onErrorLoadSlide && _ctx.onErrorLoadSlide(...args))),
                        alt: "",
                        key: _ctx.reloadKey
                      }, null, 40, _hoisted_5))
                    : _createCommentVNode("", true),
                  _createElementVNode("audio", {
                    ref: "audioAnnotation",
                    id: _ctx.ALTERNATE_AUDIO_ID,
                    controlslist: "noplaybackrate nodownload",
                    class: "audio-config",
                    onTimeupdate: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.onAudioTimeUpdate && _ctx.onAudioTimeUpdate(...args))),
                    muted: _ctx.teacherDisconnected,
                    "data-slide-id": _ctx.currentExposureItemMedia?.id,
                    volume: _ctx.DEFAULT_ALTERNATE_CONTENT_VOLUME
                  }, [
                    _createElementVNode("source", {
                      src: _ctx.image.url,
                      type: "audio/mp3"
                    }, null, 8, _hoisted_7),
                    _createTextVNode(" " + _toDisplayString(_ctx.WarningAudioTagText), 1)
                  ], 40, _hoisted_6)
                ], 4))
              : (_ctx.mediaTypeId === _ctx.MediaType.mp4 && _ctx.isValidUrl)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 4,
                    style: _normalizeStyle({
          'z-index': _ctx.isWbVisible ? 0 : 9000,
        }),
                    class: _normalizeClass(["video-content", [_ctx.isWbVisible && 'opacity-0']])
                  }, [
                    _createElementVNode("video", {
                      ref: "videoAnnotation",
                      id: _ctx.ALTERNATE_VIDEO_ID,
                      controlslist: "noplaybackrate nodownload",
                      class: "video-config",
                      poster: _ctx.image.thumbnail,
                      muted: _ctx.teacherDisconnected,
                      "data-slide-id": _ctx.currentExposureItemMedia?.id,
                      volume: _ctx.DEFAULT_ALTERNATE_CONTENT_VOLUME
                    }, [
                      _createElementVNode("source", {
                        src: _ctx.image.url,
                        type: "video/mp4"
                      }, null, 8, _hoisted_9),
                      _createTextVNode(" " + _toDisplayString(_ctx.WarningVideoTagText), 1)
                    ], 8, _hoisted_8)
                  ], 6))
                : _createCommentVNode("", true)
      ]),
      _createElementVNode("canvas", _hoisted_10, null, 512),
      (_ctx.focusWordContent.condition)
        ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
            _createVNode(_component_Alert, {
              message: _ctx.focusWordContent.word,
              description: _ctx.focusWordContent.definition,
              type: "info"
            }, null, 8, ["message", "description"])
          ]))
        : _createCommentVNode("", true)
    ], 2),
    _cache[6] || (_cache[6] = _createElementVNode("div", null, null, -1))
  ], 64))
}