<template>
  <div class="flex justify-center items-center">
    <svg v-if="isProcessing" class="animate-spin h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
      <path
        class="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
    <img
      :class="[isProcessing && 'hidden']"
      :src="imgUrl"
      class="w-full h-full object-contain"
      id="imgCrop"
      ref="imageRef"
      alt=""
      crossorigin="anonymous"
      @load="onImageLoad"
      @error="onImageError"
      :style="{
        transform: `scale(${metadata.scaleX},${metadata.scaleY})`,
      }"
    />
  </div>
</template>
<!--<style lang="scss" scoped src="./crop-image.scss"></style>-->
<script lang="ts" src="./crop-image.ts"></script>
<style scoped>
img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
}
</style>
