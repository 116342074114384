import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, Transition as _Transition, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "relative z-20" }
const _hoisted_2 = { class: "absolute w-full px-3 pt-5" }
const _hoisted_3 = { class: "w-full bg-gs-purple h-14 2xl:h-16 rounded-xl flex items-center justify-between px-7" }
const _hoisted_4 = { class: "flex items-center" }
const _hoisted_5 = { class: "text-white w-40 2xl:w-60 flex justify-start" }
const _hoisted_6 = {
  key: 0,
  class: "flex items-center space-x-1.5 h-[45px] text-sm ml-10 border-l border-white/[0.08] mr-auto -intro-x pl-6"
}
const _hoisted_7 = { class: "text-white/90" }
const _hoisted_8 = { class: "flex flex-nowrap space-x-5 items-center" }
const _hoisted_9 = { class: "relative" }
const _hoisted_10 = { class: "flex items-center" }
const _hoisted_11 = ["src"]
const _hoisted_12 = {
  key: 1,
  class: "ml-3 block truncate"
}
const _hoisted_13 = { class: "pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2" }
const _hoisted_14 = { class: "flex items-center" }
const _hoisted_15 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LogoWhite = _resolveComponent("LogoWhite")!
  const _component_TransitionBase = _resolveComponent("TransitionBase")!
  const _component_ChevronUpDownIcon = _resolveComponent("ChevronUpDownIcon")!
  const _component_ListboxButton = _resolveComponent("ListboxButton")!
  const _component_CheckIcon = _resolveComponent("CheckIcon")!
  const _component_ListboxOption = _resolveComponent("ListboxOption")!
  const _component_ListboxOptions = _resolveComponent("ListboxOptions")!
  const _component_Listbox = _resolveComponent("Listbox")!
  const _component_XCircleIcon = _resolveComponent("XCircleIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[3] || (_cache[3] = _createElementVNode("div", { class: "absolute h-14 2xl:h-16 w-full bg-gradient-to-b from-slate-100 via-slate-100/80 to-transparent" }, null, -1)),
    _cache[4] || (_cache[4] = _createElementVNode("div", { class: "absolute w-full px-7 pt-3" }, [
      _createElementVNode("div", { class: "w-full h-14 2xl:h-16 bg-gs-purple/30 rounded-xl" })
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("header", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_TransitionBase, {
              "appear-from": "left",
              "duration-class": "duration-300"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_LogoWhite, { class: "mb-1.5 h-8 2xl:h-10" })
              ]),
              _: 1
            })
          ]),
          _createVNode(_component_TransitionBase, {
            "appear-from": "left",
            "duration-class": "duration-500 delay-100"
          }, {
            default: _withCtx(() => [
              (_ctx.currentLesson && _ctx.currentUnit)
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.className), 1),
                    _cache[2] || (_cache[2] = _createElementVNode("span", { class: "text-white/70" }, "•", -1)),
                    _createElementVNode("a", {
                      href: "javascript:void(0)",
                      class: _normalizeClass(["text-white/70 transition-colors", _ctx.isHelper ? 'cursor-default' : 'hover:text-white']),
                      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.changeUnitAndLesson && _ctx.changeUnitAndLesson(...args)))
                    }, _toDisplayString(_ctx.unitText) + " " + _toDisplayString(_ctx.currentUnit) + " (" + _toDisplayString(_ctx.lessonText) + " " + _toDisplayString(_ctx.currentLesson) + ")", 3)
                  ]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _createVNode(_component_TransitionBase, {
          "appear-from": "right",
          "duration-class": "duration-300"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_Listbox, { as: "div" }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_9, [
                    _createVNode(_component_ListboxButton, { class: "relative cursor-pointer w-40 rounded-md bg-white/10 py-2 pl-3 pr-10 text-left text-white shadow-sm ring-inset focus:outline-none focus:ring-2 focus:ring-white/20 sm:text-sm sm:leading-6" }, {
                      default: _withCtx(() => [
                        _createElementVNode("span", _hoisted_10, [
                          (_ctx.classAction.icon)
                            ? (_openBlock(), _createElementBlock("img", {
                                key: 0,
                                src: require(`@/assets/icons/icon-action-${_ctx.classAction.icon}.png`),
                                alt: "",
                                class: "h-6 w-6 flex-shrink-0 rounded-full"
                              }, null, 8, _hoisted_11))
                            : _createCommentVNode("", true),
                          (_ctx.classAction.id)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(_ctx.genBearActionLabel(_ctx.classAction.id)), 1))
                            : _createCommentVNode("", true)
                        ]),
                        _createElementVNode("span", _hoisted_13, [
                          _createVNode(_component_ChevronUpDownIcon, {
                            class: "h-5 w-5 text-white/80",
                            "aria-hidden": "true"
                          })
                        ])
                      ]),
                      _: 1
                    }),
                    _createVNode(_Transition, {
                      "leave-active-class": "transition ease-in duration-100",
                      "leave-from-class": "opacity-100",
                      "leave-to-class": "opacity-0"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ListboxOptions, { class: "absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm nice-scroll" }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.actions, (action) => {
                              return (_openBlock(), _createBlock(_component_ListboxOption, {
                                as: "template",
                                key: action.id,
                                value: action,
                                onClick: () => _ctx.onClickSelectAction(action)
                              }, {
                                default: _withCtx(({ active }) => [
                                  _createElementVNode("li", {
                                    class: _normalizeClass([active ? 'bg-gs-purple text-white' : 'text-gray-900', 'relative cursor-pointer select-none py-2 pl-3 pr-9'])
                                  }, [
                                    _createElementVNode("div", _hoisted_14, [
                                      _createElementVNode("img", {
                                        src: require(`@/assets/icons/icon-action-${action.id}.png`),
                                        alt: "",
                                        class: "h-6 w-6 flex-shrink-0 rounded-full"
                                      }, null, 8, _hoisted_15),
                                      _createElementVNode("span", {
                                        class: _normalizeClass([_ctx.classAction.id === action.id ? 'font-semibold' : 'font-normal', 'ml-3 block truncate'])
                                      }, _toDisplayString(_ctx.genBearActionLabel(action.id)), 3)
                                    ]),
                                    (_ctx.classAction.id === action.id)
                                      ? (_openBlock(), _createElementBlock("span", {
                                          key: 0,
                                          class: _normalizeClass([active ? 'text-white' : 'text-gs-purple', 'absolute inset-y-0 right-0 flex items-center pr-4'])
                                        }, [
                                          _createVNode(_component_CheckIcon, {
                                            class: "h-5 w-5",
                                            "aria-hidden": "true"
                                          })
                                        ], 2))
                                      : _createCommentVNode("", true)
                                  ], 2)
                                ]),
                                _: 2
                              }, 1032, ["value", "onClick"]))
                            }), 128))
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ])
                ]),
                _: 1
              }),
              _createElementVNode("button", {
                type: "button",
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onClickEnd && _ctx.onClickEnd(...args))),
                class: "inline-flex items-center gap-x-1.5 rounded-md px-3 py-2 text-sm text-white/90 hover:bg-white/10 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gs-purple"
              }, [
                _createVNode(_component_XCircleIcon, {
                  class: "-ml-0.5 h-5 w-5",
                  "aria-hidden": "true"
                }),
                _createTextVNode(" " + _toDisplayString(_ctx.teacherExitText), 1)
              ])
            ])
          ]),
          _: 1
        })
      ])
    ])
  ]))
}