<template>
  <main class="grid min-h-full place-items-center px-6 py-24 sm:py-32 lg:px-8">
    <div class="text-center">
      <h1 class="mt-4 text-balance text-2xl md:text-4xl 2xl:text-7xl font-semibold tracking-tight text-gray-900">{{ MsgBrowserUnsupported }}</h1>
      <p class="mt-6 text-pretty text-lg font-medium text-gray-500 sm:text-xl/8">{{ blockedBrowserMsg }}</p>
      <div class="mt-10 flex items-center justify-center gap-x-6">
        <a
          v-if="downloadLink"
          :href="downloadLink"
          target="_blank"
          class="rounded-md bg-gs-purple px-3.5 py-2.5 text-lg font-semibold text-white shadow-sm hover:bg-gs-purple/80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 hover:text-white focus-visible:outline-gs-purple/80 transition-colors"
          >{{ isDesktop ? MsgDownloadGoogleChrome : MsgDownloadApp }}</a
        >
      </div>
    </div>
  </main>
</template>
<script lang="ts" src="./blocked-browser.ts"></script>
