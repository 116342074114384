export interface AlterContentMediaState {
  startMediaAt: null | string;
  duration: number;
  pauseMediaAt: null | string;
}
export interface WhiteboardState {
  /**
   * When the current lesson plan item is a video or audio, this represents the whiteboard's status (playing or not, duration played).
   */
  mediaState: AlterContentMediaState | null;
  shouldFetchMediaState: boolean;
}
export const initWhiteboardModuleState = () => ({ mediaState: null, shouldFetchMediaState: false });

const state: WhiteboardState = initWhiteboardModuleState();

export default state;
