import { GetterTree } from "vuex";
import { CallingState } from "./state";

const getters: GetterTree<CallingState, any> = {
  callingUserIds: (state: CallingState): string[] => {
    return state.callingUserIds;
  },
  enabledAgoraNoiseCancelling: (state: CallingState): boolean => {
    return state.enabledAgoraNoiseCancelling;
  },
  checkCallingHasUserById: (state: CallingState): ((userId: string) => boolean) => {
    return (userId: string) => state.callingUserIds.findIndex((id) => id === userId) > -1;
  },
  postLocalUserReconnectedIntervalId: (state: CallingState): number | undefined => {
    return state.postLocalUserReconnectedIntervalId;
  },
};

export default getters;
