import { computed, ComputedRef, defineComponent, inject, PropType, ref } from "vue";
import { TeamState } from "@/store/teams/state";
import TeamWrapper from "@/components/common/teams/components/team-wrapper";
import { Button, Popover, Tooltip } from "ant-design-vue";
import Member from "../member";
import Draggable from "vuedraggable";
import { calColorByBackground, upperCaseFirstLetter } from "@/utils/utils";
import { fmtMsg } from "vue-glcommonui";
import { CommonLocale, TeacherClassGallery, TeamLocales } from "@/locales/localeid";
import { ArrowUturnLeftIcon, PencilSquareIcon, TrashIcon } from "@heroicons/vue/20/solid";
import { MicrophoneIcon } from "@/components/common";
import { RoomStore, useRoomStore } from "@/hooks/use-room-store";
import { RoleView, usePage } from "@/hooks/use-page";
import { useManipulateTeamState } from "@/hooks/use-team";
import { readOnlyNumbOfTeams } from "@/components/common/teams/components/teams/constants";

export default defineComponent({
  name: "TeamComponent",
  methods: { calColorByBackground },
  emits: ["handleEdit", "handleDel", "handleMute"],
  components: { MicrophoneIcon, ArrowUturnLeftIcon, Tooltip, Draggable, Member, TeamWrapper, Popover, Button, PencilSquareIcon, TrashIcon },
  props: {
    team: {
      type: Object as PropType<TeamState>,
      required: true,
    },
  },
  setup(props) {
    const delPopoverOpened = ref(false);
    const { usersMedia, oneStudentIds } = useRoomStore(RoomStore.Teacher);
    const { isClassPage } = usePage(RoleView.Teacher);
    const { isTeamMode } = useManipulateTeamState();
    const isMuted = computed(() =>
      props.team.students.every((member) => {
        const userMedia = usersMedia.value.find((user) => user.id === member.id);
        return !userMedia?.audio;
      }),
    );
    const disableMute = computed(() => props.team.students.some((member) => oneStudentIds.value.includes(member.id)));
    const numbOfTeams: ComputedRef<number> | undefined = inject(readOnlyNumbOfTeams);
    // When the number of teams is greater than 3, the mute button will be displayed as a small icon to show more the team's name
    const isMuteBtnShrink = computed(() => !!numbOfTeams && numbOfTeams.value > 3);
    const MsgUnmute = computed(() => fmtMsg(TeacherClassGallery.UnmuteAll));
    const MsgMute = computed(() => fmtMsg(TeacherClassGallery.MuteAll));
    const MsgUnableMute = computed(() => fmtMsg(TeamLocales.UnableMuteTeam));

    return {
      muteBtnClass: computed(() => [
        "flex items-center space-x-2 mr-2 bg-slate-50",
        disableMute.value && "text-disabled",
        isMuteBtnShrink.value && "p-1",
      ]),
      muteBtnLabel: computed(() => (isMuteBtnShrink.value ? "" : isMuted.value ? MsgUnmute.value : MsgMute.value)),
      muteBtnSize: computed(() => (isMuteBtnShrink.value ? "small" : "default")),
      muteIconSize: computed(() => (isMuteBtnShrink.value ? 15 : 20)),
      muteTooltipTitle: computed(() => (disableMute.value ? MsgUnableMute.value : isMuted.value ? MsgUnmute.value : MsgMute.value)),
      upperCaseFirstLetter,
      delPopoverOpened,
      isMuted,
      isClassPage,
      isTeamMode,
      disableMute,
      MsgConfirmDelTeam: computed(() => fmtMsg(TeamLocales.ConfirmDelTeam)),
      MsgYes: computed(() => fmtMsg(CommonLocale.CommonYesButtonText)),
      MsgNo: computed(() => fmtMsg(CommonLocale.CommonNoButtonText)),
    };
  },
});
