import { useStore } from "vuex";
import { computed } from "vue";
import { vuexName, VuexNames } from "@/store/utils";

export const useClassTeaching = () => {
  const { getters } = useStore();
  const isSocketAttemptedReconnecting = computed<boolean>(
    () => getters[vuexName(VuexNames.CLASS_TEACHING.GETTERS.GET_IS_SOCKET_ATTEMPTED_RECONNECTING)],
  );

  return {
    isSocketAttemptedReconnecting,
  };
};
