import { MutationTree } from "vuex";
import { TeachingMode } from "../interfaces";
import { ClassTeachingState, InitialClassDataSnapshot, initState } from "./state";
import { OneToOneDto } from "@/services";
import { defineVuexName, VuexNames } from "@/store/utils";

const mutations: MutationTree<ClassTeachingState> = {
  setSessionInfo(state: ClassTeachingState, payload: { sessionId: string; groupId: string; classId: string }): void {
    Object.assign(state, payload);
  },
  endClass(state: ClassTeachingState): void {
    Object.assign(state, initState());
  },
  setMode(state: ClassTeachingState, payload: TeachingMode) {
    state.mode = payload;
  },
  setIndependentModeCurrentUnit(state: ClassTeachingState, payload: number | null) {
    state.independentModeCurrentUnit = payload;
  },
  setIndependentModeCurrentLesson(state: ClassTeachingState, payload: number | null) {
    state.independentModeCurrentLesson = payload;
  },
  setOneToOneWithHelperIgnoreTeacherVoice(state: ClassTeachingState, ignore: boolean) {
    state.oneToOneWithHelperIgnoreTeacherVoice = ignore;
  },
  setMyOneAndOneData(state: ClassTeachingState, payload: null | OneToOneDto) {
    state.myOneAndOneData = payload;
  },
  [defineVuexName(VuexNames.CLASS_TEACHING.COMMITS.SET_INITIAL_CLASS_DATA_SNAPSHOT)](state: ClassTeachingState, payload: InitialClassDataSnapshot) {
    state.initialClassDataSnapshot = payload;
  },
  [defineVuexName(VuexNames.CLASS_TEACHING.COMMITS.SET_IS_SOCKET_ATTEMPTED_RECONNECTING)](state: ClassTeachingState, payload: boolean) {
    state.isSocketAttemptedReconnecting = payload;
  },
  [defineVuexName(VuexNames.CLASS_TEACHING.COMMITS.SET_IS_INIT_CLASS_FAILED)](state: ClassTeachingState, payload: boolean) {
    state.isInitClassFailed = payload;
  },
};

export default mutations;
