import { computed } from "vue";
import { useStore } from "vuex";
const module = "annotation";
export const useAnnotationStore = () => {
  const { getters } = useStore();
  const gt = (getterName: any) => {
    return getters[`${module}/${getterName}`];
  };
  return {
    naturalWidthOfCurrentSlide: computed<number>(() => gt("imgWidth")),
    naturalHeightOfCurrentSlide: computed<number>(() => gt("imgHeight")),
  };
};
