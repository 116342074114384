import { GLServiceBase } from "vue-glcommonui";
import { IndependentServiceInterface } from "./interface";
import { GetIndependentStudentResponse, GetIndependentStudentsResponse, ToggleIndependentModeResponse } from "./model";
class IndependentServiceClass extends GLServiceBase<any, any> implements IndependentServiceInterface {
  serviceRoute = { prefix: "remote/v1" };
  toggleIndependentMode(unit: number, lesson: number, itemId?: string): Promise<ToggleIndependentModeResponse> {
    const isStart = !!itemId;
    const constantParams = `isStart=${isStart}&unit=${unit}&lesson=${lesson}`;
    const dynamicParams = isStart ? `&itemId=${itemId}` : "";
    return this.create(`teacher/independent?${constantParams + dynamicParams}`);
  }
  getIndependentStudentsItem(): Promise<GetIndependentStudentsResponse> {
    return this.get(`teacher/independent`);
  }
  helperGetIndependentStudentsItem(): Promise<GetIndependentStudentsResponse> {
    return this.get(`helper/independent`);
  }
  getIndependentStudentItem(studentId: string): Promise<GetIndependentStudentResponse> {
    return this.get("student/independent/current", { studentId });
  }
}

export const IndependentService = new IndependentServiceClass();
