import { ExposureItemMedia } from "@/store/lesson/state";

// Return true if teacher choose a new slide from current slide or back to the list exposure
export const isTeacherChangedFromCurrentSlideToOtherSlideOrNoSlide = (
  newSlide: ExposureItemMedia | undefined,
  oldSlide: ExposureItemMedia | undefined,
) => !!oldSlide && newSlide?.id !== oldSlide.id;

export const isTeacherNotChooseAnySlide = (currentSlide: ExposureItemMedia | undefined) => !currentSlide;
export const isTeacherChooseTeacherUseOnlySlide = (currentSlide: ExposureItemMedia | undefined) => currentSlide?.teacherUseOnly;
