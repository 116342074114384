import { fabric } from "fabric";
import { getFourPoints } from "@/utils/fabric-utils";

export const renderPolylineAsLaserOnCanvas = (laserPath: any, canvas: any, oneOneStatus: any, studentOneAndOneId: any, student: any, originZoomRatio: number) => {
  if (laserPath.value) {
    const laserPathLine = canvas.getObjects().find((obj: any) => obj.id === "laser");
    if (laserPath.value.isDone && laserPathLine) {
      setTimeout(() => {
        canvas.remove(laserPathLine);
      }, 1000);
    } else {
      if (!studentOneAndOneId.value || studentOneAndOneId.value === student?.value?.id) {
        canvas.remove(...canvas.getObjects().filter((obj: any) => obj.id === "laser"));
        laserPath.value.lines.forEach((data: any) => {
          const points = data.points?.length === 1 ? getFourPoints(data.points[0]) : data.points;
          new fabric.Polyline.fromObject({ points }, (item: any) => {
            item.id = "laser";
            item.fill = "transparent";
            item.stroke = data.strokeColor;
            item.strokeWidth = data.strokeWidth;
            item.strokeLineCap = "round";
            item.strokeLineJoin = "round";
            canvas.add(item);
            item.animate("opacity", `0`, {
              duration: 1000,
              easing: fabric.util.ease.easeInOutExpo,
              onComplete: async () => {
                if (laserPath.value.isDone) {
                  canvas.remove(item);
                }
              },
            });
          });
        });
      }
    }
  }
};
