import { ActionTree } from "vuex";
import { CallingState } from "./state";
import { CallingUserStatus } from "@/store/calling/mutations";
import { MainRoles, RemoteTeachingService } from "@/services";
import { Logger } from "@/utils/logger";
import { CallingDeviceType } from "@/agora";
import { TeacherRoomManager } from "@/manager/room/teacher.manager";
import { StudentRoomManager } from "@/manager/room/student.manager";

export interface PostLocalUserReconnectedPayload {
  localUserRole: MainRoles;
  localUserId: string;
}

const actions: ActionTree<CallingState, any> = {
  async setCallingUserIds({ commit, dispatch }, payload: { id: string; status: CallingUserStatus }) {
    commit("setCallingUserIds", payload);
    await dispatch("callingUpdateAudioAndVideoFeed");
  },
  async callingUpdateAudioAndVideoFeed({ dispatch, rootState }, options?: { cameras: string[]; audios: string[] }) {
    await dispatch(`${rootState.teacherRoom.teacher ? "teacherRoom" : "studentRoom"}/updateAudioAndVideoFeed`, options, { root: true });
  },
  async getEnabledAgoraNoiseCancelling({ commit }) {
    try {
      const enabled = await RemoteTeachingService.fetchAgoraNoiseCancellingExtensionStatus();
      commit("setEnabledAgoraNoiseCancelling", enabled);
    } catch (e) {
      Logger.error(e);
    }
  },
  async callingUpdateDevice({ dispatch, rootState }, options: { deviceType: CallingDeviceType; isTurnOff?: boolean }) {
    const module = rootState.teacherRoom.teacher ? "teacherRoom" : "studentRoom";
    let act = "updateCameraDevice";
    if (options.deviceType === CallingDeviceType.MICROPHONE) {
      act = "updateMicrophoneDevice";
    } else if (options.deviceType === CallingDeviceType.SPEAKER) {
      act = "updateSpeakerDevice";
    }
    const payload = options.deviceType === CallingDeviceType.CAMERA && options.isTurnOff ? { blockReopen: true } : {};
    await dispatch(`${module}/${act}`, payload, { root: true });
  },
  reRegisterHotPluggingDevicesEventListener({ rootState }) {
    const manager: TeacherRoomManager | StudentRoomManager | undefined = rootState.teacherRoom.teacher
      ? rootState.teacherRoom?.manager
      : rootState.studentRoom?.manager;
    manager?.agoraClient?.registerHotPluggingDevicesEventListener();
  },
  // Every upon 1 minute, we will call this API to inform the server that the local user (especially TEACHER) is still online, to ensure the class never ends
  async postLocalUserReconnected({ getters, commit, rootGetters }, payload: PostLocalUserReconnectedPayload) {
    const sessionId = rootGetters["classTeaching/getSessionId"];
    const { localUserRole, localUserId } = payload;
    const triggerApi = async () => {
      await RemoteTeachingService.postLocalUserReconnected({
        localUserRole,
        localUserId,
        sessionId,
      });
    };
    await triggerApi();
    const postLocalUserReconnectedIntervalId = getters["postLocalUserReconnectedIntervalId"];
    if (postLocalUserReconnectedIntervalId) {
      clearInterval(postLocalUserReconnectedIntervalId);
    }
    const intervalId = setInterval(async () => {
      await triggerApi();
    }, 6000 * 10);
    commit("setPostLocalUserReconnectedIntervalId", intervalId);
  },
};

export default actions;
