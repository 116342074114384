import { CanvasObjectModel } from "@/hooks/use-send-websocket-msg";

export interface ObjectState {
  nextObjectTobeUpdated?: CanvasObjectModel;
}

export const initObjectModuleState = () => ({
  nextObjectTobeUpdated: undefined,
});

const state: ObjectState = initObjectModuleState();

export default state;
