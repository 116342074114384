import { computed } from "vue";
import { useStore } from "vuex";
import { StudentState, UserMediaStatus } from "@/store/room/interface";

export enum RoomStore {
  Teacher = 0,
  Student = 1,
}
export const useRoomStore = (room: RoomStore) => {
  const module = room === RoomStore.Teacher ? "teacherRoom" : "studentRoom";
  const { getters } = useStore();
  const usersMedia = computed<UserMediaStatus[]>(() => getters[`${module}/usersMedia`]);
  const students = computed<StudentState[]>(() => getters[`${module}/students`]);
  const oneStudentIds = computed<string[]>(() => getters[`${module}/oneStudentIds`]);
  const callingUserIds = computed<string[]>(() => getters["calling/callingUserIds"]);
  const studentIdsNotInOneToOneMode = computed<string[]>(() => getters[`${module}/studentIdsNotInOneToOneMode`]);
  const isAllOnlineStudentsVideoHidden = computed<boolean>(() =>
    students.value.filter((student) => callingUserIds.value.includes(student.id)).every((student) => !student.videoEnabled),
  );
  const isAllOnlineStudentsAudioMuted = computed<boolean>(() =>
    students.value.filter((student) => callingUserIds.value.includes(student.id)).every((student) => !student.audioEnabled),
  );
  const isAllOnlineStudentsDisabledPalette = computed<boolean>(() =>
    students.value.filter((student) => callingUserIds.value.includes(student.id)).every((student) => !student.isPalette),
  );
  const onlineStudentIdsNotInOneToOneMode = computed<string[]>(() =>
    studentIdsNotInOneToOneMode.value.filter((id) => callingUserIds.value.includes(id)),
  );

  const currentOnlineEnabledPaletteStudent = computed<StudentState | undefined>(() =>
    students.value.find((student) => student.isPalette && callingUserIds.value.includes(student.id)),
  );

  return {
    usersMedia,
    students,
    oneStudentIds,
    onlineStudentIdsNotInOneToOneMode,
    isAllOnlineStudentsVideoHidden,
    isAllOnlineStudentsAudioMuted,
    isAllOnlineStudentsDisabledPalette,
    currentOnlineEnabledPaletteStudent,
  };
};
