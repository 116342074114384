import { BaseResponse } from "@/services";
export interface SessionStatusModel {
  teacherId: string;
  helperId: string;
  platform: number;
}

export type GetSessionStatusResponse = SessionStatusModel | null;

export type JoinSessionAsHelperResponse = BaseResponse<{}>;
export type CancelJoinAsHelperResponse = BaseResponse<{}>;
export type TeacherAcceptHelperResponse = BaseResponse<{}>;
export type TeacherDenyHelperResponse = BaseResponse<{}>;
export type TeacherRemoveHelperResponse = BaseResponse<{}>;
export type ToggleHelperMicroResponse = BaseResponse<{}>;
export type ToggleHelperCameraResponse = BaseResponse<{}>;
export type HelperExitResponse = BaseResponse<{}>;
export type TeacherToggleHelperVideo = BaseResponse<{}>;
export type HelperRequestTeachingResponse = BaseResponse<{}>;
export enum JoinSessionAsHelperErrorCode {
  SessionIsNotStartedYet = 9,
  HelperIsAvailableInSession = 10,
  WaitingTeacherAccept = 12,
  TeacherDeniedHelper = 13,
  HelperAndTeacherAreDuplicated = 14,
  PlatformInvalid = 15,
}
