import { ref, onUnmounted, computed } from "vue";
import { Logger } from "@/utils/logger";
import { useStore } from "vuex";

export const MAX_RELOAD_ATTEMPTS = 5;
export const RELOAD_TIMEOUT = 5000;
export const useImageLoader = (usedForTeacher = true) => {
  const { getters } = useStore();
  const isLostConnection = computed<boolean>(() => (usedForTeacher ? getters["teacherRoom/isDisconnected"] : getters["studentRoom/isDisconnected"]));
  let timeoutId: any;
  const reloadKey = ref(0);
  const handleImageLoadError = () => {
    if (reloadKey.value > MAX_RELOAD_ATTEMPTS && !isLostConnection.value) return;
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      Logger.log(`Retrying... (Attempt ${reloadKey.value})`);
      reloadKey.value += 1;
    }, RELOAD_TIMEOUT);
  };

  const resetReloadKey = () => {
    if (reloadKey.value === 0) return;
    reloadKey.value = 0;
  };
  // Clear the timeout when the component unmounts
  onUnmounted(() => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
  });
  return {
    reloadKey,
    handleImageLoadError,
    resetReloadKey,
  };
};
