import { GetterTree } from "vuex";
import { TeamsState, TeamState, TeamStudentState } from "./state";
import { orderBy } from "lodash";

const getters: GetterTree<TeamsState, any> = {
  teams(state: TeamsState): TeamState[] {
    return orderBy(state.teams, ["order"], ["asc"]);
  },
  allStudents(state: TeamsState): TeamStudentState[] {
    return state.allStudents;
  },
  isTeamMode(state: TeamsState): boolean {
    return state.isTeamMode;
  },
  isLoading(state: TeamsState): boolean {
    return state.isLoading;
  },
  findAvatarUrlById: (state: TeamsState): ((id: string) => undefined | string) => {
    return (id: string) => state.allStudents.find((student) => student.id === id)?.avatarUrl;
  },
  settingModalOpened(state: TeamsState): boolean {
    return state.settingModalOpened;
  },
  enableTeamModeOnSave(state: TeamsState): boolean {
    return state.enableTeamModeOnSave;
  },
};

export default getters;
