import moment, { Moment } from "moment";
import { useStore } from "vuex";
import { computed, ref, watch } from "vue";
import { OneToOneDto, RemoteTeachingService, TeacherUpdateMediaStateRequestModel } from "@/services";
import { AlterContentMediaState } from "@/store/whiteboard/state";
import { Logger } from "@/utils/logger";
import { debounce } from "lodash";
import { isMediaElementInitialState, isMediaNotPlayedYetBasedOnServerState } from "@/utils/utils";

const MAX_DEVIATION = 10; //10 seconds
const calcDiffTimeAsSecond = (start: Moment, end: Moment) => end.diff(start) / 1000;
const calcDuration = (startTimeAt: null | string, pauseTimeAt: null | string, durationCalcByServer: number): number => {
  let duration = 0;
  if (startTimeAt) {
    if (pauseTimeAt) {
      duration = durationCalcByServer;
    } else {
      const currentTime = moment.utc();
      const startTime = moment.utc(startTimeAt);
      const durationCalcByStartTime = calcDiffTimeAsSecond(moment(startTime), moment(currentTime));
      if (Math.abs(durationCalcByStartTime - durationCalcByServer) < MAX_DEVIATION) {
        duration = durationCalcByStartTime;
      } else {
        duration = durationCalcByServer;
      }
    }
  }
  return duration;
};
export const useWhiteboardMediaState = () => {
  const { getters, commit, dispatch } = useStore();
  const shouldFetchMediaState = computed<boolean>(() => getters["whiteboard/getShouldFetchMediaState"]);
  const mediaCanPlay = ref(false);
  const isUpdatingByAPI = ref(false);
  const videoRef = ref<HTMLVideoElement | null>(null);
  const audioRef = ref<HTMLVideoElement | null>(null);
  const currentSlideId = computed<string | undefined>(() => getters["lesson/currentExposureItemMedia"]?.id);
  const whiteboardMediaState = computed<AlterContentMediaState | null>(() => getters["whiteboard/getWhiteboardMediaState"]);
  watch([mediaCanPlay, shouldFetchMediaState], async ([canPlayVal, shouldFetchVal]) => {
    const isDOMNotReady = () => {
      // The shouldFetchMediaState will be true before old video/audio element is removed and new video/audio element is added
      // So this function will return false if the current video/audio element is not ready to play
      const currentMediaElementId = videoRef.value?.getAttribute("data-slide-id") ?? audioRef.value?.getAttribute("data-slide-id");
      return !!(currentMediaElementId && currentSlideId.value != currentMediaElementId);
    };
    if (!canPlayVal || !shouldFetchVal || isDOMNotReady()) return;
    const oneToOneState: null | OneToOneDto = getters["classTeaching/getMyOneAndOneData"];
    const payload: AlterContentMediaState = {
      startMediaAt: null,
      pauseMediaAt: null,
      duration: 0,
    };
    if (oneToOneState) {
      const { startMediaAt, pauseMediaAt, duration } = oneToOneState;
      Object.assign(payload, { startMediaAt, pauseMediaAt, duration });
    } else {
      try {
        const initTime = moment();
        const { data } = await RemoteTeachingService.getSessionState(getters["classTeaching/getSessionId"]);
        const finishTime = moment();
        const timeSpent = calcDiffTimeAsSecond(initTime, finishTime);
        const { startMediaAt, duration, pauseMediaAt } = data;
        Object.assign(payload, { startMediaAt, duration: duration + timeSpent, pauseMediaAt });
      } catch (error) {
        Logger.error(error);
      }
    }
    commit("whiteboard/setMediaState", payload);
  });

  // * update video/audio bar whenever state changed
  watch(
    whiteboardMediaState,
    (state) => {
      if (state) {
        const mediaEl = videoRef.value ?? audioRef.value;
        if (!mediaCanPlay.value || !mediaEl) return;
        // If this update time is triggered by API, the isUpdatingByAPI variable will be true to prevent teacher call API update media state again
        if (isMediaNotPlayedYetBasedOnServerState(state) && isMediaElementInitialState(mediaEl)) return;
        isUpdatingByAPI.value = true;
        const { startMediaAt, pauseMediaAt, duration } = state;
        const finalDuration = calcDuration(startMediaAt, pauseMediaAt, duration);
        const isPlaying = !!(startMediaAt && !pauseMediaAt);
        if (videoRef.value) {
          // Set currentTime always trigger the "onseeked" event
          videoRef.value.currentTime = finalDuration;
        }
        if (audioRef.value) {
          audioRef.value.currentTime = finalDuration;
        }
        if (isPlaying) {
          try {
            videoRef.value?.play();
            audioRef.value?.play();
          } catch (error) {
            Logger.error(error);
          }
        } else {
          videoRef.value?.pause();
          audioRef.value?.pause();
        }
      }
    },
    { deep: true },
  );

  const toggleMediaCanPlay = (canPlay: boolean) => {
    mediaCanPlay.value = canPlay;
  };

  const debounceSendApiUpdateMediaState = debounce(async (payload: TeacherUpdateMediaStateRequestModel) => {
    // if updating by API, the teacher don't need to update the media state
    if (isUpdatingByAPI.value) {
      isUpdatingByAPI.value = false;
      return;
    }
    await dispatch("teacherRoom/setMediaState", payload);
  }, 150);

  return { videoRef, audioRef, isUpdatingByAPI, debounceSendApiUpdateMediaState, toggleMediaCanPlay };
};
