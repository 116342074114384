import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex flex-col flex-1" }
const _hoisted_2 = {
  key: 0,
  class: "flex items-center justify-between"
}
const _hoisted_3 = { class: "text-slate-600" }
const _hoisted_4 = {
  key: 1,
  class: "flex items-center justify-between"
}
const _hoisted_5 = { class: "flex items-center space-x-2" }
const _hoisted_6 = { class: "flex items-center space-x-2 px-3 h-[38px] border bg-white shadow-sm rounded-md" }
const _hoisted_7 = { class: "font-medium" }
const _hoisted_8 = ["disabled"]
const _hoisted_9 = { class: "h-5 w-5 flex items-center justify-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ArrowLeftIcon = _resolveComponent("ArrowLeftIcon")!
  const _component_Switch = _resolveComponent("Switch")!
  const _component_Tooltip = _resolveComponent("Tooltip")!
  const _component_PencilIcon = _resolveComponent("PencilIcon")!
  const _component_StudentControls = _resolveComponent("StudentControls")!
  const _component_StudentAll = _resolveComponent("StudentAll")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.oneAndOneStatus)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("button", {
            class: "text-gs-purple flex items-center gap-x-1",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.backToClass && _ctx.backToClass(...args)))
          }, [
            _createVNode(_component_ArrowLeftIcon, { class: "h-4 w-4" }),
            _createElementVNode("span", null, _toDisplayString(_ctx.returnText), 1)
          ]),
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.timeCount), 1)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_Tooltip, {
              title: _ctx.canToggleTeamMode ? null : _ctx.MsgPreventToggleTeamModeWarning,
              placement: "bottom"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.TeamsLocale), 1),
                  _createVNode(_component_Switch, {
                    checked: _ctx.isTeamMode,
                    disabled: !_ctx.canToggleTeamMode,
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.canToggleTeamMode ? _ctx.handleToggleTeamMode() : null))
                  }, null, 8, ["checked", "disabled"])
                ])
              ]),
              _: 1
            }, 8, ["title"]),
            _createVNode(_component_Tooltip, {
              title: _ctx.canToggleTeamMode ? _ctx.MsgEditTeam : _ctx.MsgPreventToggleTeamModeWarning,
              placement: "bottom"
            }, {
              default: _withCtx(() => [
                _createElementVNode("button", {
                  disabled: !_ctx.canToggleTeamMode,
                  type: "button",
                  class: "rounded-md border bg-white p-2 text-sm font-medium text-gray-900 shadow-sm hover:bg-gray-50",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handleSettingTeams()))
                }, [
                  _createElementVNode("span", _hoisted_9, [
                    _createVNode(_component_PencilIcon, { class: "h-5 w-5 text-slate-600" })
                  ])
                ], 8, _hoisted_8)
              ]),
              _: 1
            }, 8, ["title"])
          ]),
          _createVNode(_component_StudentControls, { isTeamMode: _ctx.isTeamMode }, null, 8, ["isTeamMode"])
        ])),
    _createVNode(_component_StudentAll, { students: _ctx.students }, null, 8, ["students"])
  ]))
}