<template>
  <Modal
    :destroyOnClose="true"
    :visible="settingModalOpened"
    :zIndex="999"
    style="top: 20px"
    :width="isLaptopDimension ? '85%' : '70%'"
    @ok="handleOk"
    @cancel="handleCancel"
    :ok-text="MsgOk"
    :cancel-text="MsgCancel"
  >
    <template #title>
      <div class="flex items-center justify-between gap-5 pr-10">
        <div class="">{{ MsgSettingTeamsTitle }}</div>
        <div class="flex justify-end space-x-1">
          <Button @click="handleAddNewTeam" type="primary" class="flex items-center space-x-2">
            <PlusCircleIcon class="h-4 w-4" />
            {{ MsgNewTeam }}
          </Button>
          <Button @click="handleResetTeams" class="flex items-center space-x-2">
            <ArrowUturnLeftIcon class="h-4 w-4" />
            {{ MsgResetTeam }}
          </Button>
        </div>
      </div>
    </template>
    <Teams ref="teamsCompRef" />
  </Modal>
</template>
<style lang="scss" scoped src="./setting-modal.scss"></style>
<script lang="ts" src="./setting-modal.ts"></script>
