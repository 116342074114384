<template>
  <div class="max-w-7xl px-6 py-8 w-full mx-auto text-sm" v-if="isLoaded">
    <div class="text-center space-y-2">
      <div class="text-2xl font-medium">{{ welcomeText }} {{ username }}</div>
      <div>{{ chooseRoleText }}</div>
    </div>
    <div class="content-container">
      <router-link to="/teacher">
        <div class="item-container teacher-container">
          <img src="@/assets/images/home-class.png" alt="" class="brightness-0 invert h-5 mx-auto" />
          <div class="item-name">{{ teacherText }}</div>
        </div>
      </router-link>
      <router-link to="/parent">
        <div class="item-container student-container">
          <UserIcon class="h-6 w-6 text-white mx-auto" />
          <div class="item-name">{{ studentText }}</div>
        </div>
      </router-link>
    </div>
  </div>
</template>
<style lang="scss" src="./home.scss"></style>
<script lang="ts" src="./home.ts"></script>
