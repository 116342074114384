import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "circle resizable draggable",
    id: _ctx.id,
    style: _normalizeStyle(_ctx.style)
  }, null, 12, _hoisted_1))
}