import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["width", "height"]
const _hoisted_2 = ["width", "height"]
const _hoisted_3 = ["width", "height"]
const _hoisted_4 = ["width", "height"]




export default /*@__PURE__*/_defineComponent({
  __name: 'microphone-icon',
  props: {
    enabled: { type: Boolean },
    isOutline: { type: Boolean },
    iconClass: {},
    width: {},
    height: {}
  },
  setup(__props: any) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.isOutline)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_ctx.enabled)
            ? (_openBlock(), _createElementBlock("svg", {
                key: 0,
                class: _normalizeClass([_ctx.iconClass]),
                xmlns: "http://www.w3.org/2000/svg",
                width: _ctx.width,
                height: _ctx.height,
                fill: "currentcolor",
                viewBox: "0 0 256 256"
              }, _cache[0] || (_cache[0] = [
                _createElementVNode("path", { d: "M80,128V64a48,48,0,0,1,96,0v64a48,48,0,0,1-96,0Zm128,0a8,8,0,0,0-16,0,64,64,0,0,1-128,0,8,8,0,0,0-16,0,80.11,80.11,0,0,0,72,79.6V232a8,8,0,0,0,16,0V207.6A80.11,80.11,0,0,0,208,128Z" }, null, -1)
              ]), 10, _hoisted_1))
            : (_openBlock(), _createElementBlock("svg", {
                key: 1,
                class: _normalizeClass([_ctx.iconClass]),
                xmlns: "http://www.w3.org/2000/svg",
                width: _ctx.width,
                height: _ctx.height,
                fill: "currentcolor",
                viewBox: "0 0 256 256"
              }, _cache[1] || (_cache[1] = [
                _createElementVNode("path", { d: "M213.38,221.92a8,8,0,0,1-11.3-.54l-26.46-29.1A79.74,79.74,0,0,1,136,207.59V232a8,8,0,0,1-16,0V207.6A80.11,80.11,0,0,1,48,128a8,8,0,0,1,16,0,64,64,0,0,0,100.79,52.36l-10.88-12A48,48,0,0,1,80,128V87.09L42.08,45.38A8,8,0,1,1,53.92,34.62l160,176A8,8,0,0,1,213.38,221.92Zm-51.3-92.11A8,8,0,0,0,176,124.43V64A48,48,0,0,0,87.16,38.78,8,8,0,0,0,88,48.37Zm30.1,31.83a8,8,0,0,0,10.36-4.55A79.62,79.62,0,0,0,208,128a8,8,0,0,0-16,0,63.71,63.71,0,0,1-4.36,23.27A8,8,0,0,0,192.18,161.64Z" }, null, -1)
              ]), 10, _hoisted_2))
        ], 64))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          (_ctx.enabled)
            ? (_openBlock(), _createElementBlock("svg", {
                key: 0,
                class: _normalizeClass([_ctx.iconClass]),
                xmlns: "http://www.w3.org/2000/svg",
                width: _ctx.width,
                height: _ctx.height,
                fill: "currentcolor",
                viewBox: "0 0 256 256"
              }, _cache[2] || (_cache[2] = [
                _createElementVNode("path", { d: "M128,176a48.05,48.05,0,0,0,48-48V64a48,48,0,0,0-96,0v64A48.05,48.05,0,0,0,128,176ZM96,64a32,32,0,0,1,64,0v64a32,32,0,0,1-64,0Zm40,143.6V232a8,8,0,0,1-16,0V207.6A80.11,80.11,0,0,1,48,128a8,8,0,0,1,16,0,64,64,0,0,0,128,0,8,8,0,0,1,16,0A80.11,80.11,0,0,1,136,207.6Z" }, null, -1)
              ]), 10, _hoisted_3))
            : (_openBlock(), _createElementBlock("svg", {
                key: 1,
                class: _normalizeClass([_ctx.iconClass]),
                xmlns: "http://www.w3.org/2000/svg",
                width: _ctx.width,
                height: _ctx.height,
                fill: "currentcolor",
                viewBox: "0 0 256 256"
              }, _cache[3] || (_cache[3] = [
                _createElementVNode("path", { d: "M213.92,210.62l-160-176A8,8,0,1,0,42.08,45.38L80,87.09V128a48,48,0,0,0,73.91,40.4l10.88,12A64,64,0,0,1,64,128a8,8,0,0,0-16,0,80.11,80.11,0,0,0,72,79.6V232a8,8,0,0,0,16,0V207.59a79.74,79.74,0,0,0,39.62-15.31l26.46,29.1a8,8,0,1,0,11.84-10.76ZM128,160a32,32,0,0,1-32-32V104.69l46.92,51.62A32,32,0,0,1,128,160ZM87.16,38.78A48,48,0,0,1,176,64v60.43a8,8,0,0,1-16,0V64a32,32,0,0,0-59.24-16.81,8,8,0,1,1-13.6-8.41ZM187.64,151.27A63.71,63.71,0,0,0,192,128a8,8,0,0,1,16,0,79.62,79.62,0,0,1-5.46,29.09,8,8,0,1,1-14.9-5.82Z" }, null, -1)
              ]), 10, _hoisted_4))
        ], 64))
  ]))
}
}

})