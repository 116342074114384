import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "lg flex flex-col" }
const _hoisted_2 = ["src"]
const _hoisted_3 = {
  key: 0,
  class: "focus-word prevent-user-select"
}
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "bg-white border-t border-t-slate-200 text-slate-600 px-6 flex justify-between items-center gap-x-1.5 text-center py-2 text-sm prevent-user-select" }
const _hoisted_6 = { class: "flex justify-center items-center gap-x-2 text-center" }
const _hoisted_7 = { class: "flex items-center space-x-2.5 transition-opacity" }
const _hoisted_8 = { class: "flex items-center space-x-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SwiperSlide = _resolveComponent("SwiperSlide")!
  const _component_LessonNav = _resolveComponent("LessonNav")!
  const _component_Alert = _resolveComponent("Alert")!
  const _component_Swiper = _resolveComponent("Swiper")!
  const _component_MagnifyingGlassMinusIcon = _resolveComponent("MagnifyingGlassMinusIcon")!
  const _component_MagnifyingGlassPlusIcon = _resolveComponent("MagnifyingGlassPlusIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Swiper, {
      initialSlide: _ctx.initialImageIndex,
      class: "swiper-container lg-slider flex-1",
      onSlideChange: _ctx.onSwiperChange,
      modules: [_ctx.Thumbs, _ctx.Zoom],
      thumbs: { swiper: _ctx.thumbsSwiper },
      zoom: { maxRatio: 4 },
      onZoomChange: _ctx.onZoomChange,
      onSwiper: _ctx.onSwiper,
      onWheel: _ctx.onWheel
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, ({ id, imageUrl }) => {
          return (_openBlock(), _createBlock(_component_SwiperSlide, {
            class: "swiper-slide prevent-user-select",
            zoom: true,
            oncontextmenu: "return false",
            key: id
          }, {
            default: _withCtx(() => [
              _createElementVNode("img", {
                src: imageUrl,
                alt: ""
              }, null, 8, _hoisted_2)
            ]),
            _: 2
          }, 1024))
        }), 128)),
        _createVNode(_component_LessonNav, {
          class: "back",
          isNext: false
        }),
        _createVNode(_component_LessonNav, {
          class: "next",
          isNext: true
        }),
        (_ctx.focusWordContent.condition)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_Alert, {
                message: _ctx.focusWordContent.word,
                description: _ctx.focusWordContent.definition,
                type: "info"
              }, null, 8, ["message", "description"])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["initialSlide", "onSlideChange", "modules", "thumbs", "onZoomChange", "onSwiper", "onWheel"]),
    _createVNode(_component_Swiper, {
      initialSlide: _ctx.initialImageIndex,
      class: "swiper-container lg-thumbs h-[20%]",
      modules: [_ctx.Thumbs],
      "watch-slides-progress": "",
      onSwiper: _ctx.setThumbsSwiper,
      "slides-per-view": 6,
      "space-between": 5,
      threshold: 11,
      "slide-to-clicked-slide": true
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, ({ id, imageUrl }) => {
          return (_openBlock(), _createBlock(_component_SwiperSlide, {
            class: "lg-thumbs__item prevent-user-select",
            key: id
          }, {
            default: _withCtx(() => [
              _createElementVNode("img", {
                src: imageUrl,
                alt: ""
              }, null, 8, _hoisted_4)
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      _: 1
    }, 8, ["initialSlide", "modules", "onSwiper"]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.progress), 1),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("span", null, _toDisplayString(_ctx.zoomPercentage) + "%", 1),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", {
            class: "h-6 w-6 rounded-lg bg-slate-100 hover:bg-white hover:shadow flex justify-center items-center",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.zoomOut && _ctx.zoomOut(...args)))
          }, [
            _createVNode(_component_MagnifyingGlassMinusIcon, { class: "cursor-pointer h-4 w-4" })
          ]),
          _createElementVNode("div", {
            class: "h-6 w-6 rounded-lg bg-slate-100 hover:bg-white hover:shadow flex justify-center items-center",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.zoomIn && _ctx.zoomIn(...args)))
          }, [
            _createVNode(_component_MagnifyingGlassPlusIcon, { class: "cursor-pointer h-4 w-4" })
          ])
        ])
      ])
    ])
  ]))
}