import { StudentRoomManager } from "@/manager/room/student.manager";
import { RoomSessionModel, ClassModel, RoomModel, StudentModel } from "@/models";
import { GLApiStatus, GLError } from "@/models/error.model";
import { HelperModel } from "@/models/helper.model";
import { RoomOptions } from "@/manager/room/base.manager";
import { UserModel } from "@/models/user.model";
import { VCPlatform } from "@/store/app/state";
import { MutationTree } from "vuex";
import {
  CallingConnectionStatus,
  HelperInClassStatus,
  InClassStatus,
  OnTeacherToggleStudentPalette,
  OnTeacherUpdateStudentBadgeItem,
} from "../interface";
import { ClassAction, ClassActionFromValue, StudentRoomState, initState } from "./state";
import { cloneDeep } from "lodash";
import { ToggleStudentMediaDevicesAsync } from "@/services";

export interface SetStudentAudioMutation {
  id: string;
  enable: boolean;
}

const mutations: MutationTree<StudentRoomState> = {
  setInitClassLoading(s: StudentRoomState, loading: boolean) {
    s.initClassLoading = loading;
  },
  setIsSessionEnded(s: StudentRoomState, loading: boolean) {
    s.isSessionEnded = loading;
  },
  setCameraLock(
    state: StudentRoomState,
    payload: {
      enable: boolean;
    },
  ) {
    state.cameraLock = payload.enable;
  },
  setMicrophoneLock(
    state: StudentRoomState,
    payload: {
      enable: boolean;
    },
  ) {
    state.microphoneLock = payload.enable;
  },
  setGlobalAudios(state: StudentRoomState, payload: Array<string>) {
    state.globalAudios = payload;
  },
  // student exit or class end => reset the state
  leaveRoom(state: StudentRoomState, _payload: any) {
    Object.assign(state, initState());
  },
  setError(state: StudentRoomState, payload: GLError | null) {
    state.error = payload;
  },
  setApiStatus(state: StudentRoomState, payload: GLApiStatus | null) {
    state.apiStatus = payload;
  },
  setClasses(state: StudentRoomState, payload: Array<ClassModel>) {
    state.classes = payload;
  },
  setUser(state: StudentRoomState, payload: UserModel) {
    state.user = payload;
  },
  setRoomInfo(state: StudentRoomState, room: RoomSessionModel) {
    const blockStudentUpdate = room.options?.isActionUpToDate;
    state.idOne = room.studentOneToOne ? room.studentOneToOne : "";
    state.idHelperOne = room.studentHelperOneToOne ? room.studentHelperOneToOne : "";
    state.teacher = {
      id: room.teacher.id,
      name: room.teacher.name,
      avatar: state.teacher?.avatar || "",
      audioEnabled: !room.teacher.isMuteAudio,
      videoEnabled: !room.teacher.isMuteVideo,
      status: room.teacher.connectionStatus,
      callingConnectionStatus: state.teacher ? state.teacher.callingConnectionStatus : room.teacher.callingConnectionStatus,
      disconnectTime: room.teacher.disconnectTime ? Date.now() - room.teacher.disconnectTime : null,
      signalRConnectionId: room.teacher.signalrConnectId ?? null,
    };
    if (room.helper) {
      state.helper = {
        id: room.helper.id,
        name: room.helper.name,
        avatar: state.helper?.avatar || "",
        audioEnabled: !room.helper.isMuteAudio,
        videoEnabled: !room.helper.isMuteVideo,
        connectionStatus: room.helper.connectionStatus,
        callingConnectionStatus: state.helper ? state.helper.callingConnectionStatus : room.helper.callingConnectionStatus,
        isVideoShownByTeacher: room.helper.isVideoShownByTeacher,
        signalRConnectionId: room.helper.signalrConnectId ?? null,
      };
    } else {
      state.helper = undefined;
    }
    state.students = [];
    for (const st of room.students) {
      const student = {
        id: st.id,
        name: st.name,
        englishName: st.englishName,
        avatar: "",
        audioEnabled: !st.isMuteAudio,
        videoEnabled: !st.isMuteVideo,
        badge: st.badge,
        status: st.connectionStatus,
        callingConnectionStatus: st.callingConnectionStatus,
        index: state.students.length,
        raisingHand: st.isRaisingHand,
        isPalette: st.isPalette,
        imageCapturedCount: st.imageCapturedCount,
      };
      if (st.id === state.user?.id) {
        // No need to up-to-date myself when this mutation is called from the action "upToDateSessionAfterSignalRConnected"
        if (!blockStudentUpdate) {
          student.index = 999;
          state.student = student;
        }
      } else {
        state.students.push(student);
      }
    }
    state.info = room;
    const role = "audience";
    if (!state.manager) {
      const studentRoomManagerParams: RoomOptions =
        room.videoPlatformProvider === VCPlatform.Agora
          ? {
              agora: {
                appId: room.streamInfo.appId,
                webConfig: { mode: "rtc", codec: "vp8", role: role },
                user: {
                  channel: room.streamInfo.chanelId,
                  username: room.streamInfo.userId,
                  token: room.streamInfo.token,
                  role,
                },
              },
            }
          : {
              zoom: {
                user: {
                  channel: room.streamInfo.chanelId,
                  username: room.streamInfo.userId,
                  token: room.streamInfo.token,
                  role,
                },
              },
            };
      state.manager = new StudentRoomManager(studentRoomManagerParams);
    }
    state.classAction = ClassActionFromValue(room.lessonPlan.lessonAction);
  },
  setStudentAudio(state: StudentRoomState, payload: SetStudentAudioMutation) {
    const student = payload.id === state.student?.id ? state.student : state.students.find((st) => st.id === payload.id);
    if (student) student.audioEnabled = payload.enable;
  },
  toggleOtherStudentMicrophones(s: StudentRoomState, p: ToggleStudentMediaDevicesAsync): void {
    const { isMute, studentIds } = p;
    s.students.filter((student) => studentIds.includes(student.id)).forEach((student) => (student.audioEnabled = !isMute));
  },
  toggleOtherStudentCameras(s: StudentRoomState, p: ToggleStudentMediaDevicesAsync): void {
    const { isMute, studentIds } = p;
    s.students.filter((student) => studentIds.includes(student.id)).forEach((student) => (student.videoEnabled = !isMute));
  },
  setStudentConnectionStatus(state: StudentRoomState, payload: { id: string; status: InClassStatus }) {
    const student = payload.id === state.student?.id ? state.student : state.students.find((st) => st.id === payload.id);
    if (student) student.status = payload.status;
  },
  setStudentMediaStatus(s: StudentRoomState, p: StudentModel): void {
    if (s.student?.id === p.id) {
      s.student.videoEnabled = !p.isMuteVideo;
      s.student.audioEnabled = !p.isMuteAudio;
    }
    const student = s.students.find((student) => student.id === p.id);
    if (student) {
      student.videoEnabled = !p.isMuteVideo;
      student.audioEnabled = !p.isMuteAudio;
    }
  },
  updateRaisingHand(state: StudentRoomState, payload: { id: string; isRaisingHand: boolean }) {
    const student = payload.id === state.student?.id ? state.student : state.students.find((st) => st.id === payload.id);
    if (student) {
      student.raisingHand = payload.isRaisingHand;
    }
  },
  updateIsPalette(state: StudentRoomState, payload: { id: string; isPalette: boolean }) {
    const student = payload.id === state.student?.id ? state.student : state.students.find((st) => st.id === payload.id);
    if (student) {
      student.isPalette = payload.isPalette;
    }
  },
  clearCircleStatus(state: StudentRoomState, payload: { id: string }) {
    const student = payload.id === state.student?.id ? state.student : state.students.find((st) => st.id === payload.id);
    if (student) {
      student.raisingHand = false;
      student.isPalette = false;
    }
  },
  setStudentVideo(state: StudentRoomState, payload: { id: string; enable: boolean }) {
    const student = payload.id === state.student?.id ? state.student : state.students.find((st) => st.id === payload.id);
    if (student) student.videoEnabled = payload.enable;
  },
  setStudentsBadge(state: StudentRoomState, payload: OnTeacherUpdateStudentBadgeItem[]) {
    payload.forEach((item) => {
      const student = item.id === state.student?.id ? state.student : state.students.find((st) => st.id === item.id);
      if (student) student.badge = item.badge;
    });
  },
  setTeacherAudio(state: StudentRoomState, payload: { id: string; enable: boolean }) {
    if (state.teacher?.id === payload.id) state.teacher.audioEnabled = payload.enable;
  },
  setTeacherVideo(state: StudentRoomState, payload: { id: string; enable: boolean }) {
    if (state.teacher?.id === payload.id) state.teacher.videoEnabled = payload.enable;
  },
  setTeacherConnectionStatus(state: StudentRoomState, payload: { id: string; status: InClassStatus }) {
    if (state.teacher?.id === payload.id) state.teacher.status = payload.status;
  },
  setStudentPalette(state: StudentRoomState, payload: OnTeacherToggleStudentPalette) {
    // clear all palette status
    if (payload.isPalette) {
      state.students.forEach((student) => {
        student.isPalette = false;
      });
    }
    // set palette status for student
    const student = payload.id === state.student?.id ? state.student : state.students.find((st) => st.id === payload.id);
    if (student) student.isPalette = payload.isPalette;
  },
  disableAllAnnotationStatus(state: StudentRoomState, p: any) {
    state.student ? (state.student.isPalette = !p) : null;
    state.students.forEach((student) => (student.isPalette = !p));
  },
  setClassAction(state: StudentRoomState, payload: { action: ClassAction }) {
    state.classAction = payload.action;
  },
  setStudentOneId(s: StudentRoomState, p: { id: string; withTeacher: boolean }) {
    if (p.withTeacher) s.idOne = p.id;
    else s.idHelperOne = p.id;
  },
  setSpeakingUsers(state: StudentRoomState, payload: { userIds: Array<string> }) {
    state.speakingUsers = payload.userIds;
  },
  setStudentRaisingHand(state: StudentRoomState, payload: { id: string; raisingHand: boolean }) {
    if (!payload.id && state.student?.id) {
      payload.id = state.student.id;
    }
    const student = payload.id === state.student?.id ? state.student : state.students.find((st) => st.id === payload.id);
    if (student) student.raisingHand = payload.raisingHand;
  },
  setWhiteboard(state: StudentRoomState, payload: boolean) {
    state.showWhiteBoard = payload;
  },
  setMediaState(state: StudentRoomState, payload: any) {
    state.mediaState = payload;
  },
  setCurrentTimeMedia(state: StudentRoomState, payload: any) {
    state.currentTimeMedia = payload;
  },
  setOnline(state: StudentRoomState) {
    state.isDisconnected = false;
  },
  setOffline(state: StudentRoomState) {
    state.isDisconnected = true;
  },
  setIsJoined(state: StudentRoomState, p: { isJoined: boolean }) {
    state.isJoined = p.isJoined;
  },
  setTeacherDisconnected(state: StudentRoomState, p: boolean) {
    state.teacherIsDisconnected = p;
    if (!p && state.teacher) {
      state.teacher.disconnectTime = null;
    }
  },
  setAvatarTeacher(state: StudentRoomState, p: string) {
    if (state.teacher) state.teacher.avatar = p;
  },
  setAvatarHelper(state: StudentRoomState, p: string) {
    if (state.helper) state.helper.avatar = p;
  },
  toggleVideosFeed(state: StudentRoomState) {
    state.videosFeedVisible = !state.videosFeedVisible;
  },
  setStudentImageCaptured(s: StudentRoomState, p: { id: string; capture: boolean }) {
    s.startCaptureImage = p.capture;
  },
  setStudentImageCapturedCount(s: StudentRoomState, p: number) {
    if (s.student) {
      s.student.imageCapturedCount = p;
    }
  },
  setHelperInfo(s: StudentRoomState, p: HelperModel | undefined) {
    if (!p) {
      s.helper = p;
      return;
    }
    s.helper = {
      id: p.id,
      name: p.name,
      avatar: s.helper?.avatar || "",
      audioEnabled: !p.isMuteAudio,
      videoEnabled: !p.isMuteVideo,
      connectionStatus: p.connectionStatus,
      callingConnectionStatus: p.callingConnectionStatus,
      isVideoShownByTeacher: p.isVideoShownByTeacher,
      signalRConnectionId: p.signalrConnectId,
    };
  },
  setHelperConnectionStatus(s: StudentRoomState, p: HelperInClassStatus) {
    if (s.helper) {
      s.helper.connectionStatus = p;
    }
  },
  setHelperCallingConnectionStatus(s: StudentRoomState, p: CallingConnectionStatus) {
    if (s.helper) {
      s.helper.callingConnectionStatus = p;
    }
  },
  setHelperVideoStatus(s: StudentRoomState, p: boolean) {
    if (s.helper) {
      s.helper.isVideoShownByTeacher = p;
    }
  },
  setHelperCameraStatus(s: StudentRoomState, isOff: boolean) {
    if (s.helper) {
      s.helper.videoEnabled = !isOff;
    }
  },
  setHelperMicroStatus(s: StudentRoomState, isOff: boolean) {
    if (s.helper) {
      s.helper.audioEnabled = !isOff;
    }
  },
  makeHelperTheNewTeacher(s: StudentRoomState) {
    if (s.helper) {
      s.teacher = {
        ...cloneDeep(s.helper),
        status: InClassStatus.JOINED,
      };
      s.helper = undefined;
    }
  },
};

export default mutations;
