<template>
  <div v-if="isFetchingTeams" class="placeholder" :tip="MsgFetchingTeams">
    <Spin />
  </div>
  <div v-else class="container m-auto">
    <Draggable class="students border-2 border-dashed border-slate-200 mb-4 rounded-md" :list="nonTeamStudents" group="people" itemKey="name">
      <template #item="{ element: student }">
        <Member :member="student" />
      </template>
    </Draggable>
    <Draggable class="grid grid-flow-col gap-2 auto-cols-fr" :list="teams" group="teams" itemKey="name">
      <template #item="{ element: team }">
        <Team :team="team" @handle-del="handleDel" @handle-edit="handleEdit" @handle-mute="handleMute" />
      </template>
    </Draggable>
    <Modal
      :visible="createModalOpened"
      :title="createTeamState.isEdit ? MsgEditTeam : MsgNewTeam"
      @ok="handleSubmit"
      @cancel="toggleCreateTeamModal(false)"
      :zIndex="999"
    >
      <div class="flex">
        <div class="flex-1 mt-1.5 text-slate-600">{{ MsgTeamName }}</div>
        <div class="min-h-[60px] w-[350px]">
          <Input ref="teamNameInputEl" v-model:value="createTeamState.name" :maxlength="20" @press-enter.prevent="handleSubmit" />
          <div v-if="errors.name && !createTeamState.name.trim()" class="text-red-600">{{ errors.name }}</div>
        </div>
      </div>
      <div class="flex">
        <div class="flex-1 text-slate-600">{{ MsgTeamColor }}</div>
        <div class="w-[350px]">
          <ColorPicker is-widget format="rgb" :shape="'circle'" disable-history disable-alpha v-model:pureColor="createTeamState.color" />
        </div>
      </div>
    </Modal>
  </div>
</template>
<style lang="scss" scoped src="./teams.scss"></style>
<script lang="ts" src="./teams.ts"></script>
