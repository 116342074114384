import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["cursor-pointer z-[1] absolute top-1/2 -translate-y-1/2 w-12 h-12 bg-gs-purple rounded-full flex justify-center items-center opacity-80 hover:opacity-100 transition-opacity", _ctx.isNext ? 'right-[2%]' : 'left-[2%]']),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.moveSlide && _ctx.moveSlide(...args)))
  }, [
    (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.isNext ? _ctx.ChevronRightIcon : _ctx.ChevronLeftIcon), { class: "w-9 h-9 text-white" }))
  ], 2))
}