/**
 * This function is used to get the full name of an action.
 *
 * @param {string} name - The name of the action, expected to be in the format `module/action`.
 * @param {boolean} [withModuleName=false] - A boolean indicating whether the module name should be included in the returned string.
 *
 * @returns {string} If `withModuleName` is true, the function returns the `name` as it is. If `withModuleName` is false, the function returns the `name` without the module prefix.
 */
export const vuexName = (name: string, withModuleName = true): string => (withModuleName ? name : name.split("/").slice(1).join("/"));

export const defineVuexName = (name: string) => name.split("/").slice(1).join("/");

const createModuleActions = (module: string) => {
  return function createAction(action: string): string {
    return `${module}/${action}`;
  };
};

const createLessonAction = createModuleActions("lesson");
const createClassTeachingAction = createModuleActions("classTeaching");

export const VuexNames = {
  LESSON: {
    DISPATCHES: {
      PROCESS_VISIBLE_TARGETS_DATA_FROM_API: createLessonAction("PROCESS_VISIBLE_TARGETS_DATA_FROM_API"),
      PROCESS_VISIBLE_TARGET_BY_FROM_MESSAGE: createLessonAction("PROCESS_VISIBLE_TARGET_BY_FROM_MESSAGE"),
      TOGGLE_VISIBLE_TARGET: createLessonAction("TOGGLE_VISIBLE_TARGET"),
      TOGGLE_ALL_VISIBLE_TARGETS: createLessonAction("TOGGLE_ALL_VISIBLE_TARGETS"),
      REQUEST_TOGGLE_TARGET: createLessonAction("REQUEST_TOGGLE_TARGET"),
      REQUEST_TOGGLE_ALL_TARGET: createLessonAction("REQUEST_TOGGLE_ALL_TARGET"),
      // more here ...
    },
    COMMITS: {
      SET_VISIBLE_TARGET_TAGS: createLessonAction("SET_VISIBLE_TARGET_TAGS"),
      // more here ...
    },
    GETTERS: {
      GET_VISIBLE_TARGET_TAGS: createLessonAction("GET_VISIBLE_TARGET_TAGS"),
      // more here ...
    },
  },
  TEACHER_ROOM: {
    DISPATCHES: {
      // more here ...
    },
  },
  CLASS_TEACHING: {
    COMMITS: {
      SET_INITIAL_CLASS_DATA_SNAPSHOT: createClassTeachingAction("SET_INITIAL_CLASS_DATA_SNAPSHOT"),
      SET_IS_SOCKET_ATTEMPTED_RECONNECTING: createClassTeachingAction("SET_IS_SOCKET_ATTEMPTED_RECONNECTING"),
      SET_IS_INIT_CLASS_FAILED: createClassTeachingAction("SET_IS_INIT_CLASS_FAILED"),
      // more here ...
    },
    GETTERS: {
      GET_INITIAL_CLASS_DATA_SNAPSHOT_EXPOSURE_ID: createClassTeachingAction("GET_INITIAL_CLASS_DATA_SNAPSHOT_EXPOSURE_ID"),
      GET_IS_SOCKET_ATTEMPTED_RECONNECTING: createClassTeachingAction("GET_IS_SOCKET_ATTEMPTED_RECONNECTING"),
      GET_IS_INIT_CLASS_FAILED: createClassTeachingAction("GET_IS_INIT_CLASS_FAILED"),
      // more here ...
    },
  },
};
