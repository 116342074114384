import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "bg-white rounded-lg flex justify-around gap-x-1.5 py-2 px-6 shadow" }
const _hoisted_2 = { class: "grid grid-cols-2 gap-2.5" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "relative flex justify-center items-center rounded-lg cursor-pointer mr-2.5" }
const _hoisted_5 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Popover = _resolveComponent("Popover")!
  const _component_Tooltip = _resolveComponent("Tooltip")!
  const _component_CustomSelect = _resolveComponent("CustomSelect")!
  const _component_CloseSquareIcon = _resolveComponent("CloseSquareIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Tooltip, {
      title: _ctx.MsgColor,
      overlayClassName: [_ctx.showListColors && 'opacity-0 pointer-events-none', 'transition-opacity']
    }, {
      default: _withCtx(() => [
        _createVNode(_component_Popover, {
          visible: _ctx.showListColors,
          "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showListColors) = $event)),
          trigger: "click blur"
        }, {
          content: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.colorsList, (color, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: index,
                  style: _normalizeStyle(`background-color: ${color}`),
                  class: "w-5 h-5 rounded-full shadow cursor-pointer transition-transform border border-slate-200 hover:scale-125",
                  onClick: ($event: any) => {
              _ctx.updateColor(color);
              _ctx.hideListColors();
            }
                }, null, 12, _hoisted_3))
              }), 128))
            ])
          ]),
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("img", {
                src: require(`@/assets/tools/ColorSelectScribble.svg`),
                alt: "Color Icon"
              }, null, 8, _hoisted_5),
              _createElementVNode("div", {
                class: "absolute w-3 h-3 bottom-1 right-0 border border-slate-200 rounded-full shadow",
                style: _normalizeStyle(`background-color: ${_ctx.controlTextState.fill}`)
              }, null, 4)
            ])
          ]),
          _: 1
        }, 8, ["visible"])
      ]),
      _: 1
    }, 8, ["title", "overlayClassName"]),
    _createVNode(_component_CustomSelect, {
      class: "w-60",
      selectedItem: _ctx.selectedFont,
      "onUpdate:selectedItem": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedFont) = $event)),
      items: _ctx.fontFamilies
    }, null, 8, ["selectedItem", "items"]),
    _createVNode(_component_CustomSelect, {
      class: "w-24",
      selectedItem: _ctx.selectedSize,
      "onUpdate:selectedItem": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedSize) = $event)),
      items: _ctx.fontSizes
    }, null, 8, ["selectedItem", "items"]),
    _createVNode(_component_Tooltip, {
      title: _ctx.MsgClose,
      placement: "bottom"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          class: "h-8 w-8 text-gs-purple cursor-pointer flex items-center ml-0.5 justify-end self-center",
          onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
        }, [
          _createVNode(_component_CloseSquareIcon)
        ])
      ]),
      _: 1
    }, 8, ["title"])
  ]))
}