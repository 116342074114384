import { MutationTree } from "vuex";
import { CallingState, initCallingModuleState } from "./state";
export enum CallingUserStatus {
  LEFT,
  JOINED,
}
const mutations: MutationTree<CallingState> = {
  setCallingUserIds(state: CallingState, { id, status }: { id: string; status: CallingUserStatus }) {
    const index = state.callingUserIds.findIndex((remoteId) => remoteId === id);
    if (status === CallingUserStatus.LEFT) {
      return index > -1 && state.callingUserIds.splice(index, 1);
    }
    index === -1 && state.callingUserIds.push(id);
  },
  resetState(s: CallingState) {
    if (s.postLocalUserReconnectedIntervalId) {
      clearInterval(s.postLocalUserReconnectedIntervalId);
    }
    Object.assign(s, initCallingModuleState());
  },
  setEnabledAgoraNoiseCancelling(s: CallingState, enabled: boolean) {
    s.enabledAgoraNoiseCancelling = enabled;
  },
  setPostLocalUserReconnectedIntervalId(s: CallingState, intervalId: number) {
    s.postLocalUserReconnectedIntervalId = intervalId;
  },
};

export default mutations;
