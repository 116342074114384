import { GLServiceBase } from "vue-glcommonui";
export interface FetchContentSignatureRespModel {
  url: string;
  data: ContentSignatureItem[];
}

export interface ContentSignatureItem {
  container: string;
  signature: string;
  type: number;
}

export class GLContentService extends GLServiceBase<any, any> {
  serviceRoute = { prefix: "content/v2" };
  fetchContentSignature(): Promise<FetchContentSignatureRespModel> {
    const qs = {
      isAudio: true,
      isPage: true,
    };
    return this.get("versions/remote/contentsignature", qs);
  }
}

export const ContentService = new GLContentService();
