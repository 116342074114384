import { useRoute } from "vue-router";
import { computed } from "vue";

export const useClientSide = () => {
  const route = useRoute();
  const isTeacherSide = computed<boolean>(() => route.name === "TeacherClass");
  return {
    isTeacherSide,
  };
};
