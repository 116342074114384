import { vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = ["id"]
const _hoisted_3 = ["id"]
const _hoisted_4 = { class: "w-full h-full flex justify-center items-center" }
const _hoisted_5 = ["alt", "src"]
const _hoisted_6 = ["title"]
const _hoisted_7 = {
  key: 0,
  class: "flex-1 truncate"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InOneIcon = _resolveComponent("InOneIcon")!
  const _component_WifiIcon = _resolveComponent("WifiIcon")!
  const _component_MicrophoneIcon = _resolveComponent("MicrophoneIcon")!
  const _component_IndependentProgress = _resolveComponent("IndependentProgress")!
  const _component_StudentCardActions = _resolveComponent("StudentCardActions")!

  return (!_ctx.hiddenByOneOneMode)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["relative rounded-xl group bg-white overflow-hidden", [
      'w-full transition-all',
      _ctx.focusedStudent && 'z-[15] shadow',
      _ctx.isOneToOneStudent && _ctx.oneAndOne && 'one-student-mode animate__animated animate__zoomIn',
      !_ctx.isOneToOneStudent && _ctx.oneAndOne && 'animate__animated animate__zoomOut',
      !_ctx.isTurnOnCamera && 'shadow',
    ]]),
        id: _ctx.student.id + '__sub-wrapper',
        onMouseleave: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onMouseChange(false))),
        ref: "studentRef",
        style: _normalizeStyle({
      transform: _ctx.focusedStudent && !_ctx.isOneToOneStudent ? `scale(${_ctx.actualScaleRatio})` : '',
    })
      }, [
        _createElementVNode("div", {
          class: "relative aspect-w-16 aspect-h-9",
          id: _ctx.student.id + '__wrapper'
        }, [
          _createElementVNode("div", {
            class: "w-full h-full",
            onMouseover: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onMouseChange(true)))
          }, [
            _withDirectives(_createElementVNode("div", {
              class: "w-full h-full flex justify-center [&_canvas]:object-cover",
              id: _ctx.student.id,
              ref: "agoraVideoContainerRef"
            }, null, 8, _hoisted_3), [
              [_vShow, _ctx.isTurnOnCamera && !_ctx.isStudentOneToOneWithAnotherTeacher]
            ]),
            _withDirectives(_createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", {
                class: _normalizeClass([[_ctx.scaleOption === 1 ? 'w-36 h-36' : 'w-24 h-24'], "rounded-full overflow-hidden border-2 border-white shadow"])
              }, [
                _createElementVNode("img", {
                  class: _normalizeClass(['w-full h-full object-cover']),
                  alt: _ctx.student.englishName,
                  src: _ctx.avatarUrl
                }, null, 8, _hoisted_5)
              ], 2)
            ], 512), [
              [_vShow, !_ctx.isTurnOnCamera || _ctx.isStudentOneToOneWithAnotherTeacher]
            ]),
            (_ctx.borderLayers[0])
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: _normalizeClass(['absolute inset-0 rounded-xl border-4', _ctx.borderLayers[0]])
                }, null, 2))
              : _createCommentVNode("", true),
            (_ctx.borderLayers[1])
              ? (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  class: _normalizeClass(['absolute inset-1 rounded-lg border-4', _ctx.borderLayers[1]])
                }, null, 2))
              : _createCommentVNode("", true),
            (_ctx.borderLayers[2])
              ? (_openBlock(), _createElementBlock("div", {
                  key: 2,
                  class: _normalizeClass(['absolute inset-2 rounded border-4', _ctx.borderLayers[2]])
                }, null, 2))
              : _createCommentVNode("", true),
            (_ctx.isStudentOneToOneWithAnotherTeacher)
              ? (_openBlock(), _createBlock(_component_InOneIcon, {
                  key: 3,
                  class: "absolute right-3 top-2 w-8 h-8"
                }))
              : _createCommentVNode("", true),
            (_ctx.isLowBandWidth)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 4,
                  class: _normalizeClass(["absolute right-3 z-[1]", [_ctx.isIndependentMode ? 'bottom-8' : 'bottom-2']])
                }, [
                  _createVNode(_component_WifiIcon, { class: "animate-pulse h-8 w-8 text-red-600" })
                ], 2))
              : _createCommentVNode("", true),
            _createElementVNode("div", {
              class: _normalizeClass(["absolute bottom-2 inset-x-3 space-y-1", [_ctx.focusedStudent && !_ctx.isOneToOneStudent && 'origin-bottom-left']]),
              onMouseover: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onMouseChange(true))),
              style: _normalizeStyle({
            transform: _ctx.focusedStudent && !_ctx.isOneToOneStudent ? `scale(${1 / _ctx.actualScaleRatio})` : '',
          })
            }, [
              _createElementVNode("span", {
                class: _normalizeClass(["inline-flex space-x-1 flex-nowrap items-center max-w-[75%] text-white text-xs px-2 py-1 rounded transition bg-gs-green/80 z-[1]", ['cursor-pointer', _ctx.studentTeam && 'shadow']]),
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.enterOneToOneWithStudent && _ctx.enterOneToOneWithStudent(...args))),
                title: _ctx.student.englishName,
                style: _normalizeStyle([_ctx.studentTeam && `background: ${_ctx.studentTeam.color}; color: ${_ctx.calColorByBackground(_ctx.studentTeam.color)}`])
              }, [
                _createElementVNode("span", {
                  class: _normalizeClass(["truncate max-w[90%]", [_ctx.studentTeam && 'font-bold']])
                }, _toDisplayString(_ctx.student.englishName), 3),
                (_ctx.studentTeam)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_7, " | " + _toDisplayString(_ctx.studentTeam?.name), 1))
                  : _createCommentVNode("", true),
                (!_ctx.student?.audioEnabled)
                  ? (_openBlock(), _createBlock(_component_MicrophoneIcon, {
                      key: 1,
                      "icon-class": "h-4 w-4",
                      class: "flex-shrink-0"
                    }))
                  : _createCommentVNode("", true)
              ], 14, _hoisted_6),
              (_ctx.isIndependentMode && _ctx.student && !_ctx.oneAndOne)
                ? (_openBlock(), _createBlock(_component_IndependentProgress, {
                    key: 0,
                    studentId: _ctx.student.id
                  }, null, 8, ["studentId"]))
                : _createCommentVNode("", true)
            ], 38),
            (!_ctx.isStudentOneToOneWithAnotherTeacher)
              ? (_openBlock(), _createBlock(_component_StudentCardActions, {
                  key: 5,
                  student: _ctx.student,
                  "focus-disabled": _ctx.scaleOption === 1,
                  class: _normalizeClass(['z-[1] opacity-0 transition-opacity', _ctx.isMouseEntered && 'opacity-100']),
                  focusedStudent: _ctx.focusedStudent,
                  style: _normalizeStyle({
            transform: _ctx.focusedStudent && !_ctx.isOneToOneStudent ? `scale(${1 / _ctx.actualScaleRatio}) translateX(-${50 * _ctx.actualScaleRatio}%)` : '',
          })
                }, null, 8, ["student", "focus-disabled", "class", "focusedStudent", "style"]))
              : _createCommentVNode("", true)
          ], 32)
        ], 8, _hoisted_2)
      ], 46, _hoisted_1))
    : _createCommentVNode("", true)
}