import { GLServiceBase } from "vue-glcommonui";

export interface GenericLinkModel {
  id: string;
  site: string;
  location: string;
  localText: string;
  englishText: string;
  link: string;
}

export class AdminService extends GLServiceBase<any, any> {
  serviceRoute: any = { prefix: "admin/v1" };

  fetchGenericLinkConnect(): Promise<GenericLinkModel[]> {
    return this.get("generic-link/site/connect");
  }
}

export const GLAdminService = new AdminService();
