import * as audioSource from "@/utils/audioGenerator";
import { Modal } from "ant-design-vue";
import { computed, watch } from "vue";
import { fmtMsg } from "vue-glcommonui";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { LostNetwork } from "@/locales/localeid";
const STUDENT_LEAVE_ROOM_TIMING = 6000 * 10 * 5;
const TEACHER_LEAVE_ROOM_TIMING = 6000 * 10 * 3;
const TEACHER_PATH_REGEX = /\/teacher/;

export const useDisconnection = () => {
  const route = useRoute();
  const router = useRouter();
  const { getters, dispatch } = useStore();
  let timeoutId: NodeJS.Timeout;
  const studentDisconnected = computed<boolean>(() => getters["studentRoom/isDisconnected"]);
  const teacherDisconnected = computed<boolean>(() => getters["teacherRoom/isDisconnected"]);
  const messageText = computed(() => fmtMsg(LostNetwork.StudentMessage));
  //handle teacher disconnection in teacher's side
  watch(teacherDisconnected, async (isDisconnected) => {
    const pathname = window.location.pathname;
    const matchIndex = pathname.search(TEACHER_PATH_REGEX);
    if (matchIndex < 0) {
      if (isDisconnected) {
        timeoutId = setTimeout(() => {
          audioSource.teacherTryReconnectSound.stop();
          audioSource.reconnectFailedSound.play();
          router.push("/teacher");
        }, TEACHER_LEAVE_ROOM_TIMING);
        audioSource.teacherTryReconnectSound.play();
      } else {
        const { classId } = route.params;
        if (!classId) {
          window.location.reload();
        }
        if (timeoutId) {
          clearTimeout(timeoutId);
        }
        audioSource.teacherTryReconnectSound.stop();
        audioSource.reconnectSuccessSound.play();
      }
    }
  });

  //handle student disconnection
  watch(studentDisconnected, async (isDisconnected) => {
    if (isDisconnected) {
      timeoutId = setTimeout(async () => {
        audioSource.reconnectFailedSound.play();
        Modal.warning({
          content: messageText.value,
          onOk: () => {
            //
          },
        });
      }, STUDENT_LEAVE_ROOM_TIMING);
    } else {
      clearTimeout(timeoutId);
      audioSource.reconnectSuccessSound.play();
    }
  });

  watch([teacherDisconnected, studentDisconnected], async () => {
    await dispatch("calling/callingUpdateAudioAndVideoFeed", {});
  });
};
