import { determineObjectTypes, OperationTypes } from "@/utils/utils";
import { CanvasObjectModel, useSendWebSocketMsg } from "@/hooks/use-send-websocket-msg";
import { useHandleObjects } from "@/hooks/use-handle-objects";
import { useStore } from "vuex";
import { computed, watch } from "vue";
import { serializeFabricElementWithCustomAttributes } from "@/utils/fabric-utils";
import { CUSTOM_CONTROL_DELETE_ID } from "@/hooks/use-canvas";

export const useCanvasObjectUpdate = () => {
  const { getters } = useStore();
  const { removeObjectFromCanvas, editObjectFromCanvas } = useHandleObjects();
  const { requestUpdateCanvasObject } = useSendWebSocketMsg();
  const nextObjectTobeUpdated = computed<CanvasObjectModel | undefined>(() => getters["object/nextObjectTobeUpdated"]);

  const handlePressRemoveIcon = async (_: any, transform: any) => {
    // "corner": The corner of the object being interacted with
    // Some actions on the object can invoke this function, but we only want to remove the object when the corner is the delete icon
    if (transform.corner !== CUSTOM_CONTROL_DELETE_ID) return;
    await handleRemoveObjectFromCanvas(transform.target);
  };
  const handleRemoveObjectFromCanvas = async (target: any) => {
    const canvas = target.canvas;
    canvas.remove(target);
    canvas.requestRenderAll();
    const type = determineObjectTypes(target.type);
    await requestUpdateCanvasObject({ id: target.objectId, type, operation: OperationTypes.Remove });
  };

  const handleEditObjectFromCanvas = async (event: any) => {
    const target = event.target;
    const type = determineObjectTypes(target.type);
    await requestUpdateCanvasObject({
      id: target.objectId,
      type,
      operation: OperationTypes.Edit,
      metadata: JSON.stringify(serializeFabricElementWithCustomAttributes(event.target)),
    });
  };

  watch(nextObjectTobeUpdated, (val) => {
    if (val?.operation === OperationTypes.Remove) {
      removeObjectFromCanvas(val.id);
    }
    if (val?.operation === OperationTypes.Edit) {
      editObjectFromCanvas(val.id, val.metadata);
    }
  });

  return { handlePressRemoveIcon, handleEditObjectFromCanvas, handleRemoveObjectFromCanvas };
};
