import { computed } from "vue";
import { useStore } from "vuex";

export const useDevicesLocking = (isStudent = true) => {
  const module = isStudent ? "studentRoom" : "teacherRoom";
  const { getters } = useStore();
  const getLockingStatus = (device: "Camera" | "Microphone") => computed(() => getters[`${module}/is${device}Locking`]);

  return {
    isCameraLocking: getLockingStatus("Camera"),
    isMicrophoneLocking: getLockingStatus("Microphone"),
  };
};
