import { Fonts } from "@/components/common/annotation/text-control/text-control";

const sizes = [100, 96, 88, 80, 72, 64, 56, 48, 40, 36, 32, 28, 24, 20, 18, 16, 14, 12, 10, 8];
export const fontSizes = sizes.map((size, index) => ({ id: index + 1, name: size.toString(), options: {} }));
export const fontFamilies = [
  {
    id: 1,
    name: Fonts.DidactGothic,
    options: {
      fontFamily: Fonts.DidactGothic,
    },
  },
];
