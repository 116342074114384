import { useElementSize } from "@vueuse/core";
import { Ref, computed, ref } from "vue";
import { useStore } from "vuex";
export const usePointerStyle = (boardRef: Ref<HTMLDivElement | undefined>) => {
  const scaleRatio = ref(1);
  const { getters } = useStore();
  const { width: containerWidth, height: containerHeight } = useElementSize(boardRef);
  const defaultCursorSize = 0.055;
  const pointerStyle = computed(() => {
    const pointer: { x: number; y: number } = getters["annotation/pointer"];
    if (!pointer) return `display: none`;
    return `transform: translate(${pointer.x * scaleRatio.value}px, ${pointer.y * scaleRatio.value}px); width: ${
      defaultCursorSize * containerWidth.value
    }px; height: ${defaultCursorSize * containerHeight.value}px;`;
  });
  return { pointerStyle, scaleRatio };
};
