import { MutationTree } from "vuex";
import { initObjectModuleState, ObjectState } from "./state";
import { CanvasObjectModel } from "@/hooks/use-send-websocket-msg";

const mutations: MutationTree<ObjectState> = {
  resetObjectState(s: ObjectState) {
    Object.assign(s, initObjectModuleState());
  },
  setNextObjectToBeUpdated(s: ObjectState, payload: CanvasObjectModel) {
    s.nextObjectTobeUpdated = payload;
  },
};

export default mutations;
