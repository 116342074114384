<template>
  <div class="change-lesson">
    <Modal
      :zIndex="21"
      class="change-lesson__modal"
      v-model:visible="visible"
      :title="SetLessonAndUnit"
      width="600px"
      :ok-text="Ok"
      :cancel-text="Cancel"
      :ok-button-props="{
        loading,
        disabled: !currentLesson || !currentUnit || (classInfo.lesson === currentLesson && classInfo.unit === currentUnit && !isCompleted),
      }"
      @ok="onSubmit"
      @cancel="handleCancel"
    >
      <Row align="middle" class="change-lesson__mb--default">
        <div class="ant-col-24 ant-col-sm-18">
          <Space size="large" align="center">
            <Space size="small" align="center" class="text-slate-600">
              <label>{{ Unit }}</label>
              <Select :virtual="false" v-model:value="currentUnit" class="change-lesson__unit-lesson" ref="select" @change="handleUnitChange">
                <SelectOption v-for="item in unitInfo" :key="item.unit" :value="item.unit">{{ item.unit }}</SelectOption>
              </Select>
            </Space>
            <Space size="small" align="center">
              <label>{{ Lesson }}</label>
              <Select :virtual="false" v-model:value="currentLesson" class="change-lesson__unit-lesson" ref="select" @change="handleLessonChange">
                <SelectOption v-for="lesson in listLessonByUnit" :key="lesson" :value="lesson">{{ lesson }}</SelectOption>
              </Select>
            </Space>
          </Space>
        </div>
      </Row>
      <Row align="middle" class="change-lesson__mb--default">
        <div class="ant-col-24 ant-col-sm-24">
          <Checkbox v-model:checked="isCompleted" class="text-slate-600"
            >{{ MarkCurrentLessonCompleteForClass }}
            {{ classInfo?.className }}
          </Checkbox>
        </div>
      </Row>
    </Modal>
  </div>
</template>
<style lang="scss" scoped src="./change-lesson-unit.scss"></style>
<script lang="ts" src="./change-lesson-unit.ts"></script>
