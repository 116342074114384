import { notification } from "ant-design-vue";
import { AntdNotificationType } from "./type";
import { Logger } from "./logger";
import { fmtMsg } from "vue-glcommonui";
import { CommonLocale } from "@/locales/localeid";

export interface ShowNotification {
  type: AntdNotificationType;
  message: string;
  description: string;
}

export const showNotification = ({ type, message, description }: ShowNotification) => {
  switch (type) {
    case "success":
      notification.success({
        message,
        description,
      });
      break;
    case "info":
      notification.info({
        message,
        description,
      });
      break;
    case "warning":
      notification.warning({
        message,
        description,
      });
      break;
    case "error":
      notification.error({
        message,
        description,
      });
      break;
    default:
      break;
  }
};

export const showAPIErrorNotification = (error?: any) => {
  Logger.error(error);
  showNotification({
    type: AntdNotificationType.ERROR,
    message: fmtMsg(CommonLocale.RequestServerFailedTitle),
    description: fmtMsg(CommonLocale.RequesetServerFailedDesc),
  });
};
