import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Teleport as _Teleport } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Lottie = _resolveComponent("Lottie")!

  return (_ctx.toast)
    ? (_openBlock(), _createBlock(_Teleport, {
        key: 0,
        to: "body"
      }, [
        (_ctx.toast.bigIcon || _ctx.toast.icon)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(_ctx.toast.isMedal ? 'medalPosition' : 'iconPosition')
            }, [
              (_ctx.toast.bigIcon)
                ? (_openBlock(), _createBlock(_component_Lottie, {
                    key: 0,
                    options: _ctx.toast.bigIcon,
                    height: _ctx.toast.isMedal ? 140 : 70,
                    width: _ctx.toast.isMedal ? 140 : 70
                  }, null, 8, ["options", "height", "width"]))
                : (_openBlock(), _createElementBlock("img", {
                    key: 1,
                    src: _ctx.toast.icon,
                    height: 70,
                    width: 70
                  }, null, 8, _hoisted_1))
            ], 2))
          : _createCommentVNode("", true),
        (_ctx.toast.message)
          ? (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: _normalizeClass(_ctx.cssClass)
            }, [
              _createElementVNode("div", null, _toDisplayString(_ctx.toast.message), 1)
            ], 2))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}