<template>
  <div>
    <div v-if="isEmpty" class="text-slate-600 text-xs text-center">
      <Empty imageStyle="max-height: 40px" :description="NoDataText" class="hidden 2xl:block" />
      <div class="2xl:hidden mb-1">{{ NoDataText }}</div>
    </div>
    <div class="grid grid-cols-2 gap-x-2 gap-y-2">
      <div
        v-for="(item, index) in slides"
        @click="onClickItem(item.id)"
        class="relative max-w-full border rounded-lg overflow-hidden w-full bg-white aspect-w-3 aspect-h-2"
        :key="item?.id"
        :class="[
          item.id === currentExposureItemMedia?.id ? 'border-gs-purple' : 'border-slate-300',
          isHelper ? 'cursor-not-allowed' : 'cursor-pointer',
        ]"
      >
        <div v-if="item.mediaTypeId === undefined">
          <CropImage
            v-if="item.image.metaData && item.image.metaData.width > 0 && item.image.metaData.height > 0"
            :imageUrl="item.image.url"
            :metadata="item.image.metaData"
            class="w-full h-full object-contain"
          />
          <img
            v-else
            class="w-full h-full object-contain"
            :src="item.image.url"
            alt=""
            :style="[
              item.image.metaData
                ? {
                    transform: `scale(${item.image.metaData.scaleX},${item.image.metaData.scaleY}) rotate(${item.image.metaData.rotate}deg)`,
                    // if img is rotated, width equal to height of the parent div
                    width:
                      item.image.metaData &&
                      item.image.metaData.rotate &&
                      (Math.abs(item.image.metaData.rotate) === 270 || Math.abs(item.image.metaData.rotate) === 90)
                        ? '100px'
                        : '100%',
                  }
                : '',
            ]"
          />
        </div>
        <div v-else-if="item.mediaTypeId === MediaType.pdf" class="flex justify-center items-center">
          <DocumentIcon alt="" class="w-8 h-8 text-slate-600" />
        </div>
        <div v-else-if="item.mediaTypeId === MediaType.mp3" class="aspect-w-3 flex justify-center items-center">
          <div v-if="item.image?.audioBackground?.length && item.image.audioBackground[0]?.image?.url">
            <img crossorigin="anonymous" class="w-full h-full object-contain" :src="item.image.audioBackground[0].image.url" />
          </div>
          <div class="flex justify-center items-center">
            <SpeakerWaveIcon alt="" class="w-8 h-8 text-slate-600" />
          </div>
        </div>
        <div v-else-if="item.mediaTypeId === MediaType.mp4" class="aspect-w-3 flex justify-center items-center">
          <div v-if="item.image?.thumbnail">
            <img crossorigin="anonymous" class="w-full h-full object-contain" :src="item.image?.thumbnail" />
          </div>
          <div class="flex justify-center items-center">
            <FilmIcon alt="" class="w-8 h-8 text-slate-600" />
          </div>
        </div>
        <div>
          <div
            v-if="items.length > 1 || item.teacherUseOnly"
            class="absolute flex justify-center items-center text-xs 2xl:text-sm bottom-1 right-1 h-5 w-5 2xl:h-6 2xl:w-6 rounded-md text-white"
            :class="[item.teacherUseOnly ? 'bg-red-800' : 'bg-black']"
          >
            {{ index + 1 }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped src="./alternative-item.scss"></style>
<script lang="ts" src="./alternative-item.ts"></script>
