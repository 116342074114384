<template>
  <div class="w-5 h-5 cursor-help">
    <Tooltip placement="top">
      <template #title>{{ message }}</template>
      <img src="@/assets/exclamation-circle.svg" :alt="altText" />
    </Tooltip>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { Tooltip } from "ant-design-vue";
export default defineComponent({
  name: "DeviceErrorTooltip",
  props: {
    message: String,
    altText: String,
  },
  components: {
    Tooltip,
  },
});
</script>
