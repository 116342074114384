import { MediaStatus, SendRealtimePenMessage, SendPointerMessage } from "@/models";
import { GLSocketClient } from "../base";
import { HelperWSCommand as HelperWSCmd } from "../index";
import { TeacherWSCommand as WSCmd } from "./command";
import { RequestSetExposureItem } from "@/store/room/interface";
import { store } from "@/store";
import { getCurrentUTCUnixTimestamp } from "@/utils/utils";

interface TeacherJoinSessionModel {
  sessionId: string;
  deviceId: string;
  isMuteAudio?: boolean;
  isMuteVideo?: boolean;
  resolution: string;
  teacherId: string;
}

interface TeacherRequestModel {
  // teacherId can be id of teacher or helper
  teacherId?: string;
  sessionId: string;
}

interface TeacherToggleDeviceModel extends TeacherRequestModel {
  isMute: boolean;
}

interface TeacherChangeTeachingModeModel extends TeacherRequestModel {
  mode: number;
}

interface TeacherSetOneToOneModel extends TeacherRequestModel {
  studentId: string;
}

interface TeacherSetWhiteBoardModel extends TeacherRequestModel {
  isShow: boolean;
}

interface TeacherUpdateSessionLessonAndUnitModel {
  sessionId: string;
  senderId: string;
}
interface TeacherZoomSlideModel extends BoardZooming {
  sessionId: string;
}
interface TeacherMoveZoomedSlideModel extends BoardZoomingPosition {
  sessionId: string;
}
export interface FabricObject {
  fabricId: string;
  fabricData: string;
}

export interface ToggleAllTargetsModel {
  sessionId: string;
  shapes: ToggleTargetModel[];
}
export interface ToggleTargetModel {
  userId: string;
  visible: boolean;
  tag: string;
  sessionId: string;
}

export interface TeacherSetExposureModel {
  sessionId: string;
  exposureId: string;
}

export interface TeacherSetExposureItemModel extends RequestSetExposureItem {
  sessionId: string;
}

export interface TeacherSetItemContentModel extends TeacherSetExposureItemModel{
  timestamp: number; // unix
}

export interface TeacherSetBlackoutModel {
  sessionId: string;
  IsBlackOut: boolean;
}

export interface TeacherSetLessonActionModel {
  sessionId: string;
  action: number;
}

export interface TeacherSetPointerModel extends SendPointerMessage {
  sessionId: string;
}

export interface TeacherUpdateAnnotationModeModel {
  sessionId: string;
  mode: number;
}

interface RealtimePenModel {
  metadata: string;
  isOneOne: boolean;
  sessionId: string;
}

interface TeacherSetFabricModel extends FabricObject {
  sessionId: string;
}
interface TeacherToggleShapeModel extends TeacherRequestModel {
  visible: boolean;
  tag: string;
}

export interface BoardZoomingPosition {
  x: number;
  y: number;
  viewPortX?: number;
  viewPortY?: number;
}
export interface BoardZooming {
  ratio: number;
  position: BoardZoomingPosition;
}

interface HelperUpdateIgnoreTeacherAudioModel {
  sessionId: string;
  state: boolean;
}

interface TeacherScrollPdfViewModel {
  sessionId: string;
  ratio: number;
}
export class TeacherWSClient extends GLSocketClient {
  get teacherRequestModel(): TeacherRequestModel {
    return {
      teacherId: store.getters["auth/getLoginInfo"]?.profile?.sub,
      sessionId: store.getters["classTeaching/getSessionId"],
    };
  }

  sendRequestJoinRoom(roomId: string, deviceId: string, isMuteAudio = MediaStatus.noStatus, isHideVideo = MediaStatus.noStatus, isHelper = false) {
    const resolution = `${screen.width * window.devicePixelRatio}x${screen.height * window.devicePixelRatio}`;
    const { sessionId, teacherId } = this.teacherRequestModel;
    const params: TeacherJoinSessionModel = { sessionId, deviceId, resolution, teacherId: teacherId || "" };
    if (isMuteAudio !== MediaStatus.noStatus) {
      let status = false;
      if (isMuteAudio === MediaStatus.mediaLocked) {
        status = true;
      }
      params.isMuteAudio = status;
    }
    if (isHideVideo !== MediaStatus.noStatus) {
      let status = false;
      if (isHideVideo === MediaStatus.mediaLocked) {
        status = true;
      }
      params.isMuteVideo = status;
    }
    const command = isHelper ? HelperWSCmd.JOIN_CLASS : WSCmd.JOIN_CLASS;
    return this.send(command, params);
  }
  sendRequestMuteVideo(isMute: boolean) {
    const { sessionId, teacherId } = this.teacherRequestModel;
    const model: TeacherToggleDeviceModel = { isMute, sessionId, teacherId };
    return this.invoke(WSCmd.MUTE_VIDEO, model);
  }
  sendRequestMuteAudio(isMute: boolean) {
    const { sessionId, teacherId } = this.teacherRequestModel;
    const model: TeacherToggleDeviceModel = { isMute, sessionId, teacherId };
    return this.invoke(WSCmd.MUTE_AUDIO, model);
  }
  sendRequestSetTeachingMode(teachingMode: number) {
    const { sessionId } = this.teacherRequestModel;
    const model: TeacherChangeTeachingModeModel = { mode: teachingMode, sessionId };
    return this.send(WSCmd.SET_TEACHING_MODE, model);
  }
  sendRequestStartLessonContent(exposureId: string) {
    const model: TeacherSetExposureModel = { sessionId: this.teacherRequestModel.sessionId, exposureId };
    return this.send(WSCmd.START_LESSON_CONTENT, model);
  }
  sendRequestEndLessonContent(exposureId: string) {
    const model: TeacherSetExposureModel = { sessionId: this.teacherRequestModel.sessionId, exposureId };
    return this.send(WSCmd.END_LESSON_CONTENT, model);
  }
  sendRequestSetLessonItemContent(payload: RequestSetExposureItem) {
    const model: TeacherSetItemContentModel = { ...payload, sessionId: this.teacherRequestModel.sessionId, timestamp: getCurrentUTCUnixTimestamp() };
    return this.send(WSCmd.SET_LESSON_ITEM_CONTENT, model);
  }
  sendRequestBlackOutLessonContent(isBlackOut: boolean) {
    const model: TeacherSetBlackoutModel = { sessionId: this.teacherRequestModel.sessionId, IsBlackOut: isBlackOut };
    return this.send(WSCmd.BLACKOUT_LESSON_CONTENT, model);
  }
  sendRequestSetClassAction(action: number) {
    const model: TeacherSetLessonActionModel = { sessionId: this.teacherRequestModel.sessionId, action };
    return this.send(WSCmd.SET_CLASS_ACTION, model);
  }
  sendRequestSetPointer(payload: SendPointerMessage) {
    const model: TeacherSetPointerModel = { sessionId: this.teacherRequestModel.sessionId, ...payload };
    return this.send(WSCmd.TEACHER_SET_POINTER, model);
  }
  sendRequestTeacherUseOnlySetPointer(payload: SendPointerMessage) {
    const model: TeacherSetPointerModel = { sessionId: this.teacherRequestModel.sessionId, ...payload };
    return this.send(WSCmd.TEACHER_USE_ONLY_SET_POINTER, model);
  }
  sendRequestUpdateAnnotationMode(mode: number) {
    const model: TeacherUpdateAnnotationModeModel = { sessionId: this.teacherRequestModel.sessionId, mode };
    return this.send(WSCmd.TEACHER_UPDATE_ANNOTATION_MODE, model);
  }
  sendRequestTeacherUseOnlyUpdateAnnotationMode(mode: number) {
    const model: TeacherUpdateAnnotationModeModel = { sessionId: this.teacherRequestModel.sessionId, mode };
    return this.send(WSCmd.TEACHER_USE_ONLY_UPDATE_ANNOTATION_MODE, model);
  }
  sendRequestClearAllBrush(payload: any) {
    const sessionId = this.teacherRequestModel.sessionId;
    return this.send(WSCmd.TEACHER_CLEAR_ALL_BRUSH_STROKES, sessionId);
  }
  sendRequestTeacherUseOnlyClearAllBrush(payload: any) {
    const sessionId = this.teacherRequestModel.sessionId;
    return this.send(WSCmd.TEACHER_USE_ONLY_CLEAR_ALL_BRUSH_STROKES, sessionId);
  }
  sendRequestSetOneToOne(payload: { status: boolean; id: string; isHelper: boolean }) {
    const model: TeacherSetOneToOneModel = { studentId: payload.id, sessionId: this.teacherRequestModel.sessionId };
    if (payload.isHelper) return this.send(HelperWSCmd.HELPER_SET_ONE_TO_ONE, model);
    return this.send(WSCmd.TEACHER_SET_ONE_TO_ONE, model);
  }
  sendRequestSetWhiteboard(isShowWhiteBoard: boolean) {
    const model: TeacherSetWhiteBoardModel = { sessionId: this.teacherRequestModel.sessionId, isShow: isShowWhiteBoard };
    return this.send(WSCmd.TEACHER_SET_WHITEBOARD, model);
  }
  sendRequestDrawLaser(payload: SendRealtimePenMessage) {
    const model: RealtimePenModel = {
      sessionId: this.teacherRequestModel.sessionId,
      metadata: payload.data,
      isOneOne: payload.isOneOne,
    };
    return this.send(WSCmd.TEACHER_DRAW_LASER_PEN, model);
  }
  sendRequestTeacherUseOnlyDrawLaser(payload: SendRealtimePenMessage) {
    const model: RealtimePenModel = {
      sessionId: this.teacherRequestModel.sessionId,
      metadata: payload.data,
      isOneOne: payload.isOneOne,
    };
    return this.send(WSCmd.TEACHER_USE_ONLY_DRAW_LASER_PEN, model);
  }
  sendRequestCreateFabricObject(payload: FabricObject) {
    const model: TeacherSetFabricModel = {
      sessionId: this.teacherRequestModel.sessionId,
      ...payload,
    };
    return this.send(WSCmd.TEACHER_CREATE_FABRIC_OBJECT, model);
  }
  sendRequestTeacherUseOnlyCreateFabricObject(payload: FabricObject) {
    const model: TeacherSetFabricModel = {
      sessionId: this.teacherRequestModel.sessionId,
      ...payload,
    };
    return this.send(WSCmd.TEACHER_USE_ONLY_CREATE_FABRIC_OBJECT, model);
  }
  sendRequestModifyFabricObject(payload: FabricObject) {
    const model: TeacherSetFabricModel = {
      sessionId: this.teacherRequestModel.sessionId,
      ...payload,
    };
    return this.send(WSCmd.TEACHER_MODIFY_FABRIC_OBJECT, model);
  }
  sendRequestTeacherUseOnlyModifyFabricObject(payload: FabricObject) {
    const model: TeacherSetFabricModel = {
      sessionId: this.teacherRequestModel.sessionId,
      ...payload,
    };
    return this.send(WSCmd.TEACHER_USE_ONLY_MODIFY_FABRIC_OBJECT, model);
  }
  sendRequestToggleShape(payload: ToggleTargetModel) {
    const { sessionId, teacherId } = this.teacherRequestModel;
    const model: TeacherToggleShapeModel = { sessionId, teacherId, visible: payload.visible, tag: payload.tag };
    return this.send(WSCmd.TEACHER_TOGGLE_SHAPE, model);
  }
  sendRequestUpdateSessionAndUnit(payload: any) {
    const model: TeacherUpdateSessionLessonAndUnitModel = {
      sessionId: this.teacherRequestModel.sessionId,
      senderId: this.teacherRequestModel.teacherId || "",
    };
    return this.send(WSCmd.UPDATE_SESSION_LESSON_AND_UNIT, model);
  }
  sendRequestZoomSlide(payload: BoardZooming) {
    const model: TeacherZoomSlideModel = { sessionId: this.teacherRequestModel.sessionId, ...payload };
    return this.invoke(WSCmd.TEACHER_ZOOM_SLIDE, model);
  }
  sendRequestTeacherUseOnlyZoomSlide(payload: BoardZooming) {
    const model: TeacherZoomSlideModel = { sessionId: this.teacherRequestModel.sessionId, ...payload };
    return this.invoke(WSCmd.TEACHER_USE_ONLY_ZOOM_SLIDE, model);
  }

  sendRequestMoveZoomedSlide(payload: { x: number; y: number; viewPortX: number; viewPortY: number }) {
    const model: TeacherMoveZoomedSlideModel = { sessionId: this.teacherRequestModel.sessionId, ...payload };
    return this.invoke(WSCmd.TEACHER_MOVE_ZOOMED_SLIDE, model);
  }
  sendRequestTeacherUseOnlyMoveZoomedSlide(payload: { x: number; y: number; viewPortX: number; viewPortY: number }) {
    const model: TeacherMoveZoomedSlideModel = { sessionId: this.teacherRequestModel.sessionId, ...payload };
    return this.invoke(WSCmd.TEACHER_USE_ONLY_MOVE_ZOOMED_SLIDE, model);
  }
  sendRequestDrawPencil(payload: SendRealtimePenMessage) {
    const model: RealtimePenModel = {
      sessionId: this.teacherRequestModel.sessionId,
      metadata: payload.data,
      isOneOne: payload.isOneOne,
    };
    return this.send(WSCmd.TEACHER_DRAW_PENCIL_PEN, model);
  }
  sendRequestTeacherUseOnlyDrawPencil(payload: SendRealtimePenMessage) {
    const model: RealtimePenModel = {
      sessionId: this.teacherRequestModel.sessionId,
      metadata: payload.data,
      isOneOne: payload.isOneOne,
    };
    return this.send(WSCmd.TEACHER_USE_ONLY_DRAW_PENCIL_PEN, model);
  }
  sendRequestOneToOneWithHelperIgnoreTeacherVoice(ignore: boolean) {
    const model: HelperUpdateIgnoreTeacherAudioModel = {
      sessionId: this.teacherRequestModel.sessionId,
      state: ignore,
    };
    return this.invoke(WSCmd.HELPER_UPDATE_IGNORE_TEACHER_AUDIO, model);
  }
  sendRequestUpdatePdfScrollProgress(progress: number) {
    const model: TeacherScrollPdfViewModel = {
      sessionId: this.teacherRequestModel.sessionId,
      ratio: progress,
    };
    return this.invoke(WSCmd.TEACHER_SCROLL_PDF_VIEW, model);
  }
  sendRequestTeacherUseOnlyUpdatePdfScrollProgress(progress: number) {
    const model: TeacherScrollPdfViewModel = {
      sessionId: this.teacherRequestModel.sessionId,
      ratio: progress,
    };
    return this.invoke(WSCmd.TEACHER_USE_ONLY_SCROLL_PDF_VIEW, model);
  }
}
