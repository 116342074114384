import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/access-denied.png'


const _hoisted_1 = { class: "page-container text-slate-600" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("img", {
      src: _imports_0,
      alt: ""
    }, null, -1)),
    _createElementVNode("h1", null, _toDisplayString(_ctx.accessDeniedWeAreSorry), 1),
    _createElementVNode("p", null, _toDisplayString(_ctx.accessDeniedDescription), 1),
    _createElementVNode("p", null, _toDisplayString(_ctx.accessDeniedSuggest), 1)
  ]))
}