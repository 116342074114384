import { useRoute } from "vue-router";
import { computed } from "vue";

export enum RoleView {
  Student = 0,
  Teacher = 1,
}
export const usePage = (roleView: RoleView) => {
  const route = useRoute();
  const isClassPage = computed(() => (roleView === RoleView.Student ? route.name === "StudentClass" : route.name === "TeacherClass"));
  return {
    isClassPage,
  };
};
