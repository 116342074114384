import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, normalizeStyle as _normalizeStyle, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives, Fragment as _Fragment, renderList as _renderList, resolveDynamicComponent as _resolveDynamicComponent } from "vue"

const _hoisted_1 = { class: "px-3 2xl:px-5" }
const _hoisted_2 = {
  key: 0,
  class: "flex text-gs-purple justify-between items-center gap-x-6 pb-4"
}
const _hoisted_3 = { class: "flex items-center min-w-0 gap-x-2 2xl:gap-x-4" }
const _hoisted_4 = { class: "min-w-0 flex-auto" }
const _hoisted_5 = { class: "text-sm leading-6 font-medium" }
const _hoisted_6 = { class: "bg-slate-100 text-slate-600 rounded-xl px-4 pt-3 pb-2 2xl:pt-4 2xl:pb-3" }
const _hoisted_7 = { class: "w-full h-2 bg-slate-300 rounded overflow-hidden" }
const _hoisted_8 = { class: "text-sm mt-1 relative text-center" }
const _hoisted_9 = {
  key: 0,
  class: "flex text-slate-600 justify-between items-center mt-2"
}
const _hoisted_10 = { class: "pl-4 flex space-x-1" }
const _hoisted_11 = { class: "text-xs text-slate-500 space-x-4" }
const _hoisted_12 = { class: "flex space-x-1" }
const _hoisted_13 = {
  ref: "lessonContainer",
  class: "relative flex-1 overflow-auto pb-8 nice-scroll"
}
const _hoisted_14 = { key: 0 }
const _hoisted_15 = { key: 0 }
const _hoisted_16 = ["innerHTML"]
const _hoisted_17 = { key: 1 }
const _hoisted_18 = ["innerHTML"]
const _hoisted_19 = {
  key: 2,
  class: "flex flex-col items-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChevronLeftIcon = _resolveComponent("ChevronLeftIcon")!
  const _component_InformationCircleIcon = _resolveComponent("InformationCircleIcon")!
  const _component_ChevronRightIcon = _resolveComponent("ChevronRightIcon")!
  const _component_ExposureDetail = _resolveComponent("ExposureDetail")!
  const _component_LessonActivity = _resolveComponent("LessonActivity")!
  const _component_Empty = _resolveComponent("Empty")!
  const _component_PinningModal = _resolveComponent("PinningModal")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.isLpSidebarShrink ? 'w-6 mr-4' : 'w-60 2xl:w-80', "pt-24 3xl:pt-28 relative transition-all bg-slate-200"])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass([[_ctx.isLpSidebarShrink ? 'opacity-0 duration-75' : 'duration-500', _ctx.isHelper && 'cursor-not-allowed'], "transition-opacity relative overflow-hidden flex flex-col gap-y-4 h-full"])
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_Transition, {
          "enter-active-class": "transition ease-out duration-200 delay-200",
          "enter-from-class": "opacity-0 translate-x-1",
          "enter-to-class": "opacity-100 translate-x-0",
          "leave-active-class": "transition ease-in duration-100",
          "leave-from-class": "opacity-100 translate-x-0",
          "leave-to-class": "opacity-0 translate-x-1"
        }, {
          default: _withCtx(() => [
            (_ctx.isShowExposureDetail)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createElementVNode("div", _hoisted_3, [
                    _createVNode(_component_ChevronLeftIcon, {
                      class: _normalizeClass([_ctx.isHelper ? 'cursor-not-allowed' : 'cursor-pointer transition-colors hover:text-gs-purple/60', "flex-shrink-0 h-4 w-4 2xl:h-6 2xl:w-6"]),
                      onClick: _ctx.onClickCloseExposure
                    }, null, 8, ["class", "onClick"]),
                    _createElementVNode("div", _hoisted_4, [
                      _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.exposureTitle), 1)
                    ])
                  ]),
                  _createElementVNode("div", {
                    onMouseover: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleMouseOver && _ctx.handleMouseOver(...args))),
                    class: _normalizeClass([[_ctx.infoPopupStatus !== _ctx.PopupStatus.Pinned && 'cursor-pointer'], "min-h-[28px] flex justify-center items-center"]),
                    id: "lp-info"
                  }, [
                    _createVNode(_component_InformationCircleIcon, {
                      ref: "infoIconRef",
                      class: "h-4 w-4 2xl:h-5 2xl:w-5"
                    }, null, 512)
                  ], 34)
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", {
              class: "bg-gs-purple h-full rounded text-xs text-white flex justify-center items-center w-full origin-left transition-all duration-500",
              style: _normalizeStyle({ transform: `scaleX(${_ctx.progress})` })
            }, null, 4)
          ]),
          _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.remainingText) + " " + _toDisplayString(_ctx.remainingTime), 1)
        ]),
        _createVNode(_Transition, {
          "enter-active-class": "transition ease-out duration-200 delay-200",
          "enter-from-class": "opacity-0 translate-x-1",
          "enter-to-class": "opacity-100 translate-x-0",
          "leave-active-class": "transition ease-in duration-100",
          "leave-from-class": "opacity-100 translate-x-0",
          "leave-to-class": "opacity-0 translate-x-1"
        }, {
          default: _withCtx(() => [
            (_ctx.isShowExposureDetail)
              ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                  _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("div", _hoisted_11, [
                      _createElementVNode("span", null, _toDisplayString(_ctx.itemText) + " " + _toDisplayString(_ctx.activityStatistic), 1),
                      _createElementVNode("span", null, _toDisplayString(_ctx.pageText) + " " + _toDisplayString(_ctx.page), 1)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_12, [
                    _createElementVNode("div", {
                      class: _normalizeClass(["h-6 w-6 rounded-lg bg-slate-100 flex justify-center items-center", [
                  _ctx.canPrev ? 'bg-slate-100 text-slate-600 transition-colors' : 'bg-slate-300 text-slate-400',
                  _ctx.isHelper && 'cursor-not-allowed',
                  _ctx.canPrev && !_ctx.isHelper && 'hover:bg-white cursor-pointer',
                ]]),
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onClickPrevNextMedia(_ctx.PREV_EXPOSURE)))
                    }, [
                      _createVNode(_component_ChevronLeftIcon, { class: "h-4 w-4" })
                    ], 2),
                    _createElementVNode("div", {
                      class: _normalizeClass(["h-6 w-6 rounded-lg bg-slate-100 flex justify-center items-center", [
                  _ctx.canNext ? 'bg-slate-100 text-slate-600 transition-colors' : 'bg-slate-300 text-slate-400',
                  _ctx.isHelper && 'cursor-not-allowed',
                  _ctx.canNext && !_ctx.isHelper && 'hover:bg-white cursor-pointer',
                ]]),
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onClickPrevNextMedia(_ctx.NEXT_EXPOSURE)))
                    }, [
                      _createVNode(_component_ChevronRightIcon, { class: "h-4 w-4" })
                    ], 2)
                  ])
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_13, [
        _createVNode(_Transition, {
          "enter-active-class": "transition ease-out duration-200 delay-200",
          "enter-from-class": "opacity-0 translate-x-1",
          "enter-to-class": "opacity-100 translate-x-0",
          "leave-active-class": "transition ease-in duration-100",
          "leave-from-class": "opacity-100 translate-x-0",
          "leave-to-class": "opacity-0 translate-x-1"
        }, {
          default: _withCtx(() => [
            (_ctx.isShowExposureDetail)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: _normalizeClass(["space-y-3 2xl:space-y-4 mt-2", [_ctx.isHelper && 'pointer-events-none']])
                }, [
                  (_ctx.isTransitionType)
                    ? (_openBlock(), _createBlock(_component_ExposureDetail, {
                        key: 0,
                        type: _ctx.exposureTypes.TRANSITION_BLOCK,
                        exposure: _ctx.currentExposure,
                        onClickBack: _ctx.onClickCloseExposure
                      }, null, 8, ["type", "exposure", "onClickBack"]))
                    : (_ctx.isCompleteType)
                      ? (_openBlock(), _createBlock(_component_ExposureDetail, {
                          key: 1,
                          type: _ctx.exposureTypes.LP_COMPLETE_BLOCK,
                          exposure: _ctx.currentExposure,
                          onClickBack: _ctx.onClickCloseExposure
                        }, null, 8, ["type", "exposure", "onClickBack"]))
                      : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                          _createVNode(_component_ExposureDetail, {
                            type: _ctx.exposureTypes.VCP_BLOCK,
                            exposure: _ctx.currentExposure,
                            onClickBack: _ctx.onClickCloseExposure
                          }, null, 8, ["type", "exposure", "onClickBack"]),
                          _createVNode(_component_ExposureDetail, {
                            type: _ctx.exposureTypes.CONTENT_BLOCK,
                            exposure: _ctx.currentExposure,
                            onClickBack: _ctx.onClickCloseExposure
                          }, null, 8, ["type", "exposure", "onClickBack"]),
                          _createVNode(_component_ExposureDetail, {
                            type: _ctx.exposureTypes.TEACHING_ACTIVITY_BLOCK,
                            exposure: _ctx.currentExposure,
                            onClickBack: _ctx.onClickCloseExposure
                          }, null, 8, ["type", "exposure", "onClickBack"]),
                          _withDirectives(_createVNode(_component_ExposureDetail, {
                            type: _ctx.exposureTypes.ALTERNATE_MEDIA_BLOCK,
                            exposure: _ctx.currentExposure,
                            onClickBack: _ctx.onClickCloseExposure
                          }, null, 8, ["type", "exposure", "onClickBack"]), [
                            [_vShow, _ctx.isAlternateMediaShown]
                          ])
                        ], 64))
                ], 2))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        _createVNode(_Transition, {
          "enter-active-class": "transition ease-out duration-200 delay-200",
          "enter-from-class": "opacity-0 -translate-x-1",
          "enter-to-class": "opacity-100 translate-x-0",
          "leave-active-class": "transition ease-in duration-100",
          "leave-from-class": "opacity-100 translate-x-0",
          "leave-to-class": "opacity-0 -translate-x-1"
        }, {
          default: _withCtx(() => [
            (!_ctx.isShowExposureDetail)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: _normalizeClass(["px-3 2xl:px-5 cursor-pointer text-slate-600", [_ctx.isHelper && 'pointer-events-none']])
                }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.exposures, (exposure) => {
                    return (_openBlock(), _createBlock(_component_LessonActivity, {
                      key: exposure.id,
                      id: exposure.id,
                      title: exposure.name,
                      type: exposure.type,
                      duration: exposure.duration,
                      status: exposure.status,
                      "content-root-type": exposure.contentRootType,
                      onClick: () => _ctx.onClickExposure(exposure)
                    }, null, 8, ["id", "title", "type", "duration", "status", "content-root-type", "onClick"]))
                  }), 128))
                ], 2))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ], 512),
      _cache[4] || (_cache[4] = _createElementVNode("div", { class: "absolute bottom-0 inset-x-5 h-8 bg-gradient-to-t from-slate-200 via-slate-200/60 to-transparent pointer-events-none" }, null, -1))
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass([[
        _ctx.isLpSidebarShrink ? 'bg-gs-purple hover:bg-gs-purple/90 text-white' : 'bg-slate-100 hover:bg-white text-slate-600',
        _ctx.isSignalRConnected && !_ctx.isHelper && 'cursor-pointer',
        _ctx.isHelper && 'cursor-not-allowed',
      ], "absolute top-32 right-0 shadow translate-x-1/2 h-6 w-6 2xl:h-7 2xl:w-7 flex items-center justify-center rounded-md transition-colors"]),
      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.isSignalRConnected && _ctx.toggleLpSidebar()))
    }, [
      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.isLpSidebarShrink ? _ctx.ChevronRightIcon : _ctx.ChevronLeftIcon), { class: "h-4 w-4" }))
    ], 2),
    _createVNode(_component_PinningModal, {
      status: _ctx.infoPopupStatus,
      position: _ctx.teachingIconPosition,
      onPinOrHide: _ctx.handlePinOrHide
    }, {
      "final-modal-content": _withCtx(() => [
        (_ctx.currentExposure)
          ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
              (!_ctx.teachingContentEmpty)
                ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                    _createElementVNode("ul", null, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.currentExposure.teachingActivityBlockItems, ({ id, textContent }) => {
                        return (_openBlock(), _createElementBlock("li", { key: id }, [
                          _createElementVNode("div", { innerHTML: textContent }, null, 8, _hoisted_16)
                        ]))
                      }), 128))
                    ])
                  ]))
                : _createCommentVNode("", true),
              (_ctx.isTransitionBlock)
                ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                    _createElementVNode("ul", null, [
                      _createElementVNode("li", null, [
                        _createElementVNode("div", {
                          innerHTML: _ctx.currentExposure.name
                        }, null, 8, _hoisted_18)
                      ])
                    ])
                  ]))
                : _createCommentVNode("", true),
              (!_ctx.isTransitionBlock && _ctx.teachingContentEmpty)
                ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                    _createVNode(_component_Empty, {
                      imageStyle: "max-height: 45px",
                      description: ''
                    }),
                    _createElementVNode("div", null, _toDisplayString(_ctx.noDataText), 1)
                  ]))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["status", "position", "onPinOrHide"])
  ], 2))
}