import { MediaType, ReaderContentModel } from "@/models";
import { StorageContainerEnum } from "@/store/lesson/actions";
import { ExposureItem, ExposureItemMedia } from "@/store/lesson/state";
import { ExposureDetailType } from "@/views/teacher-class/components/lesson-plan/lesson-plan";

export const fromReader = (
  readerContents: ReaderContentModel[],
  buildUrl: (src: string, type: StorageContainerEnum) => string,
  imagesToPreLoad: string[],
): ExposureItem[] => {
  let order = 0;
  const createUuidWithOrder = (id: string) => {
    const uuid = id + order;
    order++;
    return uuid;
  };
  const items: ExposureItem[] = [];
  readerContents.forEach(({ pages, medias, focusWords, contentId }) => {
    pages.forEach((page) => {
      const audioUrl = medias.find((media) => media.id === page.audioMediaId)?.url;
      const builtImageUrl = buildUrl(page.url, StorageContainerEnum.Page);
      builtImageUrl && imagesToPreLoad.push(builtImageUrl);
      const media: ExposureItemMedia[] = [
        {
          id: page.id,
          teacherUseOnly: false,
          teachingContent: "",
          image: {
            url: builtImageUrl,
          },
          audio: {
            url: audioUrl ? buildUrl(audioUrl, StorageContainerEnum.Audio) : "",
          },
          exposureType: ExposureDetailType.CONTENT_BLOCK,
        },
      ];
      const item: ExposureItem = {
        id: page.id,
        name: page.id,
        textContent: "",
        isClicked: false,
        mediaTypeId: MediaType.png,
        teacherUseOnly: false,
        media,
      };
      items.push(item);
    });

    focusWords.forEach((fw) => {
      const image = fw.medias.find((media) => media.mediaTypeId === MediaType.png);
      const builtImageUrl = image ? buildUrl(image.url, StorageContainerEnum.Page) : "";
      builtImageUrl && imagesToPreLoad.push(builtImageUrl);
      const fwId = createUuidWithOrder(image ? image.id : contentId);
      const readerFocusWord = {
        word: fw.focusWordText,
        definition: fw.focusWordDefinition,
      };
      const media: ExposureItemMedia[] = [
        {
          id: fwId,
          teacherUseOnly: false,
          teachingContent: "",
          image: {
            url: builtImageUrl,
          },
          audio: {
            url: "",
          },
          readerFocusWord,
          exposureType: ExposureDetailType.CONTENT_BLOCK,
        },
      ];
      const item: ExposureItem = {
        id: fwId,
        name: image ? image.id : contentId,
        textContent: "",
        isClicked: false,
        mediaTypeId: MediaType.png,
        teacherUseOnly: false,
        media,
      };
      items.push(item);
    });
  });
  return items;
};
