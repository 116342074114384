import IconPdf from "@/assets/teacher-class/pdf.png";
import IconAudio from "@/assets/teacher-class/sound.png";
import IconVideo from "@/assets/teacher-class/video.png";
import { ExposureItemMedia } from "@/store/lesson/state";
import { Empty, Tooltip } from "ant-design-vue";
import { PropType, computed, defineComponent, inject } from "vue";
import { useStore } from "vuex";
import { fmtMsg } from "vue-glcommonui";
import { TeacherClassLessonPlan } from "@/locales/localeid";
import { ExposureDetailType } from "@/views/teacher-class/components/lesson-plan/lesson-plan";
import Thumbnail from "@/views/teacher-class/components/lesson-plan/exposure-detail/exposure-item/thumbnail/thumbnail.vue";
export default defineComponent({
  props: {
    items: {
      type: Object as PropType<ExposureItemMedia[]>,
      required: true,
    },
    exposureType: Object as PropType<ExposureDetailType>,
  },
  components: {
    Tooltip,
    Empty,
    Thumbnail,
  },
  setup(props) {
    const store = useStore();
    const isHelper = computed<boolean>(() => store.getters["teacher/isHelper"]);
    const NoDataText = computed(() => fmtMsg(TeacherClassLessonPlan.NoData));
    const currentExposureItemMedia = computed<ExposureItemMedia | undefined>(() => store.getters["lesson/currentExposureItemMedia"]);
    const currentExposureType = computed<ExposureDetailType | undefined>(() => store.getters["lesson/currentExposureType"]);
    const handleLessonItemChange: ((id: string, exposureType?: ExposureDetailType) => void) | undefined = inject("lesson-item-change");

    const onClickItem = (id: string) => {
      if (isHelper.value) return;
      handleLessonItemChange?.(id, props.exposureType);
    };
    const isTeaching = computed(() => props.exposureType === ExposureDetailType.TEACHING_ACTIVITY_BLOCK);
    const hasZeroImage = computed(() => !props.items.length);
    return {
      onClickItem,
      currentExposureItemMedia,
      currentExposureType,
      isTeaching,
      hasZeroImage,
      IconAudio,
      IconVideo,
      IconPdf,
      NoDataText,
      isHelper,
    };
  },
});
