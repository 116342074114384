<template>
  <div class="cursor-move flex flex-col items-center relative">
    <InOneIcon class="w-9 h-9 absolute top-0 right-0" v-if="isOne" />
    <span class="absolute flex h-4 w-4 top-2 right-2" v-else-if="isOnline">
      <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span>
      <span class="relative inline-flex rounded-full h-4 w-4 bg-green-500"></span>
    </span>
    <div :class="[!teamColor && '3xl:w-32 3xl:h-32']" class="w-24 h-24 shadow border border-white rounded-full overflow-hidden">
      <img :src="generateAvatar(member.avatarUrl)" alt="" class="w-full h-full object-cover" />
    </div>
    <NameTag
      :is-muted="isMuted"
      :name="member.name"
      :class="[!teamColor && '!3xl:max-w-[128px]']"
      class="mt-2 !max-w-[96px]"
      :background-color="teamColor"
    />
  </div>
</template>
<style lang="scss" scoped src="./member.scss"></style>
<script lang="ts" src="./member.ts"></script>
