import IconLowWifi from "@/assets/teacher-class/slow-wifi.svg";
import { StudentCard } from "@/locales/localeid";
import { VCPlatform } from "@/store/app/state";
import { StudentState } from "@/store/room/interface";
import { calColorByBackground, generateAvatar } from "@/utils/utils";
import { useElementBounding } from "@vueuse/core";
import "animate.css";
import { notification } from "ant-design-vue";
import { computed, defineComponent, reactive, ref } from "vue";
import { fmtMsg } from "vue-glcommonui";
import { useStore } from "vuex";
import StudentBadge from "../student-badge/student-badge.vue";
import { StudentCardActions } from "../student-card-actions";
import IndependentProgress from "./components/independent-progress/independent-progress.vue";
import { TeacherRoomManager } from "@/manager/room/teacher.manager";
import { WifiIcon } from "@heroicons/vue/20/solid";
import { useManipulateTeamState } from "@/hooks/use-team";
import { TeamState } from "@/store/teams/state";
import { MicrophoneIcon } from "@/components/common";

export enum InteractiveStatus {
  DEFAULT = 0,
  ASSIGNED = 1,
  COMPLETED = 2,
}

export default defineComponent({
  methods: { calColorByBackground },
  components: {
    MicrophoneIcon,
    WifiIcon,
    StudentBadge,
    StudentCardActions,
    IndependentProgress,
  },
  props: {
    setModeOne: {
      type: Boolean,
      default: false,
    },
    student: { type: Object as () => StudentState, required: true },
    isLarge: Boolean,
    focusStudentId: String,
    scaleOption: Number,
  },

  setup(props) {
    const isHelper = computed(() => getters["teacher/isHelper"]);
    const { getters, dispatch } = useStore();
    const interactive = computed(() => getters["interactive/interactiveStatus"](props.student.id));
    const isUsingAgora = computed<boolean>(() => getters["isUsingAgora"]);
    const agoraVideoContainerRef = ref<HTMLDivElement | null>(null);
    const isMouseEntered = ref<boolean>(false);
    const teacherRoomManager = computed<TeacherRoomManager>(() => getters["teacherRoom/roomManager"]);
    const isOneToOneStudent = computed(() => {
      return getters["teacherRoom/getStudentModeOneId"] === props.student.id;
    });
    const focusedStudent = computed(() => props.focusStudentId === props.student.id);
    const { findTeamByStudentId, isTeamMode } = useManipulateTeamState();
    const studentTeam = computed<TeamState | undefined>(() => (isTeamMode.value ? findTeamByStudentId(props.student.id) : undefined));
    const hiddenByOneOneMode = computed(
      () => getters["teacherRoom/getStudentModeOneId"] && getters["teacherRoom/getStudentModeOneId"] !== props.student.id,
    );
    const currentExposure = computed(() => getters["lesson/currentExposure"]);
    const currentExposureItemMedia = computed(() => getters["lesson/currentExposureItemMedia"]);
    const isLowBandWidth = computed(() => {
      const listStudentLowBandWidth = getters["teacherRoom/listStudentLowBandWidth"];
      return listStudentLowBandWidth.findIndex((id: string) => id === props.student.id) > -1;
    });
    const showCorrectAnswer = computed(() => {
      return interactive.value.status !== 0 && interactive.value.multiAssign;
    });
    const studentIdsInOneToOneMode = computed(() => getters["teacherRoom/studentIdsInOneToOneMode"]);
    const oneAndOne = computed(() => getters["teacherRoom/getStudentModeOneId"]);
    const oneAndOneWithAnotherTeacher = computed(() => getters["teacherRoom/getStudentModeOneWithAnotherTeacherId"]);

    const enterOneToOneWithStudent = async () => {
      if (studentIdsInOneToOneMode.value.includes(props.student?.id)) return;
      if (props.setModeOne && props.student?.id !== oneAndOne.value && getters["platform"] === VCPlatform.Agora) {
        if (currentExposure.value?.id) {
          await dispatch("lesson/setPreviousExposure", {
            id: currentExposure.value.id,
          });
        }
        if (currentExposureItemMedia.value) {
          await dispatch("lesson/setPreviousExposureItemMedia", {
            id: currentExposureItemMedia.value.id,
          });
        }
        await dispatch("teacherRoom/sendOneAndOne", {
          status: true,
          id: props.student.id,
          isHelper: isHelper.value,
        });
      } else {
        if (getters["platform"] === VCPlatform.Zoom) {
          notification.error({
            message: fmtMsg(StudentCard.OneToOneNotification),
          });
        }
      }
    };

    const avatarUrl = computed(() => generateAvatar(getters["teams/findAvatarUrlById"](props.student.id)));

    const onDragStart = (event: any) => {
      event.dataTransfer.setData("studentId", props.student.id);
    };

    const onMouseChange = (entered: boolean) => {
      if (isStudentOneToOneWithAnotherTeacher.value) {
        return (isMouseEntered.value = false);
      }
      isMouseEntered.value = entered;
    };

    const isSpeaking = computed(() => {
      const speakingUsers: Array<string> = getters["teacherRoom/speakingUsers"];
      return speakingUsers.indexOf(props.student.id) >= 0;
    });

    const studentRef = ref<any>(null);
    const parentCard = computed(() => studentRef.value?.parentElement);
    const { width, height } = useElementBounding(studentRef);
    const { width: parentWidth } = useElementBounding(parentCard);

    const maxScaleRatio = computed(() => {
      return width.value ? parentWidth.value / width.value : 1;
    });
    const actualScaleRatio = computed(() => {
      return Math.min(props.scaleOption || 1, maxScaleRatio.value);
    });
    const isTurnOnCamera = computed(() => {
      return props.student.videoEnabled;
    });
    const isIndependentMode = computed<boolean>(() => getters["classTeaching/isIndependentMode"]);
    const isStudentOneToOneWithAnotherTeacher = computed(() => getters["teacherRoom/getStudentModeOneWithAnotherTeacherId"] === props.student.id);

    const borderLayers = computed(() => {
      const layers = [];
      if (props.student.raisingHand) {
        layers.push("border-red-600 animate-pulse");
      }
      if (props.student.isPalette) {
        layers.push("border-slate-900");
      }
      if (isSpeaking.value) {
        layers.push("border-blue-600");
      }
      return layers;
    });

    return {
      onDragStart,
      enterOneToOneWithStudent,
      interactive,
      showCorrectAnswer,
      onMouseChange,
      isMouseEntered,
      isSpeaking,
      hiddenByOneOneMode,
      IconLowWifi,
      isLowBandWidth,
      focusedStudent,
      studentRef,
      isTurnOnCamera,
      isOneToOneStudent,
      avatarUrl,
      oneAndOne,
      isUsingAgora,
      actualScaleRatio,
      maxScaleRatio,
      agoraVideoContainerRef,
      isIndependentMode,
      isStudentOneToOneWithAnotherTeacher,
      borderLayers,
      studentTeam,
    };
  },
});
