import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "px-3 2xl:px-5",
  oncontextmenu: "return false"
}
const _hoisted_2 = { class: "bg-slate-100 pt-2 pb-2.5 2xl:pt-3 2xl:pb-4 rounded-lg 2xl:rounded-xl" }
const _hoisted_3 = { class: "text-xs 2xl:text-sm px-3 2xl:px-5 pb-3 border-b-slate-200 border-b leading-6 text-slate-600" }
const _hoisted_4 = { class: "px-3 2xl:px-5 mt-2 2xl:mt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ExposureItemBlock = _resolveComponent("ExposureItemBlock")!
  const _component_AlternativeItemBlock = _resolveComponent("AlternativeItemBlock")!

  return (!_ctx.isAlternateMediaBlock || _ctx.listMediaAsAlternative.length)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.exposureTitle), 1),
          _createElementVNode("div", _hoisted_4, [
            (!_ctx.isAlternateMediaBlock)
              ? (_openBlock(), _createBlock(_component_ExposureItemBlock, {
                  key: 0,
                  teachingContent: _ctx.exposure.teachingActivityBlockItems,
                  items: _ctx.listMedia,
                  "exposure-type": _ctx.type
                }, null, 8, ["teachingContent", "items", "exposure-type"]))
              : (_openBlock(), _createBlock(_component_AlternativeItemBlock, {
                  key: 1,
                  items: _ctx.listMediaAsAlternative
                }, null, 8, ["items"]))
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}