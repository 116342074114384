import { ActionTree } from "vuex";
import { TeamsState, TeamState, TeamStudentState } from "./state";
import { RemoteTeachingService, StudentService, TeacherService, TeamDto } from "@/services";
import { Logger } from "@/utils/logger";
import { createGuid } from "@/utils/utils";

//* Serialize */
const dtoToState = (teams: TeamDto[], allStudents: TeamStudentState[]): TeamState[] =>
  teams.map(({ id, name, color, studentIds, order }, index) => {
    const students = allStudents.filter((student) => studentIds.findIndex((id) => id === student.id) > -1);
    return {
      id: id ?? createGuid(),
      name,
      color,
      order,
      students,
      isNew: false,
    };
  });
//* Deserialize */
const stateToDto = (teams: TeamState[]): TeamDto[] =>
  teams.map(({ name, color, isNew, id, students, order }) => ({
    name,
    color,
    order,
    id: isNew ? null : id,
    studentIds: students.map((s) => s.id),
  }));

const actions: ActionTree<TeamsState, any> = {
  async getAllStudents({ commit }, payload: { groupId: string; classId: string }) {
    const { groupId, classId } = payload;
    try {
      const stResponse = await TeacherService.fetchStudentsByGroupId({ groupId, classId });
      if (!stResponse) return;
      commit("teacher/setClassSetUpStudents", stResponse, { root: true });
      const avResponse = await StudentService.fetchListAvatars(stResponse.map((st) => st.studentId));
      commit(
        "setAllStudents",
        stResponse
          .filter(({ isActivated }) => isActivated)
          .map(({ studentId, studentName }) => ({
            name: studentName,
            id: studentId,
            avatarUrl: avResponse.find((av) => av.id === studentId)?.avatar ?? "",
          })),
      );
    } catch (err) {
      Logger.error(err);
    }
  },
  async getTeams({ rootState, commit, state, dispatch }) {
    const { sessionId, classId, groupId } = rootState.classTeaching;
    commit("setLoading", true);
    try {
      if (!state.allStudentsFetched) {
        await dispatch("getAllStudents", { groupId, classId });
      }
      const response = await RemoteTeachingService.fetchTeams({ sessionId });
      commit("setTeams", dtoToState(response.data, state.allStudents));
    } catch (err) {
      Logger.error(err);
    } finally {
      commit("setLoading", false);
    }
  },
  async updateTeamsAsync({ rootState, commit }, teams: TeamState[]) {
    try {
      const sessionId = rootState.classTeaching.sessionId;
      if (!sessionId) return commit("setTeams", teams);
      await RemoteTeachingService.putTeams({ sessionId, teams: stateToDto(teams) });
      commit("setTeams", teams);
    } catch (err) {
      throw new Error("Update team failed!");
    }
  },
  async toggleTeamModeAsync({ rootState, commit }, isOpen: boolean) {
    try {
      const sessionId = rootState.classTeaching.sessionId;
      if (!sessionId) return;
      await RemoteTeachingService.putToggleTeamMode({ sessionId, isOpen });
      commit("setIsTeamMode", isOpen);
    } catch (err) {
      throw new Error("Toggle team failed!");
    }
  },
  async onSettingModalSave({ commit, dispatch, state }, teams: TeamState[]) {
    try {
      await dispatch("updateTeamsAsync", teams);
      state.enableTeamModeOnSave && (await dispatch("toggleTeamModeAsync", true));
      commit("setSettingModalOpened", false);
      commit("setEnableTeamModeOnSave", false);
    } catch (err) {
      Logger.error(err);
    }
  },
  async onSettingModalCancel({ commit }) {
    commit("setSettingModalOpened", false);
    commit("setEnableTeamModeOnSave", false);
  },
};

export default actions;
