import { LessonGalleryItem } from "@/components/common/lesson-gallery/lesson-gallery";
import { IndependentStudentItem } from "@/services/independent";
import { Progress } from "ant-design-vue";
import { computed, defineComponent } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  components: { Progress },
  props: {
    studentId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { getters } = useStore();
    const currentPercent = computed<number>(() => {
      let thePercent = 0;
      const currentItemId: null | string = getters["teacherTeaching/getIndependentListStudentItems"]?.find(
        (studentItem: IndependentStudentItem) => studentItem.studentId === props.studentId,
      )?.currentItemId;
      const theItems: LessonGalleryItem[] | null = getters["lesson/listReaderItemsOfCurrentExposure"];
      if (!currentItemId || !theItems) return thePercent;
      const itemIndex = theItems.findIndex((item) => item.id === currentItemId);
      if (itemIndex < 0) return thePercent;
      thePercent = ((itemIndex + 1) / theItems.length) * 100;
      return thePercent;
    });
    const barColor = computed(() => {
      let color = "#E6AE29";
      switch (true) {
        case currentPercent.value > 60:
          color = "#8BB049";
          break;
        case currentPercent.value < 30:
          color = "#A80916";
          break;
        default:
          break;
      }
      return color;
    });
    return { currentPercent, barColor };
  },
});
