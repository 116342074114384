<template>
  <transition
    :enter-active-class="`transition ease-out ${durationClass}`"
    :enter-from-class="`${isOpacity ? 'opacity-0' : ''} ${enterFromClass}`"
    :enter-to-class="`${isOpacity ? 'opacity-100' : ''} ${enterToClass}`"
    :leave-active-class="`transition ease-out ${durationClass}`"
    :leave-from-class="`${isOpacity ? 'opacity-100' : ''} ${enterToClass}`"
    :leave-to-class="`${isOpacity ? 'opacity-0' : ''} ${enterFromClass}`"
    :appear="appear"
  >
    <slot />
  </transition>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";

export default defineComponent({
  props: {
    durationClass: {
      type: String,
      default: "duration-300",
    },
    isOpacity: {
      type: Boolean,
      default: true,
    },
    isScale: {
      type: Boolean,
      default: false,
    },
    appearFrom: String,
    appear: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const enterFromClass = computed(() => {
      switch (props.appearFrom) {
        case "top":
          return "-translate-y-12";
        case "bottom":
          return "translate-y-12";
        case "left":
          return "-translate-x-12";
        case "right":
          return "translate-x-12";
        default:
          return "";
      }
    });
    const enterToClass = computed(() => {
      switch (props.appearFrom) {
        case "top":
        case "bottom":
          return "translate-y-0";
        case "left":
        case "right":
          return "translate-x-0";
        default:
          return "";
      }
    });
    return {
      enterFromClass,
      enterToClass,
    };
  },
});
</script>
