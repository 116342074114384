import { useSwiper } from "swiper/vue";
import { defineComponent } from "vue";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/vue/24/solid";
import { MIN_ZOOM_RATIO } from "@/utils/constant";
export default defineComponent({
  props: {
    isNext: {
      required: true,
      type: Boolean,
    },
  },
  setup(props) {
    const swiper = useSwiper();
    const moveSlide = async () => {
      const zoom = swiper.value?.zoom;
      if (!!zoom && zoom.scale > MIN_ZOOM_RATIO) {
        zoom.out();
      }
      if (props.isNext) {
        swiper.value.slideNext();
      } else {
        swiper.value.slidePrev();
      }
    };
    return { moveSlide, ChevronLeftIcon, ChevronRightIcon };
  },
});
