import { renderSlot as _renderSlot, Transition as _Transition, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Transition, {
    "enter-active-class": `transition ease-out ${_ctx.durationClass}`,
    "enter-from-class": `${_ctx.isOpacity ? 'opacity-0' : ''} ${_ctx.enterFromClass}`,
    "enter-to-class": `${_ctx.isOpacity ? 'opacity-100' : ''} ${_ctx.enterToClass}`,
    "leave-active-class": `transition ease-out ${_ctx.durationClass}`,
    "leave-from-class": `${_ctx.isOpacity ? 'opacity-100' : ''} ${_ctx.enterToClass}`,
    "leave-to-class": `${_ctx.isOpacity ? 'opacity-0' : ''} ${_ctx.enterFromClass}`,
    appear: _ctx.appear
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 8, ["enter-active-class", "enter-from-class", "enter-to-class", "leave-active-class", "leave-from-class", "leave-to-class", "appear"]))
}