import { computed, createVNode, defineComponent, nextTick, provide, reactive, readonly, ref, unref, watch } from "vue";
import { cloneDeep } from "lodash";
import Draggable from "vuedraggable";
import { ColorPicker } from "vue3-colorpicker";
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";
import { Button, Input, Modal, notification, Popover, Spin } from "ant-design-vue";
import { fmtMsg } from "vue-glcommonui";
import { createGuid, MediaDeviceTypes } from "@/utils/utils";
import { useManipulateTeamState } from "@/hooks/use-team";
import { DynamicKeysObject } from "@/utils/type";
import { CommonLocale, TeamLocales } from "@/locales/localeid";
import "vue3-colorpicker/style.css";
import Member from "../member";
import Team from "../team";
import { defaultColors, dummyTeams, MaximumNumberOfTeams, readOnlyNumbOfTeams } from "./constants";
import { useSendWebSocketMsg } from "@/hooks/use-send-websocket-msg";

export default defineComponent({
  components: {
    Modal,
    Input,
    Draggable,
    Button,
    ColorPicker,
    Spin,
    Popover,
    Member,
    Team,
  },
  setup() {
    //* reactive */
    const createModalOpened = ref(false);
    const errors = reactive<DynamicKeysObject>({});
    const { teams: globalTeams, nonTeamStudents: globalNonTeamStudents, isFetchingTeams, dispatch } = useManipulateTeamState();
    const { requestToggleStudentMediaDevices } = useSendWebSocketMsg();
    //** Local component's state: teams */
    const teams = ref(globalTeams.value.length ? cloneDeep(globalTeams.value) : cloneDeep(dummyTeams));
    //** Local component's state: nonTeamStudents */
    const nonTeamStudents = ref(unref(globalNonTeamStudents));
    const teamNameInputEl = ref<HTMLInputElement | undefined>();
    const anyTeamsChanged = ref(false);

    const initialState = computed(() => {
      const firstUnusedColor = defaultColors.find((color) => !teams.value.find((team) => team.color === color));
      return {
        id: "",
        name: "",
        color: firstUnusedColor || defaultColors[0],
        isEdit: false,
      };
    });
    const createTeamState = reactive({ ...initialState.value });

    //* methods */
    const toggleCreateTeamModal = (isOpen: boolean) => {
      createModalOpened.value = isOpen;
      Object.keys(errors).forEach((key) => delete errors[key]);
    };
    const addNewTeam = () => {
      if (teams.value.length >= MaximumNumberOfTeams) {
        notification.warn({
          message: MsgMaxTeamWarning.value,
        });
        return;
      }
      Object.assign(createTeamState, initialState.value);
      toggleCreateTeamModal(true);
    };
    const resetTeams = () => {
      const teamStudents = teams.value.map((t) => t.students);
      nonTeamStudents.value.push(...teamStudents.flat());
      teams.value = cloneDeep(dummyTeams);
    };
    const handleEdit = (id: string) => {
      const team = teams.value.find((t) => t.id === id);
      if (!team) return;
      const { name, color } = team;
      Object.assign(createTeamState, {
        id,
        name,
        color,
        isEdit: true,
      });
      toggleCreateTeamModal(true);
    };
    const handleDel = (id: string) => {
      const targetTeam = teams.value.find((t) => t.id === id);
      const targetTeamIndex = teams.value.findIndex((t) => t.id === id);
      if (!targetTeam) return;
      nonTeamStudents.value.push(...targetTeam.students);
      teams.value.splice(targetTeamIndex, 1);
    };
    const handleMute = async (id: string, isMuted: boolean) => {
      const nextStatus = !isMuted;
      await requestToggleStudentMediaDevices({
        studentIds: teams.value.find((t) => t.id === id)?.students.map((s) => s.id) || [],
        isMute: nextStatus,
        mediaDeviceType: MediaDeviceTypes.Microphone,
      });
    };
    const handleSubmit = () => {
      if (!createTeamState.name.trim()) {
        errors.name = MsgTeamNameRequired.value;
        return;
      }
      const { name, color, isEdit, id } = createTeamState;
      const edit = () => {
        const editingTeam = teams.value.find((t) => t.id === id);
        const editingTeamIndex = teams.value.findIndex((t) => t.id === id);
        if (editingTeam) {
          teams.value[editingTeamIndex] = { ...editingTeam, color, name };
        }
      };
      const add = () => {
        teams.value.push({
          id: createGuid(),
          color,
          name,
          order: teams.value.length - 1,
          students: [],
          isNew: true,
        });
      };
      isEdit ? edit() : add();
      toggleCreateTeamModal(false);
    };

    const saveChanges = async () => {
      await dispatch(
        "teams/onSettingModalSave",
        teams.value.map((team, index) => ({ ...team, order: index })),
      );
    };

    const cancelChanges = async () => {
      if (anyTeamsChanged.value) {
        Modal.confirm({
          title: () => MsgConfirmCancelSettingTitle.value,
          icon: () => createVNode(ExclamationCircleOutlined),
          content: () => MsgConfirmCancelSettingDesc.value,
          okText: () => MsgOk.value,
          okButtonProps: {
            type: "primary",
            danger: true,
          },
          cancelText: () => MsgNo.value,
          async onOk() {
            await dispatch("teams/onSettingModalCancel");
          },
          onCancel() {
            //
          },
        });
      } else {
        await dispatch("teams/onSettingModalCancel");
      }
    };

    //* watchers */
    watch(createModalOpened, async (opened) => {
      if (opened) {
        await nextTick();
        teamNameInputEl.value?.focus();
      }
    });

    watch(
      teams,
      () => {
        if (!anyTeamsChanged.value) {
          anyTeamsChanged.value = true;
        }
      },
      { deep: true },
    );

    watch(
      () => createTeamState.color,
      (value) => {
        if (value.startsWith("rgba")) {
          createTeamState.color = createTeamState.color.replace("rgba", "rgb");
        }
      },
    );
    provide(readOnlyNumbOfTeams, readonly(computed(() => teams.value.length)));

    //* Messages */
    const MsgFetchingTeams = computed(() => fmtMsg(TeamLocales.FetchingTeams));
    const MsgEditTeam = computed(() => fmtMsg(TeamLocales.EditTeam));
    const MsgNewTeam = computed(() => fmtMsg(TeamLocales.NewTeam));
    const MsgTeamName = computed(() => fmtMsg(TeamLocales.TeamName));
    const MsgTeamColor = computed(() => fmtMsg(TeamLocales.TeamColor));
    const MsgMaxTeamWarning = computed(() => fmtMsg(TeamLocales.MaxTeamWaring));
    const MsgTeamNameRequired = computed(() => fmtMsg(TeamLocales.TeamNameRequired));
    const MsgConfirmCancelSettingTitle = computed(() => fmtMsg(TeamLocales.ConfirmCancelSettingTitle));
    const MsgConfirmCancelSettingDesc = computed(() => fmtMsg(TeamLocales.ConfirmCancelSettingDesc));
    const MsgOk = computed(() => fmtMsg(CommonLocale.CommonYesButtonText));
    const MsgNo = computed(() => fmtMsg(CommonLocale.CommonNoButtonText));
    return {
      MsgFetchingTeams,
      MsgTeamName,
      MsgTeamColor,
      MsgEditTeam,
      MsgNewTeam,
      errors,
      teams,
      nonTeamStudents,
      addNewTeam,
      resetTeams,
      handleEdit,
      handleDel,
      handleMute,
      handleSubmit,
      createModalOpened,
      createTeamState,
      toggleCreateTeamModal,
      isFetchingTeams,
      teamNameInputEl,
      saveChanges,
      cancelChanges,
    };
  },
});
