import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@/assets/images/home-class.png'


const _hoisted_1 = {
  key: 0,
  class: "max-w-7xl px-6 py-8 w-full mx-auto text-sm"
}
const _hoisted_2 = { class: "text-center space-y-2" }
const _hoisted_3 = { class: "text-2xl font-medium" }
const _hoisted_4 = { class: "content-container" }
const _hoisted_5 = { class: "item-container teacher-container" }
const _hoisted_6 = { class: "item-name" }
const _hoisted_7 = { class: "item-container student-container" }
const _hoisted_8 = { class: "item-name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_UserIcon = _resolveComponent("UserIcon")!

  return (_ctx.isLoaded)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.welcomeText) + " " + _toDisplayString(_ctx.username), 1),
          _createElementVNode("div", null, _toDisplayString(_ctx.chooseRoleText), 1)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_router_link, { to: "/teacher" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_5, [
                _cache[0] || (_cache[0] = _createElementVNode("img", {
                  src: _imports_0,
                  alt: "",
                  class: "brightness-0 invert h-5 mx-auto"
                }, null, -1)),
                _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.teacherText), 1)
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_router_link, { to: "/parent" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_UserIcon, { class: "h-6 w-6 text-white mx-auto" }),
                _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.studentText), 1)
              ])
            ]),
            _: 1
          })
        ])
      ]))
    : _createCommentVNode("", true)
}