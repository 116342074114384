import { computed, watch } from "vue";
import { useStore } from "vuex";

export const useObjectState = () => {
  const { getters } = useStore();
  const lines = computed(() => getters["annotation/shapes"]);
  const linesOne = computed(() => getters["annotation/oneOneTeacherStrokes"]);
  const shapes = computed(() => getters["annotation/teacherShape"]);
  const shapesOne = computed(() => getters["annotation/oneTeacherShape"]);
  const texts = computed(() => getters["annotation/drawings"]);
  const lastUpdatedFabricObject = computed(() => getters["annotation/lastFabricUpdated"]);
  const lastDeletedFabricObjectId = computed<null | string>(() => getters["annotation/lastFabricIdDeleted"]);
  return {
    lines,
    linesOne,
    shapes,
    shapesOne,
    texts,
    lastDeletedFabricObjectId,
    lastUpdatedFabricObject,
  };
};
