import { Logger } from "./logger";

export class LocalStorageHandler<T> {
  private storageKey: string;

  constructor(storageKey: string) {
    this.storageKey = storageKey;
  }

  // Get value from localStorage
  get(): T | null {
    try {
      const storedValue = localStorage.getItem(this.storageKey);
      return storedValue ? JSON.parse(storedValue) : null;
    } catch (error) {
      Logger.error(`Error retrieving data from localStorage: ${error.message}`);
      return null;
    }
  }

  // Set value to localStorage
  set(value: T): void {
    try {
      const serializedValue = JSON.stringify(value);
      localStorage.setItem(this.storageKey, serializedValue);
    } catch (error) {
      Logger.error(`Error saving data to localStorage: ${error.message}`);
    }
  }

  // Clear the value from localStorage
  clear(): void {
    try {
      localStorage.removeItem(this.storageKey);
    } catch (error) {
      Logger.error(`Error clearing data from localStorage: ${error.message}`);
    }
  }
}
