import { TeacherClassLessonPlan } from "@/locales/localeid";
import { Exposure, ExposureItem, ExposureItemMedia } from "@/store/lesson/state";
import { Empty } from "ant-design-vue";
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { fmtMsg } from "vue-glcommonui";
import { ExposureDetailType } from "../lesson-plan";
import ExposureItemBlock from "./exposure-item/exposure-item.vue";
import AlternativeItemBlock from "./alternative-item/alternative-item.vue";

export default defineComponent({
  emits: ["click-back"],
  props: ["exposure", "type"],
  components: {
    ExposureItemBlock,
    AlternativeItemBlock,
    Empty,
  },
  setup(props) {
    const showInfo = ref(false);
    const listMedia = ref<ExposureItemMedia[]>([]);
    const listMediaAsAlternative = ref<ExposureItemMedia[][]>([]);
    const exposureTitle = ref("");
    const teachingContentEmpty = ref(true);
    const transitionText = computed(() => fmtMsg(TeacherClassLessonPlan.Transition));
    const lessonCompleteText = computed(() => fmtMsg(TeacherClassLessonPlan.LessonComplete));
    const relatedSlidesText = computed(() => fmtMsg(TeacherClassLessonPlan.RelatedSlides));
    const componentSlidesText = computed(() => fmtMsg(TeacherClassLessonPlan.ComponentSlides));
    const activitySlidesText = computed(() => fmtMsg(TeacherClassLessonPlan.ActivitySlides));
    const alternateMediaText = computed(() => fmtMsg(TeacherClassLessonPlan.AlternateMedia));
    const isTeachingActivity = computed(() => props.type === ExposureDetailType.TEACHING_ACTIVITY_BLOCK);
    const isAlternative = computed(() => props.type === ExposureDetailType.ALTERNATE_MEDIA_BLOCK);
    const checkExposureIncludedMedia = (exposureItem: ExposureItem) => exposureItem.media[0]?.image?.url;
    const buildMediaFromExposure = (exposureItem: ExposureItem) => {
      return exposureItem.media.map((media) => {
        const mediaCloned = { ...media };
        mediaCloned.teachingContent = isTeachingActivity.value ? exposureItem.textContent : "";
        mediaCloned.teacherUseOnly = exposureItem.teacherUseOnly || mediaCloned.teacherUseOnly;
        if (isAlternative.value) {
          mediaCloned.mediaTypeId = exposureItem.mediaTypeId;
        }
        return mediaCloned;
      });
    };
    watch(props, () => {
      configLessonPlan(props.exposure);
    });
    onMounted(() => {
      configLessonPlan(props.exposure);
    });
    const configLessonPlan = (exposure?: Exposure) => {
      if (!exposure) return;
      const { alternateMediaBlockItems, teachingActivityBlockItems, contentBlockItems } = exposure;
      let resultList = exposure.items;
      if (exposure.teachingActivityBlockItems.findIndex((teachingItem) => teachingItem.textContent) > -1) {
        teachingContentEmpty.value = false;
      }
      switch (props.type) {
        case ExposureDetailType.TRANSITION_BLOCK:
          exposureTitle.value = transitionText.value;
          break;
        case ExposureDetailType.LP_COMPLETE_BLOCK:
          exposureTitle.value = lessonCompleteText.value;
          break;
        case ExposureDetailType.VCP_BLOCK:
          exposureTitle.value = relatedSlidesText.value;
          break;
        case ExposureDetailType.TEACHING_ACTIVITY_BLOCK:
          resultList = teachingActivityBlockItems;
          exposureTitle.value = activitySlidesText.value;
          break;
        case ExposureDetailType.CONTENT_BLOCK:
          resultList = contentBlockItems;
          exposureTitle.value = componentSlidesText.value;
          break;
        case ExposureDetailType.ALTERNATE_MEDIA_BLOCK:
          listMediaAsAlternative.value = alternateMediaBlockItems.map((item) => {
            return item.filter(checkExposureIncludedMedia).map(buildMediaFromExposure).flat(1);
          });
          exposureTitle.value = alternateMediaText.value;
          break;
        default:
          break;
      }
      listMedia.value = resultList.filter(checkExposureIncludedMedia).map(buildMediaFromExposure).flat(1);
    };
    const toggleInformationBox = () => {
      showInfo.value = !showInfo.value;
    };
    const isContentBlock = computed(() => props.type === ExposureDetailType.CONTENT_BLOCK);
    const isVCPBlock = computed(() => props.type === ExposureDetailType.VCP_BLOCK);
    const isTransitionBlock = computed(() => props.type === ExposureDetailType.TRANSITION_BLOCK);
    const isLpCompleteBlock = computed(() => props.type === ExposureDetailType.LP_COMPLETE_BLOCK);
    const isTeachingActivityBlock = computed(() => props.type === ExposureDetailType.TEACHING_ACTIVITY_BLOCK);
    const isAlternateMediaBlock = computed(() => props.type === ExposureDetailType.ALTERNATE_MEDIA_BLOCK);
    return {
      toggleInformationBox,
      showInfo,
      listMedia,
      listMediaAsAlternative,
      isContentBlock,
      isVCPBlock,
      isTeachingActivityBlock,
      isLpCompleteBlock,
      isAlternateMediaBlock,
      exposureTitle,
      teachingContentEmpty,
      isTransitionBlock,
    };
  },
});
