import { CAMERA_ID_KEY, MICROPHONE_ID_KEY, SPEAKER_ID_KEY } from "@/utils/constant";
import { ActionTree } from "vuex";
import { AppState, AppView, LayoutType, ToastData, UserRole, VCPlatform } from "./state";

const actions: ActionTree<AppState, any> = {
  setToast(store, payload: ToastData) {
    store.commit("setToast", payload);
  },
  setLayout(store, payload: { layout: LayoutType }) {
    store.commit("setLayout", payload);
  },
  setAppView(store, payload: { appView: AppView }) {
    store.commit("setAppView", payload);
  },
  setMuteAudio(store, payload: { status: number }) {
    store.commit("setMuteAudio", payload);
  },
  setHideVideo(store, payload: { status: number }) {
    store.commit("setHideVideo", payload);
  },
  async setCameraDeviceId(store, payload: string) {
    localStorage.setItem(CAMERA_ID_KEY, payload);
    store.commit("setCameraDeviceId", payload);
  },
  async setMicrophoneDeviceId(store, payload: string) {
    localStorage.setItem(MICROPHONE_ID_KEY, payload);
    store.commit("setMicrophoneDeviceId", payload);
  },
  async setSpeakerDeviceId(store, payload: string) {
    localStorage.setItem(SPEAKER_ID_KEY, payload);
    store.commit("setSpeakerDeviceId", payload);
  },
  setHavePermissionCamera(store, payload: boolean) {
    store.commit("setHavePermissionCamera", payload);
  },
  setHavePermissionMicrophone(store, payload: boolean) {
    store.commit("setHavePermissionMicrophone", payload);
  },
  setUserRoleByView(store, payload: UserRole) {
    store.commit("setUserRoleByView", payload);
  },
  setVideoCallPlatform(store, payload: VCPlatform) {
    store.commit("setVideoCallPlatform", payload);
  },
  setSingalrInited(store, payload: boolean) {
    store.commit("setSingalrInited", payload);
  },
  setCheckMessageVersionTimer(store, payload: number) {
    store.commit("setCheckMessageVersionTimer", payload);
  },
  setTeacherMessageVersion(store, payload: number) {
    store.commit("setTeacherMessageVersion", payload);
  },
  setCheckMediaTimer(store) {
    store.commit("setCheckMediaTimer");
  },
  setLowBandWidth(store, payload: boolean) {
    store.commit("setLowBandWidth", payload);
  },
};

export default actions;
