<template>
  <div class="flex items-center space-x-1 select-none">
    <Tooltip :title="stickerAllText" placement="bottom">
      <button
        type="button"
        class="relative rounded-md border bg-white p-2 text-sm font-medium text-gray-900 shadow-sm hover:bg-gray-50"
        @click="onClickBadgeAll"
        :disabled="isLoadingBadge"
      >
        <LoadingOutlined class="animate-spin" v-if="isLoadingBadge" />
        <template v-else>
          <img :src="require('@/assets/teacher-class/sticker-star-small.svg')" :alt="stickerAllText" class="h-5 w-5" />
          <PlusIcon class="absolute left-1/2 top-1/2 text-white -translate-x-1/2 -translate-y-1/2 h-4 w-4" />
        </template>
      </button>
    </Tooltip>
    <Tooltip :title="isAllOnlineStudentsAudioMuted ? unmuteAllText : muteAllText" placement="bottom">
      <button
        type="button"
        class="rounded-md border bg-white p-2 text-sm font-medium text-gray-900 shadow-sm hover:bg-gray-50"
        @click="toggleAudio"
        :disabled="isLoadingMicrophone"
      >
        <span class="h-5 w-5 flex items-center justify-center">
          <LoadingOutlined class="animate-spin" v-if="isLoadingMicrophone" />
          <MicrophoneIcon v-else icon-class="h-5 w-5 text-slate-600" :enabled="isAllOnlineStudentsAudioMuted" />
        </span>
      </button>
    </Tooltip>
    <Tooltip :title="isAllOnlineStudentsVideoHidden ? showAllText : hideAllText" placement="bottom">
      <button
        type="button"
        class="rounded-md border bg-white p-2 text-sm font-medium text-gray-900 shadow-sm hover:bg-gray-50"
        @click="toggleVideo"
        :disabled="isLoadingCamera"
      >
        <span class="h-5 w-5 flex items-center justify-center">
          <LoadingOutlined class="animate-spin" v-if="isLoadingCamera" />
          <component
            v-else
            class="h-5 w-5 text-slate-600"
            :is="isAllOnlineStudentsVideoHidden ? VideoCameraIcon : VideoCameraSlashIcon"
            :alt="isAllOnlineStudentsVideoHidden ? showAllText : hideAllText"
          />
        </span>
      </button>
    </Tooltip>
    <Tooltip :title="disableAllText" placement="bottom">
      <button
        type="button"
        class="rounded-md border bg-white p-2 text-sm font-medium text-gray-900 shadow-sm hover:bg-gray-50 disabled:bg-slate-200 disabled:grayscale"
        :disabled="isAllOnlineStudentsDisabledPalette || isLoadingPalette"
        @click="onClickDisableAllPalette"
      >
        <LoadingOutlined class="animate-spin" v-if="isLoadingPalette" />
        <span v-else class="h-5 w-5 flex items-center justify-center">
          <img
            class="h-5 w-5 text-slate-600"
            :class="[isAllOnlineStudentsDisabledPalette && 'opacity-60']"
            :src="
              isAllOnlineStudentsDisabledPalette
                ? require(`@/assets/teacher-class/touch-on-small-blue.svg`)
                : require(`@/assets/teacher-class/touch-off-small-blue.svg`)
            "
            :alt="disableAllText"
          />
        </span>
      </button>
    </Tooltip>
    <Tooltip :title="independentButtonText" placement="bottomRight" v-if="independentModeShown">
      <button
        type="button"
        class="rounded-md border bg-white p-2 text-sm font-medium text-gray-900 shadow-sm hover:bg-gray-50 disabled:bg-slate-200 disabled:grayscale"
        :disabled="independentModeDisabled"
        @click="toggleIndependentMode"
      >
        <span class="h-5 w-5 flex items-center justify-center">
          <img
            class="h-5 w-5 text-slate-600"
            :class="[independentModeDisabled && 'opacity-60']"
            :src="
              isIndependentMode
                ? require(`@/assets/images/student-control/independent-mode-end.png`)
                : require(`@/assets/images/student-control/independent-mode.png`)
            "
            :alt="isIndependentMode ? MsgStopIndependentMode : MsgStartIndependentMode"
          />
        </span>
      </button>
    </Tooltip>
  </div>
</template>

<!--<style lang="scss" scoped src="./student-controls.scss"></style>-->
<script lang="ts" src="./student-controls.ts"></script>
