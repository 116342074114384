<template>
  <div
    class="cursor-pointer z-[1] absolute top-1/2 -translate-y-1/2 w-12 h-12 bg-gs-purple rounded-full flex justify-center items-center opacity-80 hover:opacity-100 transition-opacity"
    :class="isNext ? 'right-[2%]' : 'left-[2%]'"
    @click="moveSlide"
  >
    <component :is="isNext ? ChevronRightIcon : ChevronLeftIcon" class="w-9 h-9 text-white" />
  </div>
</template>
<script lang="ts" src="./lesson-nav.ts"></script>
