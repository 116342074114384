<template>
  <div class="w-full h-full">
    <CropImage
      v-if="
        typeof item.image.metaData?.width === 'number' &&
        item.image.metaData?.width > 0 &&
        typeof item.image.metaData?.height === 'number' &&
        item.image.metaData.height > 0
      "
      :imageUrl="item.image.url"
      :metadata="item.image.metaData"
      class="w-full h-full object-contain"
      @img-error="handleImageLoadError"
      :key="reloadKey"
    />
    <img
      v-else
      crossorigin="anonymous"
      class="w-full h-full object-contain"
      :src="item.image.url"
      alt=""
      :style="[
        item.image.metaData
          ? {
              transform: `scale(${item.image.metaData.scaleX},${item.image.metaData.scaleY}) rotate(${item.image.metaData.rotate}deg)`,
              // if img is rotated, width equal to height of the parent div
              width:
                item.image.metaData.rotate && (Math.abs(item.image.metaData.rotate) === 270 || Math.abs(item.image.metaData.rotate) === 90)
                  ? '100px'
                  : '100%',
            }
          : '',
      ]"
      @error="handleImageLoadError"
      :key="reloadKey"
    />
  </div>
</template>
<script lang="ts" src="./thumbnail.ts"></script>
<style scoped>
img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
}
</style>
