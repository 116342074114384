import { IndependentService, IndependentStudentItem } from "@/services/independent";
import { ActionTree } from "vuex";
import { StudentState } from "../../room/interface";
import { TeachingMode } from "../interfaces";
import { TeacherTeachingState } from "./state";
import { Logger } from "@/utils/logger";

const actions: ActionTree<TeacherTeachingState, any> = {
  async toggleIndependentMode({ commit, rootGetters }) {
    const isIndependentMode = rootGetters["classTeaching/isIndependentMode"];
    let currentUnit: null | number = null;
    let currentLesson: null | number = null;
    try {
      if (isIndependentMode) {
        //* end the independent mode
        currentUnit = rootGetters["classTeaching/getIndependentModeCurrentUnit"];
        currentLesson = rootGetters["classTeaching/getIndependentModeCurrentLesson"];
        if (!currentUnit || !currentLesson) return;
        await IndependentService.toggleIndependentMode(currentUnit, currentLesson);
        commit("setIndependentListStudentItems", null);
        commit("classTeaching/setIndependentModeCurrentUnit", null, { root: true });
        commit("classTeaching/setIndependentModeCurrentLesson", null, { root: true });
      } else {
        //* start the independent mode
        const firstBlockItemId: string | undefined = rootGetters["lesson/listReaderItemsOfCurrentExposure"]?.[0]?.id;
        currentUnit = rootGetters["teacherRoom/currentUnit"];
        currentLesson = rootGetters["teacherRoom/currentLesson"];
        if (!currentUnit || !currentLesson) return;
        if (firstBlockItemId) {
          await IndependentService.toggleIndependentMode(currentUnit, currentLesson, firstBlockItemId);
          const students: StudentState[] = rootGetters["teacherRoom/students"];
          const payload: IndependentStudentItem[] = students.map((student) => ({ studentId: student.id, currentItemId: firstBlockItemId }));
          commit("setIndependentListStudentItems", payload);
          commit("classTeaching/setIndependentModeCurrentUnit", currentUnit, { root: true });
          commit("classTeaching/setIndependentModeCurrentLesson", currentLesson, { root: true });
        }
      }
      commit("classTeaching/setMode", isIndependentMode ? TeachingMode.Normal : TeachingMode.Independent, { root: true });
    } catch (error) {
      Logger.error(error);
    }
  },
  helperToggleIndependentMode({ commit, rootGetters }, firstItemId: string | null) {
    if (!firstItemId) {
      commit("setIndependentListStudentItems", null);
      commit("classTeaching/setMode", TeachingMode.Normal, { root: true });
      return;
    }
    const students: StudentState[] = rootGetters["teacherRoom/students"];
    const payload: IndependentStudentItem[] = students.map((student) => ({ studentId: student.id, currentItemId: firstItemId }));
    commit("setIndependentListStudentItems", payload);
    commit("classTeaching/setMode", TeachingMode.Independent, { root: true });
  },
};

export default actions;
