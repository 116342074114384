import { fabric } from "fabric";
import { computed } from "vue";
import { DefaultCanvasDimension } from "vue-glcommonui";
import { useStore } from "vuex";
import { MIN_ZOOM_RATIO } from "@/utils/constant";
import {
  assignUniquePropertiesToNewlyCreatedCanvasObject,
  getAllObjectsConsideredAsShapes,
  getFixedStrokeSizeProperties,
  serializeFabricElementWithCustomAttributes,
} from "@/utils/fabric-utils";
import { TeacherState } from "@/store/room/interface";

export const addShape = () => {
  const store = useStore();
  const teacher = computed<TeacherState | undefined>(() => store.getters["teacherRoom/teacher"]);
  const sendRequestUpdateCanvasObjectsConsideredShapes = async (canvas: any) => {
    const shapes: Array<string> = [];
    getAllObjectsConsideredAsShapes(canvas).forEach((obj: any) => {
      obj = serializeFabricElementWithCustomAttributes(obj);
      obj.id = teacher.value?.id;
      shapes.push(JSON.stringify(obj));
    });
    if (shapes.length) {
      await store.dispatch("teacherRoom/setShapesForStudent", shapes);
    }
  };

  const addCircle = async (canvas: any, strokeColor: any, strokeWidth: any, oneAndOne: any) => {
    const vpTransform = canvas?.viewportTransform;
    const zoom = vpTransform?.[0] ?? MIN_ZOOM_RATIO;
    const canvasTranslateX = vpTransform?.[4] ?? 0;
    const canvasTranslateY = vpTransform?.[5] ?? 0;
    const initTopLeftX = (DefaultCanvasDimension.width / 2 - canvasTranslateX) / zoom;
    const initTopLeftY = (DefaultCanvasDimension.height / 2 - canvasTranslateY) / zoom;
    const circle = new fabric.Circle({
      left: initTopLeftX,
      top: initTopLeftY,
      radius: 30,
      fill: "",
      stroke: strokeColor.value,
      strokeWidth: strokeWidth.value,
      id: teacher.value?.id,
      isOneToOne: oneAndOne.value || null,
      originX: "center",
      originY: "center",
      ...getFixedStrokeSizeProperties(),
    });
    assignUniquePropertiesToNewlyCreatedCanvasObject(circle);
    canvas.add(circle);
    canvas.setActiveObject(circle);
    await sendRequestUpdateCanvasObjectsConsideredShapes(canvas);
  };
  const addSquare = async (canvas: any, strokeColor: any, strokeWidth: any, oneAndOne: any) => {
    const vpTransform = canvas?.viewportTransform;
    const zoom = vpTransform?.[0] ?? MIN_ZOOM_RATIO;
    const canvasTranslateX = vpTransform?.[4] ?? 0;
    const canvasTranslateY = vpTransform?.[5] ?? 0;
    const initTopLeftX = (DefaultCanvasDimension.width / 2 - canvasTranslateX) / zoom;
    const initTopLeftY = (DefaultCanvasDimension.height / 2 - canvasTranslateY) / zoom;
    const square = new fabric.Rect({
      left: initTopLeftX,
      top: initTopLeftY,
      width: 50,
      height: 50,
      fill: "",
      stroke: strokeColor.value,
      strokeWidth: strokeWidth.value,
      id: teacher.value?.id,
      isOneToOne: oneAndOne.value || null,
      originX: "center",
      originY: "center",
      ...getFixedStrokeSizeProperties(),
    });
    assignUniquePropertiesToNewlyCreatedCanvasObject(square);
    canvas.add(square);
    canvas.setActiveObject(square);
    await sendRequestUpdateCanvasObjectsConsideredShapes(canvas);
  };
  return {
    sendRequestUpdateCanvasObjectsConsideredShapes,
    addCircle,
    addSquare,
  };
};
