import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, Teleport as _Teleport } from "vue"

const _hoisted_1 = { class: "final-modal__content final-modal-scroll" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PushpinOutlined = _resolveComponent("PushpinOutlined")!
  const _component_CloseOutlined = _resolveComponent("CloseOutlined")!
  const _component_vue_final_modal = _resolveComponent("vue-final-modal")!

  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    _createVNode(_component_vue_final_modal, {
      modelValue: _ctx.showed,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.showed) = $event)),
      classes: _ctx.PINNING_MODAL_CONTAINER,
      "content-class": [_ctx.MODAL_CONTENT, _ctx.pressing && 'translating'],
      resize: _ctx.pined,
      drag: _ctx.pined,
      "click-to-close": false,
      "min-width": 150,
      "min-height": 100,
      "hide-overlay": true,
      "prevent-click": true,
      style: {"z-index":"20"}
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          class: _normalizeClass(['final-modal', !_ctx.pined && _ctx.showed && 'has-before', _ctx.pined && 'cursor-move'])
        }, [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", {
              class: "final-modal__close",
              onMousedown: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onMouseDown && _ctx.onMouseDown(...args))),
              onMouseup: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onMouseUp && _ctx.onMouseUp(...args)))
            }, [
              (!_ctx.pined && _ctx.showed)
                ? (_openBlock(), _createBlock(_component_PushpinOutlined, {
                    key: 0,
                    class: "final-modal__close--icon text-white"
                  }))
                : _createCommentVNode("", true),
              (_ctx.pined && _ctx.showed)
                ? (_openBlock(), _createBlock(_component_CloseOutlined, {
                    key: 1,
                    class: "final-modal__close--icon text-white"
                  }))
                : _createCommentVNode("", true)
            ], 32),
            _renderSlot(_ctx.$slots, "final-modal-content")
          ])
        ], 2)
      ]),
      _: 3
    }, 8, ["modelValue", "classes", "content-class", "resize", "drag"])
  ]))
}