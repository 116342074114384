import useCanvas from "@/hooks/use-canvas";
import { AnnotationTypes, LPItemMetadata } from "@/store/lesson/state";
import { calcRatioVal, getRadius, isRotatedLPSlide, TransparentRgba } from "@/utils/utils";
import { ConnectObjectTypes, FbEvents, FbObjectOrigins, FbObjects } from "@/utils/fabric-utils";
import { fabric } from "fabric";
import { useLessonStore } from "@/hooks/use-lesson-store";
import { computed, Ref, ref, watch } from "vue";
import { useAnnotationStore } from "@/hooks/use-annotation-store";
import { vuexName, VuexNames } from "@/store/utils";
import { useStore } from "vuex";
import * as slideUtils from "@/utils/slide-utils";
export enum CustomPropertiesForTarget {
  RealFill = "realFill",
  RealOpacity = "realOpacity",
  RealStroke = "realStroke",
}
export interface UseTargetOptions {
  isBlockClickOnTarget?: Ref<boolean>;
}
export const useTarget = (options?: UseTargetOptions) => {
  // * groupRef: reference to the group object which contains the slide and its targets
  const groupRef = ref<any>(null);
  const { dispatch } = useStore();
  const { visibleTargetTags, currentSelectedSlide, setVisibleTargetTagsToEmpty } = useLessonStore();
  const { naturalWidthOfCurrentSlide, naturalHeightOfCurrentSlide } = useAnnotationStore();
  const isAllTargetsOnCanvasVisible = computed(
    () => visibleTargetTags.value.length === currentSelectedSlide.value?.image?.metaData?.annotations?.length,
  );
  const isAllTargetsOnCanvasTransparent = computed(() => visibleTargetTags.value.length === 0);
  // * After slide is added to canvas, if slide has targets, initialize them to canvas as well
  const initializeTargets = (metadata: LPItemMetadata) => {
    const slideTargets = metadata.annotations;
    const [canvas] = useCanvas();
    if (!slideTargets) return;
    const group = canvas.getObjects("group")[0];
    if (!group) return;
    groupRef.value = group;
    const isRotatedSlide = isRotatedLPSlide(metadata);
    const naturalWidth = isRotatedSlide ? naturalHeightOfCurrentSlide.value : naturalWidthOfCurrentSlide.value;
    const naturalHeight = isRotatedSlide ? naturalWidthOfCurrentSlide.value : naturalHeightOfCurrentSlide.value;
    const { imgLeftCrop, ratio } = calcRatioVal({
      metadata,
      widthImg: naturalWidth,
      heightImg: naturalHeight,
    });
    slideTargets.forEach((item) => {
      const fixedAttributes = {
        originX: FbObjectOrigins.Center,
        originY: FbObjectOrigins.Center,
        fill: TransparentRgba,
        left: (item.x - metadata.x) * ratio + imgLeftCrop,
        top: (item.y - metadata.y) * ratio,
        angle: item.rotate,
        [CustomPropertiesForTarget.RealFill]: item.fill ?? TransparentRgba,
        [CustomPropertiesForTarget.RealOpacity]: item.opacity,
        [CustomPropertiesForTarget.RealStroke]: item.color,
        stroke: TransparentRgba,
        opacity: item.opacity,
        strokeWidth: item.fill ? 0 : 5 * ratio,
        id: ConnectObjectTypes.Target,
        perPixelTargetFind: true,
        // TODO: currently the named tag logic is duplicated on web and mobile app, we need to put it in backend
        tag: `${item.type === AnnotationTypes.Rect ? FbObjects.Rect : FbObjects.Circle}-${Math.floor(item.x)}${Math.floor(item.y)}`,
      };
      let shape;
      if (item.type === AnnotationTypes.Rect) {
        shape = new fabric.Rect({
          width: item.width * ratio,
          height: item.height * ratio,
          ...fixedAttributes,
        });
      } else {
        shape = new fabric.Ellipse({
          radius: getRadius(item.width * ratio, item.height * ratio),
          rx: (item.width / 2) * ratio,
          ry: (item.height / 2) * ratio,
          ...fixedAttributes,
        });
      }
      group.addWithUpdate(shape);
      shape.on(FbEvents.ObjectMouseDown, async (event: any) => {
        if (options?.isBlockClickOnTarget?.value) return;
        const clickedObject = event.subTargets[0];
        await handleClickOnTarget(clickedObject.tag);
      });
    });
    toggleTargetsTransparencyOnCanvas(visibleTargetTags.value);
  };

  // * Get all targets of the current group on canvas
  const getTargetsOfCurrentGroup = () => groupRef.value?.getObjects().filter((item: any) => !!item.tag) ?? [];

  // * Handle teacher press "Show All" or "Hide All" button (only for teacher)
  const handleClickToggleAllTargetsBtn = async (isShowAll: boolean) => {
    await dispatch(vuexName(VuexNames.LESSON.DISPATCHES.TOGGLE_ALL_VISIBLE_TARGETS), {
      tags: getTargetsOfCurrentGroup().map((item: any) => item.tag),
      isShowAll,
    });
  };

  // * Handle teacher/student click on a target
  const handleClickOnTarget = async (tag: string) => {
    await dispatch(vuexName(VuexNames.LESSON.DISPATCHES.TOGGLE_VISIBLE_TARGET), tag);
  };

  // * Show/Hide targets on canvas based on the visibleTargetTags
  const toggleTargetsTransparencyOnCanvas = (nextVisibleTags: string[]) => {
    if (!groupRef.value) return;
    const [canvas] = useCanvas();
    getTargetsOfCurrentGroup().forEach((target: any) => {
      const isVisible = nextVisibleTags.includes(target.tag);
      target.fill = isVisible ? target[CustomPropertiesForTarget.RealFill] : TransparentRgba;
      target.stroke = isVisible ? target[CustomPropertiesForTarget.RealStroke] : TransparentRgba;
    });
    groupRef.value.setCoords(); // update group's bounding box
    canvas.renderAll(); // re-render the canvas
  };

  watch(currentSelectedSlide, (newSlide, oldSlide) => {
    // We don't clear the visibleTargetTags when the first time entering the class, that's why we need to check if oldSlide exists
    slideUtils.isTeacherChangedFromCurrentSlideToOtherSlideOrNoSlide(newSlide, oldSlide) && setVisibleTargetTagsToEmpty();
  });

  // * Watch visibleTargetTags and update targets on canvas
  watch(visibleTargetTags, toggleTargetsTransparencyOnCanvas);

  return {
    initializeTargets,
    visibleTargetTags,
    handleClickToggleAllTargetsBtn,
    isAllTargetsOnCanvasVisible,
    isAllTargetsOnCanvasTransparent,
  };
};
