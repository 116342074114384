import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavMenu = _resolveComponent("NavMenu")!
  const _component_ResourceMenu = _resolveComponent("ResourceMenu")!
  const _component_HelpMenu = _resolveComponent("HelpMenu")!
  const _component_LanguagePicker = _resolveComponent("LanguagePicker")!
  const _component_DropdownItem = _resolveComponent("DropdownItem")!
  const _component_UserAvatar = _resolveComponent("UserAvatar")!
  const _component_DeviceSettingsModal = _resolveComponent("DeviceSettingsModal")!
  const _component_AppHeader = _resolveComponent("AppHeader")!

  return (_openBlock(), _createBlock(_component_AppHeader, {
    class: "header text-sm !bg-gs-purple",
    title: _ctx.title || ''
  }, {
    right: _withCtx(() => [
      (_ctx.isShowNavMenu)
        ? (_openBlock(), _createBlock(_component_NavMenu, { key: 0 }))
        : _createCommentVNode("", true),
      _createVNode(_component_ResourceMenu),
      _createVNode(_component_HelpMenu),
      _createVNode(_component_LanguagePicker),
      _createVNode(_component_UserAvatar, null, {
        extraItems: _withCtx(() => [
          (_ctx.testDeviceShown)
            ? (_openBlock(), _createBlock(_component_DropdownItem, {
                key: 0,
                text: _ctx.testConnectText,
                onClick: _withModifiers(_ctx.onClickTestDevice, ["prevent"])
              }, null, 8, ["text", "onClick"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      (_ctx.testDeviceShown)
        ? (_openBlock(), _createBlock(_component_DeviceSettingsModal, {
            key: 0,
            hideFooter: true,
            visible: _ctx.deviceSettingsModalShown,
            "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.deviceSettingsModalShown) = $event))
          }, null, 8, ["visible"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["title"]))
}