import { TeacherRoomManager } from "@/manager/room/teacher.manager";
import { ClassModel, RoomModel } from "@/models";
import { BlobTagItem } from "@/services/storage/interface";
import { GetterTree } from "vuex";
import {
  ClassView,
  HelperState,
  InClassStatus,
  ParamsToJoinCurSessionInterface,
  StudentCaptureStatus,
  StudentState,
  TeacherState,
  UserMediaStatus,
} from "../interface";
import { ClassAction } from "../student/state";
import { TeacherRoomState } from "./state";
import { TeacherWSClient } from "@/ws";

const getters: GetterTree<TeacherRoomState, any> = {
  isConnected(state: TeacherRoomState): boolean {
    return !!state.manager?.WSClient.isConnected;
  },
  enableAudios(state: TeacherRoomState): Array<string> {
    if (state.localAudios.length > 0) return state.localAudios;
    else if (state.globalAudios.length > 0) return state.globalAudios;
    return [];
  },
  globalAudios(state: TeacherRoomState): Array<{ studentId: string; tag: string }> {
    return state.students
      .filter((s) => state.globalAudios.indexOf(s.id) !== -1)
      .map((s) => {
        return {
          studentId: s.id,
          tag: `${s.index + 1}`,
        };
      });
  },
  localAudios(state: TeacherRoomState): Array<{ studentId: string; tag: string }> {
    return state.students
      .filter((s) => state.localAudios.indexOf(s.id) !== -1)
      .map((s) => {
        return {
          studentId: s.id,
          tag: `${s.index + 1}`,
        };
      });
  },
  info(state: TeacherRoomState): RoomModel {
    return state.info as RoomModel;
  },
  sessionId(state: TeacherRoomState): string {
    return state.info?.id ?? "";
  },
  error(state: TeacherRoomState): any {
    return state.error;
  },
  classes(state: TeacherRoomState): Array<ClassModel> {
    return state.classes;
  },
  students(state: TeacherRoomState): Array<StudentState> {
    return state.students;
  },
  teacher(state: TeacherRoomState): TeacherState | undefined {
    return state.teacher as TeacherState;
  },
  teacherId(state: TeacherRoomState): string | undefined {
    return state.teacher?.id;
  },
  roomManager(state: TeacherRoomState): TeacherRoomManager {
    return state.manager as TeacherRoomManager;
  },
  wsClient(state: TeacherRoomState): TeacherWSClient | undefined {
    return state.manager?.WSClient;
  },
  classView(state: TeacherRoomState): ClassView {
    return state.classView;
  },
  getPaletteStudent(state: TeacherRoomState) {
    return state.students.find((student) => student.isPalette);
  },
  classAction(state: TeacherRoomState): ClassAction {
    return state.classAction;
  },
  getStudentModeOneId(state: TeacherRoomState): string {
    return state.idOne;
  },
  getStudentModeOneWithAnotherTeacherId(state: TeacherRoomState): string {
    return state.idOneWithAnotherTeacher;
  },
  getTeacherOrHelperOneToOne(state: TeacherRoomState): boolean {
    return !!state.idOne || !!state.idOneWithAnotherTeacher;
  },
  oneStudentIds(state: TeacherRoomState): string[] {
    const studentIds = [];
    if (state.idOne) {
      studentIds.push(state.idOne);
    }
    if (state.idOneWithAnotherTeacher) {
      studentIds.push(state.idOneWithAnotherTeacher);
    }
    return studentIds;
  },
  studentIdsNotInOneToOneMode(state: TeacherRoomState): string[] {
    return state.students.filter((st) => st.id !== state.idOne && st.id !== state.idOneWithAnotherTeacher).map((s) => s.id);
  },
  getStudentModeOneWithAnotherTeacher(state: TeacherRoomState): StudentState | null {
    const id = state.idOneWithAnotherTeacher;
    const student = state.students.find((s) => s.status !== InClassStatus.LEFT && s.id === id);
    return student ?? null;
  },
  speakingUsers(state: TeacherRoomState): Array<string> {
    return state.speakingUsers;
  },
  isDisconnected(state: TeacherRoomState): boolean {
    return state.isDisconnected;
  },
  listStudentLowBandWidth(state: TeacherRoomState): string[] {
    return state.listStudentLowBandWidth;
  },
  currentLesson(state: TeacherRoomState): number {
    return state.currentLesson;
  },
  currentUnit(state: TeacherRoomState): number {
    return state.currentUnit;
  },
  isShowWhiteBoard(state: TeacherRoomState): boolean {
    return state.isShowWhiteboard;
  },
  isTeacherUseOnly(state: TeacherRoomState): boolean {
    return state.isTeacherUseOnly;
  },
  studentsImageCaptured(state: TeacherRoomState): Array<BlobTagItem> {
    return state.studentsImageCaptured;
  },
  studentCaptureAll(state: TeacherRoomState): Array<StudentCaptureStatus> {
    return state.studentCaptureAll;
  },
  isCaptureAll(state: TeacherRoomState): boolean {
    return state.isCaptureAll;
  },
  getMediaState(state: TeacherRoomState): boolean {
    return state.mediaState;
  },
  getCurrentTimeMedia(state: TeacherRoomState): number {
    return state.currentTimeMedia;
  },
  helperId(state: TeacherRoomState): string | undefined {
    return state.helper?.id;
  },
  helperInfo(state: TeacherRoomState): HelperState | undefined {
    return state.helper;
  },
  helperVideoStatus(state: TeacherRoomState): boolean {
    return state.helper?.isVideoShownByTeacher ?? false;
  },
  helperCameraOn(state: TeacherRoomState): boolean {
    return state.helper?.videoEnabled ?? false;
  },
  helperMicroOn(state: TeacherRoomState): boolean {
    return state.helper?.audioEnabled ?? false;
  },
  helperAvatar(state: TeacherRoomState): string {
    return state.helper?.avatar ?? "";
  },
  getParamsToJoinCurSession(state: TeacherRoomState): ParamsToJoinCurSessionInterface {
    return state.paramsToJoinCurSession;
  },
  signalRConnected(state: TeacherRoomState): boolean {
    return !!state.manager?.WSClient.isConnected;
  },
  usersMedia(state: TeacherRoomState, _, rootState): UserMediaStatus[] {
    const { teacher, helper, students } = state;
    const joinAsHelper = rootState.teacher.isHelper;
    const users = students.map((student) => ({
      id: student.id,
      video: student.videoEnabled,
      audio: student.audioEnabled,
    }));
    if (joinAsHelper) {
      if (teacher?.id) {
        const { id, videoEnabled, audioEnabled } = teacher;
        users.push({ id, video: videoEnabled, audio: audioEnabled });
      }
    } else {
      if (helper?.id) {
        const { id, videoEnabled, audioEnabled } = helper;
        users.push({ id, video: videoEnabled, audio: audioEnabled });
      }
    }
    return users;
  },
  teacherIsDisconnected(state: TeacherRoomState): boolean {
    return state.teacherIsDisconnected;
  },
  studentIdsInOneToOneMode(state: TeacherRoomState): string[] {
    const studentIds = [];
    if (state.idOne) {
      studentIds.push(state.idOne);
    }
    if (state.idOneWithAnotherTeacher) {
      studentIds.push(state.idOneWithAnotherTeacher);
    }
    return studentIds;
  },
  getStudentById: (state: TeacherRoomState): ((studentId: string) => StudentState | undefined) => {
    return (studentId: string) => state.students.find((student) => student.id === studentId);
  },
  isCameraLocking(state: TeacherRoomState): boolean {
    return state.cameraLock;
  },
  isMicrophoneLocking(state: TeacherRoomState): boolean {
    return state.microphoneLock;
  },
};

export default getters;
