<template>
  <div class="my-4 flex-1 overflow-auto" ref="scrollElement">
    <div>
      <div v-if="!totalOnlineStudents" class="w-full mt-2 text-center border border-dotted rounded-xl p-4 border-slate-400 text-slate-500">
        {{ MsgNoStudentOnline }}
      </div>
      <div
        v-else
        ref="studentGallery"
        :id="'student-list'"
        class="grid gap-x-2 gap-y-2"
        :class="[oneWithTeacherStudentId ? 'grid-cols-1' : studentLayoutClass]"
      >
        <StudentCard
          v-for="(student, index) in topStudents"
          :key="student.id"
          :scaleOption="scaleVideoOption"
          :student="student"
          :setModeOne="true"
          :focusStudentId="focusedStudent"
          :class="getTransformOriginClass(index)"
        />
        <!--      <div-->
        <!--        class="participant-videos-wrapper"-->
        <!--        :id="'participant-videos-wrapper'"-->
        <!--        v-show="!oneWithTeacherStudentId && totalOnlineStudents"-->
        <!--        v-if="!isUsingAgora"-->
        <!--      >-->
        <!--        <canvas ref="canvasListStudentVideosRef" id="participant-videos-zoom" />-->
        <!--      </div>-->
        <!--      <div v-if="!isUsingAgora" v-show="focusedStudent" :style="expandedWrapperStyle" class="expanded__video__wrapper">-->
        <!--        <canvas id="expanded-video-canvas" />-->
        <!--      </div>-->
      </div>
    </div>
  </div>
</template>
<!--<style lang="scss" scoped src="./student-all.scss"></style>-->
<script lang="ts" src="./student-all.ts"></script>
