import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "cursor-move flex flex-col items-center relative" }
const _hoisted_2 = {
  key: 1,
  class: "absolute flex h-4 w-4 top-2 right-2"
}
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InOneIcon = _resolveComponent("InOneIcon")!
  const _component_NameTag = _resolveComponent("NameTag")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isOne)
      ? (_openBlock(), _createBlock(_component_InOneIcon, {
          key: 0,
          class: "w-9 h-9 absolute top-0 right-0"
        }))
      : (_ctx.isOnline)
        ? (_openBlock(), _createElementBlock("span", _hoisted_2, _cache[0] || (_cache[0] = [
            _createElementVNode("span", { class: "animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75" }, null, -1),
            _createElementVNode("span", { class: "relative inline-flex rounded-full h-4 w-4 bg-green-500" }, null, -1)
          ])))
        : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass([[!_ctx.teamColor && '3xl:w-32 3xl:h-32'], "w-24 h-24 shadow border border-white rounded-full overflow-hidden"])
    }, [
      _createElementVNode("img", {
        src: _ctx.generateAvatar(_ctx.member.avatarUrl),
        alt: "",
        class: "w-full h-full object-cover"
      }, null, 8, _hoisted_3)
    ], 2),
    _createVNode(_component_NameTag, {
      "is-muted": _ctx.isMuted,
      name: _ctx.member.name,
      class: _normalizeClass([[!_ctx.teamColor && '!3xl:max-w-[128px]'], "mt-2 !max-w-[96px]"]),
      "background-color": _ctx.teamColor
    }, null, 8, ["is-muted", "name", "class", "background-color"])
  ]))
}