import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/home-class.png'
import _imports_1 from '@/assets/images/today-icon.png'


export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderMenuItem = _resolveComponent("HeaderMenuItem")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_HeaderMenuItem, {
      title: _ctx.homeText,
      onClick: _ctx.onClickHome,
      class: "pr-2",
      url: "javascript:void(0)"
    }, {
      icon: _withCtx(() => _cache[0] || (_cache[0] = [
        _createElementVNode("img", {
          src: _imports_0,
          alt: "",
          class: "brightness-0 invert h-4 mr-2"
        }, null, -1)
      ])),
      _: 1
    }, 8, ["title", "onClick"]),
    _createVNode(_component_HeaderMenuItem, {
      title: _ctx.scheduleText,
      onClick: _ctx.onClickCalendar,
      class: "pr-1.5",
      url: "javascript:void(0)"
    }, {
      icon: _withCtx(() => _cache[1] || (_cache[1] = [
        _createElementVNode("img", {
          src: _imports_1,
          alt: "",
          class: "brightness-0 invert h-5 mr-2"
        }, null, -1)
      ])),
      _: 1
    }, 8, ["title", "onClick"])
  ], 64))
}