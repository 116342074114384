import { MutationTree } from "vuex";
import { TeamsState, initTeamModuleState, TeamState, TeamStudentState } from "./state";

const mutations: MutationTree<TeamsState> = {
  resetState(s: TeamsState) {
    Object.assign(s, initTeamModuleState());
  },
  setAllStudents(s: TeamsState, p: TeamStudentState[]) {
    s.allStudents = p;
    s.allStudentsFetched = true;
    s.teams = [];
  },
  setTeams(s: TeamsState, p: TeamState[]) {
    s.teams = p;
  },
  setIsTeamMode(s: TeamsState, p: boolean) {
    s.isTeamMode = p;
  },
  setLoading(s: TeamsState, p: boolean) {
    s.isLoading = p;
  },
  setSettingModalOpened(s: TeamsState, isOpen: boolean) {
    s.settingModalOpened = isOpen;
  },
  setEnableTeamModeOnSave(s: TeamsState, enabled: boolean) {
    s.enableTeamModeOnSave = enabled;
  },
};

export default mutations;
