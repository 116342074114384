<template>
  <DynamicModal />
  <SettingTeamsModal />
  <LoadingPage v-if="appView === AppView.Blank" />
  <NotFoundPage v-else-if="appView === AppView.NotFound" />
  <MainLayout v-else-if="appView === AppView.UnAuthorized">
    <template v-slot:header>
      <AppHeader :title="siteTitle" />
    </template>
    <AccessDeniedPage />
    <template v-slot:footer>
      <AppFooter />
    </template>
  </MainLayout>
  <MainLayout v-else>
    <template v-if="isHeaderVisible" v-slot:header>
      <AppHeader :title="siteTitle" />
    </template>
    <BlockedBrowser v-if="isBrowserBlocked" :is-desktop="isDesktopBrowser" />
    <router-view v-else :key="$route.fullPath" />
    <div v-if="isMaskGrandAccess" class="mask-grand-access">
      <Spin class="ant-custom-home" />
    </div>
    <LostConnectionWarning />
    <template v-if="isFooterVisible" v-slot:footer>
      <AppFooter />
    </template>
  </MainLayout>
  <Toast></Toast>
  <Notification></Notification>
</template>
<script lang="ts" src="./app.ts" />
<style lang="scss" src="./app.scss" />
