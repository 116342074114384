import { LostNetwork } from "@/locales/localeid";
import { UserRole } from "@/store/app/state";
import { computed, defineComponent } from "vue";
import { fmtMsg } from "vue-glcommonui";
import { useStore } from "vuex";
import WarningMessage from "../warning-message/warning-message.vue";

export default defineComponent({
  components: { WarningMessage },
  setup() {
    const { getters } = useStore();
    const userRole = computed(() => getters["userRole"]);
    const isTeacher = computed(() => getters["auth/isTeacher"]);
    const teacherDisconnected = computed<boolean>(() => getters["teacherRoom/isDisconnected"]);
    const disconnectedMsgShown = computed(() => isTeacher.value && teacherDisconnected.value && userRole.value !== UserRole.UnConfirm);
    const MsgLostNetwork = computed(() => fmtMsg(LostNetwork.Message));
    return {
      MsgLostNetwork,
      disconnectedMsgShown,
    };
  },
});
