<template>
  <div class="px-3 2xl:px-5" v-if="!isAlternateMediaBlock || listMediaAsAlternative.length" oncontextmenu="return false">
    <div class="bg-slate-100 pt-2 pb-2.5 2xl:pt-3 2xl:pb-4 rounded-lg 2xl:rounded-xl">
      <p class="text-xs 2xl:text-sm px-3 2xl:px-5 pb-3 border-b-slate-200 border-b leading-6 text-slate-600">
        {{ exposureTitle }}
      </p>
      <div class="px-3 2xl:px-5 mt-2 2xl:mt-4">
        <ExposureItemBlock
          v-if="!isAlternateMediaBlock"
          :teachingContent="exposure.teachingActivityBlockItems"
          :items="listMedia"
          :exposure-type="type"
        />
        <AlternativeItemBlock v-else :items="listMediaAsAlternative" />
      </div>
    </div>
  </div>
</template>
<!--<style lang="scss" scoped src="./exposure-detail.scss"></style>-->
<script lang="ts" src="./exposure-detail.ts"></script>
