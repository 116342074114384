import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/student-class/bear-confuse.png'


const _hoisted_1 = { class: "page-container" }
const _hoisted_2 = { class: "title-text" }
const _hoisted_3 = { class: "mb-2" }
const _hoisted_4 = { class: "mb-2" }
const _hoisted_5 = { class: "mb-2" }
const _hoisted_6 = { class: "btn-homepage" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("img", {
      src: _imports_0,
      alt: ""
    }, null, -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.title), 1),
      _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.contentFirst), 1),
      _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.contentSecond), 1)
    ]),
    _createVNode(_component_router_link, { to: "/" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.goTo), 1)
      ]),
      _: 1
    })
  ]))
}