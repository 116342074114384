<template>
  <div class="lg flex flex-col">
    <Swiper
      :initialSlide="initialImageIndex"
      class="swiper-container lg-slider flex-1"
      @slideChange="onSwiperChange"
      :modules="[Thumbs, Zoom]"
      :thumbs="{ swiper: thumbsSwiper }"
      :zoom="{ maxRatio: 4 }"
      @zoomChange="onZoomChange"
      @swiper="onSwiper"
      @wheel="onWheel"
    >
      <SwiperSlide class="swiper-slide prevent-user-select" :zoom="true" oncontextmenu="return false" v-for="{ id, imageUrl } in items" :key="id">
        <img :src="imageUrl" alt=""
      /></SwiperSlide>
      <LessonNav class="back" :isNext="false" />
      <LessonNav class="next" :isNext="true" />
      <div class="focus-word prevent-user-select" v-if="focusWordContent.condition">
        <Alert :message="focusWordContent.word" :description="focusWordContent.definition" type="info" />
      </div>
    </Swiper>
    <Swiper
      :initialSlide="initialImageIndex"
      class="swiper-container lg-thumbs h-[20%]"
      :modules="[Thumbs]"
      watch-slides-progress
      @swiper="setThumbsSwiper"
      :slides-per-view="6"
      :space-between="5"
      :threshold="11"
      :slide-to-clicked-slide="true"
    >
      <SwiperSlide class="lg-thumbs__item prevent-user-select" v-for="{ id, imageUrl } in items" :key="id"
        ><img :src="imageUrl" alt=""
      /></SwiperSlide>
    </Swiper>
    <div
      class="bg-white border-t border-t-slate-200 text-slate-600 px-6 flex justify-between items-center gap-x-1.5 text-center py-2 text-sm prevent-user-select"
    >
      <div class="flex justify-center items-center gap-x-2 text-center">
        {{ progress }}
      </div>
      <div class="flex items-center space-x-2.5 transition-opacity">
        <span>{{ zoomPercentage }}%</span>
        <div class="flex items-center space-x-1">
          <div class="h-6 w-6 rounded-lg bg-slate-100 hover:bg-white hover:shadow flex justify-center items-center" @click="zoomOut">
            <MagnifyingGlassMinusIcon class="cursor-pointer h-4 w-4" />
          </div>
          <div class="h-6 w-6 rounded-lg bg-slate-100 hover:bg-white hover:shadow flex justify-center items-center" @click="zoomIn">
            <MagnifyingGlassPlusIcon class="cursor-pointer h-4 w-4" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" src="./lesson-gallery.ts"></script>
<style lang="scss" src="./lesson-gallery.scss"></style>
