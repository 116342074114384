import { GetterTree } from "vuex";
import { ObjectState } from "./state";
import { CanvasObjectModel } from "@/hooks/use-send-websocket-msg";

const getters: GetterTree<ObjectState, any> = {
  nextObjectTobeUpdated(s: ObjectState): CanvasObjectModel | undefined {
    return s.nextObjectTobeUpdated;
  },
};

export default getters;
