import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle } from "vue"
import _imports_0 from '@/assets/tools/Cursor.svg'
import _imports_1 from '@/assets/tools/InputText_Icon.png'
import _imports_2 from '@/assets/tools/LaserPointer.svg'
import _imports_3 from '@/assets/tools/Pencil.svg'
import _imports_4 from '@/assets/tools/StrokeSize.svg'
import _imports_5 from '@/assets/tools/Circle.svg'
import _imports_6 from '@/assets/tools/Square.svg'
import _imports_7 from '@/assets/tools/EraseAll.svg'


const _hoisted_1 = { class: "bg-white rounded-lg flex justify-around gap-x-4 py-2 px-6 shadow" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "grid grid-cols-2 gap-2.5" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Tooltip = _resolveComponent("Tooltip")!
  const _component_Space = _resolveComponent("Space")!
  const _component_Popover = _resolveComponent("Popover")!
  const _component_ArrowUturnLeftIcon = _resolveComponent("ArrowUturnLeftIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Tooltip, { title: _ctx.MsgCursor }, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          class: _normalizeClass(['tool-item', _ctx.toolSelected === 'cursor' && 'selected']),
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.clickedTool('cursor')))
        }, _cache[12] || (_cache[12] = [
          _createElementVNode("img", {
            src: _imports_0,
            alt: "Cursor Icon"
          }, null, -1)
        ]), 2)
      ]),
      _: 1
    }, 8, ["title"]),
    _createVNode(_component_Tooltip, { title: _ctx.MsgText }, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          class: _normalizeClass(['tool-item', _ctx.toolSelected === 'TextBox' && 'selected']),
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.clickedTool('TextBox')))
        }, _cache[13] || (_cache[13] = [
          _createElementVNode("img", {
            src: _imports_1,
            alt: "Text Icon"
          }, null, -1)
        ]), 2)
      ]),
      _: 1
    }, 8, ["title"]),
    _createVNode(_component_Tooltip, { title: _ctx.MsgLaserPen }, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          class: _normalizeClass(['tool-item', _ctx.toolSelected === 'laser' && 'selected']),
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.clickedTool('laser')))
        }, _cache[14] || (_cache[14] = [
          _createElementVNode("img", {
            src: _imports_2,
            alt: "Laser Icon"
          }, null, -1)
        ]), 2)
      ]),
      _: 1
    }, 8, ["title"]),
    _createVNode(_component_Tooltip, { title: _ctx.MsgPencil }, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          class: _normalizeClass(['tool-item', _ctx.toolSelected === 'pen' && 'selected']),
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.clickedTool('pen')))
        }, _cache[15] || (_cache[15] = [
          _createElementVNode("img", {
            src: _imports_3,
            alt: "Pen Icon"
          }, null, -1)
        ]), 2)
      ]),
      _: 1
    }, 8, ["title"]),
    _createVNode(_component_Tooltip, {
      title: _ctx.MsgSize,
      mouseLeaveDelay: 0
    }, {
      default: _withCtx(() => [
        _createVNode(_component_Popover, {
          visible: _ctx.showListSize,
          "onUpdate:visible": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.showListSize) = $event)),
          trigger: "click"
        }, {
          content: _withCtx(() => [
            _createVNode(_component_Space, { direction: "vertical" }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.strokeSize, (size, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "px-2 py-1 cursor-pointer",
                    key: index,
                    onClick: ($event: any) => {
                _ctx.updateStrokeSize(size);
                _ctx.hideListSize();
              }
                  }, [
                    _createElementVNode("div", {
                      class: "w-10 bg-slate-600",
                      style: _normalizeStyle(`height: ${size * 1.2}px`)
                    }, null, 4)
                  ], 8, _hoisted_2))
                }), 128))
              ]),
              _: 1
            })
          ]),
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: _normalizeClass(['relative tool-item', _ctx.toolSelected === 'stroke' && 'selected', _ctx.strokeWidth === 2 && 'thin', _ctx.strokeWidth === 4 && 'thick']),
              onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.clickedTool('stroke')))
            }, _cache[16] || (_cache[16] = [
              _createElementVNode("img", {
                src: _imports_4,
                alt: "Size Icon"
              }, null, -1)
            ]), 2)
          ]),
          _: 1
        }, 8, ["visible"])
      ]),
      _: 1
    }, 8, ["title"]),
    _createVNode(_component_Tooltip, {
      title: _ctx.MsgColor,
      mouseLeaveDelay: 0
    }, {
      default: _withCtx(() => [
        _createVNode(_component_Popover, {
          visible: _ctx.showListColors,
          "onUpdate:visible": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.showListColors) = $event)),
          trigger: "click blur"
        }, {
          content: _withCtx(() => [
            _createElementVNode("div", _hoisted_3, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.colorsList, (color, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: index,
                  style: _normalizeStyle(`background-color: ${color}`),
                  class: "w-5 h-5 rounded-full shadow cursor-pointer transition-transform border border-slate-200 hover:scale-125",
                  onClick: ($event: any) => {
                _ctx.updateColor(color);
                _ctx.hideListColors();
              }
                }, null, 12, _hoisted_4))
              }), 128))
            ])
          ]),
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: _normalizeClass(['relative tool-item', _ctx.toolSelected === 'stroke-color' && 'selected']),
              onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.clickedTool('stroke-color')))
            }, [
              _createElementVNode("img", {
                src: require(`@/assets/tools/ColorSelectScribble.svg`),
                alt: "Color Icon"
              }, null, 8, _hoisted_5),
              _createElementVNode("div", {
                class: "absolute w-3 h-3 bottom-1 right-0 border border-slate-200 rounded-full shadow",
                style: _normalizeStyle(`background-color: ${_ctx.strokeColor}`)
              }, null, 4)
            ], 2)
          ]),
          _: 1
        }, 8, ["visible"])
      ]),
      _: 1
    }, 8, ["title"]),
    _createVNode(_component_Tooltip, { title: _ctx.MsgCircle }, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          class: _normalizeClass(['tool-item', _ctx.toolSelected === 'circle' && 'selected']),
          onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.clickedTool('circle')))
        }, _cache[17] || (_cache[17] = [
          _createElementVNode("img", {
            src: _imports_5,
            alt: "Circle Icon"
          }, null, -1)
        ]), 2)
      ]),
      _: 1
    }, 8, ["title"]),
    _createVNode(_component_Tooltip, { title: _ctx.MsgSquare }, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          class: _normalizeClass(['tool-item', _ctx.toolSelected === 'square' && 'selected']),
          onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.clickedTool('square')))
        }, _cache[18] || (_cache[18] = [
          _createElementVNode("img", {
            src: _imports_6,
            alt: "Square Icon"
          }, null, -1)
        ]), 2)
      ]),
      _: 1
    }, 8, ["title"]),
    _createVNode(_component_Tooltip, { title: _ctx.MsgUndo }, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          class: _normalizeClass(['tool-item', _ctx.toolSelected === 'delete' && 'selected']),
          onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.clickedTool('delete')))
        }, [
          _createVNode(_component_ArrowUturnLeftIcon, {
            class: _normalizeClass(["h-5 w-5", [_ctx.toolSelected === 'delete' ? 'text-white' : 'text-black/60']])
          }, null, 8, ["class"])
        ], 2)
      ]),
      _: 1
    }, 8, ["title"]),
    _createVNode(_component_Tooltip, { title: _ctx.MsgClear }, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          class: _normalizeClass(['tool-item', _ctx.toolSelected === 'clear' && 'selected']),
          onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.clickedTool('clear')))
        }, _cache[19] || (_cache[19] = [
          _createElementVNode("img", {
            src: _imports_7,
            alt: "Clear Icon"
          }, null, -1)
        ]), 2)
      ]),
      _: 1
    }, 8, ["title"])
  ]))
}