import { ref } from "vue";
import { useMutationObserver } from "@vueuse/core";
// TODO: This composable is just an alternative solution, it's best to combine the element containing the agora video and the spinner into one component.
export const useAgoraVideoSpinner = () => {
  const spinnerVisible = ref(true);
  const videoRef = ref<HTMLVideoElement | null>(null);

  useMutationObserver(
    videoRef,
    () => {
      spinnerVisible.value = !(videoRef.value && videoRef.value.children.length > 0);
    },
    {
      childList: true,
    },
  );

  return {
    spinnerVisible,
    videoRef,
  };
};
