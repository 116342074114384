import { useStore } from "vuex";
import { computed } from "vue";
import { TeamState, TeamStudentState } from "@/store/teams/state";
export const useManipulateTeamState = () => {
  const { getters, dispatch, commit } = useStore();
  const isTeamMode = computed<boolean>(() => getters["teams/isTeamMode"]);
  const isFetchingTeams = computed<boolean>(() => getters["teams/isLoading"]);
  const allStudents = computed<TeamStudentState[]>(() => getters["teams/allStudents"]);
  const teams = computed<TeamState[]>(() => getters["teams/teams"]);
  const settingModalOpened = computed<boolean>(() => getters["teams/settingModalOpened"]);
  const enableTeamModeOnSave = computed<boolean>(() => getters["teams/enableTeamModeOnSave"]);
  //** List students who are not on any team */
  const nonTeamStudents = computed<TeamStudentState[]>(() =>
    allStudents.value.filter(
      (s) =>
        teams.value
          .map((team) => team.students.map((s) => s.id))
          .flat()
          .findIndex((id) => id === s.id) === -1,
    ),
  );
  const findTeamByStudentId = (studentId: string) => teams.value.find((t) => t.students.findIndex((ts) => ts.id === studentId) > -1);
  const toggleTeamMode = async () => {
    await dispatch("teams/toggleTeamModeAsync", !isTeamMode.value);
  };
  const settingTeams = async (enableTeamOnSave = false) => {
    if (enableTeamOnSave) {
      commit("teams/setEnableTeamModeOnSave", true);
    }
    commit("teams/setSettingModalOpened", true);
  };
  return {
    getters,
    dispatch,
    commit,
    isTeamMode,
    isFetchingTeams,
    teams,
    allStudents,
    nonTeamStudents,
    findTeamByStudentId,
    toggleTeamMode,
    settingModalOpened,
    enableTeamModeOnSave,
    settingTeams,
  };
};
