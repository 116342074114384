import { CommonLocale, Survey } from "@/locales/localeid";
import { RemoteTeachingService } from "@/services";
import { store } from "@/store";
import { SESSION_ID } from "@/utils/constant";
import { computed, defineComponent, ref } from "vue";
import { fmtMsg } from "vue-glcommonui";
export default defineComponent({
  setup() {
    const feedback = ref("");
    const showResponse = ref(false);
    const textarea = ref();
    const currentSessionId = localStorage.getItem(SESSION_ID) ?? "";

    const handleYesClick = () => {
      RemoteTeachingService.updateCallingQualitySurvey({
        sessionId: currentSessionId,
        isGoodQuality: true,
        feedback: feedback.value,
      });
      closeSurvey();
      localStorage.removeItem(SESSION_ID);
    };

    const handleNoClick = () => {
      showResponse.value = true;
    };

    const handleSubmit = () => {
      RemoteTeachingService.updateCallingQualitySurvey({
        sessionId: currentSessionId,
        isGoodQuality: false,
        feedback: feedback.value,
      });
      closeSurvey();
      localStorage.removeItem(SESSION_ID);
    };

    const closeSurvey = () => {
      store.dispatch("modal/close", null, { root: true });
    };

    const adjustTextareaHeight = () => {
      const textareaElement = textarea.value;
      if (textareaElement.clientHeight < textareaElement.scrollHeight) {
        textareaElement.style.height = textareaElement.scrollHeight + "px";
      }
    };

    const MsgTitle = computed(() => fmtMsg(Survey.Title));
    const MsgQuestion = computed(() => fmtMsg(Survey.Question));
    const MsgResponseYes = computed(() => fmtMsg(Survey.ResponseYes));
    const MsgResponseNo = computed(() => fmtMsg(Survey.ResponseNo));
    const MsgFeedbackPlaceholder = computed(() => fmtMsg(Survey.FeedbackPlaceholder).replace(/\n|\\n/g, "<br>"));
    const MsgButtonSubmit = computed(() => fmtMsg(Survey.ButtonSubmit));
    const MsgButtonCancel = computed(() => fmtMsg(CommonLocale.CommonCancelButtonText));

    return {
      handleYesClick,
      handleNoClick,
      handleSubmit,
      feedback,
      showResponse,
      MsgTitle,
      MsgQuestion,
      MsgResponseYes,
      MsgResponseNo,
      MsgFeedbackPlaceholder,
      MsgButtonSubmit,
      MsgButtonCancel,
      textarea,
      adjustTextareaHeight,
      closeSurvey,
    };
  },
});
