import { LessonGalleryItem } from "@/components/common/lesson-gallery/lesson-gallery";
import { getSeconds, secondsToTimeStr } from "@/utils/convertDuration";
import { GetterTree } from "vuex";
import { ContentRootType, LPItemMetadata, Exposure, ExposureItem, ExposureItemMedia, LessonState } from "./state";
import { ExposureDetailType } from "@/views/teacher-class/components/lesson-plan/lesson-plan";
import { defineVuexName, VuexNames } from "@/store/utils";
interface LessonGetterInterface<S> {
  currentExposure(s: S): Exposure | undefined;
  nextExposure(s: S): Exposure | undefined;
  currentExposureItemMedia(s: S): ExposureItemMedia | undefined;
  allMedia(s: S): ExposureItemMedia[];
  nextExposureItemMedia(s: S, g: any): ExposureItemMedia | undefined;
  prevExposureItemMedia(s: S, g: any): ExposureItemMedia | undefined;
  exposures(s: S): Exposure[];
  isBlackOut(s: S): boolean;
  activityStatistic(s: S): string;
  remainingTimeStatistic(s: S): string;
  progressStatistic(s: S): number;
}

interface LessonGetters<S, R> extends GetterTree<S, R>, LessonGetterInterface<S> {}

const getters: LessonGetters<LessonState, any> = {
  currentExposure(s: LessonState): Exposure | undefined {
    return s.currentExposure;
  },
  nextExposure(s: LessonState): Exposure | undefined {
    return s.nextExposure;
  },
  currentExposureItemMedia(s: LessonState): ExposureItemMedia | undefined {
    return s.currentExposureItemMedia;
  },
  currentExposureType(s: LessonState): ExposureDetailType | undefined {
    return s.currentExposureType;
  },
  allMedia(s: LessonState): ExposureItemMedia[] {
    if (!s.currentExposure) return [];
    const groupMedia = [];
    const isAlternateMediaShown = getSeconds(s.playedTime) === getSeconds(s.totalTime);
    const combinedItems = [
      ...s.currentExposure.items,
      ...s.currentExposure.contentBlockItems,
      ...s.currentExposure.teachingActivityBlockItems,
      ...(isAlternateMediaShown ? s.currentExposure.alternateMediaBlockItems.flat() : []),
    ].filter((item: ExposureItem) => item.media[0]?.image?.url);
    for (const item of combinedItems) {
      groupMedia.push(item.media);
    }
    return groupMedia.flat();
  },
  nextExposureItemMedia(s: LessonState, g: any): ExposureItemMedia | undefined {
    if (!s.currentExposure || !s.currentExposureItemMedia) return;
    const allMedia: ExposureItemMedia[] = g.allMedia;
    const indexOfMedia =
      (s.currentExposureType
        ? allMedia.findIndex((media) => media.id === s.currentExposureItemMedia?.id && media.exposureType === s.currentExposureType)
        : 0) || allMedia.findIndex((media) => media.id === s.currentExposureItemMedia?.id);
    if (indexOfMedia + 1 < allMedia.length) {
      return (s.nextExposureItemMedia = allMedia[indexOfMedia + 1]);
    } else {
      return (s.nextExposureItemMedia = undefined);
    }
  },
  prevExposureItemMedia(s: LessonState, g: any): ExposureItemMedia | undefined {
    if (!s.currentExposure || !s.currentExposureItemMedia) return;
    const allMedia: ExposureItemMedia[] = g.allMedia;
    const indexOfMedia =
      (s.currentExposureType
        ? allMedia.findIndex((media) => media.id === s.currentExposureItemMedia?.id && media.exposureType === s.currentExposureType)
        : 0) || allMedia.findIndex((media) => media.id === s.currentExposureItemMedia?.id);
    if (indexOfMedia - 1 >= 0) {
      return (s.nextExposureItemMedia = allMedia[indexOfMedia - 1]);
    } else {
      return (s.nextExposureItemMedia = undefined);
    }
  },
  exposures(s: LessonState): Exposure[] {
    return s.exposures;
  },
  isBlackOut(s: LessonState): boolean {
    return s.isBlackout;
  },
  activityStatistic(s: LessonState): string {
    // count only required exposure as total exposure
    const listExpo: (string | undefined)[] = [];
    const requiredExposures = s.exposures.filter((expo) => {
      const isRequiredExposure = expo.contentRootType !== ContentRootType.Optional && expo.contentRootType !== ContentRootType.Complete;

      if (isRequiredExposure) {
        listExpo.push(expo.id);
      }

      return isRequiredExposure;
    });

    return requiredExposures.length ? `${listExpo.indexOf(s.currentExposure?.id) + 1}/${requiredExposures.length}` : "0";
  },
  getPage(s: LessonState): string {
    const listMedia: string[] = [];
    if (!s.currentExposure) {
      return "";
    }
    const isAlternateMediaShown = getSeconds(s.playedTime) === getSeconds(s.totalTime);
    const combinedItems = [
      ...s.currentExposure.items,
      ...s.currentExposure.contentBlockItems,
      ...s.currentExposure.teachingActivityBlockItems,
      ...(isAlternateMediaShown ? s.currentExposure.alternateMediaBlockItems.flat() : []),
    ].filter((item: ExposureItem) => item.media[0]?.image?.url);
    combinedItems.map((item) => {
      item.media.map((media) => {
        listMedia.push(media.id);
      });
    });
    const currentPage: string[] = [];
    if (listMedia.length > 0) {
      listMedia.map((media) => {
        if (media == s.currentExposureItemMedia?.id) {
          currentPage.push(media);
        }
      });
    }
    return s.exposures.length ? `${listMedia.indexOf(currentPage[0]) + 1}/${listMedia.length}` : "0";
  },
  remainingTimeStatistic(s: LessonState): string {
    return secondsToTimeStr(getSeconds(s.totalTime) - getSeconds(s.playedTime));
  },
  progressStatistic(s: LessonState): number {
    return getSeconds(s.playedTime) / getSeconds(s.totalTime);
  },
  isAlternateMediaShown(s: LessonState): boolean {
    return getSeconds(s.playedTime) === getSeconds(s.totalTime);
  },
  previousExposure(s: LessonState): Exposure | undefined {
    return s.previousExposure;
  },
  previousExposureItemMedia(s: LessonState): ExposureItemMedia | undefined {
    return s.previousExposureItemMedia;
  },
  findCachedImage(s: LessonState) {
    return function (imgData: { url: string; metadata: LPItemMetadata }): string | undefined {
      const cacheData = s.cropCache?.cacheValues.find(
        (cacheValue) => cacheValue.url === imgData.url && JSON.stringify(cacheValue.metadata) === JSON.stringify(imgData.metadata),
      );
      if (cacheData) {
        // founded, then return the base64 string
        return cacheData.base64String;
      }
      // not found
      return undefined;
    };
  },
  zoomRatio(s: LessonState) {
    return s.zoomRatio;
  },
  imgCoords(s: LessonState) {
    return s.imgCoords;
  },
  previewObjects(s: LessonState) {
    return s.previewObjects;
  },
  isShowPreviewCanvas(s: LessonState) {
    return s.isShowPreviewCanvas;
  },
  //* IM mode's items
  listReaderItemsOfCurrentExposure(s: LessonState): LessonGalleryItem[] | null {
    if (!s.currentExposure?.includedIndependentMode) return null;
    return (
      s.currentExposure?.contentBlockItems
        .filter((item: ExposureItem) => item.media[0]?.image?.url && !item.media[0].teacherUseOnly)
        .map((item: ExposureItem) => {
          const { media } = item;
          const readerAsGalleryItem: LessonGalleryItem = {
            id: item.id,
            imageUrl: media[0].image.url,
          };
          if (media[0]?.audio) {
            readerAsGalleryItem.audioUrl = media[0].audio?.url;
          }
          if (media[0]?.readerFocusWord) {
            readerAsGalleryItem.focusWord = media[0].readerFocusWord;
          }
          return readerAsGalleryItem;
        }) ?? null
    );
  },
  pdfScrollProgress(s: LessonState) {
    return s.pdfScrollProgress;
  },
  alternativeShown(s: LessonState): boolean {
    return getSeconds(s.playedTime) === getSeconds(s.totalTime);
  },
  [defineVuexName(VuexNames.LESSON.GETTERS.GET_VISIBLE_TARGET_TAGS)](s: LessonState) {
    return s.visibleTargetTags;
  },
};

export default getters;
