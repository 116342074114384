import { TeamState } from "@/store/teams/state";
import { createGuid } from "@/utils/utils";

export const MaximumNumberOfTeams = 6;
export const defaultColors = [
  "rgb(216, 27, 67)",
  "rgb(31, 135, 232)",
  "rgb(5, 150, 105)",
  "rgb(142, 36, 170)",
  "rgb(230, 74, 25)",
  "rgb(87, 83, 78)",
];
export const dummyTeams: TeamState[] = [
  {
    id: createGuid(),
    color: defaultColors[0],
    name: "Red",
    order: 0,
    students: [],
    isNew: true,
  },
  {
    id: createGuid(),
    color: defaultColors[1],
    name: "Blue",
    order: 1,
    students: [],
    isNew: true,
  },
];

export const readOnlyNumbOfTeams = Symbol();
