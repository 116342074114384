import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex items-center justify-between gap-5 pr-10" }
const _hoisted_2 = { class: "" }
const _hoisted_3 = { class: "flex justify-end space-x-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PlusCircleIcon = _resolveComponent("PlusCircleIcon")!
  const _component_Button = _resolveComponent("Button")!
  const _component_ArrowUturnLeftIcon = _resolveComponent("ArrowUturnLeftIcon")!
  const _component_Teams = _resolveComponent("Teams")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, {
    destroyOnClose: true,
    visible: _ctx.settingModalOpened,
    zIndex: 999,
    style: {"top":"20px"},
    width: _ctx.isLaptopDimension ? '85%' : '70%',
    onOk: _ctx.handleOk,
    onCancel: _ctx.handleCancel,
    "ok-text": _ctx.MsgOk,
    "cancel-text": _ctx.MsgCancel
  }, {
    title: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.MsgSettingTeamsTitle), 1),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_Button, {
            onClick: _ctx.handleAddNewTeam,
            type: "primary",
            class: "flex items-center space-x-2"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_PlusCircleIcon, { class: "h-4 w-4" }),
              _createTextVNode(" " + _toDisplayString(_ctx.MsgNewTeam), 1)
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_Button, {
            onClick: _ctx.handleResetTeams,
            class: "flex items-center space-x-2"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ArrowUturnLeftIcon, { class: "h-4 w-4" }),
              _createTextVNode(" " + _toDisplayString(_ctx.MsgResetTeam), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ])
      ])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_Teams, { ref: "teamsCompRef" }, null, 512)
    ]),
    _: 1
  }, 8, ["visible", "width", "onOk", "onCancel", "ok-text", "cancel-text"]))
}