export class CommonLocale {
  static CommonOkButtonText = "common.okButtonText";
  static CommonCancelButtonText = "common.cancelButtonText";
  static CommonUpdateButtonText = "common.updateButtonText";
  static CommonYesButtonText = "common.yesButtonText";
  static CommonNoButtonText = "common.noButtonText";
  static CommonWarningTitle = "common.warningTitle";
  static CommonSuccessTitle = "common.successTitle";
  static CommonFailedTitle = "common.failedTitle";
  static CommonHttpAggregate = "common.httpAggregate";
  static CommonHttpArgumentIsNullOrInvalid = "common.httpArgumentIsnullorInvalid";
  static CommonHttpBadRequest = "common.httpBadrequest";
  static CommonHttpCodeHasBeenUsed = "common.httpCodeHasBeenUsed";
  static CommonHttpCodeNotExist = "common.httpCodeNotExist";
  static CommonHttpInvalidGrant = "common.httpInvalidGrant";
  static CommonHttpLengthOverRun = "common.httpLengthOverRun";
  static CommonHttpNoPermission = "common.httpNoPermission";
  static CommonHttpNotFound = "common.httpNotFound";
  static CommonHttpServerError = "common.httpServerError";
  static CommonHttpStrongAssociation = "common.httpStrongAssociation";
  static CommonHttpTargetDisabled = "common.httpTargetDisabled";
  static CommonHttpTargetHasExisted = "common.httpTargetHasExisted";
  static CommonHttpTargetIsNull = "common.httpTargetIsNull";
  static CommonHttpTooManyRequest = "common.httpTooManyRequests";
  static CommonHttpUnknown = "common.httpUnknown";
  static CommonHttpUnsuportedOperation = "common.httpUnsuportedOperation";
  static CommonHttpDuplicateWordException = "common.httpDuplicateWordException";
  static NoVersionGroupMsg = "common.noversiongroup.message";
  static CommonRoleSystemAdmin = "common.role.systemadmin";
  static CommonRoleGlobalHead = "common.role.globalhead";
  static CommonRoleContentAdmin = "common.role.contentadmin";
  static CommonRoleTrainingAdmin = "common.role.trainingadmin";
  static CommonRoleRegionAdmin = "common.role.regionadmin";
  static CommonRoleAccountManager = "common.role.accountmanager";
  static CommonRoleTrainingManager = "common.role.trainingmanager";
  static CommonRoleTrainer = "common.role.trainer";
  static CommonRoleSchoolAdmin = "common.role.schooladmin";
  static CommonRoleCampusAdmin = "common.role.campusadmin";
  static CommonRoleTeacher = "common.role.teacher";
  static CommonRoleParent = "common.role.parent";
  static CommonSiteTitle = "common.site.title";
  static CommonAccessDenied = "common.access.denied";
  static CommonEndSession = "common.warning.end_class";
  static OverlapWarningSession = "common.warning.overlap";
  static Save = "common.save";
  static CommonWarningDeviceTeacher = "common.warning.device.teacher";
  static CommonWarningDeviceStudent = "common.warning.device.student";
  static BrowserNotSupported = "common.not_supported_browser_message";
  static HereLinkBtn = "common.here_link_btn";
  static RequestServerFailedTitle = "common.request_server_failed_title";
  static RequesetServerFailedDesc = "common.request_server_failed_desc";
  static RetrySetupClassButton = "common.retry_setup_class_button";
}

export class ErrorLocale {
  static UnauthorizedMessage = "error.unauthorized";
  static NotFoundMessage = "error.notfound";
  static ClassNotStarted = "error.class.not.started";
  static ParentAccountNotHaveThisStudent = "error.parent_not_have_this_student";
  static CallingServerConnectFailTitle = "calling_server_connect_fail.title";
  static CallingServerConnectFailDesc = "calling_server_connect_fail.description";
  static ToggleCameraError = "error.toggle_camera";
  static ToggleMicroError = "error.toggle_micro";
}

export class PrivacyPolicy {
  static PrivacyPolicy = "privacy.policy";
  static TeacherPolicyTitle = "teacher.policy.title";
  static TeacherPolicySubtitle = "teacher.policy.subtitle";
  static TeacherPolicyText1 = "teacher.policy.text1";
  static TeacherPolicyText2 = "teacher.policy.text2";
  static TeacherPolicyText3 = "teacher.policy.text3";
  static TeacherPolicyText4 = "teacher.policy.text4";
  static TeacherAcceptPolicy = "teacher.accept.policy";
  static StudentPolicyTitle = "student.policy.title";
  static StudentPolicySubtitle = "student.policy.subtitle";
  static StudentPolicyText1 = "student.policy.text1";
  static StudentPolicyText2 = "student.policy.text2";
  static StudentPolicyText3 = "student.policy.text3";
  static StudentPolicyText4 = "student.policy.text4";
  static StudentAcceptPolicy = "student.accept.policy";
  static StudentMessageJoin = "student.message.join";
  static ReadPolicy = "read.policy";
}

export class DisconnectScreenLocale {
  static Title = "disconnect.title";
  static Content1 = "disconnect.content1";
  static Content2 = "disconnect.content2";
  static Goto = "disconnect.goto";
}

export class AccessInformationScreenLocale {
  static Line1 = "accessinformation.line1";
  static Line2 = "accessinformation.line2";
  static Line3 = "accessinformation.line3";
  static Line4 = "accessinformation.line4";
  static Line5Part1 = "accessinformation.line5.part1";
  static Line5Part2 = "accessinformation.line5.part2";
  static Line6 = "accessinformation.line6";
  static ContactLink = "accessinformation.link";
}
export class AccessDeniedLocale {
  static AccessDeniedWeAreSorry = "access_denied.we_are_sorry";
  static AccessDeniedDescription = "access_denied.description";
  static AccessDeniedNonTeacherDescription = "access_denied.non_teacher_description";
  static AccessDeniedSuggest = "access_denied.suggest";
}

export class StudentClassLocale {
  static Exit = "student_class.exit";
  static LeaveConfirm = "student_class.leave_confirm";
  static GoToHomePage = "student_class.go_to_home_page";
}
export class TeacherClassLocale {
  static ShowWhiteboard = "teacher_class.show_whiteboard";
  static HideWhiteboard = "teacher_class.hide_whiteboard";
  static PreviewTarget = "teacher_class.preview_target";
  static WarningAudioTag = "teacher_class.warning_audio_tag";
  static WarningVideoTag = "teacher_class.warning_video_tag";
  static ConfirmRemoveHelper = "teacher_class.confirm_remove_helper";
  static RemoveHelperSuccessfully = "teacher_class.remove_helper_successfully";
  static StudentDroppedOutClass = "teacher_class.student_dropped_out";
  static StudentLeftClass = "teacher_class.student_left";
  static StudentJoinedClass = "teacher_class.student_joined";
  static TeacherExitClass = "teacher_class.teacher_exit";
}

export class ToolboxCanvas {
  static Cursor = "toolbox_canvas.cursor";
  static Text = "toolbox_canvas.text";
  static LaserPen = "toolbox_canvas.laser_pen";
  static Pencil = "toolbox_canvas.pencil";
  static Size = "toolbox_canvas.size";
  static Color = "toolbox_canvas.color";
  static Circle = "toolbox_canvas.circle";
  static Square = "toolbox_canvas.square";
  static Undo = "toolbox_canvas.undo";
  static Clear = "toolbox_canvas.clear";
}

export class ParentHomeLocale {
  static Welcome = "parent_home.welcome";
  static ChooseStudent = "parent_home.choose_student";
  static Cancel = "parent_home.cancel";
  static Submit = "parent_home.submit";
}

export class ParentStudentCardLocale {
  static ClassGroup = "parent_student_card.class_group";
  static StartTime = "parent_student_card.start_time";
}
export class ChangeLessonUnitLocale {
  static SetLessonAndUnit = "change_lesson_unit.set_lesson_and_unit";
  static Ok = "change_lesson_unit.ok";
  static MarkCurrentLessonCompleteForClass = "change_lesson_unit.mark_current_lesson_complete_for_class";
}
export class DeviceTesterLocale {
  static SystemCheck = "device_tester.system_check";
  static CheckMic = "device_tester.check_mic";
  static Platform = "device_tester.platform";
  static SelectDevice = "device_tester.select_device";
  static SystemDefaultSpeakerDevice = "device_tester.system_default_speaker_device";
  static CheckCam = "device_tester.check_cam";
  static TeacherVideoMirroring = "device_tester.teacher_video_mirroring";
  static StudentVideoMirroring = "device_tester.student_video_mirroring";
  static CamOff = "device_tester.cam_off";
  static ClassStatus = "device_tester.class_status";
  static DefaultMessage1 = "device_tester.default_message_1";
  static DefaultMessage2 = "device_tester.default_message_2";
  static JoinNow = "device_tester.join_now";
  static LessonUnit = "device_tester.lesson_unit";
  static Lesson = "device_tester.lesson";
  static Unit = "device_tester.unit";
  static Cancel = "device_tester.cancel";
  static JoinSession = "device_tester.join_session";
  static JoinSessionAsHelperText = "device_tester.join_session_as_helper";
  static MessageWarningMic = "device_tester.message_warning_mic";
  static MessageWarningCamera = "device_tester.message_warning_camera";
  static CheckSpeaker = "device_tester.check_speaker";
  static MessageWarningSpeaker = "device_tester.message_warning_speaker";
  static MessageErrorJoinSession = "device_tester.error_join_session";
  static MessageErrorPermissionsNotGranted = "device_tester.error_permissions_not_granted";
  static CameraErrorInfo = "device_tester.camera_error_info";
  static MicroErrorInfo = "device_tester.micro_error_info";
  static SpeakerErrorInfo = "device_tester.speaker_error_info";
  static Recommended = "device_tester.recommended";
  static StudentAlreadyInClass = "device_tester.student_already_joined_class";
}

export class HelperLocales {
  static NotifyTitle = "helper_joining.notify.title";
  static NotifyPart1 = "helper_joining.notify.part_1";
  static NotifyPart2 = "helper_joining.notify.part_2";
  static NotifyPart3 = "helper_joining.notify.part_3";
  static NotifyPart4 = "helper_joining.notify.part_4";
  static ErrorWaitingTeacherConfirm = "helper_joining.error.waiting_teacher_confirm";
  static ErrorTeacherDeniedRequest = "helper_joining.error.teacher_denied";
  static ErrorAnotherHelper = "helper_joining.error.class_has_another_helper";
  static ErrorClassNotHappening = "helper_joining.error.class_not_happening";
  static ShowVideoButton = "helper_joining.show_video_button";
  static HideVideoButton = "helper_joining.hide_video_button";
  static ExitClass = "helper_joining.warning.exit_class";
  static Disconnected = "helper_joining.warning.disconnected";
  static ErrorAlreadyJoinedAsTeacher = "helper_joining.error.already_joined_as_teacher";
  static HelperTakeOverSuccessNotification = "helper_takeover.helper_success_notification";
  static PreventChangeUnitAndLesson = "helper_in_one_to_one.unable_change_unit_and_lesson";
}
export class HomeLocale {
  static Welcome = "home.welcome";
  static ChooseRole = "home.choose_role";
  static Teacher = "home.teacher";
  static Student = "home.student";
}

export class LoadingLocale {
  static Title = "loading.title";
}

export class NotFoundLocale {
  static PageNotFound = "not_found.page_not_found";
  static Description = "not_found.description";
  static GoToPage = "not_found.go_to_page";
}

export class StudentClassHeaderLocale {
  static Exit = "student_class_header.exit";
}

export class TeacherCalendarLocale {
  static Title = "teacher_calendar.title";
  static ClassLabel = "teacher_calendar.class_label";
  static GroupLabel = "teacher_calendar.group_label";
  static StartTimeLabel = "teacher_calendar.start_time_label";
  static EndTimeLabel = "teacher_calendar.end_time_label";
  static Delete = "teacher_calendar.delete";
  static Cancel = "teacher_calendar.cancel";
  static Save = "teacher_calendar.save";
  static Skip = "teacher_calendar.skip";
  static Close = "teacher_calendar.close";
  static Note = "teacher_calendar.note";
  static School = "teacher_calendar.school";
  static All = "teacher_calendar.all";
  static Back = "teacher_calendar.back";
  static ScheduleNewRemoteSession = "teacher_calendar.schedule_new_remote_session";
  static ShowWeekends = "teacher_calendar.show_weekends";
}

export class TeacherClassDesignate {
  static DesignateBtn = "teacher_class_designate.designate_btn";
  static AnnotationBtn = "teacher_class_designate.annotation_btn";
  static StudentTitle = "teacher_class_designate.student_title";
  static AssignAll = "teacher_class_designate.assign_all";
  static ClearAll = "teacher_class_designate.clear_all";
  static RevealAll = "teacher_class_designate.reveal_all";
  static Previous = "teacher_class_designate.previous";
  static Next = "teacher_class_designate.next";
  static ClickToAssignAll = "teacher_class_designate.click_to_assign_all";
  static ClickToUnAssignAll = "teacher_class_designate.click_to_unassign_all";
}

export class TeacherClassLessonPlan {
  static Unit = "teacher_class_lesson_plan.unit";
  static Lesson = "teacher_class_lesson_plan.lesson";
  static Remaining = "teacher_class_lesson_plan.remaining";
  static Item = "teacher_class_lesson_plan.item";
  static Page = "teacher_class_lesson_plan.page";
  static LessonComplete = "teacher_class_lesson_plan.lesson_complete";
  static TeachingActivity = "teacher_class_lesson_plan.teaching_activity";
  static Transition = "teacher_class_lesson_plan.transition";
  static RelatedSlides = "teacher_class_lesson_plan.related_slides";
  static ComponentSlides = "teacher_class_lesson_plan.component_slides";
  static ActivitySlides = "teacher_class_lesson_plan.activity_slides";
  static NoData = "teacher_class_lesson_plan.no_data";
  static AlternateMedia = "teacher_class_lesson_plan.alternate_media";
}

export class TeacherClassGallery {
  static Return = "teacher_class_gallery.return";
  static StickerAll = "teacher_class_gallery.sticker_all";
  static UnmuteAll = "teacher_class_gallery.unmute_all";
  static MuteAll = "teacher_class_gallery.mute_all";
  static EnableAll = "teacher_class_gallery.enable_all";
  static DisableAll = "teacher_class_gallery.disable_all";
  static ShowAll = "teacher_class_gallery.show_all";
  static HideAll = "teacher_class_gallery.hide_all";
  static NoStudentJoinClass = "teacher_class_gallery.no_student_join_class";
  static CaptureAll = "teacher_class_gallery.capture_all";
}

export class TeacherHome {
  static Welcome = "teacher_home.welcome";
  static Schedule = "teacher_home.schedule";
  static Cancel = "teacher_home.cancel";
  static Submit = "teacher_home.submit";
  static Group = "teacher_home.group";
  static Next = "teacher_home.next";
  static Home = "teacher_home.home";
  static Gallery = "teacher_home.gallery";
  static NoData = "teacher_home.no_data";
  static HasNotClass = "teacher_home.has_not_class";
  static HasClassWithoutGroup = "teacher_home.has_class_without_group";
  static Region = "teacher_home.region";
  static School = "teacher_home.school";
  static Campus = "teacher_home.campus";
  static FilterAll = "teacher_home.filter_all";
}

export class TeacherClass {
  static LeavePage = "teacher_class.leave_page";
  static LeaveNotice = "teacher_class.leave_notice";
  static MarkAsComplete = "teacher_class.mark_as_complete";
  static TargetText = "teacher_class.target_text";
  static TargetsText = "teacher_class.targets_text";
  static ShowAllTargets = "teacher_class.show_all_targets";
  static HideAllTargets = "teacher_class.hide_all_targets";
  static ForTeacherUseOnly = "teacher_class.for_teacher_use_only";
}

export class BearAction {
  static None = "teacher_class.bear_action_none";
  static Default = "teacher_class.bear_action_default";
  static Interactive = "teacher_class.bear_action_interactive";
  static Listen = "teacher_class.bear_action_listen";
  static Question = "teacher_class.bear_action_question";
  static Quiet = "teacher_class.bear_action_quiet";
  static Sing = "teacher_class.bear_action_sing";
  static Speak = "teacher_class.bear_action_speak";
}

export class LostNetwork {
  static Message = "lost_network.message";
  static StudentMessage = "lost_network.student_message";
}

export class Layout {
  static EditProfile = "layout.edit_profile";
  static TestConnect = "layout.test_connect";
  static SignOut = "layout.sign_out";
  static PrivacyPolicy = "layout.privacy_policy";
  static CopyRight = "layout.copy_right";
}

export class JoinLoading {
  static Message = "join_class_loading.message";
}

export class StoreLocale {
  static WaitYourTeacher = "store.wait_your_teacher";
  static ClickBoardToAnswer = "store.click_board_to_answer";
}

export class WhiteBoard {
  static TextBoxWarning = "whiteboard.textbox_warning";
}

export class TeacherClassError {
  static ConnectAgoraServersError = "teacher_class_error.connect_agora_servers_error";
  static ConnectAgoraServersErrorTitle = "teacher_class_error.connect_agora_servers_error_title";
  static PublishStreamAgoraServersError = "teacher_class_error.publish_stream_error";
  static MissingImportantClassMessages = "teacher_class_error.miss_messages";
  static UpdateUnitOneOneMode = "teacher_class_error.update_unit_1_1_mode";
  static FailSetOneOneMode = "teacher_class_error.fail_set_1_1_mode";
  static FailRemoveHelper = "teacher_class_error.fail_remove_helper";
}

export class ResourcesMenuLocale {
  static ResourcesMenuResources = "resources.menu.resources";
  static ResourcesMenuCurriculumVault = "resources.menu.curriculumvault";
  static ResourcesMenuSchoolPortal = "resources.menu.schoolportal";
  static ResourcesMenuDigitalContent = "resources.menu.digitalcontent";
  static ResourcesMenuReports = "resources.menu.reports";
  static ResourcesMenuStudentREP = "resources.menu.studentrep";
  static ResourcesMenuTeacherTraining = "resources.menu.teachertraining";
  static ResourcesMenuLMS = "resources.menu.lms";
  static ResourcesParentPortal = "resources.menu.parentportal";
}

export class HelpMenuLocal {
  static Help = "help.menu.help";
  static Contact = "help.menu.contact";
  static Documentation = "help.menu.documentation";
}
export class WriterReview {
  static Title = "writer_review.title";
  static NoClassOrGroup = "writer_review.no_class_or_group";
  static NoStudent = "writer_review.no_student";
}
export class CaptureNotification {
  static CaptureSuccessStudent = "capture_status.capture_successfully_student";
  static CaptureSuccessAll = "capture_status.capture_successfully_all";
  static CaptureSuccessAmount = "capture_status.capture_successfully_amount";
  static CaptureErrorStudent = "capture_status.capture_error_student";
  static CaptureErrorAmount = "capture_status.capture_error_amount";
  static ReachedMaximum = "capture_status.reached_maximum";
  static StudentsReachedMaximum = "capture_status.students_reached_maximum";
  static EnableStudentVideo = "capture_status.enable_student_video";
  static EnableAllStudentVideo = "capture_status.enable_all_student_video";
}
export class ClassSetUp {
  static StartSession = "class_setup.start_session";
  static Members = "class_setup.members";
  static ActiveStudents = "class_setup.active_students";
  static InActiveStudents = "class_setup.inactive_students";
  static RemoteClassSetUp = "class_setup.remote_class_set_up";
  static DeviceSettings = "class_setup.device_settings";
  static Platform = "class_setup.Platform";
  static SelectUnitLesson = "class_setup.select_unit_lesson";
  static ZoomConfirmationMessage = "class_setup.zoom_confirmation.message";
  static ZoomConfirmationOk = "class_setup.zoom_confirmation.ok";
  static ZoomConfirmationOkHelper = "class_setup.zoom_confirmation.ok_helper";
  static ZoomConfirmationCancel = "class_setup.zoom_confirmation.cancel";
}

export class ClassCard {
  static Unit = "class_card.unit";
  static Lesson = "class_card.lesson";
  static Members = "class_card.members";
}
export class ScheduleInfo {
  static EnterTime = "schedule_info.enter_time";
  static BottomNote = "schedule_info.bottom_note";
  static NonRecurringNote = "schedule_info.non_recurring_note";
  static AddSession = "schedule_info.add_session";
  static Hour = "schedule_info.hour";
  static Minute = "schedule_info.minute";
  static NewSession = "schedule_info.new_session";
  static Restore = "schedule_info.restore";
  static AM = "schedule_info.am";
  static PM = "schedule_info.pm";
  static CantCreateSchedule = "schedule.cant_create_schedule";
}
export class StudentCard {
  static OneToOneNotification = "student_card.one_to_one_notification";
}
export class LowBandWidthLocales {
  static LocalUserLowBandWidth = "lowbandwidth.local";
  static RemoteUserLowBandWidth = "lowbandwidth.remote";
}

export class SessionReplacementLocales {
  static Description = "session_replacement.description";
  static Accept = "session_replacement.accept";
  static Decline = "session_replacement.decline";
  static Title = "session_replacement.title";
  static NotificationOldTeacherDesc = "session_replacement.notification_teacher";
}
export class IndependentLocales {
  static IndependentMode = "independent_mode.independent_mode_name";
  static StartMode = "independent_mode.start_mode";
  static StopMode = "independent_mode.stop_mode";
  static StopWarning = "independent_mode.stop_warning";
  static PreventToggleIndependentModeWarning = "independent_mode.prevent_toggle_independent_mode_warning";
  static StartMessage = "independent_mode.start_message";
  static StopMessage = "independent_mode.stop_message";
}

export class TeamLocales {
  static TeamMode = "team_mode.team_mode_name";
  static Teams = "team_mode.teams";
  static FetchingTeams = "team_mode.fetching_teams";
  static NewTeam = "team_mode.new_team";
  static EditTeam = "team_mode.edit_team";
  static ResetTeam = "team_mode.reset_team";
  static TeamName = "team_mode.team_name";
  static TeamColor = "team_mode.team_color";
  static SettingTeams = "team_mode.setting_teams";
  static TeamNameRequired = "team_mode.name_required";
  static MaxTeamWaring = "team_mode.max_team_warning";
  static ConfirmDelTeam = "team_mode.confirm_del_team";
  static ConfirmCancelSettingTitle = "team_mode.confirm_cancel_setting_title";
  static ConfirmCancelSettingDesc = "team_mode.confirm_cancel_setting_desc";
  static PreventToggleTeamModeWarning = "team_mode.prevent_toggle_team_mode_warning";
  static UnableMuteTeam = "team_mode.unable_mute_team_desc";
}

export class Survey {
  static Title = "survey.title";
  static Question = "survey.question_including_students";
  static ResponseYes = "survey.response.yes";
  static ResponseNo = "survey.response.no";
  static FeedbackPlaceholder = "survey.feedback_placeholder";
  static ButtonSubmit = "survey.button.submit";
}

export class TextBoxControl {
  static Color = "textbox_control.change_color";
  static Font = "textbox_control.font";
  static FontSize = "textbox_control.font_size";
  static Close = "textbox_control.close";
}

export class BrowserSupportLocale {
  static BrowserRecommendation = "browser_support.browser_recommendation";
  static BrowserUnsupported = "browser_support.browser_unsupported";
  static This = "browser_support.this";
  static DontShowAgain = "browser_support.dont_show_again";
  static DownloadGoogleChrome = "browser_support.download_google_chrome";
  static Close = "browser_support.close";
  static DownloadApp = "browser_support.download_app";
  static YourBrowserIsUnsupported = "browser_support.your_browser_is_unsupported";
  static SwitchToChrome = "browser_support.switch_to_chrome";
}

export class TeacherActionLocale {
  static WaringTitle = "teacher_action.warning_title";
  static WarningUpdateMediaDeviceFailed = "teacher_action.notif_switch_media_device_desc";
  static WarningUpdatePaletteFailed = "teacher_action.notif_toggle_palette_desc";
  static WarningMediaDeviceCamera = "teacher_action.media_device_camera";
  static WarningMediaDeviceMicro = "teacher_action.media_device_micro";
}
