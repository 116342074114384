import { fabric } from "fabric";
import { removeCanvasObjectIcon } from "@/utils/utils";
let canvas: any = null;
const img = document.createElement("img");
const { size: originalSize, dataUrl } = removeCanvasObjectIcon;
img.src = dataUrl;
export const CUSTOM_CONTROL_DELETE_ID = "deleteControl";
const updateClickAreaPosition = (position: { x: number; y: number }) => {
  const { x, y } = position;
  fabric.Object.prototype.controls[CUSTOM_CONTROL_DELETE_ID].y = y;
  fabric.Object.prototype.controls[CUSTOM_CONTROL_DELETE_ID].x = x;
  fabric.Textbox.prototype.controls[CUSTOM_CONTROL_DELETE_ID].y = y;
  fabric.Textbox.prototype.controls[CUSTOM_CONTROL_DELETE_ID].x = x;
};
const renderTrashIcon = (ctx: any, left: any, top: any, _: any, fabricObject: any) => {
  ctx.save();
  const control = fabricObject.getBoundingRect();
  const scale = Math.max(fabricObject.scaleX, fabricObject.scaleY);
  const size = Math.min(originalSize * scale, 30);

  // Update the size of the deleteControl dynamically
  fabricObject.controls[CUSTOM_CONTROL_DELETE_ID].cornerSize = size;
  fabricObject.controls[CUSTOM_CONTROL_DELETE_ID].sizeX = size + 8;
  fabricObject.controls[CUSTOM_CONTROL_DELETE_ID].sizeY = size + 8;
  updateClickAreaPosition({ x: 0.5, y: -0.5 });
  // TODO: All conditions below can be removed if we allow edit (move, scale, rotate ...) the "path" canvas object
  if (fabricObject.angle !== 0) {
    ctx.translate(left, top);
  } else if (
    control.top < size &&
    control.top + control.height > canvas.height - size &&
    control.left < size &&
    control.left + control.width > canvas.width - size
  ) {
    // If object close 4 corners of canvas
    ctx.translate(control.left + control.width / 2, control.top + control.height / 2);
    updateClickAreaPosition({ x: 0, y: 0 });
  } else if (control.top < size && control.top + control.height > canvas.height - size) {
    // If object close 2 corners top,bottom of canvas
    ctx.translate(control.left + control.width, control.top + control.height / 2);
    updateClickAreaPosition({ x: 0.5, y: 0 });
  } else if (control.left < size && control.left + control.width > canvas.width - size) {
    // If object close 2 corners left, right of canvas
    ctx.translate(control.left + control.width / 2, control.top);
    updateClickAreaPosition({ x: 0, y: -0.5 });
  } else if (control.top < size) {
    // If object close top corner of canvas
    if (control.left + control.width > canvas.width) {
      ctx.translate(control.left, control.top + control.height);
      updateClickAreaPosition({ x: -0.5, y: 0.5 });
    } else {
      ctx.translate(control.left + control.width, control.top + control.height);
      updateClickAreaPosition({ x: 0.5, y: 0.5 });
    }
  } else if (control.top + control.height > canvas.height - size) {
    // If object close bottom corner of canvas
    if (control.left + control.width > canvas.width) {
      ctx.translate(control.left, control.top);
      updateClickAreaPosition({ x: -0.5, y: -0.5 });
    } else {
      ctx.translate(control.left + control.width, control.top);
      updateClickAreaPosition({ x: 0.5, y: -0.5 });
    }
  } else if (control.left < size) {
    // If object close left corner of canvas
    ctx.translate(control.left + control.width, control.top);
    updateClickAreaPosition({ x: 0.5, y: -0.5 });
  } else if (control.left + control.width > canvas.width - size) {
    // If object close right corner of canvas
    ctx.translate(control.left, control.top);
    updateClickAreaPosition({ x: -0.5, y: -0.5 });
  } else {
    ctx.translate(control.width + control.left, control.top);
    updateClickAreaPosition({ x: 0.5, y: -0.5 });
  }
  ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
  ctx.drawImage(img, -size / 2, -size / 2, size, size);
  ctx.restore();
};
export const initCanvas = (cvRef: any) => {
  canvas = cvRef;
};
export interface ICanvasCallbacks {
  handlePressRemoveIcon: (eventData: any, transform: any) => void;
}
export const initConf = (callbacks: ICanvasCallbacks) => {
  const { handlePressRemoveIcon } = callbacks;
  const options = {
    x: 0.5,
    y: -0.5,
    offsetY: 0,
    offsetX: 0,
    cursorStyle: "pointer",
    mouseUpHandler: handlePressRemoveIcon,
    render: renderTrashIcon,
    cornerSize: originalSize,
    // Make the clickable area larger than the trash image https://github.com/fabricjs/fabric.js/issues/8612#issuecomment-1396944208
    sizeX: originalSize + 8,
    sizeY: originalSize + 8,
  };
  fabric.Object.prototype.controls[CUSTOM_CONTROL_DELETE_ID] = new fabric.Control(options);
  fabric.Textbox.prototype.controls[CUSTOM_CONTROL_DELETE_ID] = new fabric.Control(options);
};
export default (): any => [canvas];
