import { Paths } from "./paths";

declare module "@vue/runtime-core" {
  interface ComponentCustomProperties {
    $paths: typeof Paths;
  }
}

export interface DynamicKeysObject {
  [key: string]: any;
}

export enum AntdNotificationType {
  SUCCESS = "success",
  INFO = "info",
  ERROR = "error",
  WARNING = "warning",
}
