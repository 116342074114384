import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/exclamation-circle.svg'


const _hoisted_1 = { class: "w-5 h-5 cursor-help" }
const _hoisted_2 = ["alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Tooltip = _resolveComponent("Tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Tooltip, { placement: "top" }, {
      title: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.message), 1)
      ]),
      default: _withCtx(() => [
        _createElementVNode("img", {
          src: _imports_0,
          alt: _ctx.altText
        }, null, 8, _hoisted_2)
      ]),
      _: 1
    })
  ]))
}