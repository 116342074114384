import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WarningMessage = _resolveComponent("WarningMessage")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.disconnectedMsgShown)
      ? (_openBlock(), _createBlock(_component_WarningMessage, {
          key: 0,
          textMsg: _ctx.MsgLostNetwork
        }, null, 8, ["textMsg"]))
      : _createCommentVNode("", true)
  ]))
}