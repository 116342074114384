import { computed, defineComponent } from "vue";

export default defineComponent({
  props: ["textMsg"],
  setup(props) {
    const textMessage = computed(() => props.textMsg);
    return {
      textMessage,
    };
  },
});
