import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/logo-light.png'


const _hoisted_1 = { class: "loading-container" }
const _hoisted_2 = { class: "gl-splash__container" }
const _hoisted_3 = { class: "gl-splash__title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createElementVNode("img", {
        class: "gl-splash__logo",
        src: _imports_0
      }, null, -1)),
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "gl-splash__divider ant-divider-horizontal" }, [
        _createElementVNode("div", { class: "gl-progress gl-splash__progress gl-progress--visible !bg-gs-purple/40" }, [
          _createElementVNode("div", { class: "gl-progress__indeterminate" })
        ])
      ], -1)),
      _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.titleLoadingText), 1)
    ])
  ]))
}