import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "grid min-h-full place-items-center px-6 py-24 sm:py-32 lg:px-8" }
const _hoisted_2 = { class: "text-center" }
const _hoisted_3 = { class: "mt-4 text-balance text-2xl md:text-4xl 2xl:text-7xl font-semibold tracking-tight text-gray-900" }
const _hoisted_4 = { class: "mt-6 text-pretty text-lg font-medium text-gray-500 sm:text-xl/8" }
const _hoisted_5 = { class: "mt-10 flex items-center justify-center gap-x-6" }
const _hoisted_6 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("main", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.MsgBrowserUnsupported), 1),
      _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.blockedBrowserMsg), 1),
      _createElementVNode("div", _hoisted_5, [
        (_ctx.downloadLink)
          ? (_openBlock(), _createElementBlock("a", {
              key: 0,
              href: _ctx.downloadLink,
              target: "_blank",
              class: "rounded-md bg-gs-purple px-3.5 py-2.5 text-lg font-semibold text-white shadow-sm hover:bg-gs-purple/80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 hover:text-white focus-visible:outline-gs-purple/80 transition-colors"
            }, _toDisplayString(_ctx.isDesktop ? _ctx.MsgDownloadGoogleChrome : _ctx.MsgDownloadApp), 9, _hoisted_6))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}