import { ref, onBeforeUnmount } from "vue";
import { Logger } from "@/utils/logger";
// The purpose of this hook is to fix the issue called "Browser sleeping tab" https://learn.microsoft.com/en-us/aspnet/core/signalr/javascript-client?view=aspnetcore-6.0&tabs=visual-studio#bsleep:~:text=Browser%20sleeping%20tab
export function useTabLock() {
  let lockResolver: (() => void) | undefined;
  const isLocked = ref(false);

  const requestLock = (): void => {
    if (navigator && navigator.locks && navigator.locks.request) {
      const promise = new Promise<void>((res) => {
        lockResolver = res;
      });

      navigator.locks.request("unique_lock_name", { mode: "exclusive" }, () => {
        isLocked.value = true;
        Logger.log("Lock acquired successfully!");
        return promise;
      });
    } else {
      Logger.warn("navigator.locks API is not supported in this browser.");
    }
  };

  const releaseLock = (): void => {
    if (isLocked.value && lockResolver) {
      lockResolver();
      isLocked.value = false;
      Logger.log("Lock released successfully!");
    } else {
      Logger.warn("Attempted to release lock, but lock was not acquired or resolver is undefined.");
    }
  };

  // Automatically release the lock when the component is about to be unloaded
  onBeforeUnmount(() => {
    releaseLock();
  });

  // Request the lock when the component is created
  requestLock();

  return {
    isLocked,
    releaseLock,
  };
}
