import { computed, defineComponent } from "vue";
import { fmtMsg } from "vue-glcommonui";
import { BrowserSupportLocale, CommonLocale } from "@/locales/localeid";
import { isUnknownDevice, isAndroidDevice } from "@/utils/utils";
import { useStore } from "vuex";
export default defineComponent({
  props: {
    isDesktop: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { getters } = useStore();
    const isParent = computed(() => getters["auth/isParent"]);
    const MsgBrowserUnsupported = computed(() => fmtMsg(BrowserSupportLocale.YourBrowserIsUnsupported));
    const MsgDownloadGoogleChrome = computed(() => fmtMsg(BrowserSupportLocale.DownloadGoogleChrome));
    const MsgDownloadApp = computed(() => fmtMsg(BrowserSupportLocale.DownloadApp));
    const MsgWarningDeviceTeacher = computed(() => fmtMsg(CommonLocale.CommonWarningDeviceTeacher));
    const MsgWarningDeviceStudent = computed(() => fmtMsg(CommonLocale.CommonWarningDeviceStudent));
    const MsgSwitchToChrome = computed(() => fmtMsg(BrowserSupportLocale.SwitchToChrome));
    const blockedBrowserMsg = computed(() => {
      if (props.isDesktop) return MsgSwitchToChrome.value;
      return isParent.value ? MsgWarningDeviceStudent.value : MsgWarningDeviceTeacher.value;
    });

    enum StoreLinks {
      iOS = "https://apps.apple.com/app/id1638056937",
      Android = "https://play.google.com/store/apps/details?id=com.gsconnect.prod",
    }
    const chromeLink = "https://www.google.com/intl/en_us/chrome/";
    const downloadLink = computed(() => {
      if (props.isDesktop) return chromeLink;
      return isUnknownDevice || !isParent.value ? "" : isAndroidDevice ? StoreLinks.Android : StoreLinks.iOS;
    });

    return {
      downloadLink,
      MsgBrowserUnsupported,
      MsgDownloadGoogleChrome,
      MsgDownloadApp,
      blockedBrowserMsg,
    };
  },
});
