import { computed } from "vue";
import { useStore } from "vuex";
import { useClientSide } from "@/hooks/use-client-side";

export const useWhiteboard = () => {
  const { getters } = useStore();
  const { isTeacherSide } = useClientSide();
  const isWbVisible = computed<boolean>(() => {
    if (isTeacherSide.value) return getters["teacherRoom/isShowWhiteBoard"];
    return getters["studentRoom/isShowWhiteboard"];
  });
  return {
    isWbVisible,
  };
};
