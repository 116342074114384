import { defineComponent } from "vue";
import { generateAvatar } from "@/utils/utils";

export default defineComponent({
  props: {
    src: {
      type: String,
      default: generateAvatar(),
    },
    alt: {
      type: String,
      default: "",
    },
  },
  setup() {
    return {};
  },
});
