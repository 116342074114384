import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full h-full" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CropImage = _resolveComponent("CropImage")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (
        typeof _ctx.item.image.metaData?.width === 'number' &&
        _ctx.item.image.metaData?.width > 0 &&
        typeof _ctx.item.image.metaData?.height === 'number' &&
        _ctx.item.image.metaData.height > 0
      )
      ? (_openBlock(), _createBlock(_component_CropImage, {
          imageUrl: _ctx.item.image.url,
          metadata: _ctx.item.image.metaData,
          class: "w-full h-full object-contain",
          onImgError: _ctx.handleImageLoadError,
          key: _ctx.reloadKey
        }, null, 8, ["imageUrl", "metadata", "onImgError"]))
      : (_openBlock(), _createElementBlock("img", {
          crossorigin: "anonymous",
          class: "w-full h-full object-contain",
          src: _ctx.item.image.url,
          alt: "",
          style: _normalizeStyle([
        _ctx.item.image.metaData
          ? {
              transform: `scale(${_ctx.item.image.metaData.scaleX},${_ctx.item.image.metaData.scaleY}) rotate(${_ctx.item.image.metaData.rotate}deg)`,
              // if img is rotated, width equal to height of the parent div
              width:
                _ctx.item.image.metaData.rotate && (Math.abs(_ctx.item.image.metaData.rotate) === 270 || Math.abs(_ctx.item.image.metaData.rotate) === 90)
                  ? '100px'
                  : '100%',
            }
          : '',
      ]),
          onError: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleImageLoadError && _ctx.handleImageLoadError(...args))),
          key: _ctx.reloadKey
        }, null, 44, _hoisted_2))
  ]))
}