import { GetterTree } from "vuex";
import { TeacherTeachingState } from "./state";

const getters: GetterTree<TeacherTeachingState, any> = {
  getIndependentListStudentItems(s: TeacherTeachingState) {
    return s.independentListStudentItems;
  },
};

export default getters;
