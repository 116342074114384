import { GenerateTokenModal, RoomModel, UnitAndLessonModel, MinimalSessionDtoInterface, StudentModel } from "@/models";
import { DrawingPayloadInterface, PositionPayloadInterface } from "@/store/room/interface";
import { IndependentStudentItem } from "../independent";
import { CanvasObjectModel } from "@/hooks/use-send-websocket-msg";
import { MediaDeviceTypes } from "@/utils/utils";

export interface BaseResponse<T> {
  success: boolean;
  message?: string;
  data: T;
  code?: number;
}
export interface NextSessionResponse {
  classInfo: {
    classId: string;
    className: string;
    groupId: string;
    groupName: string;
    lesson: number;
    startTime: null | string;
    unit: number;
  };
  nextTime: null | string;
  sessionId: string | null;
  studentId: string;
}

export interface SessionStateModel {
  startMediaAt: null | string;
  duration: number;
  pauseMediaAt: null | string;
}

export type FetchSessionDtoResModel = BaseResponse<RoomModel>;
export type UnitAndLessonResponse = BaseResponse<UnitAndLessonModel[]>;
export type GetMinimalSessionDtoResponse = BaseResponse<MinimalSessionDtoInterface>;
export type GenerateTokenResponse = GenerateTokenModal;
export type SessionReplacementResponse = BaseResponse<{}>;
export type SessionStateResponse = BaseResponse<SessionStateModel>;
export type CheckSessionStartResp = BaseResponse<{}>;
export type TeacherToggleStudentMediaDevicesResp = BaseResponse<{
  disconnectedStudents: string[];
  successStudents: string[];
}>;
export type ToggleStudentPalettesResp = BaseResponse<{}>;
export type SetStudentsBadgeResp = BaseResponse<{}>;
export type ClearStudentRaisingHandResp = BaseResponse<{}>;

export enum MainRoles {
  TEACHER = "teacher",
  HELPER = "helper",
  STUDENT = "student",
}
export interface PutRemoteUserDisconnectedReqModel {
  localUserId: string;
  remoteUserRole: MainRoles;
  remoteUserId: string;
  sessionId: string;
}
export interface TeacherUpdateMediaStateRequestModel {
  isPlay: boolean;
  duration: number;
}

export interface PostLocalUserReconnectedReqModel {
  localUserRole: MainRoles;
  localUserId: string;
  sessionId: string;
}

export interface FetchTeamsReqModel {
  sessionId: string;
}
export interface TeamDto {
  id: string | null;
  name: string;
  color: string;
  order: number;
  studentIds: string[];
}
export interface OneToOneDto {
  id: string;
  exposureSelected: string;
  itemContentSelected: string;
  isEnablePalette?: boolean;
  isShowWhiteBoard: boolean;
  drawing: null | DrawingPayloadInterface;
  student: null | StudentModel;
  teachingMode: number;
  messageVersion: number;
  ratio: number;
  position: PositionPayloadInterface;
  studentIndependents: null | IndependentStudentItem[];
  isIgnoreTeacherAudio: null | boolean;
  ratioScrollPdf: number;
  startMediaAt: null | string;
  pauseMediaAt: null | string;
  duration: number;
}
export type FetchTeamsResModel = BaseResponse<TeamDto[]>;

export interface PutToggleTeamModeReqModel {
  isOpen: boolean;
  sessionId: string;
}
export type PutToggleTeamModeResModel = BaseResponse<boolean>;

export interface PutTeamsReqModel {
  sessionId: string;
  teams: TeamDto[];
}

export interface SurveyResModel {
  sessionId: string;
  isGoodQuality: boolean;
  feedback: string;
}

export interface TeacherUpdateCanvasObjectRequestModel {
  canvasObject: CanvasObjectModel;
  sessionId: string;
  isTeacherUseOnly: boolean;
}

export interface ToggleStudentMediaDevicesAsync {
  mediaDeviceType: MediaDeviceTypes;
  studentIds: string[];
  isMute: boolean;
}

export interface ToggleStudentMediaDevicesReq extends ToggleStudentMediaDevicesAsync {
  sessionId: string;
}

export interface ToggleStudentPaletteAsync {
  studentId: string;
  isEnable: boolean;
  isHelper: boolean;
  isOneOne: boolean;
}
export interface ToggleStudentPaletteReq extends ToggleStudentPaletteAsync {
  sessionId: string;
}

export interface SetStudentsBadgeReq {
  sessionId: string;
  studentIds: string[];
}

export type PutTeamsResModel = BaseResponse<any>;
