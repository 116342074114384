import { TeacherClassGallery } from "@/locales/localeid";
import { StudentState } from "@/store/room/interface";
import { useElementSize } from "@vueuse/core";
import { computed, defineComponent, provide, ref, PropType } from "vue";
import { fmtMsg } from "vue-glcommonui";
import { useStore } from "vuex";
import StudentCard from "../student-card/student-card.vue";
import { useManipulateTeamState } from "@/hooks/use-team";
import { sortBy } from "lodash";

// const ANT_PROGRESS_CLASS = "ant-progress-bg";
export default defineComponent({
  props: {
    students: {
      required: true,
      type: Object as PropType<StudentState[]>,
    },
  },
  components: {
    StudentCard,
  },
  setup(props) {
    const MsgNoStudentOnline = computed(() => fmtMsg(TeacherClassGallery.NoStudentJoinClass));
    const BREAKPOINTS = 450;
    const { getters } = useStore();
    const studentGallery = ref<HTMLElement>();
    const { width: studentGalleryWidth } = useElementSize(studentGallery);
    const { isTeamMode, findTeamByStudentId } = useManipulateTeamState();
    const topStudents = computed<StudentState[]>(() => {
      const listStudents = props.students.slice(0, 12).filter(({ id }) => getters["calling/checkCallingHasUserById"](id));
      return isTeamMode
        ? sortBy(listStudents, [
            function (s) {
              return findTeamByStudentId(s.id)?.order;
            },
          ])
        : listStudents;
    });
    const oneWithTeacherStudentId = computed(() => getters["teacherRoom/getStudentModeOneId"]);
    const totalOnlineStudents = computed(() => topStudents.value.length);
    const studentPerRow = computed(() => {
      if (totalOnlineStudents.value <= 1 || (totalOnlineStudents.value <= 6 && studentGalleryWidth.value < BREAKPOINTS)) return 1;
      if (totalOnlineStudents.value <= 6 || (totalOnlineStudents.value > 6 && studentGalleryWidth.value < BREAKPOINTS)) return 2;
      return 3;
    });
    const studentLayoutClass = computed(() => {
      switch (studentPerRow.value) {
        case 3:
          return "grid-cols-3";
        case 2:
          return "grid-cols-2";
        default:
          return "grid-cols-1";
      }
    });
    const scaleVideoOption = computed(() => {
      if (studentPerRow.value === 1) return 1;
      if (totalOnlineStudents.value <= 3) return 1.6;
      if (totalOnlineStudents.value <= 6) return 1.4;
      return 2;
    });
    const focusedStudent = ref<string>("");
    const updateFocusStudent = (studentId?: string) => {
      if (studentId) {
        return (focusedStudent.value = studentId);
      }
      focusedStudent.value = "";
    };
    const getTransformOriginClass = (index: number) => {
      const numberOfRows = Math.ceil(totalOnlineStudents.value / studentPerRow.value);
      const rowIndex = Math.ceil((index + 1) / studentPerRow.value);
      const colIndex = (index + 1) % studentPerRow.value;
      let vertical = "";
      let horizontal = "";
      if (colIndex === 1) {
        horizontal = "-left";
      } else if (colIndex === 0) {
        horizontal = "-right";
      }
      if (rowIndex === 1) {
        vertical = "-top";
      } else if (rowIndex === numberOfRows) {
        vertical = "-bottom";
      }
      if (vertical || horizontal) {
        return "origin" + vertical + horizontal;
      }
      return "";
    };
    //** TODO: this block of code use only for Zoom but always affect the component, although current GSConnect does not support Zoom */
    // const isUsingAgora = computed(() => getters["isUsingAgora"]);
    // const forceUpdateZoomVideoLayout = async () => {
    //   const isUsingAgora = getters["isUsingAgora"];
    //   if (isUsingAgora) return;
    //   await teacherRoomManager.value?.adjustRenderedVideoPosition();
    // };
    // watch(studentGalleryWidth, async () => {
    //   await forceUpdateZoomVideoLayout();
    // });
    // const canvasRef = ref(null);
    // const canvasListStudentVideosRef = ref(null);
    // const { height: bodyHeight, width: bodyWidth } = useElementSize(document.body);
    // watch([bodyHeight, bodyWidth], async () => {
    //   await forceUpdateZoomVideoLayout();
    // });
    // const { width: customWidth, height: customHeight } = useElementSize(canvasListStudentVideosRef);
    // watch([customWidth, customHeight], async () => {
    //   await forceUpdateZoomVideoLayout();
    // });
    // const adjustRenderedVideoPosition = async (e: any) => {
    //   if (!teacherRoomManager.value || e.target.className === ANT_PROGRESS_CLASS) {
    //     return;
    //   }
    //   handleFocusedCanvasDimensions();
    //   if (e.propertyName === "margin-left") {
    //     await teacherRoomManager.value?.adjustRenderedVideoPosition();
    //   } else if (e.propertyName === "width") {
    //     await teacherRoomManager.value?.adjustRenderedVideoPosition(e.target.id.split("__")[0]);
    //   }
    // };
    // watch(totalOnlineStudents, async () => {
    //   if (!teacherRoomManager.value || isUsingAgora) {
    //     return;
    //   }
    //   await nextTick();
    //   handleFocusedCanvasDimensions();
    //   await teacherRoomManager.value?.adjustRenderedVideoPosition();
    // });
    //
    // const sidebarEl = ref<HTMLDivElement>();
    // const studentListEl = ref<HTMLDivElement>();
    // const expandedWrapperStyle = ref<any>();
    // const studentEl = ref<HTMLDivElement>();
    // const handleFocusedCanvasDimensions = () => {
    //   if (isUsingAgora.value || !focusedStudent.value) {
    //     return;
    //   }
    //   const studentListEl = document.getElementById("student-list") as HTMLDivElement;
    //   if (studentListEl && studentEl.value)
    //     expandedWrapperStyle.value = {
    //       width: studentEl.value.getBoundingClientRect().width + "px",
    //       height: studentEl.value.getBoundingClientRect().height + "px",
    //       top: studentEl.value.getBoundingClientRect().y - studentListEl.getBoundingClientRect().y + "px",
    //       left: studentEl.value.getBoundingClientRect().x - studentListEl.getBoundingClientRect().x + "px",
    //     };
    // };
    // const stopRenderExpandedZoomVideo = async (userId: string) => {
    //   await teacherRoomManager.value.renderExpandedVideo(userId, false);
    //   if (studentEl.value) {
    //     studentEl.value.removeEventListener("transitionend", handleRenderExpandedZoomVideo);
    //     studentEl.value = undefined;
    //   }
    // };
    // const handleRenderExpandedZoomVideo = async (e: any) => {
    //   if (e.propertyName === "transform") {
    //     await teacherRoomManager.value?.adjustRenderedVideoPosition();
    //     if (focusedStudent.value) {
    //       handleFocusedCanvasDimensions();
    //       await nextTick();
    //       await teacherRoomManager.value.renderExpandedVideo(focusedStudent.value, true);
    //     } else {
    //       const id: string = e.target.id.split("__")[0];
    //       stopRenderExpandedZoomVideo(id);
    //     }
    //   }
    // };
    // watch(focusedStudent, async (currentValue, prevValue) => {
    //   if (isUsingAgora.value) {
    //     return;
    //   }
    //   if (currentValue && prevValue) {
    //     await stopRenderExpandedZoomVideo(prevValue);
    //   }
    //   if (currentValue) {
    //     studentEl.value = document.getElementById(`${currentValue}__sub-wrapper`) as HTMLDivElement;
    //     studentEl.value.addEventListener("transitionend", handleRenderExpandedZoomVideo);
    //   } else {
    //     studentEl.value = undefined;
    //     expandedWrapperStyle.value = {
    //       width: 0,
    //       height: 0,
    //       top: 0,
    //       left: 0,
    //     };
    //   }
    // });
    // const timer = ref<any>();
    // const onWindowResize = async () => {
    //   if (timer.value) {
    //     clearTimeout(timer.value);
    //   }
    //   const canvasWrapper = document.getElementById("participant-videos-wrapper");
    //   if (canvasWrapper) {
    //     canvasWrapper.style.visibility = "hidden";
    //   }
    //   timer.value = setTimeout(async () => {
    //     handleFocusedCanvasDimensions();
    //     await nextTick();
    //     await teacherRoomManager.value?.adjustRenderedVideoPosition();
    //     if (canvasWrapper) {
    //       canvasWrapper.style.visibility = "visible";
    //     }
    //   }, 100);
    // };
    // onMounted(() => {
    //   window.addEventListener("resize", onWindowResize);
    //   if (!isUsingAgora.value) {
    //     sidebarEl.value = document.getElementById("tc__sidebar") as HTMLDivElement;
    //     studentListEl.value = document.getElementById("student-list") as HTMLDivElement;
    //     sidebarEl.value.addEventListener("transitionend", adjustRenderedVideoPosition);
    //     studentListEl.value.addEventListener("transitionend", adjustRenderedVideoPosition);
    //   }
    //   try {
    //     const studentListElement = document.getElementById("student-list") as HTMLDivElement;
    //     const canvas = document.getElementById(PARTICIPANT_CANVAS_ID) as HTMLCanvasElement;
    //     if (canvas) {
    //       canvas.width = studentListElement.offsetWidth;
    //       canvas.height = studentListElement.offsetHeight;
    //     }
    //   } catch (error) {
    //     Logger.error(error);
    //   }
    // });
    // onUnmounted(() => {
    //   window.removeEventListener("resize", onWindowResize);
    //   if (!isUsingAgora.value && sidebarEl.value && studentListEl.value) {
    //     sidebarEl.value.removeEventListener("transitionend", adjustRenderedVideoPosition);
    //     studentListEl.value.removeEventListener("transitionend", adjustRenderedVideoPosition);
    //   }
    // });
    //** TODO: this block of code use only for Zoom but always affect the component, although current GSConnect does not support Zoom */
    provide("updateFocusStudent", updateFocusStudent);
    return {
      topStudents,
      oneWithTeacherStudentId,
      focusedStudent,
      totalOnlineStudents,
      scaleVideoOption,
      MsgNoStudentOnline,
      studentPerRow,
      studentLayoutClass,
      studentGallery,
      // expandedWrapperStyle,
      // isUsingAgora,
      // canvasRef,
      // canvasListStudentVideosRef,
      getTransformOriginClass,
    };
  },
});
