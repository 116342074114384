import { computed, ComputedRef } from "vue";
import { useStore } from "vuex";
import { ExposureItemMedia } from "@/store/lesson/state";
import { useWhiteboard } from "@/hooks/use-whiteboard";

export interface IFocusWordContent {
  condition: boolean;
  word: string;
  definition: string;
}
export const useFocusWord = (): { focusWordContent: ComputedRef<IFocusWordContent> } => {
  const { getters } = useStore();
  const { isWbVisible } = useWhiteboard();
  const currentExposureItemMedia = computed<ExposureItemMedia | undefined>(() => getters["lesson/currentExposureItemMedia"]);
  const focusWordContent: ComputedRef<IFocusWordContent> = computed(() => {
    const readerFocusWord = currentExposureItemMedia.value?.readerFocusWord;
    const isFocusWordVisible = readerFocusWord && !isWbVisible.value;
    return {
      condition: !!isFocusWordVisible,
      word: isFocusWordVisible ? readerFocusWord.word : "",
      definition: isFocusWordVisible ? readerFocusWord.definition : "",
    };
  });

  return {
    focusWordContent,
  };
};
