<template>
  <AppHeader class="header text-sm !bg-gs-purple" :title="title || ''">
    <template #right>
      <NavMenu v-if="isShowNavMenu" />
      <ResourceMenu />
      <HelpMenu />
      <LanguagePicker />
      <UserAvatar>
        <template #extraItems>
          <DropdownItem v-if="testDeviceShown" :text="testConnectText" @click.prevent="onClickTestDevice" />
          <!-- <DropdownItem v-if="isShowWriterReview" :text="writerReviewText" @click.prevent="onClickWriterReview" /> -->
        </template>
      </UserAvatar>
    </template>
    <DeviceSettingsModal v-if="testDeviceShown" :hideFooter="true" v-model:visible="deviceSettingsModalShown" />
  </AppHeader>
</template>
<style src="./header.scss" lang="scss" scoped></style>
<script src="./header.ts" lang="ts"></script>
