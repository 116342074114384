import { MutationTree } from "vuex";
import { WhiteboardState, AlterContentMediaState, initWhiteboardModuleState } from "./state";

const mutations: MutationTree<WhiteboardState> = {
  resetState(s: WhiteboardState) {
    Object.assign(s, initWhiteboardModuleState());
  },
  setMediaState(state: WhiteboardState, payload: AlterContentMediaState) {
    state.mediaState = payload;
    state.shouldFetchMediaState = false;
  },
  setShouldFetchMediaState(state: WhiteboardState, payload: boolean) {
    state.shouldFetchMediaState = payload;
  },
};

export default mutations;
