import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, vShow as _vShow, createElementBlock as _createElementBlock, withDirectives as _withDirectives, normalizeClass as _normalizeClass, resolveDynamicComponent as _resolveDynamicComponent, createVNode as _createVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "relative text-slate-600 rounded-xl w-full overflow-hidden group" }
const _hoisted_2 = { class: "aspect-w-16 aspect-h-9 bg-white" }
const _hoisted_3 = { class: "absolute inset-0 flex justify-center items-center" }
const _hoisted_4 = {
  key: 1,
  class: "animate-spin h-6 w-6 text-slate-600",
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 24 24"
}
const _hoisted_5 = ["id"]
const _hoisted_6 = ["id"]
const _hoisted_7 = ["id"]
const _hoisted_8 = ["disabled"]
const _hoisted_9 = ["disabled"]
const _hoisted_10 = {
  key: 0,
  class: "absolute bottom-2 right-3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ConnectAvatar = _resolveComponent("ConnectAvatar")!
  const _component_LoadingOutlined = _resolveComponent("LoadingOutlined")!
  const _component_MicrophoneIcon = _resolveComponent("MicrophoneIcon")!
  const _component_Cog6ToothIcon = _resolveComponent("Cog6ToothIcon")!
  const _component_DeviceSettingsModal = _resolveComponent("DeviceSettingsModal")!
  const _component_ArrowsPointingInIcon = _resolveComponent("ArrowsPointingInIcon")!
  const _component_WifiIcon = _resolveComponent("WifiIcon")!
  const _component_NameTag = _resolveComponent("NameTag")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("figure", null, [
        _createElementVNode("div", _hoisted_3, [
          (!_ctx.videoEnabled)
            ? (_openBlock(), _createBlock(_component_ConnectAvatar, {
                key: 0,
                src: _ctx.avatarTeacher,
                alt: _ctx.teacher?.name
              }, null, 8, ["src", "alt"]))
            : _withDirectives((_openBlock(), _createElementBlock("svg", _hoisted_4, _cache[6] || (_cache[6] = [
                _createElementVNode("circle", {
                  class: "opacity-25",
                  cx: "12",
                  cy: "12",
                  r: "10",
                  stroke: "currentColor",
                  "stroke-width": "4"
                }, null, -1),
                _createElementVNode("path", {
                  class: "opacity-75",
                  fill: "currentColor",
                  d: "M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                }, null, -1)
              ]), 512)), [
                [_vShow, _ctx.spinnerVisible]
              ])
        ]),
        (_ctx.isUsingAgora)
          ? _withDirectives((_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(['w-full h-full object-cover', _ctx.teacherVideoHidden && 'hidden']),
              id: _ctx.teacher?.id,
              ref: "videoRef"
            }, null, 10, _hoisted_5)), [
              [_vShow, _ctx.videoEnabled]
            ])
          : _withDirectives((_openBlock(), _createElementBlock("div", {
              key: 1,
              class: _normalizeClass(['w-full h-full object-cover', _ctx.teacherVideoHidden && 'hidden'])
            }, [
              (_ctx.isSupportedVideo && !_ctx.joinAsHelper)
                ? (_openBlock(), _createElementBlock("video", {
                    key: 0,
                    id: _ctx.zoomId
                  }, null, 8, _hoisted_6))
                : (_openBlock(), _createElementBlock("canvas", {
                    key: 1,
                    id: _ctx.zoomId
                  }, null, 8, _hoisted_7))
            ], 2)), [
              [_vShow, _ctx.videoEnabled]
            ])
      ])
    ]),
    _createElementVNode("div", {
      class: _normalizeClass([[_ctx.videoEnabled ? 'opacity-0' : ''], "absolute top-2 left-1/2 -translate-x-1/2 flex space-x-1.5 group-hover:opacity-100 transition-opacity"])
    }, [
      (!_ctx.currentUserIsHelper)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createElementVNode("button", {
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleVideo && _ctx.toggleVideo(...args))),
              disabled: _ctx.isCameraLocking,
              class: "h-7 w-7 bg-slate-100/80 flex items-center justify-center rounded-md hover:bg-white hover:shadow transition-colors cursor-pointer disabled:cursor-wait"
            }, [
              (_ctx.isCameraLocking)
                ? (_openBlock(), _createBlock(_component_LoadingOutlined, {
                    key: 0,
                    class: "animate-spin"
                  }))
                : (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.teacher?.videoEnabled ? _ctx.VideoCameraIcon : _ctx.VideoCameraSlashIcon), {
                    key: 1,
                    class: "h-4 w-4"
                  }))
            ], 8, _hoisted_8),
            _createElementVNode("button", {
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggleAudio && _ctx.toggleAudio(...args))),
              disabled: _ctx.isMicrophoneLocking,
              class: "h-7 w-7 bg-slate-100/80 flex items-center justify-center rounded-md hover:bg-white hover:shadow transition-colors cursor-pointer disabled:cursor-wait"
            }, [
              (_ctx.isMicrophoneLocking)
                ? (_openBlock(), _createBlock(_component_LoadingOutlined, {
                    key: 0,
                    class: "animate-spin"
                  }))
                : (_openBlock(), _createBlock(_component_MicrophoneIcon, {
                    key: 1,
                    "icon-class": "h-4 w-4",
                    enabled: _ctx.teacher?.audioEnabled
                  }, null, 8, ["enabled"]))
            ], 8, _hoisted_9),
            _createElementVNode("button", {
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.openDeviceSettingsModal && _ctx.openDeviceSettingsModal(...args))),
              class: "h-7 w-7 bg-slate-100/80 flex items-center justify-center rounded-md hover:bg-white hover:shadow transition-colors cursor-pointer"
            }, [
              _createVNode(_component_Cog6ToothIcon, { class: "h-4 w-4" })
            ]),
            _createVNode(_component_DeviceSettingsModal, {
              visible: _ctx.isDeviceSettingsModalShown,
              "onUpdate:visible": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.isDeviceSettingsModalShown) = $event)),
              isMicEnabled: _ctx.teacher?.audioEnabled,
              isCamEnabled: _ctx.teacher?.videoEnabled,
              onUpdateCamOpen: _ctx.updateCamOpen,
              onUpdateMicOpen: _ctx.updateMicOpen
            }, null, 8, ["visible", "isMicEnabled", "isCamEnabled", "onUpdateCamOpen", "onUpdateMicOpen"])
          ], 64))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.onClickMinimized && _ctx.onClickMinimized(...args))),
        class: "h-7 w-7 bg-slate-100/80 flex items-center justify-center rounded-md hover:bg-white hover:shadow transition-colors cursor-pointer"
      }, [
        _createVNode(_component_ArrowsPointingInIcon, { class: "h-4 w-4" })
      ]),
      (_ctx.currentUserIsHelper && _ctx.isInOneToOneMode && !_ctx.anotherTeacherIsInOneToOneMode)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.toggleOneToOneWithHelperIgnoreTeacherVoice && _ctx.toggleOneToOneWithHelperIgnoreTeacherVoice(...args))),
            class: "h-7 w-7 bg-slate-100/80 flex items-center justify-center rounded-md hover:bg-white hover:shadow transition-colors cursor-pointer"
          }, [
            (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.isOneToOneWithHelperIgnoreTeacherVoice ? _ctx.SpeakerXMarkIcon : _ctx.SpeakerWaveIcon), { class: "h-4 w-4" }))
          ]))
        : _createCommentVNode("", true)
    ], 2),
    (_ctx.isLowBandWidth)
      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
          _createVNode(_component_WifiIcon, { class: "animate-pulse h-8 w-8 text-red-600" })
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_NameTag, {
      name: _ctx.teacher?.name,
      "is-muted": !_ctx.teacher?.audioEnabled,
      class: "absolute bottom-2 left-3"
    }, null, 8, ["name", "is-muted"])
  ]))
}