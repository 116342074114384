import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/not-found.png'


const _hoisted_1 = { class: "page-container min-h-screen" }
const _hoisted_2 = { class: "btn-homepage" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("img", {
      src: _imports_0,
      alt: ""
    }, null, -1)),
    _createElementVNode("h1", null, _toDisplayString(_ctx.pageNotFoundText), 1),
    _createElementVNode("p", null, _toDisplayString(_ctx.descriptionText), 1),
    _createVNode(_component_router_link, { to: "/" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.goToPageText), 1)
      ]),
      _: 1
    })
  ]))
}