import { HelpMenu, ResourceMenu } from "@/components/common";
import NavMenu from "@/components/common/nav-menu/nav-menu.vue";
import { Layout, WriterReview } from "@/locales/localeid";
import { store } from "@/store";
import { computed, defineComponent, ref } from "vue";
import { AppHeader, BaseIcon, DrawerHelper, DropdownItem, fmtMsg, LanguagePicker, UserAvatar } from "vue-glcommonui";
import { useRoute, useRouter } from "vue-router";
import { DeviceSettingsModal } from "@/components/common";

export default defineComponent({
  props: {
    title: String,
  },
  components: {
    AppHeader,
    LanguagePicker,
    UserAvatar,
    BaseIcon,
    DrawerHelper,
    DropdownItem,
    ResourceMenu,
    HelpMenu,
    NavMenu,
    DeviceSettingsModal,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const testConnectText = computed(() => fmtMsg(Layout.TestConnect));
    const writerReviewText = computed(() => fmtMsg(WriterReview.Title));
    const currentSchoolId = computed(() => store.getters["teacher/currentSchoolId"]);
    const isShowWriterReview = computed(() => route.path.includes("teacher"));
    const testDeviceShown = computed(() => route.name !== "ClassSetUp");
    const deviceSettingsModalShown = ref(false);
    const onClickTestDevice = () => {
      deviceSettingsModalShown.value = true;
    };
    const onClickWriterReview = () => {
      if (isShowWriterReview.value) {
        router.push(`/teacher/image-view/${currentSchoolId.value}`);
      }
    };
    const isShowNavMenu = computed(() => {
      if (route.path.includes("teacher") || route.path.includes("teacher-calendars")) {
        return true;
      }
      return false;
    });
    return {
      onClickTestDevice,
      onClickWriterReview,
      testConnectText,
      isShowWriterReview,
      writerReviewText,
      isShowNavMenu,
      deviceSettingsModalShown,
      testDeviceShown,
    };
  },
});
