import { IndependentStudentItem } from "@/services/independent";
import { MutationTree } from "vuex";
import { TeacherTeachingState } from "./state";

const mutations: MutationTree<TeacherTeachingState> = {
  setIndependentListStudentItems(s: TeacherTeachingState, p: IndependentStudentItem[] | null) {
    s.independentListStudentItems = p;
  },
  updateIndependentListStudentItems(s: TeacherTeachingState, p: IndependentStudentItem) {
    const student = s.independentListStudentItems?.find((item) => item.studentId === p.studentId);
    if (student) student.currentItemId = p.currentItemId;
  },
};

export default mutations;
