import { GetterTree } from "vuex";
import { WhiteboardState, AlterContentMediaState } from "./state";

const getters: GetterTree<WhiteboardState, any> = {
  getWhiteboardMediaState(state: WhiteboardState): AlterContentMediaState | null {
    return state.mediaState;
  },
  getShouldFetchMediaState(state: WhiteboardState): boolean {
    return state.shouldFetchMediaState;
  },
};

export default getters;
