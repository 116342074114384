import { computed, defineComponent, onMounted, ref } from "vue";
import { fmtMsg, DropdownItem, DropdownHelper, HeaderMenuItem, GLGlobal, RoleName } from "vue-glcommonui";
import { ResourcesMenuLocale } from "@/locales/localeid";
import { GLAdminService, PermissionService } from "@/services";
import { Logger } from "@/utils/logger";

interface ResourceMenuItem {
  textLocaleId: string;
  url?: string;
  color: string;
  action?: string;
  isGenericLink: boolean;
}
/** Define in school-web src/page/admin/regions/components/link-manager/constants.ts */
export enum LinkLocation {
  Resource = "resource",
}
enum ResourceAction {
  TrainingPortal = "GS_Home_Training",
  ReportPortal = "GS_Home_Report",
  StudentPortal = "GS_Home_Student",
  ContentPortal = "GS_Home_Resource",
  LMS = "GS_Home_LMS",
  CurriculumVault = "GS_Home_Curriculum",
}

export default defineComponent({
  components: {
    DropdownItem,
    DropdownHelper,
    HeaderMenuItem,
  },
  setup() {
    const permissions = ref<string[]>([]);
    const permissionsLoaded = ref(false);
    const isActionValid = (action: string) => permissionsLoaded.value && permissions.value.includes(action);
    const loginInfo = GLGlobal.loginInfo();
    const roleIds = loginInfo.profile.roleInfos.map((roleinfo: any) => roleinfo.id);
    const filterResources = (resources: ResourceMenuItem[]) =>
      resources.filter((resource: ResourceMenuItem) => {
        if (resource.action) {
          return isActionValid(resource.action);
        }
        if (resource.textLocaleId === ResourcesMenuLocale.ResourcesMenuSchoolPortal) {
          return !(isParent && roles.length === 1);
        }
        if (resource.textLocaleId === ResourcesMenuLocale.ResourcesParentPortal) {
          return isParent;
        }
        return true;
      });
    const sortResources = (resources: ResourceMenuItem[]) =>
      resources.sort((a, b) => {
        return fmtMsg(a.textLocaleId).localeCompare(fmtMsg(b.textLocaleId));
      });
    const roles = loginInfo.profile.roles;
    const isParent = roles.indexOf(RoleName.parent) !== -1;
    const displayedResources = ref<ResourceMenuItem[]>([]);
    const resources: ResourceMenuItem[] = [
      {
        textLocaleId: ResourcesMenuLocale.ResourcesMenuCurriculumVault,
        url: process.env.VUE_APP_URL_CURRICULUM_PORTAL,
        action: ResourceAction.CurriculumVault,
        color: "#d34c02",
        isGenericLink: false,
      },
      {
        textLocaleId: ResourcesMenuLocale.ResourcesMenuSchoolPortal,
        url: process.env.VUE_APP_URL_SCHOOL_PORTAL,
        color: "#00591c",
        isGenericLink: false,
      },
      {
        textLocaleId: ResourcesMenuLocale.ResourcesMenuDigitalContent,
        url: process.env.VUE_APP_URL_CONTENT_PORTAL,
        action: ResourceAction.ContentPortal,
        color: "#b84c97",
        isGenericLink: false,
      },
      {
        textLocaleId: ResourcesMenuLocale.ResourcesMenuReports,
        url: process.env.VUE_APP_URL_REPORT_PORTAL,
        action: ResourceAction.ReportPortal,
        color: "#cd6814",
        isGenericLink: false,
      },
      {
        textLocaleId: ResourcesMenuLocale.ResourcesMenuStudentREP,
        url: process.env.VUE_APP_URL_STUDENT_PORTAL,
        action: ResourceAction.StudentPortal,
        color: "#1890ff",
        isGenericLink: false,
      },
      {
        textLocaleId: ResourcesMenuLocale.ResourcesMenuTeacherTraining,
        url: process.env.VUE_APP_URL_TRAINING_PORTAL,
        action: ResourceAction.TrainingPortal,
        color: "#008b9c",
        isGenericLink: false,
      },
      {
        textLocaleId: ResourcesMenuLocale.ResourcesMenuLMS,
        url: process.env.VUE_APP_URL_LMS,
        action: ResourceAction.LMS,
        color: "#008b9c",
        isGenericLink: false,
      },
      {
        textLocaleId: ResourcesMenuLocale.ResourcesParentPortal,
        url: process.env.VUE_APP_URL_PARENT_PORTAL,
        color: "#5c2d91",
        isGenericLink: false,
      },
    ];
    onMounted(async () => {
      Promise.all([GLAdminService.fetchGenericLinkConnect(), PermissionService.getPermissionNames({ roleIds })])
        .then(([genericLinks, permissionNames]) => {
          permissionsLoaded.value = true;
          permissions.value = permissionNames as string[];
          displayedResources.value = [
            ...sortResources(filterResources(resources)),
            ...genericLinks
              .filter((i) => i.location === LinkLocation.Resource)
              .map((i) => ({
                textLocaleId: i.localText,
                url: i.link,
                color: "rgba(0, 0, 0, 0.65)",
                isGenericLink: true,
              })),
          ];
        })
        .catch((err) => Logger.log(err));
    });
    const resourcesText = computed(() => fmtMsg(ResourcesMenuLocale.ResourcesMenuResources));
    return {
      displayedResources,
      fmtMsg,
      resourcesText,
    };
  },
});
