import { GetterTree } from "vuex";
import { StudentTeachingState } from "./state";

const getters: GetterTree<StudentTeachingState, any> = {
  getCurrentIndependentItemId(s: StudentTeachingState): null | string {
    return s.currentIndependentItemId;
  },
};

export default getters;
