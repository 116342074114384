import { onKeyStroke } from "@vueuse/core";
import { useCanvasObjectUpdate } from "@/hooks/use-canvas-object-update";
import useCanvas from "@/hooks/use-canvas";
import { FbObjects } from "@/utils/fabric-utils";
import { isInputBeingEdited } from "@/utils/utils";
enum KeyStrokeEnum {
  Backspace = "Backspace",
  Delete = "Delete",
}
export const useKeystrokeOnCanvas = () => {
  const { handleRemoveObjectFromCanvas } = useCanvasObjectUpdate();
  onKeyStroke([KeyStrokeEnum.Backspace, KeyStrokeEnum.Delete], async (e) => {
    const [canvas] = useCanvas();
    const activeObject = canvas.getActiveObject();
    if (activeObject && !isInputBeingEdited() && !(activeObject.type === FbObjects.Textbox && activeObject.isEditing)) {
      e.preventDefault();
      await handleRemoveObjectFromCanvas(activeObject);
    }
  });
};
