<template>
  <div>
    <div v-if="hasZeroImage" class="text-slate-600 text-xs text-center" :class="isHelper ? 'cursor-not-allowed' : ''">
      <Empty imageStyle="max-height: 40px" :description="NoDataText" class="hidden 2xl:block" />
      <div class="2xl:hidden mb-1">{{ NoDataText }}</div>
    </div>
    <div class="grid grid-cols-2 gap-x-2 gap-y-2">
      <div
        v-for="(item, index) in items"
        @click="() => onClickItem(item.id)"
        :key="item.id"
        class="relative max-w-full border rounded-lg overflow-hidden w-full bg-white aspect-w-3 aspect-h-2"
        :class="[
          item.id === currentExposureItemMedia?.id && (!currentExposureType || exposureType === currentExposureType)
            ? 'border-gs-purple'
            : 'border-slate-300',
          isHelper ? 'cursor-not-allowed' : 'cursor-pointer',
        ]"
      >
        <Tooltip placement="rightBottom" :overlayStyle="{ maxWidth: '400px' }">
          <template v-if="isTeaching" #title>
            <span v-html="item.teachingContent"></span>
          </template>
          <Thumbnail :item="item" />
          <img v-if="item.image.metaData?.annotations?.length" src="@/assets/icons/bullseye.png" class="absolute top-1 right-1 h-6 w-6" alt="" />
          <div
            v-if="items.length > 1 || item.teacherUseOnly"
            class="absolute flex justify-center items-center text-xs 2xl:text-sm bottom-1 right-1 h-5 w-5 2xl:h-6 2xl:w-6 rounded-md text-white"
            :class="[item.teacherUseOnly ? 'bg-red-800' : 'bg-black']"
          >
            {{ index + 1 }}
          </div>
        </Tooltip>
      </div>
    </div>
  </div>
</template>
<!--<style lang="scss" scoped src="./exposure-item.scss"></style>-->
<script lang="ts" src="./exposure-item.ts"></script>
