import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = { class: "px-3 2xl:px-5 flex justify-between items-center gap-x-6 py-3 2xl:py-5 cursor-pointer hover:bg-slate-100 rounded-xl transition-colors hover:shadow" }
const _hoisted_2 = { class: "flex items-center min-w-0 gap-x-3 2xl:gap-x-4" }
const _hoisted_3 = { class: "min-w-0 flex-auto" }
const _hoisted_4 = { class: "text-sm leading-6 truncate" }
const _hoisted_5 = {
  key: 0,
  class: "truncate text-xs leading-5"
}
const _hoisted_6 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CheckIcon = _resolveComponent("CheckIcon")!
  const _component_ClockIcon = _resolveComponent("ClockIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.activityIcon), {
        name: "icon-abc",
        class: "flex-shrink-0 h-4 w-4 2xl:h-6 2xl:w-6"
      })),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.exposureTitle), 1),
        (!_ctx.isExposureLpComplete)
          ? (_openBlock(), _createElementBlock("p", _hoisted_5, [
              (_ctx.type)
                ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.type) + " • ", 1))
                : _createCommentVNode("", true),
              _createElementVNode("span", null, _toDisplayString(_ctx.formattedDuration), 1)
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", null, [
      (_ctx.isCompleted)
        ? (_openBlock(), _createBlock(_component_CheckIcon, {
            key: 0,
            class: "h-4 w-4 2xl:h-5 2xl:w-5"
          }))
        : _createCommentVNode("", true),
      (_ctx.isOptionalComponent)
        ? (_openBlock(), _createBlock(_component_ClockIcon, {
            key: 1,
            class: "h-4 w-4 2xl:h-5 2xl:w-5"
          }))
        : _createCommentVNode("", true)
    ])
  ]))
}