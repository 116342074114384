import { computed, defineComponent, ref } from "vue";
import { fmtMsg } from "vue-glcommonui";
import { BrowserSupportLocale } from "@/locales/localeid";
import { isChromeBrowser, isDesktopBrowser, isEdgeBrowser, isOperaBrowser, isYandexBrowser, getBrowserName } from "@/utils/utils";
import { useRoute } from "vue-router";
import { Paths } from "@/utils/paths";
import { Checkbox, Modal } from "ant-design-vue";
import { ExclamationTriangleIcon } from "@heroicons/vue/24/outline";
import { HAVE_READ_BROWSER_WARNING_KEY } from "@/utils/constant";
export default defineComponent({
  components: { Checkbox, Modal, ExclamationTriangleIcon },
  setup() {
    const route = useRoute();
    const appPath = computed(() => route.path);
    const appFullPath = computed(() => route.fullPath);
    const isCorrectPageToShow = computed(
      () =>
        //dashboard and class setup of both teacher & parent
        appFullPath.value === Paths.Parent ||
        appFullPath.value === Paths.Teacher ||
        appPath.value === Paths.ParentClassSetup ||
        appPath.value === Paths.TeacherClassSetup,
    );
    const isSupportedBrowser = computed(() => {
      return isChromeBrowser() || isOperaBrowser() || isEdgeBrowser() || isYandexBrowser();
    });
    const haveReadBrowserWarning = !!localStorage.getItem(HAVE_READ_BROWSER_WARNING_KEY);
    const isNotSupportedBrowser = computed(() => {
      return isDesktopBrowser && isCorrectPageToShow.value && !isSupportedBrowser.value && !haveReadBrowserWarning;
    });
    const chromeLink = "https://www.google.com/intl/en_us/chrome/";
    const visible = ref<boolean>(true);
    const dontShowAgain = ref<boolean>(false);

    const handleCancel = () => {
      visible.value = false;
      if (dontShowAgain.value) {
        localStorage.setItem(HAVE_READ_BROWSER_WARNING_KEY, "true");
      }
    };

    const MsgBrowserRecommendation = computed(() => fmtMsg(BrowserSupportLocale.BrowserRecommendation));
    const MsgBrowserUnsupported = computed(() =>
      fmtMsg(BrowserSupportLocale.BrowserUnsupported, { browserName: getBrowserName() ?? fmtMsg(BrowserSupportLocale.This) }),
    );
    const MsgDontShowAgain = computed(() => fmtMsg(BrowserSupportLocale.DontShowAgain));
    const MsgDownloadGoogleChrome = computed(() => fmtMsg(BrowserSupportLocale.DownloadGoogleChrome));
    const MsgClose = computed(() => fmtMsg(BrowserSupportLocale.Close));

    return {
      isNotSupportedBrowser,
      chromeLink,
      visible,
      handleCancel,
      dontShowAgain,
      MsgBrowserRecommendation,
      MsgBrowserUnsupported,
      MsgDontShowAgain,
      MsgDownloadGoogleChrome,
      MsgClose,
    };
  },
});
