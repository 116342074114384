import { computed, defineComponent, ref } from "vue";
import { Button, Modal } from "ant-design-vue";
import Teams from "../teams";
import { useManipulateTeamState } from "@/hooks/use-team";
import { Devices, LaptopDimensions } from "./constants";
import { useBreakpoints } from "@vueuse/core";
import { fmtMsg } from "vue-glcommonui";
import { TeamLocales, CommonLocale } from "@/locales/localeid";
import { PlusCircleIcon, ArrowUturnLeftIcon } from "@heroicons/vue/20/solid";
export default defineComponent({
  name: "TeamsSettingModalComponent",
  components: { Button, Modal, Teams, PlusCircleIcon, ArrowUturnLeftIcon },
  setup() {
    //* reactive */
    const { settingModalOpened } = useManipulateTeamState();
    const teamsCompRef = ref<InstanceType<typeof Teams>>();

    //* methods */
    const handleOk = () => {
      teamsCompRef.value?.saveChanges();
    };
    const handleCancel = () => {
      teamsCompRef.value?.cancelChanges();
    };
    const handleAddNewTeam = () => {
      teamsCompRef.value?.addNewTeam();
    };

    const handleResetTeams = () => {
      teamsCompRef.value?.resetTeams();
    };

    //* utils */
    const breakpoints = useBreakpoints({
      [Devices.Laptop]: LaptopDimensions.Min,
      [Devices.Desktop]: LaptopDimensions.Max,
    });
    const isLaptopDimension = breakpoints.between(Devices.Laptop, Devices.Desktop);

    //* Messages */
    const MsgSettingTeamsTitle = computed(() => fmtMsg(TeamLocales.SettingTeams));
    const MsgNewTeam = computed(() => fmtMsg(TeamLocales.NewTeam));
    const MsgResetTeam = computed(() => fmtMsg(TeamLocales.ResetTeam));
    const MsgOk = computed(() => fmtMsg(CommonLocale.CommonOkButtonText));
    const MsgCancel = computed(() => fmtMsg(CommonLocale.CommonCancelButtonText));
    return {
      MsgNewTeam,
      MsgResetTeam,
      MsgSettingTeamsTitle,
      settingModalOpened,
      isLaptopDimension,
      teamsCompRef,
      handleOk,
      handleCancel,
      handleAddNewTeam,
      handleResetTeams,
      MsgOk,
      MsgCancel,
    };
  },
});
