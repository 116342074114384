<template>
  <div class="absolute inset-0 flex justify-center items-center bg-white">
    <div class="w-32 max-w-[42%]">
      <div class="w-full aspect-w-1 aspect-h-1">
        <div class="w-full h-full rounded-full overflow-hidden border-2 border-white shadow">
          <img :src="src" class="w-full h-full object-cover" :alt="alt" />
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped src="./connect-avatar.scss"></style>
<script lang="ts" src="./connect-avatar.ts"></script>
