import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Transition as _Transition } from "vue"

const _hoisted_1 = { class: "relative" }
const _hoisted_2 = { class: "flex items-center" }
const _hoisted_3 = { class: "ml-3 block truncate" }
const _hoisted_4 = { class: "pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2" }
const _hoisted_5 = { class: "flex items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChevronUpDownIcon = _resolveComponent("ChevronUpDownIcon")!
  const _component_ListboxButton = _resolveComponent("ListboxButton")!
  const _component_CheckIcon = _resolveComponent("CheckIcon")!
  const _component_ListboxOption = _resolveComponent("ListboxOption")!
  const _component_ListboxOptions = _resolveComponent("ListboxOptions")!
  const _component_Listbox = _resolveComponent("Listbox")!

  return (_openBlock(), _createBlock(_component_Listbox, {
    as: "div",
    modelValue: _ctx.selected,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selected) = $event))
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_ListboxButton, { class: "relative w-full cursor-default rounded-md bg-white py-1 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-gs-purple sm:text-sm sm:leading-6" }, {
          default: _withCtx(() => [
            _createElementVNode("span", _hoisted_2, [
              _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.selectedItem.name), 1)
            ]),
            _createElementVNode("span", _hoisted_4, [
              _createVNode(_component_ChevronUpDownIcon, {
                class: "h-5 w-5 text-gray-400",
                "aria-hidden": "true"
              })
            ])
          ]),
          _: 1
        }),
        _createVNode(_Transition, {
          "leave-active-class": "transition ease-in duration-100",
          "leave-from-class": "opacity-100",
          "leave-to-class": "opacity-0"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ListboxOptions, { class: "bottom-full nice-scroll z-[300] absolute mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm" }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
                  return (_openBlock(), _createBlock(_component_ListboxOption, {
                    as: "template",
                    key: item.id,
                    value: item
                  }, {
                    default: _withCtx(({ active, selected }) => [
                      _createElementVNode("li", {
                        class: _normalizeClass([active ? 'bg-gs-purple text-white' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9'])
                      }, [
                        _createElementVNode("div", _hoisted_5, [
                          _createElementVNode("span", {
                            class: _normalizeClass([selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate']),
                            style: _normalizeStyle(item.options?.fontFamily ? { fontFamily: item.options.fontFamily } : {})
                          }, _toDisplayString(item.name), 7)
                        ]),
                        selected
                          ? (_openBlock(), _createElementBlock("span", {
                              key: 0,
                              class: _normalizeClass([active ? 'text-white' : 'text-gs-purple', 'absolute inset-y-0 right-0 flex items-center pr-4'])
                            }, [
                              _createVNode(_component_CheckIcon, {
                                class: "h-5 w-5",
                                "aria-hidden": "true"
                              })
                            ], 2))
                          : _createCommentVNode("", true)
                      ], 2)
                    ]),
                    _: 2
                  }, 1032, ["value"]))
                }), 128))
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}