import useCanvas from "@/hooks/use-canvas";
import { FbObjects } from "@/utils/fabric-utils";
import { fabric } from "fabric";
import { Logger } from "@/utils/logger";

export const useHandleObjects = () => {
  const removeObjectFromCanvas = (objectId: string) => {
    const [canvas] = useCanvas();
    const target = canvas?.getObjects().find((obj: any) => obj.objectId === objectId);
    if (target) {
      canvas?.remove(target);
      canvas?.renderAll();
    }
  };
  const editObjectFromCanvas = (objectId: string, metadata?: string) => {
    const [canvas] = useCanvas();
    const target = canvas?.getObjects().find((obj: any) => obj.objectId === objectId);
    if (target) {
      canvas?.remove(target);
    }
    if (metadata) {
      const path = new fabric.Path.fromObject(JSON.parse(metadata), (item: any) => {
        canvas.add(item);
        canvas.renderAll();
      });
    }
  };
  const removeTextBoxObjectsFromCanvas = () => {
    const [canvas] = useCanvas();
    canvas?.remove(...canvas.getObjects().filter((obj: any) => obj.objectId && obj.type === FbObjects.Textbox));
    canvas?.renderAll();
  };
  const removeShapeObjectsFromCanvas = () => {
    const [canvas] = useCanvas();
    canvas?.remove(...canvas.getObjects().filter((obj: any) => obj.type === FbObjects.Circle || obj.type === FbObjects.Rect));
    canvas?.renderAll();
  };

  const logAllCanvasObjects = () => {
    const [canvas] = useCanvas();
    Logger.log(canvas?.getObjects().map((obj: any) => obj));
  };

  return {
    removeObjectFromCanvas,
    editObjectFromCanvas,
    removeTextBoxObjectsFromCanvas,
    removeShapeObjectsFromCanvas,
    logAllCanvasObjects,
  };
};
