<template>
  <div class="relative z-20">
    <div class="absolute h-14 2xl:h-16 w-full bg-gradient-to-b from-slate-100 via-slate-100/80 to-transparent"></div>
    <div class="absolute w-full px-7 pt-3">
      <div class="w-full h-14 2xl:h-16 bg-gs-purple/30 rounded-xl"></div>
    </div>
    <div class="absolute w-full px-3 pt-5">
      <header class="w-full bg-gs-purple h-14 2xl:h-16 rounded-xl flex items-center justify-between px-7">
        <div class="flex items-center">
          <div class="text-white w-40 2xl:w-60 flex justify-start">
            <TransitionBase appear-from="left" duration-class="duration-300">
              <LogoWhite class="mb-1.5 h-8 2xl:h-10" />
            </TransitionBase>
          </div>
          <TransitionBase appear-from="left" duration-class="duration-500 delay-100">
            <div
              v-if="currentLesson && currentUnit"
              class="flex items-center space-x-1.5 h-[45px] text-sm ml-10 border-l border-white/[0.08] mr-auto -intro-x pl-6"
            >
              <span class="text-white/90">{{ className }}</span>
              <span class="text-white/70">&#x2022;</span>
              <a
                href="javascript:void(0)"
                class="text-white/70 transition-colors"
                :class="isHelper ? 'cursor-default' : 'hover:text-white'"
                @click="changeUnitAndLesson"
                >{{ unitText }} {{ currentUnit }} ({{ lessonText }} {{ currentLesson }})</a
              >
            </div>
          </TransitionBase>
        </div>
        <TransitionBase appear-from="right" duration-class="duration-300">
          <div class="flex flex-nowrap space-x-5 items-center">
            <Listbox as="div">
              <div class="relative">
                <ListboxButton
                  class="relative cursor-pointer w-40 rounded-md bg-white/10 py-2 pl-3 pr-10 text-left text-white shadow-sm ring-inset focus:outline-none focus:ring-2 focus:ring-white/20 sm:text-sm sm:leading-6"
                >
                  <span class="flex items-center">
                    <img
                      v-if="classAction.icon"
                      :src="require(`@/assets/icons/icon-action-${classAction.icon}.png`)"
                      alt=""
                      class="h-6 w-6 flex-shrink-0 rounded-full"
                    />
                    <span class="ml-3 block truncate" v-if="classAction.id">{{ genBearActionLabel(classAction.id) }}</span>
                  </span>
                  <span class="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                    <ChevronUpDownIcon class="h-5 w-5 text-white/80" aria-hidden="true" />
                  </span>
                </ListboxButton>

                <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                  <ListboxOptions
                    class="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm nice-scroll"
                  >
                    <ListboxOption
                      as="template"
                      v-for="action in actions"
                      :key="action.id"
                      :value="action"
                      @click="() => onClickSelectAction(action)"
                      v-slot="{ active }"
                    >
                      <li :class="[active ? 'bg-gs-purple text-white' : 'text-gray-900', 'relative cursor-pointer select-none py-2 pl-3 pr-9']">
                        <div class="flex items-center">
                          <img :src="require(`@/assets/icons/icon-action-${action.id}.png`)" alt="" class="h-6 w-6 flex-shrink-0 rounded-full" />
                          <span :class="[classAction.id === action.id ? 'font-semibold' : 'font-normal', 'ml-3 block truncate']">{{
                            genBearActionLabel(action.id)
                          }}</span>
                        </div>

                        <span
                          v-if="classAction.id === action.id"
                          :class="[active ? 'text-white' : 'text-gs-purple', 'absolute inset-y-0 right-0 flex items-center pr-4']"
                        >
                          <CheckIcon class="h-5 w-5" aria-hidden="true" />
                        </span>
                      </li>
                    </ListboxOption>
                  </ListboxOptions>
                </transition>
              </div>
            </Listbox>
            <button
              type="button"
              @click="onClickEnd"
              class="inline-flex items-center gap-x-1.5 rounded-md px-3 py-2 text-sm text-white/90 hover:bg-white/10 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gs-purple"
            >
              <XCircleIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />
              {{ teacherExitText }}
            </button>
          </div>
        </TransitionBase>
      </header>
    </div>
  </div>
</template>
<script lang="ts" src="./teacher-page-header.ts"></script>
