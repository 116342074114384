import { DEVICE_ID_KEY } from "@/utils/constant";
import { createGuid } from "@/utils/utils";
import { useStorage } from "@vueuse/core";

// Set DeviceId to session storage
export const useSetDeviceId = () => {
  const archivedDeviceId = useStorage(DEVICE_ID_KEY, "", sessionStorage);
  if (!archivedDeviceId.value) {
    const deviceId = createGuid();
    sessionStorage.setItem(DEVICE_ID_KEY, deviceId);
  }
};
