import { useStore } from "vuex";
import { ObjectTypes, OperationTypes } from "@/utils/utils";
import { ToggleStudentMediaDevicesAsync, ToggleStudentPaletteAsync } from "@/services";

export interface CanvasObjectModel {
  id: string;
  type: ObjectTypes;
  operation: OperationTypes;
  metadata?: string;
}

export const useSendWebSocketMsg = () => {
  const { dispatch } = useStore();

  const dispatchAsync = async (action: string, payload: any) => {
    await dispatch(`teacherRoom/${action}`, payload);
  };

  return {
    requestUpdateCanvasObject: (payload: CanvasObjectModel) => dispatchAsync("requestUpdateCanvasObjectAsync", payload),
    requestToggleStudentMediaDevices: (payload: ToggleStudentMediaDevicesAsync) => dispatchAsync("toggleStudentMediaDevicesAsync", payload),
    requestToggleStudentPalette: (payload: ToggleStudentPaletteAsync) => dispatchAsync("toggleStudentPaletteAsync", payload),
    requestSetStudentsBadge: (payload: string[]) => dispatchAsync("setStudentsBadgeAsync", payload),
    requestClearStudentRaisingHand: (studentId: string) => dispatchAsync("clearStudentRaisingHandAsync", studentId),
    requestModifyTextBoxObject: (text: any) => dispatchAsync("teacherModifyFabricObject", text),
    requestClearBoard: () => dispatchAsync("requestClearBoardAsync", null),
  };
};
