import { Howl } from "howler";
import { store } from "@/store";
import { computed, watch } from "vue";

const audioGenerator = (path: string, loop = false) => {
  const sound = new Howl({
    src: [require(`@/assets/audio/disconnection${path}`)],
    html5: true,
    loop,
    onplayerror: function () {
      sound.once("unlock", function () {
        sound.play();
      });
    },
    onload: () => {
      const speakerDeviceId = computed(() => store.getters["speakerDeviceId"]);
      const setSink = () => {
        const audio = (sound as any)._sounds[0]._node as HTMLAudioElement;
        if (typeof audio.setSinkId === "function") {
          audio.setSinkId(speakerDeviceId.value);
        }
      };
      setSink();
      watch(speakerDeviceId, setSink);
    },
  });
  return sound;
};

export const reconnectFailedSound = audioGenerator("/reconnect-failed.mp3");

export const reconnectSuccessSound = audioGenerator("/reconnect-success.mp3");

export const teacherTryReconnectSound = audioGenerator("/teacher-try-reconnect.mp3", true);

export const tryReconnectLoop2 = audioGenerator("/try-reconnect-loop-2.mp3");

export const watchStory = audioGenerator("/watch-story.mp3");

export const canGoToClassRoomToday = audioGenerator("/can-go-to-classroom-today.mp3");

export const pleaseWaitTeacher = audioGenerator("/please-wait-teacher.mp3");
