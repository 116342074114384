import { TeacherHome } from "@/locales/localeid";
import { computed, defineComponent } from "vue";
import { fmtMsg, HeaderMenuItem } from "vue-glcommonui";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { HomeIcon, CalendarIcon } from "@heroicons/vue/24/solid";

export default defineComponent({
  components: { HeaderMenuItem, HomeIcon, CalendarIcon },
  setup() {
    const store = useStore();
    const route = useRoute();
    const homeText = computed(() => fmtMsg(TeacherHome.Home));
    const galleryText = computed(() => fmtMsg(TeacherHome.Gallery));
    const scheduleText = computed(() => fmtMsg(TeacherHome.Schedule));
    const isParentAndTeacher = store.getters["auth/isParentAndTeacher"];

    const router = useRouter();
    const onClickHome = async () => {
      if (isParentAndTeacher) {
        await router.push("/");
      }
      if (route.path.includes("teacher")) {
        await router.push("/teacher");
      }
    };
    const onClickCalendar = async () => {
      await router.push("/teacher-calendars");
    };
    return {
      onClickHome,
      onClickCalendar,
      homeText,
      galleryText,
      scheduleText,
    };
  },
});
