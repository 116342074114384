import { ExposureItemMedia } from "@/store/lesson/state";
import { Empty, Tooltip } from "ant-design-vue";
import { PropType, defineComponent } from "vue";
import { useImageLoader } from "@/hooks/use-image-loader";

export default defineComponent({
  name: "ExposureItemThumbnailComponent",
  props: {
    item: {
      type: Object as PropType<ExposureItemMedia>,
      required: true,
    },
  },
  components: {
    Tooltip,
    Empty,
  },
  setup() {
    const { reloadKey, handleImageLoadError } = useImageLoader();
    return {
      reloadKey,
      handleImageLoadError,
    };
  },
});
