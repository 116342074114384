<template>
  <div class="text-base font-medium">{{ RemoteSetUpText }}</div>
  <div class="mt-6 flex flex-col items-stretch space-y-4">
    <div class="flex items-center space-x-4">
      <div class="flex flex-1 justify-between items-center">
        <div class="flex flex-1 space-x-1.5 items-center">
          <SpeakerWaveIcon class="w-6 h-6" />
          <div>{{ CheckSpeaker }}</div>
          <DeviceErrorTooltip v-if="!havePermissionSpeaker" :message="MsgSpeakerErrorInfo" :altText="'Speaker error info'" />
        </div>
        <div class="relative">
          <component :is="isPlayingSound ? PauseCircleIcon : PlayCircleIcon" class="w-10 h-10 text-gs-purple cursor-pointer" @click="toggleSpeaker" />
          <img
            v-if="isPlayingSound"
            src="@/assets/images/audio-wave.gif"
            class="h-4 w-4 absolute bottom-2 right-2 translate-x-full translate-y-full"
            alt=""
          />
        </div>
        <audio loop id="audio" class="hidden" :onplay="onSoundPlay" :onpause="onSoundPause">
          <source src="@/assets/audio/ConnectTestSound.mp3" type="audio/mp3" />
        </audio>
      </div>
      <Select
        :placeholder="SystemDefaultSpeakerDevice"
        style="width: 600px"
        :disabled="!isCheckSpeaker || !listSpeakersId.length"
        v-model:value="currentSpeakerLabel"
        ref="select"
        @change="handleSpeakerChange"
      >
        <SelectOption v-for="deviceId in listSpeakersId" :key="deviceId" :value="deviceId">
          {{ listSpeakers.find((speaker) => speaker.deviceId === deviceId)?.label }}
        </SelectOption>
      </Select>
    </div>
    <div class="text-red-600 pb-2" v-if="!havePermissionSpeaker">{{ warningMsgSpeaker }}</div>
    <div class="flex items-center space-x-4">
      <div class="flex flex-1 justify-between items-center">
        <div class="flex flex-1 space-x-1.5 items-center">
          <MicrophoneIcon class="w-6 h-6" />
          <div>{{ CheckMic }}</div>
          <DeviceErrorTooltip v-if="!havePermissionMicrophone || agoraMicError" :message="MsgMicroErrorInfo" :altText="'Micro error info'" />
        </div>
        <Switch :disabled="isSettingUpAudioTrack" v-model:checked="isOpenMic" />
      </div>
      <Select
        :placeholder="SelectDevice"
        class=""
        style="width: 600px"
        :disabled="!isOpenMic"
        v-model:value="currentMicLabel"
        ref="select"
        @change="handleMicroChange"
      >
        <SelectOption v-for="deviceId in listMicsId" :key="deviceId" :value="deviceId">
          {{ listMics.find((mic) => mic.deviceId === deviceId)?.label }}
        </SelectOption>
      </Select>
    </div>
    <div class="flex justify-end" v-if="listMics.length > 0">
      <div class="w-[600px]">
        <Progress :strokeWidth="24" :percent="!isOpenMic ? 0 : volumeByPercent" strokeColor="#2f0b57" :show-info="false" class="progress" />
      </div>
    </div>
    <div class="text-red-600 pb-2" v-if="!havePermissionMicrophone">{{ warningMsgMicrophone }}</div>
    <div class="flex items-center space-x-4" v-if="showCameraOptions">
      <div class="flex flex-1 justify-between items-center">
        <div class="flex flex-1 space-x-1.5 items-center">
          <VideoCameraIcon class="w-6 h-6" />
          <div>{{ CheckCam }}</div>
          <DeviceErrorTooltip v-if="!havePermissionCamera || agoraCamError" :message="MsgCameraErrorInfo" :altText="'Camera error info'" />
        </div>
        <Switch :disabled="isSettingUpVideoTrack" v-model:checked="isOpenCam" />
      </div>
      <Select
        :placeholder="SelectDevice"
        style="width: 600px"
        :disabled="!isOpenCam"
        v-model:value="currentCamId"
        ref="select"
        @change="handleCameraChange"
      >
        <SelectOption v-for="deviceId in listCamsId" :key="deviceId" :value="deviceId">
          {{ listCams.find((cam) => cam.deviceId === deviceId)?.label }}
        </SelectOption>
      </Select>
    </div>
    <div class="text-red-600 pb-2" v-if="showCameraOptions && !havePermissionCamera">{{ warningMsgCamera }}</div>
    <div class="flex justify-end" v-if="showCameraOptions">
      <div class="w-[600px]">
        <div class="w-full aspect-w-16 aspect-h-9">
          <video
            :ref="
              (el) => {
                playerRef = el;
              }
            "
            :id="videoElementId"
            v-show="isOpenCam && currentCam && !zoomCamError"
            v-if="!isUsingAgora"
            class="rounded-lg overflow-hidden w-full h-full object-cover"
          ></video>
          <div
            :ref="
              (el) => {
                playerRef = el;
              }
            "
            :id="videoElementId"
            v-show="isOpenCam && currentCam && !agoraCamError"
            v-else
            class="rounded-lg overflow-hidden w-full h-full object-cover"
          ></div>
          <div v-show="!isOpenCam || isCurrentCamError || !currentCam" class="rounded-lg overflow-hidden w-full h-full object-cover">
            <div class="relative w-full h-full flex flex-col justify-center items-center bg-slate-900" v-if="isCurrentCamError || !isOpenCam">
              <div class="device-tester__camera--player__notify">
                <div class="device-tester__camera--player__icon">
                  <VideoCameraSlashIcon class="w-8 h-8 text-white" />
                </div>
                <div class="device-tester__camera--player__text text-center px-10">
                  {{ isCurrentCamError && isOpenCam ? MsgCameraErrorInfo : CamOff }}
                </div>
              </div>
            </div>
            <VideoPlaceholder v-else style="z-index: 1" />
          </div>
        </div>
      </div>
    </div>
    <slot />
  </div>
</template>
<style lang="scss" scoped src="./device-settings.scss"></style>
<script lang="ts" src="./device-settings.ts" />
