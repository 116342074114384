import { ModalAction } from "@/store/modal/state";
import { Modal, Button } from "ant-design-vue";
import { computed, defineComponent, ref } from "vue";
import { useStore } from "vuex";
import { DynamicKeysObject } from "@/utils/type";

export default defineComponent({
  components: { Modal, Button },
  setup() {
    const { getters, commit } = useStore();
    const dataFromView = ref();
    const isOpen = computed<boolean>(() => getters["modal/getIsOpen"]);
    const title = computed<string>(() => getters["modal/getTitle"]);
    const actions = computed<ModalAction[]>(() => getters["modal/getActions"]);
    const view = computed<object>(() => getters["modal/getView"]);
    const extraProps = computed<DynamicKeysObject>(() => getters["modal/getProps"]);
    const closeable = computed<boolean>(() => extraProps.value["closeable"]);
    const isFooterHidden = computed<boolean>(() => extraProps.value["isFooterHidden"]);

    const handleOk = () => {
      actions.value[0]?.callback(dataFromView.value);
    };
    const handleCancel = () => {
      actions.value[1]?.callback();
      commit("modal/close");
    };
    const submitText = actions.value[0]?.label;
    const cancelText = actions.value[1]?.label;
    const onDataFromViewUpdate = (payload: any) => {
      dataFromView.value = payload;
    };
    return {
      isOpen,
      actions,
      view,
      closeable,
      isFooterHidden,
      handleOk,
      handleCancel,
      title,
      submitText,
      cancelText,
      extraProps,
      onDataFromViewUpdate,
      dataFromView,
    };
  },
});
