import { GetterTree } from "vuex";
import { AppState, AppView, LayoutType, ToastData, UserRole, VCPlatform } from "./state";

const getters: GetterTree<AppState, any> = {
  appLayout(state: AppState): LayoutType {
    return state.layout;
  },
  appView(state: AppState): AppView {
    return state.appView;
  },
  appToast(state: AppState): ToastData {
    return state.toast;
  },
  isMuteAudio(state: AppState): number {
    return state.isMuteAudio;
  },
  isHideVideo(state: AppState): number {
    return state.isHideVideo;
  },
  cameraDeviceId(state: AppState): string {
    return state.cameraDeviceId;
  },
  microphoneDeviceId(state: AppState): string {
    return state.microphoneDeviceId;
  },
  speakerDeviceId(state: AppState): string {
    return state.speakerDeviceId;
  },
  userRole(state: AppState): UserRole {
    return state.userRole;
  },
  platform(state: AppState): VCPlatform {
    return state.platform;
  },
  isUsingAgora(state: AppState): boolean {
    return state.platform === VCPlatform.Agora;
  },
  isSingalrInited(state: AppState): boolean {
    return state.singalrInited;
  },
  teacherMessageVersion(state: AppState): number {
    return state.teacherMessageVersion;
  },
  checkMessageVersionTimer(state: AppState): number {
    return state.checkMessageVersionTimer;
  },
  getLowBandWidth(state: AppState): boolean {
    return state.isLowBandWidth;
  },
  getReplacedClassId(state: AppState): string {
    return state.replacedClassId;
  },
  havePermissionCamera(state: AppState) {
    return state.havePermissionCamera;
  },
  havePermissionMicrophone(state: AppState) {
    return state.havePermissionMicrophone;
  },
  havePermissionSpeaker(state: AppState) {
    return state.havePermissionSpeaker;
  },
};

export default getters;
