import { TeacherClassLessonPlan } from "@/locales/localeid";
import { ContentRootType, ExposureStatus, ExposureType } from "@/store/lesson/state";
import { getSeconds, secondsToTimeStr } from "@/utils/convertDuration";
import { computed, defineComponent, FunctionalComponent } from "vue";
import { BaseIcon, fmtMsg } from "vue-glcommonui";
import { BookOpenIcon, ArrowPathIcon, MusicalNoteIcon, PlayIcon, CheckIcon, ClockIcon } from "@heroicons/vue/24/outline";

const exposureIcon: (type: ExposureType) => FunctionalComponent = (type: ExposureType) => {
  let icon = BookOpenIcon;
  switch (type) {
    case ExposureType.ACTIVITY:
    case ExposureType.POEM:
    case ExposureType.BIG_BOOK:
    case ExposureType.STORY:
    case ExposureType.VPC:
      icon = BookOpenIcon;
      break;
    case ExposureType.SONG:
      icon = MusicalNoteIcon;
      break;
    case ExposureType.PHONOGRAM:
    case ExposureType.CHANT:
      icon = BaseIcon as unknown as FunctionalComponent;
      break;
    case ExposureType.VIDEO:
      icon = PlayIcon;
      break;
    case ExposureType.TRANSITION:
      icon = ArrowPathIcon;
      break;
  }
  return icon;
};

export default defineComponent({
  props: ["id", "title", "type", "duration", "status", "contentRootType"],
  components: {
    CheckIcon,
    ClockIcon,
  },
  setup(props) {
    const activityIcon = exposureIcon(props.type);
    const isCompleted = computed(() => props.status === ExposureStatus.COMPLETED);
    const formattedDuration = computed(() => secondsToTimeStr(getSeconds(props.duration)));
    const exposureTitle = computed(() =>
      props.type === ExposureType.TRANSITION
        ? fmtMsg(TeacherClassLessonPlan.Transition)
        : props.type === ExposureType.COMPLETE
        ? fmtMsg(TeacherClassLessonPlan.LessonComplete)
        : props.title,
    );
    const isExposureLpComplete = computed(() => props.type === ExposureType.COMPLETE);
    const isOptionalComponent = computed(() => props.contentRootType === ContentRootType.Optional);
    return {
      activityIcon,
      isCompleted,
      formattedDuration,
      exposureTitle,
      isExposureLpComplete,
      isOptionalComponent,
    };
  },
});
