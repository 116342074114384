import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "text-slate-600 text-xs text-center"
}
const _hoisted_2 = { class: "2xl:hidden mb-1" }
const _hoisted_3 = { class: "grid grid-cols-2 gap-x-2 gap-y-2" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { key: 0 }
const _hoisted_6 = ["src"]
const _hoisted_7 = {
  key: 1,
  class: "flex justify-center items-center"
}
const _hoisted_8 = {
  key: 2,
  class: "aspect-w-3 flex justify-center items-center"
}
const _hoisted_9 = { key: 0 }
const _hoisted_10 = ["src"]
const _hoisted_11 = { class: "flex justify-center items-center" }
const _hoisted_12 = {
  key: 3,
  class: "aspect-w-3 flex justify-center items-center"
}
const _hoisted_13 = { key: 0 }
const _hoisted_14 = ["src"]
const _hoisted_15 = { class: "flex justify-center items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Empty = _resolveComponent("Empty")!
  const _component_CropImage = _resolveComponent("CropImage")!
  const _component_DocumentIcon = _resolveComponent("DocumentIcon")!
  const _component_SpeakerWaveIcon = _resolveComponent("SpeakerWaveIcon")!
  const _component_FilmIcon = _resolveComponent("FilmIcon")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.isEmpty)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_Empty, {
            imageStyle: "max-height: 40px",
            description: _ctx.NoDataText,
            class: "hidden 2xl:block"
          }, null, 8, ["description"]),
          _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.NoDataText), 1)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.slides, (item, index) => {
        return (_openBlock(), _createElementBlock("div", {
          onClick: ($event: any) => (_ctx.onClickItem(item.id)),
          class: _normalizeClass(["relative max-w-full border rounded-lg overflow-hidden w-full bg-white aspect-w-3 aspect-h-2", [
          item.id === _ctx.currentExposureItemMedia?.id ? 'border-gs-purple' : 'border-slate-300',
          _ctx.isHelper ? 'cursor-not-allowed' : 'cursor-pointer',
        ]]),
          key: item?.id
        }, [
          (item.mediaTypeId === undefined)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                (item.image.metaData && item.image.metaData.width > 0 && item.image.metaData.height > 0)
                  ? (_openBlock(), _createBlock(_component_CropImage, {
                      key: 0,
                      imageUrl: item.image.url,
                      metadata: item.image.metaData,
                      class: "w-full h-full object-contain"
                    }, null, 8, ["imageUrl", "metadata"]))
                  : (_openBlock(), _createElementBlock("img", {
                      key: 1,
                      class: "w-full h-full object-contain",
                      src: item.image.url,
                      alt: "",
                      style: _normalizeStyle([
              item.image.metaData
                ? {
                    transform: `scale(${item.image.metaData.scaleX},${item.image.metaData.scaleY}) rotate(${item.image.metaData.rotate}deg)`,
                    // if img is rotated, width equal to height of the parent div
                    width:
                      item.image.metaData &&
                      item.image.metaData.rotate &&
                      (Math.abs(item.image.metaData.rotate) === 270 || Math.abs(item.image.metaData.rotate) === 90)
                        ? '100px'
                        : '100%',
                  }
                : '',
            ])
                    }, null, 12, _hoisted_6))
              ]))
            : (item.mediaTypeId === _ctx.MediaType.pdf)
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _createVNode(_component_DocumentIcon, {
                    alt: "",
                    class: "w-8 h-8 text-slate-600"
                  })
                ]))
              : (item.mediaTypeId === _ctx.MediaType.mp3)
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                    (item.image?.audioBackground?.length && item.image.audioBackground[0]?.image?.url)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                          _createElementVNode("img", {
                            crossorigin: "anonymous",
                            class: "w-full h-full object-contain",
                            src: item.image.audioBackground[0].image.url
                          }, null, 8, _hoisted_10)
                        ]))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", _hoisted_11, [
                      _createVNode(_component_SpeakerWaveIcon, {
                        alt: "",
                        class: "w-8 h-8 text-slate-600"
                      })
                    ])
                  ]))
                : (item.mediaTypeId === _ctx.MediaType.mp4)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                      (item.image?.thumbnail)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                            _createElementVNode("img", {
                              crossorigin: "anonymous",
                              class: "w-full h-full object-contain",
                              src: item.image?.thumbnail
                            }, null, 8, _hoisted_14)
                          ]))
                        : _createCommentVNode("", true),
                      _createElementVNode("div", _hoisted_15, [
                        _createVNode(_component_FilmIcon, {
                          alt: "",
                          class: "w-8 h-8 text-slate-600"
                        })
                      ])
                    ]))
                  : _createCommentVNode("", true),
          _createElementVNode("div", null, [
            (_ctx.items.length > 1 || item.teacherUseOnly)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: _normalizeClass(["absolute flex justify-center items-center text-xs 2xl:text-sm bottom-1 right-1 h-5 w-5 2xl:h-6 2xl:w-6 rounded-md text-white", [item.teacherUseOnly ? 'bg-red-800' : 'bg-black']])
                }, _toDisplayString(index + 1), 3))
              : _createCommentVNode("", true)
          ])
        ], 10, _hoisted_4))
      }), 128))
    ])
  ]))
}