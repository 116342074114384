<template>
  <HeaderMenuItem :title="homeText" @click="onClickHome" class="pr-2" url="javascript:void(0)">
    <template #icon>
      <img src="@/assets/images/home-class.png" alt="" class="brightness-0 invert h-4 mr-2" />
    </template>
  </HeaderMenuItem>
  <HeaderMenuItem :title="scheduleText" @click="onClickCalendar" class="pr-1.5" url="javascript:void(0)">
    <template #icon>
      <img src="@/assets/images/today-icon.png" alt="" class="brightness-0 invert h-5 mr-2" />
    </template>
  </HeaderMenuItem>
</template>
<style lang="scss" scoped src="./nav-menu.scss"></style>
<script lang="ts" src="./nav-menu.ts"></script>
