import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createBlock as _createBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderMenuItem = _resolveComponent("HeaderMenuItem")!
  const _component_DropdownItem = _resolveComponent("DropdownItem")!
  const _component_DropdownHelper = _resolveComponent("DropdownHelper")!

  return (_ctx.displayedResources.length)
    ? (_openBlock(), _createBlock(_component_DropdownHelper, { key: 0 }, {
        popup: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.displayedResources, (resource, index) => {
            return (_openBlock(), _createBlock(_component_DropdownItem, {
              key: index,
              text: resource.isGenericLink ? resource.textLocaleId : _ctx.fmtMsg(resource.textLocaleId),
              url: resource.url,
              "is-external-url": true,
              style: _normalizeStyle({ color: resource.color })
            }, null, 8, ["text", "url", "style"]))
          }), 128))
        ]),
        default: _withCtx(() => [
          _createVNode(_component_HeaderMenuItem, {
            "show-dropdown-trigger": true,
            title: _ctx.resourcesText,
            icon: "article"
          }, null, 8, ["title"])
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}