<template>
  <Teleport to="body">
    <vue-final-modal
      v-model="showed"
      :classes="PINNING_MODAL_CONTAINER"
      :content-class="[MODAL_CONTENT, pressing && 'translating']"
      :resize="pined"
      :drag="pined"
      :click-to-close="false"
      :min-width="150"
      :min-height="100"
      :hide-overlay="true"
      :prevent-click="true"
      style="z-index: 20"
    >
      <div :class="['final-modal', !pined && showed && 'has-before', pined && 'cursor-move']">
        <div class="final-modal__content final-modal-scroll">
          <div class="final-modal__close" @mousedown="onMouseDown" @mouseup="onMouseUp">
            <PushpinOutlined class="final-modal__close--icon text-white" v-if="!pined && showed" />
            <CloseOutlined class="final-modal__close--icon text-white" v-if="pined && showed" />
          </div>
          <slot name="final-modal-content"></slot>
        </div>
      </div>
    </vue-final-modal>
  </Teleport>
</template>
<script lang="ts" src="./pinning-modal.ts"></script>
<style lang="scss">
.vfm-modal-content {
  @apply bg-gs-green text-white rounded-lg shadow hover:shadow-lg hover:drop-shadow-xl;
  box-sizing: border-box;
  position: absolute;
  padding: 30px 5px 10px;
  font-size: 14px;
  text-align: start;
  border-bottom: 1px solid #c1c1c1;
  transition: box-shadow 0.5s, transform 0.25s;
  min-width: 100px;
  min-height: 100px;
  &.translating {
    transform: translate(3px, 3px);
  }
  .final-modal {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    max-height: 75vh;
    &__content {
      padding: 10px 20px;
      width: 100%;
      height: 100%;
      overflow: auto;
      &:hover {
        // prevent text selection when move the modal
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }
    }
    &__close {
      position: absolute;
      top: 0;
      right: 4px;
      padding: 0.5em;
      cursor: pointer;
      &--icon {
        transition: all 0.2s;
        &:hover {
          box-shadow: 0 1rem 2rem rgba(black, 0.15);
          .modal-content {
            transform: scale(1.5);
          }
        }
        &:active {
          box-shadow: 0 0.5rem 1rem rgba(black, 0.15);
        }
      }
    }
    &.has-before {
      &::before {
        content: "";
        position: absolute;
        bottom: 100%;
        left: 50%;
        transform: translateX(-50%);
        border-width: 5px;
        border-style: solid;
        @apply border-transparent border-b-gs-green;
      }
    }
  }
}

.vfm--inset {
  top: -5px !important;
}
</style>
