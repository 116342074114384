import { IndependentStudentItem } from "@/services/independent";

export interface TeacherTeachingState {
  independentListStudentItems: IndependentStudentItem[] | null;
}

const state: TeacherTeachingState = {
  independentListStudentItems: null,
};

export default state;
