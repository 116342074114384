import { h } from "vue";
import { rgbToRgba } from "@/utils/utils";

interface Props {
  color: string;
}

const WHITE_COLOR = "rgb(255, 255, 255)";
const TeamWrapper = (props: Props, context: any) => {
  const { color } = props;
  const style = {
    borderColor: color === WHITE_COLOR ? null : color,
    background: color === WHITE_COLOR ? "#f3f4f6" : rgbToRgba(color),
  };
  return h(
    "div",
    {
      class: `border-slate-200 overflow-hidden rounded-md flex flex-col grow ${color === WHITE_COLOR ? "border" : ""}`,
      style,
    },
    context.slots.default(),
  );
};

TeamWrapper.props = ["color"];

export default TeamWrapper;
