<template>
  <div
    class="px-3 2xl:px-5 flex justify-between items-center gap-x-6 py-3 2xl:py-5 cursor-pointer hover:bg-slate-100 rounded-xl transition-colors hover:shadow"
  >
    <div class="flex items-center min-w-0 gap-x-3 2xl:gap-x-4">
      <component :is="activityIcon" name="icon-abc" class="flex-shrink-0 h-4 w-4 2xl:h-6 2xl:w-6" />
      <div class="min-w-0 flex-auto">
        <p class="text-sm leading-6 truncate">{{ exposureTitle }}</p>
        <p class="truncate text-xs leading-5" v-if="!isExposureLpComplete">
          <span v-if="type">{{ type }} • </span><span>{{ formattedDuration }}</span>
        </p>
      </div>
    </div>
    <div>
      <CheckIcon class="h-4 w-4 2xl:h-5 2xl:w-5" v-if="isCompleted" />
      <ClockIcon class="h-4 w-4 2xl:h-5 2xl:w-5" v-if="isOptionalComponent" />
    </div>
  </div>
</template>
<script lang="ts" src="./lesson-activity.ts"></script>
