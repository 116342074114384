<template>
  <div class="page-container">
    <img src="@/assets/student-class/bear-confuse.png" alt="" />
    <div class="title-text">
      <h1 class="mb-2">{{ title }}</h1>
      <p class="mb-2">{{ contentFirst }}</p>
      <p class="mb-2">{{ contentSecond }}</p>
    </div>
    <router-link to="/">
      <div class="btn-homepage">{{ goTo }}</div>
    </router-link>
  </div>
</template>
<style lang="scss" scoped src="./disconnect-issue.scss"></style>
<script lang="ts" src="./disconnect-issue.ts"></script>
