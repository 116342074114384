import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, createSlots as _createSlots } from "vue"
import _imports_0 from '@/assets/icons/bullseye.png'


const _hoisted_1 = { class: "2xl:hidden mb-1" }
const _hoisted_2 = { class: "grid grid-cols-2 gap-x-2 gap-y-2" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = {
  key: 0,
  src: _imports_0,
  class: "absolute top-1 right-1 h-6 w-6",
  alt: ""
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Empty = _resolveComponent("Empty")!
  const _component_Thumbnail = _resolveComponent("Thumbnail")!
  const _component_Tooltip = _resolveComponent("Tooltip")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.hasZeroImage)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["text-slate-600 text-xs text-center", _ctx.isHelper ? 'cursor-not-allowed' : ''])
        }, [
          _createVNode(_component_Empty, {
            imageStyle: "max-height: 40px",
            description: _ctx.NoDataText,
            class: "hidden 2xl:block"
          }, null, 8, ["description"]),
          _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.NoDataText), 1)
        ], 2))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
        return (_openBlock(), _createElementBlock("div", {
          onClick: () => _ctx.onClickItem(item.id),
          key: item.id,
          class: _normalizeClass(["relative max-w-full border rounded-lg overflow-hidden w-full bg-white aspect-w-3 aspect-h-2", [
          item.id === _ctx.currentExposureItemMedia?.id && (!_ctx.currentExposureType || _ctx.exposureType === _ctx.currentExposureType)
            ? 'border-gs-purple'
            : 'border-slate-300',
          _ctx.isHelper ? 'cursor-not-allowed' : 'cursor-pointer',
        ]])
        }, [
          _createVNode(_component_Tooltip, {
            placement: "rightBottom",
            overlayStyle: { maxWidth: '400px' }
          }, _createSlots({
            default: _withCtx(() => [
              _createVNode(_component_Thumbnail, { item: item }, null, 8, ["item"]),
              (item.image.metaData?.annotations?.length)
                ? (_openBlock(), _createElementBlock("img", _hoisted_5))
                : _createCommentVNode("", true),
              (_ctx.items.length > 1 || item.teacherUseOnly)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    class: _normalizeClass(["absolute flex justify-center items-center text-xs 2xl:text-sm bottom-1 right-1 h-5 w-5 2xl:h-6 2xl:w-6 rounded-md text-white", [item.teacherUseOnly ? 'bg-red-800' : 'bg-black']])
                  }, _toDisplayString(index + 1), 3))
                : _createCommentVNode("", true)
            ]),
            _: 2
          }, [
            (_ctx.isTeaching)
              ? {
                  name: "title",
                  fn: _withCtx(() => [
                    _createElementVNode("span", {
                      innerHTML: item.teachingContent
                    }, null, 8, _hoisted_4)
                  ]),
                  key: "0"
                }
              : undefined
          ]), 1024)
        ], 10, _hoisted_3))
      }), 128))
    ])
  ]))
}