<template>
  <TeamWrapper :color="team.color">
    <div class="flex gap-2 items-center mb-2 pl-4 pr-3 py-3" :style="{ backgroundColor: team.color, color: calColorByBackground(team.color) }">
      <Tooltip placement="topLeft">
        <template #title> {{ team.name }}</template>
        <div class="font-medium text-base truncate flex-1">
          {{ team.name }}
        </div>
      </Tooltip>
      <div class="inline-flex overflow-hidden">
        <Tooltip placement="topLeft">
          <template #title>{{ muteTooltipTitle }}</template>
          <Button
            v-if="isClassPage && isTeamMode"
            @click="$emit('handleMute', team.id, isMuted)"
            :class="muteBtnClass"
            :disabled="disableMute"
            :size="muteBtnSize"
          >
            {{ muteBtnLabel }}
            <MicrophoneIcon :enabled="isMuted" :isOutline="true" :width="muteIconSize" :height="muteIconSize" />
          </Button>
        </Tooltip>
        <button @click="$emit('handleEdit', team.id)" class="inline-block p-1 hover:bg-white/20 transition rounded focus:relative" title="Edit Team">
          <PencilSquareIcon class="h-4 w-4" />
        </button>
        <Popover v-model:visible="delPopoverOpened" trigger="click">
          <template #content>
            <div class="p-2">
              <div class="text-slate-600">{{ MsgConfirmDelTeam }}</div>
              <div class="mt-4 flex justify-end items-center space-x-1">
                <Button @click="delPopoverOpened = false">{{ MsgNo }}</Button>
                <Button type="primary" @click="$emit('handleDel', team.id)">{{ MsgYes }}</Button>
              </div>
            </div>
          </template>
          <button class="inline-block p-1 hover:bg-white/20 transition rounded focus:relative" title="Delete Team">
            <TrashIcon class="h-4 w-4" />
          </button>
        </Popover>
      </div>
    </div>
    <Draggable class="members px-3 py-2 grow" :list="team.students" group="people" itemKey="name">
      <template #item="{ element: student }">
        <Member :member="student" :team-color="team.color" />
      </template>
    </Draggable>
  </TeamWrapper>
</template>
<style lang="scss" scoped src="./team.scss"></style>
<script lang="ts" src="./team.ts" />
