export interface TeamStudentState {
  name: string;
  avatarUrl: string;
  id: string;
}

export interface TeamState {
  id: string;
  name: string;
  color: string;
  order: number;
  isNew: boolean;
  students: TeamStudentState[];
}
export interface TeamsState {
  settingModalOpened: boolean;
  enableTeamModeOnSave: boolean;
  allStudents: TeamStudentState[];
  allStudentsFetched: boolean;
  teams: TeamState[];
  isTeamMode: boolean;
  isLoading: boolean;
}
export const initTeamModuleState = () => ({
  settingModalOpened: false,
  enableTeamModeOnSave: false,
  allStudents: [],
  allStudentsFetched: false,
  teams: [],
  isTeamMode: false,
  isLoading: false,
});

const state: TeamsState = initTeamModuleState();

export default state;
