import { ErrorLocale } from "@/locales/localeid";
import { RoomSessionModel, MediaStatus } from "@/models";
import { GLErrorCode } from "@/models/error.model";
import { UserModel } from "@/models/user.model";
import router from "@/router";
import {
  FetchSessionDtoResModel,
  InfoService,
  OneToOneDto,
  RemoteTeachingService,
  StudentStorageService,
  ToggleStudentMediaDevicesAsync,
} from "@/services";
import { IndependentService } from "@/services/independent";
import { store } from "@/store";
import { UserRole, VCPlatform } from "@/store/app/state";
import { TeachingMode } from "@/store/teaching/interfaces";
import { AGORA_MINIMUM_USER_VOLUME } from "@/utils/constant";
import { Logger } from "@/utils/logger";
import { Paths } from "@/utils/paths";
import { getDeviceId, isMobileBrowser, isTeacherUseOnlySlideVisible, MediaDeviceTypes } from "@/utils/utils";
import { ChangeIndependentItemParams } from "@/ws";
import { ConnectionDisconnectedReason, ConnectionState, IAgoraRTCRemoteUser, UID } from "agora-rtc-sdk-ng";
import { ErrorCode, fmtMsg } from "vue-glcommonui";
import { ActionTree } from "vuex";
import { CallingConnectionStatus, NetworkQualityPayload } from "../interface";
import { useStudentRoomHandler } from "./handler";
import { StudentRoomState } from "./state";
import { callingUtils } from "@/store/room/student/utils";
import { AgoraEventHandler } from "@/agora";
import { TeachingActivityTarget } from "@/store/lesson/state";
import { notification } from "ant-design-vue";
import { vuexName, VuexNames } from "@/store/utils";

export interface SetStudentMediaDeviceAction {
  id: string;
  enable: boolean;
  preventSendMsg?: boolean;
}

const actions: ActionTree<StudentRoomState, any> = {
  async getClassRoomInfo({ dispatch, state }) {
    if (!state.info?.id) return;
    const { data: roomInfo } = await RemoteTeachingService.fetchSessionDtoById(state.info.id);
    await dispatch("setClassData", roomInfo);
  },
  async initClassRoom(
    { commit, dispatch },
    payload: {
      classId: string;
      userId: string;
      userName: string;
      studentId: string;
      role: string;
      deviceId: string;
      callFirstTime: boolean;
    },
  ) {
    commit("setUser", {
      id: payload.studentId,
      name: payload.userName,
    });
    try {
      const setIndependentModeData = async () => {
        try {
          const response = await IndependentService.getIndependentStudentItem(payload.studentId);
          commit("classTeaching/setMode", TeachingMode.Independent, { root: true });
          commit("studentTeaching/setCurrentIndependentItemId", response.data, { root: true });
        } catch (err) {
          Logger.error(err);
        }
      };
      const setOneToOneWithHelperData = async (oneToOneWithHelperDto: OneToOneDto) => {
        const { isIgnoreTeacherAudio } = oneToOneWithHelperDto;
        commit("classTeaching/setOneToOneWithHelperIgnoreTeacherVoice", isIgnoreTeacherAudio, { root: true });
      };
      const setClassTeachingData = (sessionInfo: { sessionId: string; groupId: string; classId: string }) => {
        commit("classTeaching/setSessionInfo", sessionInfo, { root: true });
      };
      //** This is the only time to check if the teacher has lost connection using our server, then it will rely on AGORA */
      const checkTeacherDisconnect = (teacherCallingConnectionStatus: CallingConnectionStatus) => {
        commit("setTeacherDisconnected", teacherCallingConnectionStatus === CallingConnectionStatus.Disconnected);
      };
      const setTeamModeData = async (isTeamMode: boolean) => {
        commit("teams/setIsTeamMode", isTeamMode, { root: true });
        await dispatch("teams/getTeams", null, { root: true });
      };
      const { data: roomInfo } = await RemoteTeachingService.studentGetRoomInfo(payload.studentId, payload.deviceId);
      const invalidClassInfo = roomInfo?.classInfo?.classId !== payload.classId;
      commit("setApiStatus", {
        code: invalidClassInfo ? GLErrorCode.CLASS_IS_NOT_ACTIVE : GLErrorCode.SUCCESS,
        message: invalidClassInfo ? fmtMsg(ErrorLocale.ClassNotStarted) : "",
      });
      if (invalidClassInfo) return;
      if (payload.callFirstTime) {
        checkTeacherDisconnect(roomInfo.teacher.callingConnectionStatus);
      }
      setClassTeachingData({ sessionId: roomInfo.id, classId: roomInfo.classInfo.classId, groupId: roomInfo.classInfo.groupId });
      commit("whiteboard/setShouldFetchMediaState", true, { root: true });
      if (roomInfo.independentMode) {
        await setIndependentModeData();
      }
      await setTeamModeData(roomInfo.isTeamMode);
      if (payload.studentId === roomInfo.studentHelperOneToOne) {
        await setOneToOneWithHelperData(roomInfo.helperOneAndOneDto);
      }
      await dispatch("setClassData", roomInfo);
      await dispatch("setVideoCallPlatform", roomInfo.videoPlatformProvider, { root: true });
      await dispatch("interactive/setInfo", roomInfo.lessonPlan.interactive, {
        root: true,
      });
      await dispatch("interactive/setCurrentUserId", payload.studentId, {
        root: true,
      });
      await dispatch("setTeacherMessageVersion", roomInfo.teacher.messageVersion, { root: true });
    } catch (error) {
      Logger.error(error);
      await dispatch("setApiError", error);
    }
  },
  setUser({ commit }, payload: UserModel) {
    commit("setUser", payload);
  },
  async updateAudioAndVideoFeed({ state, rootGetters }, options?: { studentCameras: string[] }) {
    const { manager, students, teacher, idOne, idHelperOne, student, videosFeedVisible, helper, isDisconnected: localStudentDisconnected } = state;
    if (!teacher || !student || !manager?.agoraClient?.joined) return;
    if (localStudentDisconnected) return manager.updateAudioAndVideoFeed([], []);
    const hasHelper = !!helper;
    const isIndependentMode = rootGetters["classTeaching/isIndependentMode"];
    let cameras = students
      .filter((s) => {
        if (!videosFeedVisible || isMobileBrowser) return false;
        return s.videoEnabled && rootGetters["calling/checkCallingHasUserById"](s.id);
      })
      .map((s) => s.id);
    let audios = students.filter((s) => s.audioEnabled && rootGetters["calling/checkCallingHasUserById"](s.id)).map((s) => s.id);
    if (isIndependentMode) {
      cameras = [];
      audios = [];
    }
    if (options?.studentCameras) {
      cameras = options.studentCameras;
    }
    if (teacher.videoEnabled && rootGetters["calling/checkCallingHasUserById"](teacher.id)) {
      cameras.push(teacher.id);
    }
    if (teacher.audioEnabled && rootGetters["calling/checkCallingHasUserById"](teacher.id)) {
      audios.push(teacher.id);
    }
    if (hasHelper && rootGetters["calling/checkCallingHasUserById"](helper.id)) {
      // condition to subscribe helper's video
      if (helper.videoEnabled && (helper.isVideoShownByTeacher || idHelperOne === student.id)) {
        cameras.push(helper.id);
      }
      if (helper.audioEnabled) {
        audios.push(helper.id);
      }
    }
    if (idOne) {
      //handle one to one student
      if (idOne === student.id) {
        const cameraOtherStudentId = cameras.filter((camId) => camId !== idOne && camId !== teacher.id);
        const audioOtherStudentId = audios.filter((audioId) => audioId !== idOne && audioId !== teacher.id);

        for (const id of cameraOtherStudentId) {
          const cameraIndex = cameras.findIndex((camId) => camId === id);
          cameras.splice(cameraIndex, 1);
        }
        for (const id of audioOtherStudentId) {
          const audioIndex = audios.findIndex((audioId) => audioId === id);
          audios.splice(audioIndex, 1);
        }

        const helperCamIndex = cameras.findIndex((camId) => camId === helper?.id);
        if (helperCamIndex > -1) {
          cameras.splice(helperCamIndex, 1);
        }
        const helperAudioIndex = audios.findIndex((camId) => camId === helper?.id);
        if (helperAudioIndex > -1) {
          audios.splice(helperAudioIndex, 1);
        }
      }
      //handle other students
      if (idOne !== student?.id) {
        //remove student one to one
        const studentCameraIndex = cameras.findIndex((camId) => camId === idOne);
        if (studentCameraIndex > -1) {
          cameras.splice(studentCameraIndex, 1);
        }
        const studentAudioIndex = audios.findIndex((audioId) => audioId === idOne);
        if (studentAudioIndex > -1) {
          audios.splice(studentAudioIndex, 1);
        }
        //remove teacher one to one
        const teacherCameraIndex = cameras.findIndex((camId) => camId === teacher.id);
        if (teacherCameraIndex > -1) {
          cameras.splice(teacherCameraIndex, 1);
        }
        const teacherAudioIndex = audios.findIndex((audioId) => audioId === teacher.id);
        if (teacherAudioIndex > -1) {
          audios.splice(teacherAudioIndex, 1);
        }
      }
    }
    const shouldIgnoreTeacherVoice = rootGetters["classTeaching/getOneToOneWithHelperIgnoreTeacherVoice"];
    if (hasHelper && idHelperOne) {
      //handle one to one student
      if (idHelperOne === student.id) {
        const clonedCameras = [...cameras];
        clonedCameras.forEach((cameraId) => {
          if (cameraId !== helper.id && cameraId !== teacher.id) {
            const index = cameras.findIndex((id) => id === cameraId);
            if (index > -1) {
              cameras.splice(index, 1);
            }
          }
        });
        const clonedAudios = [...audios];
        clonedAudios.forEach((audioId) => {
          if ((audioId !== helper.id && audioId !== teacher.id) || (audioId === teacher.id && shouldIgnoreTeacherVoice)) {
            const index = audios.findIndex((id) => id === audioId);
            if (index > -1) {
              audios.splice(index, 1);
            }
          }
        });
      }
      //handle other students
      if (idHelperOne !== student?.id) {
        //remove student one to one
        const studentCameraIndex = cameras.findIndex((camId) => camId === idHelperOne);
        if (studentCameraIndex > -1) {
          cameras.splice(studentCameraIndex, 1);
        }
        const studentAudioIndex = audios.findIndex((audioId) => audioId === idHelperOne);
        if (studentAudioIndex > -1) {
          audios.splice(studentAudioIndex, 1);
        }
        //remove helper one to one
        const helperCameraIndex = cameras.findIndex((camId) => camId === helper?.id);
        if (helperCameraIndex > -1) {
          cameras.splice(helperCameraIndex, 1);
        }
        const helperAudioIndex = audios.findIndex((audioId) => audioId === helper?.id);
        if (helperAudioIndex > -1) {
          audios.splice(helperAudioIndex, 1);
        }
      }
    }
    return manager?.updateAudioAndVideoFeed([...new Set(cameras)], [...new Set(audios)]);
  },
  async joinWSRoom(store, rejoin = false) {
    if (!store.state.info || !store.state.manager || !store.state.user) return;
    const isMuteAudio = store.rootGetters["isMuteAudio"];
    const isHideVideo = store.rootGetters["isHideVideo"];
    const deviceId = getDeviceId();
    await store.state.manager?.WSClient.sendRequestJoinRoom(store.state.info.id, store.state.user.id, deviceId, isMuteAudio, isHideVideo);
    if (rejoin) return;
    const eventHandler = useStudentRoomHandler(store);
    store.state.manager?.registerEventHandler(eventHandler);
    store.dispatch("setMuteAudio", { status: MediaStatus.noStatus }, { root: true });
    store.dispatch("setHideVideo", { status: MediaStatus.noStatus }, { root: true });
  },
  async joinRoom(store, _payload: any) {
    const { state, dispatch, rootGetters } = store;
    if (!state.info || !state.user) return;
    if (!state.manager?.isJoinedRoom()) {
      let cameraStatus = state.student?.videoEnabled;
      let microphoneStatus = state.student?.audioEnabled;
      const isMuteAudio = rootGetters["isMuteAudio"];
      if (isMuteAudio !== MediaStatus.noStatus) {
        if (isMuteAudio === MediaStatus.mediaNotLocked) {
          microphoneStatus = true;
        }
        if (isMuteAudio === MediaStatus.mediaLocked) {
          microphoneStatus = false;
        }
      }
      const isHideVideo = rootGetters["isHideVideo"];
      if (isHideVideo !== MediaStatus.noStatus) {
        if (isHideVideo === MediaStatus.mediaNotLocked) {
          cameraStatus = true;
        }
        if (isHideVideo === MediaStatus.mediaLocked) {
          cameraStatus = false;
        }
      }
      let callingEventHandlers: AgoraEventHandler | null = null;
      if (rootGetters["platform"] == VCPlatform.Agora) {
        const { bandwidthUpdateBasedOnAgora, remoteUserJoined, remoteUserLeft, localUserConnectionChanged } = callingUtils(store);
        callingEventHandlers = {
          onLocalNetworkUpdate: (payload: NetworkQualityPayload) => {
            bandwidthUpdateBasedOnAgora(payload);
          },
          onUserJoined: ({ uid }: IAgoraRTCRemoteUser) => {
            const id = typeof uid === "string" ? uid : uid.toString();
            remoteUserJoined(id);
          },
          onUserLeft: async ({ uid }: IAgoraRTCRemoteUser, reason: string) => {
            const id = typeof uid === "string" ? uid : uid.toString();
            await remoteUserLeft(id);
          },
          onConnectionStateChange: (curState: ConnectionState, prevState: ConnectionState, reason: ConnectionDisconnectedReason | undefined) => {
            localUserConnectionChanged(curState, prevState, reason);
          },
          onException: (payload) => {
            //
          },
        };
      }

      await state.manager?.join({
        camera: cameraStatus,
        microphone: microphoneStatus,
        classId: state.info?.id,
        studentId: state.user?.id,
        idOne: state.idOne,
        isMirror: state.info.isStudentVideoMirror,
        isRemoteMirror: state.info.isTeacherVideoMirror,
        callingEventHandlers,
      });
    }
    await dispatch("upToDateSessionAfterSignalRConnected");
    // ** TODO: Comment because checkMsgVersion function is not optimal now */
    // const checkMsgVersion = async () => {
    //   if (state.manager?.WSClient.hubConnection.state != HubConnectionState.Connected) return;
    //   try {
    //     const msgVersion = await state.manager?.WSClient.sendCheckTeacherMessageVersion();
    //     const localMsgVersion = store.rootGetters["teacherMessageVersion"];
    //     if (msgVersion > localMsgVersion) {
    //       Logger.log("Local data is obsolete, start update...", { msgVersion, localMsgVersion });
    //       const user = store.getters["user"] as UserModel;
    //       const room = store.getters["info"] as RoomModel;
    //       await dispatch("initClassRoom", {
    //         classId: room.classInfo.classId,
    //         userId: user.id,
    //         userName: user.name,
    //         studentId: user.id,
    //         role: "parent",
    //         deviceId: getDeviceId(),
    //       });
    //     }
    //   } catch (err) {
    //     Logger.error(err);
    //   }
    // };
    // await checkMsgVersion();
    // const oldInterval = store.rootGetters["checkMessageVersionTimer"];
    // if (oldInterval) {
    //   clearInterval(oldInterval);
    // }
    // const newInterval = setInterval(checkMsgVersion, 3000 * 10);
    // store.dispatch("setCheckMessageVersionTimer", newInterval, { root: true });
  },
  setSpeakingUsers({ commit }, payload: { level: number; uid: UID }[]) {
    const validSpeakings: Array<string> = [];
    if (payload) {
      payload.map((item) => {
        if (item.level >= AGORA_MINIMUM_USER_VOLUME) {
          // should check by a level
          validSpeakings.push(item.uid.toString());
        }
      });
    }
    commit("setSpeakingUsers", { userIds: validSpeakings });
  },
  async leaveRoom({ state, commit, rootGetters, dispatch }, payload: any) {
    await state.manager?.close(payload?.leave);
    if (!payload?.leave) {
      return;
    }
    commit("leaveRoom", payload);
    commit("calling/resetState", null, { root: true });
    commit({ type: "lesson/clearLessonData" }, { root: true });
    commit({ type: "lesson/clearCacheImage" }, { root: true });
    const checkMessageTimer = rootGetters["checkMessageVersionTimer"];
    if (checkMessageTimer) clearInterval(checkMessageTimer);
    dispatch("setCheckMessageVersionTimer", -1, { root: true });
    dispatch("annotation/clearPencilPath", null, { root: true });
    dispatch("annotation/addShape", null, { root: true });
    dispatch("annotation/setLastFabricUpdated", null, { root: true });
    commit("lesson/resetState", null, { root: true });
    commit("classTeaching/setMode", TeachingMode.Normal, { root: true });
    commit("studentTeaching/setCurrentIndependentItemId", null, { root: true });
  },
  async setStudentAudio({ commit, state, dispatch }, payload: SetStudentMediaDeviceAction) {
    if (payload.id === state.student?.id) {
      await dispatch("toggleCurrentStudentMicrophone", payload);
    } else {
      commit("setStudentAudio", payload);
    }
  },
  async toggleCurrentStudentMicrophone({ commit, state }, payload: SetStudentMediaDeviceAction) {
    if (state.microphoneLock) return;
    commit("setMicrophoneLock", { enable: true });
    try {
      await state.manager?.setMicrophone({ enable: payload.enable });
      commit("setStudentAudio", payload);
      const studentId = state.student?.id;
      const sessionId = state.info?.id;
      if (!payload.preventSendMsg && studentId && sessionId) {
        await state.manager?.WSClient.sendRequestMuteAudio({ studentId, sessionId, isMute: !payload.enable });
      }
    } catch (err) {
      Logger.error(err);
      notification.error({
        message: fmtMsg(ErrorLocale.ToggleMicroError),
      });
    } finally {
      commit("setMicrophoneLock", { enable: false });
    }
  },
  async setStudentVideo({ state, commit, dispatch }, payload: SetStudentMediaDeviceAction) {
    if (payload.id === state.student?.id) {
      await dispatch("toggleCurrentStudentCamera", payload);
    } else {
      commit("setStudentVideo", payload);
    }
  },
  async toggleCurrentStudentCamera({ commit, state }, payload: SetStudentMediaDeviceAction) {
    if (state.cameraLock) return;
    try {
      commit("setCameraLock", { enable: true });
      await state.manager?.setCamera({ enable: payload.enable });
      commit("setStudentVideo", payload);
      const studentId = state.student?.id;
      const sessionId = state.info?.id;
      if (!payload.preventSendMsg && studentId && sessionId) {
        await state.manager?.WSClient.sendRequestMuteVideo({ studentId, sessionId, isMute: !payload.enable });
      }
    } catch (err) {
      Logger.error(err);
      notification.error({
        message: fmtMsg(ErrorLocale.ToggleCameraError),
      });
    } finally {
      commit("setCameraLock", { enable: false });
    }
  },
  setTeacherDisconnect({ commit }, payload: boolean) {
    commit("setTeacherDisconnected", payload);
  },
  setTeacherAudio(store, payload: { id: string; enable: boolean }) {
    store.commit("setTeacherAudio", payload);
  },

  setTeacherVideo(store, payload: { id: string; enable: boolean }) {
    store.commit("setTeacherVideo", payload);
  },
  async studentRaisingHand({ commit, state }, payload: boolean) {
    commit("setStudentRaisingHand", {
      raisingHand: payload,
    });
    const studentId = state.student?.id;
    const sessionId = state.info?.id;
    if (!studentId || !sessionId) return;
    await state.manager?.WSClient.sendRequestRaisingHand({ studentId, sessionId, isRaisedHand: payload });
  },
  async studentLike({ state }, _: any) {
    await state.manager?.WSClient.sendRequestLike();
  },
  async studentAnswer(
    { state },
    payload: {
      x: number;
      y: number;
      contentId: string;
    },
  ) {
    await state.manager?.WSClient.sendRequestAnswer(payload);
  },
  async setStudentOneId({ commit }, p: { id: string; withTeacher: boolean }) {
    commit("setStudentOneId", p);
  },
  updateDisconnectStatus({ commit }, p: false) {
    commit("updateDisconnectStatus", p);
  },
  setOnline({ commit }) {
    commit("setOnline");
  },
  setOffline({ commit, rootState }) {
    if (rootState.app.userRole === UserRole.Student) {
      commit("setOffline");
    }
  },
  async studentLeaveClass({ state }) {
    if (!state.info || !state.manager || !state.user) return;
    await state.manager?.WSClient.sendRequestStudentLeaveClass(state.info.id, state.user.id);
  },
  setIsJoined({ commit }, p: { isJoined: boolean }) {
    commit("setIsJoined", p);
  },
  async getAvatarTeacher({ commit }, payload: { teacherId: string }) {
    const response = await InfoService.fetchTeacherAvatarUrl(payload.teacherId);
    if (response) commit("setAvatarTeacher", response);
  },
  async getAvatarHelper({ commit }, payload: { helperId: string }) {
    const response = await InfoService.fetchTeacherAvatarUrl(payload.helperId);
    if (response) commit("setAvatarHelper", response);
  },
  async toggleVideosFeed({ commit, dispatch }) {
    commit("toggleVideosFeed");
    await dispatch("updateAudioAndVideoFeed");
  },
  // setTargetsVisibleListAction({ state }, payload: any) {
  //   state.manager?.WSClient.sendRequestToggleShape(payload);
  // },
  async generateOneToOneToken({ state }, payload: { classId: string; studentId: string }) {
    try {
      const zoom = state.manager?.zoomClient;
      if (zoom) {
        await zoom.studentJoinOneToOneSubSession();
      }
    } catch (error) {
      Logger.error(error);
    }
  },
  setStudentImageCaptured({ commit }, p: { id: string; capture: boolean }) {
    commit("setStudentImageCaptured", p);
  },
  async uploadCapturedImage(
    { state, commit },
    p: {
      token: string;
      formData: FormData;
      fileName: string;
      studentId: string;
    },
  ) {
    try {
      await StudentStorageService.uploadFile(p.token, p.formData);
      const count = state.student ? state.student.imageCapturedCount + 1 : 1;
      commit("setStudentImageCapturedCount", count);
      state.manager?.WSClient.sendCapturedImageStatus({
        StudentId: p.studentId,
        FileName: p.fileName,
        ImageCapturedCount: count,
        IsUploaded: true,
        Error: "",
      });
    } catch (error) {
      state.manager?.WSClient.sendCapturedImageStatus({
        StudentId: p.studentId,
        FileName: "",
        ImageCapturedCount: 0,
        IsUploaded: false,
        Error: error.message,
      });
      Logger.log(error);
    }
  },
  async setRoomInfo({ commit }, p: FetchSessionDtoResModel) {
    commit("setRoomInfo", p);
  },
  async setClassData({ commit, dispatch, state, rootGetters }, roomInfo: RoomSessionModel) {
    // set members data: teacher, helper, students ...
    commit("setRoomInfo", roomInfo);
    // setup lesson plan sidebar data; if isSetCurrentExposure === true, set default default Exposure and Slide is first item
    if (!roomInfo.options?.isActionUpToDate) {
      await dispatch(
        "lesson/setInfo",
        {
          lessonPlan: roomInfo?.lessonPlan,
          isSetCurrentExposure: roomInfo?.lessonPlan.contentSelected && !(state.student?.id === roomInfo.studentOneToOne),
        },
        { root: true },
      );
    }
    // set users in one to one mode with teacher and helper
    if (roomInfo.studentOneToOne) {
      await dispatch("studentRoom/setStudentOneId", { id: roomInfo.studentOneToOne, withTeacher: true }, { root: true });
    } else {
      await dispatch("studentRoom/setStudentOneId", { id: "", withTeacher: true }, { root: true });
    }
    if (roomInfo.studentHelperOneToOne) {
      await dispatch("studentRoom/setStudentOneId", { id: roomInfo.studentHelperOneToOne, withTeacher: false }, { root: true });
    } else {
      await dispatch("studentRoom/setStudentOneId", { id: "", withTeacher: false }, { root: true });
    }

    const isMeOneOneTeacher = state.student?.id === roomInfo.studentOneToOne;
    const isMeOneOneHelper = state.student?.id === roomInfo.studentHelperOneToOne;
    if (
      !isMeOneOneTeacher &&
      !isMeOneOneHelper &&
      !isTeacherUseOnlySlideVisible({
        isTeacherUseOnly: !!rootGetters["lesson/currentExposureItemMedia"]?.teacherUseOnly,
        isWhiteboardVisible: roomInfo.isShowWhiteBoard,
      })
    ) {
      // annotation data outside 1:1: shapes + lines
      await dispatch("annotation/setInfo", roomInfo.annotation, { root: true });
      // annotation data outside 1:1: texts
      commit("annotation/setDrawings", roomInfo.annotation?.drawing?.fabrics ?? [], {
        root: true,
      });
    }

    let studentOneToOne: string | undefined;
    let oneAndOneDto: OneToOneDto | undefined;
    if (isMeOneOneTeacher) {
      store.commit("classTeaching/setMyOneAndOneData", roomInfo.oneAndOneDto, { root: true });
      studentOneToOne = roomInfo.studentOneToOne;
      oneAndOneDto = roomInfo.oneAndOneDto;
    }
    if (isMeOneOneHelper) {
      store.commit("classTeaching/setMyOneAndOneData", roomInfo.helperOneAndOneDto, { root: true });
      studentOneToOne = roomInfo.studentHelperOneToOne;
      oneAndOneDto = roomInfo.helperOneAndOneDto;
    }

    // data related to Target feature
    const fillTargets = async (isShowingAllShapes: boolean | undefined, visibleShapes: TeachingActivityTarget[] | undefined) => {
      await dispatch(vuexName(VuexNames.LESSON.DISPATCHES.PROCESS_VISIBLE_TARGETS_DATA_FROM_API), visibleShapes, {
        root: true,
      });
    };
    // does whiteboard open or not.
    const fillWhiteboardStatus = (opened: boolean) => {
      commit("setWhiteboard", opened);
    };
    // canvas (board) zoom ratio
    const fillBoardZoomRatio = async (ratio: number) => {
      await dispatch("lesson/setZoomRatio", ratio, { root: true });
    };

    const fillBoardZoomPosition = async (coords: { x: number; y: number } | null) => {
      await dispatch("lesson/setImgCoords", coords ?? undefined, { root: true });
    };

    const fillBoardPdfScrolledRatio = async (ratio: number) => {
      await dispatch("lesson/setPdfScrollProgress", ratio, { root: true });
    };

    //** Current student is in 1:1 mode with teacher/helper */
    if (studentOneToOne) {
      if (!oneAndOneDto) throw new Error("Something went wrong!");
      commit("lesson/setCurrentExposure", { id: oneAndOneDto.exposureSelected, preventSelectFirstSlideAutomatically: true }, { root: true });
      commit("lesson/setCurrentExposureItemMedia", { id: oneAndOneDto.itemContentSelected }, { root: true });
      commit("updateIsPalette", { id: oneAndOneDto.id, isPalette: oneAndOneDto.isEnablePalette });
      fillWhiteboardStatus(oneAndOneDto.isShowWhiteBoard);
      if (roomInfo.annotation) {
        const isTeacherOnlyForOneToOne = !!rootGetters["lesson/currentExposureItemMedia"]?.teacherUseOnly;
        const isWbVisibleForOneToOne = oneAndOneDto.isShowWhiteBoard;
        const isNeedToSetAnnotationInsideOneToOne = !(isTeacherOnlyForOneToOne && !isWbVisibleForOneToOne);
        if (isNeedToSetAnnotationInsideOneToOne) {
          // annotation data inside 1:1: targets
          await fillTargets(roomInfo.annotation.oneOneDrawing.isShowingAllShapes, roomInfo.annotation.oneOneDrawing.visibleShapes);
          // annotation data inside 1:1: shapes + lines
          await dispatch("annotation/setOneTeacherStrokes", roomInfo.annotation.oneOneDrawing.brushstrokes, { root: true });
          await dispatch("annotation/setTeacherAddShape", { teacherShapes: roomInfo.annotation.oneOneDrawing.shapes }, { root: true });
          // annotation data inside 1:1: texts
          commit("annotation/setDrawings", roomInfo.annotation?.oneOneDrawing?.fabrics ?? [], {
            root: true,
          });
        }
      }
      await fillBoardZoomRatio(oneAndOneDto.ratio);
      await fillBoardPdfScrolledRatio(oneAndOneDto.ratioScrollPdf);
      await fillBoardZoomPosition(oneAndOneDto.position ? { x: oneAndOneDto.position.x, y: oneAndOneDto.position.y } : null);
    } else {
      if (roomInfo.options?.isActionUpToDate) await dispatch("lesson/setBothExposureAndSlide", roomInfo.lessonPlan, { root: true });
      fillWhiteboardStatus(roomInfo.isShowWhiteBoard);
      if (
        roomInfo.annotation &&
        !isTeacherUseOnlySlideVisible({
          isTeacherUseOnly: !!rootGetters["lesson/currentExposureItemMedia"]?.teacherUseOnly,
          isWhiteboardVisible: roomInfo.isShowWhiteBoard,
        })
      ) {
        // annotation data outside 1:1: targets
        await fillTargets(roomInfo.annotation.drawing.isShowingAllShapes, roomInfo.annotation.drawing.visibleShapes);
      }
      await fillBoardZoomRatio(roomInfo.lessonPlan.ratio);
      await fillBoardPdfScrolledRatio(roomInfo.ratioScrollPdf);
      await fillBoardZoomPosition(roomInfo.lessonPlan.position ? { x: roomInfo.lessonPlan.position.x, y: roomInfo.lessonPlan.position.y } : null);
    }
  },
  async setApiError({ commit }, payload: any) {
    if (payload.code == null) {
      commit("setApiStatus", {
        code: GLErrorCode.DISCONNECT,
        message: "",
      });
      return Logger.log(payload);
    }
    if (payload.code === ErrorCode.ConcurrentUserException) {
      await router.push(Paths.Home);
    } else if (payload.code === ErrorCode.StudentNotInClass) {
      commit("setApiStatus", {
        code: GLErrorCode.PARENT_NOT_HAVE_THIS_STUDENT,
        message: fmtMsg(ErrorLocale.ParentAccountNotHaveThisStudent),
      });
    } else {
      commit("setApiStatus", {
        code: GLErrorCode.CLASS_IS_NOT_ACTIVE,
        message: fmtMsg(ErrorLocale.ClassNotStarted),
      });
      return;
    }
  },
  async checkAndReconnectVideo({ state }) {
    state.manager?.agoraClient?.checkAndReconnectCamera();
  },
  async changeIndependentItem({ state }, payload: { currentItemId: string; progress: number }) {
    const studentId = state.student?.id;
    const currentUnit = state.info?.classInfo.unit;
    const currentLesson = state.info?.classInfo.lesson;
    const sessionId = state.info?.id;
    if (!studentId || !currentUnit || !currentLesson || !sessionId) return;
    const { currentItemId, progress } = payload;
    const params: ChangeIndependentItemParams = {
      studentId,
      currentItemId,
      sessionId,
      unit: currentUnit,
      lesson: currentLesson,
      progress,
    };
    await state.manager?.WSClient.sendRequestChangeIndependentItem(params);
  },
  async updateCameraDevice({ state }, { blockReopen }: { blockReopen?: boolean }) {
    await state.manager?.updateCameraDevice();
    if (blockReopen) return;
    if (store.getters.platform === VCPlatform.Agora) {
      await state.manager?.setCamera({
        enable: false,
        videoEncoderConfigurationPreset: "480p",
      });
      await state.manager?.setCamera({
        enable: true,
        videoEncoderConfigurationPreset: "480p",
      });
    }
  },
  async updateMicrophoneDevice({ state }) {
    await state.manager?.updateMicrophoneDevice();
  },
  async updateSpeakerDevice({ state }) {
    await state.manager?.updateSpeakerDevice();
  },
  async upToDateSessionAfterSignalRConnected({ state, rootState, commit, dispatch }) {
    const sessionId = rootState.classTeaching.sessionId;
    const studentId = state.student?.id;
    if (!sessionId || !studentId) return;
    const toTeamMode = (enabled: boolean) => {
      commit("teams/setIsTeamMode", enabled, { root: true });
    };
    const toIndependentMode = (enabled: boolean) => {
      commit("classTeaching/setMode", enabled ? TeachingMode.Independent : TeachingMode.Normal, { root: true });
    };
    try {
      const response = await RemoteTeachingService.fetchSessionDtoById(sessionId);
      const roomInfo = response.data;
      toTeamMode(roomInfo.isTeamMode);
      toIndependentMode(roomInfo.independentMode);
      await dispatch("setClassData", {
        ...response.data,
        options: {
          isActionUpToDate: true,
        },
      });
    } catch (e) {
      Logger.error(e);
    }
  },
  async handleTeacherToggleStudentMediaDevices({ commit, dispatch, state }, payload: ToggleStudentMediaDevicesAsync) {
    const { mediaDeviceType, studentIds, isMute } = payload;
    const toggleMedia = async (commitAction: string, dispatchAction: string) => {
      commit(commitAction, payload);
      await dispatch("updateAudioAndVideoFeed", {});
      // If the current student is in the list of students to be muted, then mute the current student
      if (state.student && studentIds.includes(state.student.id)) {
        const payload: SetStudentMediaDeviceAction = { enable: !isMute, preventSendMsg: true, id: state.student.id };
        await dispatch(dispatchAction, payload);
      }
    };

    if (mediaDeviceType === MediaDeviceTypes.Microphone) {
      await toggleMedia("toggleOtherStudentMicrophones", "toggleCurrentStudentMicrophone");
    }

    if (mediaDeviceType === MediaDeviceTypes.Camera) {
      await toggleMedia("toggleOtherStudentCameras", "toggleCurrentStudentCamera");
    }
  },
  async sendHeartBeat({ state }) {
    state.manager?.WSClient.sendStudentHeartBeat();
  },
};

export default actions;
