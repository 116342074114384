import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "flex justify-center items-center" }
const _hoisted_2 = {
  key: 0,
  class: "animate-spin h-4 w-4",
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 24 24"
}
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isProcessing)
      ? (_openBlock(), _createElementBlock("svg", _hoisted_2, _cache[2] || (_cache[2] = [
          _createElementVNode("circle", {
            class: "opacity-25",
            cx: "12",
            cy: "12",
            r: "10",
            stroke: "currentColor",
            "stroke-width": "4"
          }, null, -1),
          _createElementVNode("path", {
            class: "opacity-75",
            fill: "currentColor",
            d: "M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          }, null, -1)
        ])))
      : _createCommentVNode("", true),
    _createElementVNode("img", {
      class: _normalizeClass([[_ctx.isProcessing && 'hidden'], "w-full h-full object-contain"]),
      src: _ctx.imgUrl,
      id: "imgCrop",
      ref: "imageRef",
      alt: "",
      crossorigin: "anonymous",
      onLoad: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onImageLoad && _ctx.onImageLoad(...args))),
      onError: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onImageError && _ctx.onImageError(...args))),
      style: _normalizeStyle({
        transform: `scale(${_ctx.metadata.scaleX},${_ctx.metadata.scaleY})`,
      })
    }, null, 46, _hoisted_3)
  ]))
}