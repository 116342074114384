import { useSetDeviceId } from "@/hooks/use-client-storage";
import { useDisconnection } from "@/hooks/use-disconnection";
import { CommonLocale } from "@/locales/localeid";
import { AppView } from "@/store/app/state";
import { CalendarFilter } from "@/store/teacher/state";
import { Paths } from "@/utils/paths";
import { Spin } from "ant-design-vue";
import { computed, defineComponent, watch } from "vue";
import { AuthService, LoginInfo, MainLayout, RoleName, fmtMsg } from "vue-glcommonui";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { DynamicModal, SettingTeamsModal, LostConnectionWarning } from "@/components/common";
import { AppFooter, AppHeader } from "@/components/layout";
import { PARENT_PATH_REGEX, TEACHER_CALENDAR_PATH_REGEX, TEACHER_PATH_REGEX, TEACHER_SCHEDULE_INFO_PATH_REGEX } from "@/utils/constant";
import { useTabLock } from "@/hooks/use-tab-lock";
import { isChromeBrowser, isDesktopBrowser, isEdgeBrowser, isOperaBrowser, isYandexBrowser } from "@/utils/utils";
import BlockedBrowser from "@/views/blocked-browser/blocked-browser.vue";

export default defineComponent({
  components: {
    MainLayout,
    AppHeader,
    AppFooter,
    Spin,
    DynamicModal,
    SettingTeamsModal,
    LostConnectionWarning,
    BlockedBrowser,
  },
  created() {
    AuthService.localSilentLogin();
  },
  setup() {
    const { getters, dispatch } = useStore();
    useTabLock();
    const router = useRouter();
    const route = useRoute();
    useSetDeviceId();
    useDisconnection();
    const isHeaderVisible = computed(() => getters.appLayout !== "full");
    const isFooterVisible = computed(() => getters.appLayout !== "full");
    const loginInfo = computed(() => getters["auth/getLoginInfo"]);
    const isSignedIn = computed(() => getters["auth/isLoggedIn"]);
    const appView = computed(() => getters["appView"]);
    const siteTitle = computed(() => fmtMsg(CommonLocale.CommonSiteTitle));
    const appPath = computed(() => route.path);
    const calendarFilter = computed<CalendarFilter>(() => getters["teacher/calendarFilter"]);
    const isSupportedBrowser = computed(() => {
      return isChromeBrowser() || isOperaBrowser() || isEdgeBrowser() || isYandexBrowser();
    });
    const isBrowserBlocked = computed(() => !isDesktopBrowser || !isSupportedBrowser.value);

    const onTeacherSignedIn = async (loginInfo: LoginInfo) => {
      await dispatch("teacher/setInfo", {
        id: loginInfo.profile.sub,
        name: loginInfo.profile.name,
      });
    };

    const onParentSignedIn = async (loginInfo: LoginInfo) => {
      await dispatch("parent/setInfo", {
        id: loginInfo.profile.sub,
        name: loginInfo.profile.name,
      });
    };

    const onUserSignedIn = async () => {
      const loginInfo: LoginInfo = getters["auth/getLoginInfo"];
      const isTeacher: boolean = getters["auth/isTeacher"];
      const isParent: boolean = getters["auth/isParent"];
      if (isTeacher) await onTeacherSignedIn(loginInfo);
      if (isParent) {
        await onParentSignedIn(loginInfo);
        await checkPolicy("parent");
      }
    };

    const checkPolicy = async (role: "parent" | "teacher"): Promise<void> => {
      if (role === "parent") {
        const policyAccepted = getters["parent/acceptPolicy"];
        if (!policyAccepted && location.pathname && location.pathname.includes("student")) {
          router.push({
            path: Paths.Parent,
            query: { target: location.pathname },
          });
        }
      }
    };

    watch(isSignedIn, async () => {
      if (isSignedIn.value) onUserSignedIn();
    });

    watch(loginInfo, (currentLoginInfo: LoginInfo | null) => {
      if (!currentLoginInfo || typeof currentLoginInfo.profile?.remoteTsiSettings === "undefined") return;
      const isTeacher = currentLoginInfo.profile.roles.indexOf(RoleName.teacher) !== -1 && currentLoginInfo.profile.remoteTsiSettings;
      const isParent = currentLoginInfo.profile.roles.indexOf(RoleName.parent) !== -1;
      const { pathname } = window.location;
      if ((!isParent && !isTeacher) || (isParent && isTeacher)) {
        return;
      }
      if (isTeacher) {
        const matchIndex = pathname.search(PARENT_PATH_REGEX);
        if (matchIndex > -1) {
          location.pathname = pathname.replace(PARENT_PATH_REGEX, "/teacher");
        }
      }
      if (isParent) {
        const matchIndex = pathname.search(TEACHER_PATH_REGEX);
        if (matchIndex > -1) {
          location.pathname = pathname.replace(TEACHER_PATH_REGEX, "/parent");
        }
      }
    });

    watch(appPath, async () => {
      window.scrollTo(0, 0);
      if (calendarFilter.value.classId || calendarFilter.value.groupId || calendarFilter.value.date || calendarFilter.value.isShowWeekends) {
        const calenderPathMatchIndex = appPath.value.search(TEACHER_CALENDAR_PATH_REGEX);
        const scheduleInfoPathMatchIndex = appPath.value.search(TEACHER_SCHEDULE_INFO_PATH_REGEX);
        if (calenderPathMatchIndex < 0 && scheduleInfoPathMatchIndex < 0) {
          await dispatch("teacher/setCalendarFilter", {
            classId: "",
            groupId: "",
            date: "",
            isShowWeekends: false,
          });
        }
      }
    });

    const isMaskGrandAccess = computed(() => getters["spin/getMaskGrandAccess"]);
    return {
      siteTitle,
      appView,
      AppView,
      isSignedIn,
      isHeaderVisible,
      isFooterVisible,
      isMaskGrandAccess,
      isBrowserBlocked,
      isDesktopBrowser,
    };
  },
});
