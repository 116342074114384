import { defineComponent, computed } from "vue";
import { useStore } from "vuex";
import { TeamStudentState } from "@/store/teams/state";
import { PropType } from "vue/dist/vue";
import { generateAvatar } from "@/utils/utils";
import NameTag from "@/components/common/name-tag/name-tag.vue";
import { RoomStore, useRoomStore } from "@/hooks/use-room-store";
import { RoleView, usePage } from "@/hooks/use-page";

export default defineComponent({
  components: { NameTag },
  props: {
    member: {
      type: Object as PropType<TeamStudentState>,
      required: true,
    },
    teamColor: {
      type: String,
      required: false,
    },
  },
  setup(props) {
    const { getters } = useStore();
    const { usersMedia, oneStudentIds } = useRoomStore(RoomStore.Teacher);
    const { isClassPage } = usePage(RoleView.Teacher);
    const isOnline = computed<boolean>(() => getters["calling/checkCallingHasUserById"](props.member.id));
    const isMuted = computed(() => isClassPage.value && !usersMedia.value.find((user) => user.id === props.member.id)?.audio);
    const isOne = computed(() => oneStudentIds.value.includes(props.member.id));
    return { generateAvatar, isOnline, isMuted, isOne };
  },
});
