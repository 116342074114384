<template>
  <div v-show="helperInfo?.id" class="relative text-slate-600 rounded-xl w-full overflow-hidden group" :class="[showHelperVideo ? '' : 'shadow']">
    <div class="aspect-w-16 aspect-h-9 bg-white">
      <figure>
        <div class="absolute inset-0 flex justify-center items-center">
          <ConnectAvatar v-if="!showHelperVideo" :src="helperAvatar" :alt="helperInfo?.name" />
          <svg v-else class="animate-spin h-6 w-6 text-slate-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
            <path
              class="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        </div>
        <div v-if="isUsingAgora" :class="['w-full h-full object-cover', !showHelperVideo && 'hidden']" :id="helperId" />
        <div v-else :class="['w-full h-full object-cover', !showHelperVideo && 'hidden']" v-show="showHelperVideo">
          <video class="w-full h-full object-cover" v-if="isSupportedVideo && isHelper" :id="zoomId" />
          <canvas class="w-full h-full object-cover" v-else :id="zoomId" />
        </div>
      </figure>
    </div>
    <div
      :class="[showHelperVideo ? 'opacity-0' : '']"
      class="absolute top-2 left-1/2 -translate-x-1/2 flex space-x-1.5 group-hover:opacity-100 transition-opacity"
    >
      <template v-if="isHelper">
        <button
          @click="onHelperToggleCamera"
          :disabled="isCameraLocking"
          class="h-7 w-7 bg-slate-100/80 flex items-center justify-center rounded-md hover:bg-white hover:shadow transition-colors cursor-pointer disabled:cursor-wait"
        >
          <LoadingOutlined class="animate-spin" v-if="isCameraLocking" />
          <component v-else :is="helperCameraOn ? VideoCameraIcon : VideoCameraSlashIcon" class="h-4 w-4" />
        </button>
        <button
          @click="onHelperToggleMicro"
          :disabled="isMicrophoneLocking"
          class="h-7 w-7 bg-slate-100/80 flex items-center justify-center rounded-md hover:bg-white hover:shadow transition-colors cursor-pointer disabled:cursor-wait"
        >
          <LoadingOutlined class="animate-spin" v-if="isMicrophoneLocking" />
          <MicrophoneIcon v-else icon-class="h-4 w-4" :enabled="helperMicroOn" />
        </button>
        <div
          @click="openDeviceSettingsModal"
          class="h-7 w-7 bg-slate-100/80 flex items-center justify-center rounded-md hover:bg-white hover:shadow transition-colors cursor-pointer"
        >
          <Cog6ToothIcon class="h-4 w-4" />
        </div>
        <div
          @click="onClickMinimized"
          class="h-7 w-7 bg-slate-100/80 flex items-center justify-center rounded-md hover:bg-white hover:shadow transition-colors cursor-pointer"
        >
          <ArrowsPointingInIcon class="h-4 w-4" />
        </div>
        <DeviceSettingsModal
          v-model:visible="isDeviceSettingsModalShown"
          :isMicEnabled="helperMicroOn"
          :isCamEnabled="helperCameraOn"
          :onUpdateCamOpen="updateCamOpen"
          :onUpdateMicOpen="updateMicOpen"
        />
      </template>
      <template v-else>
        <div
          @click="toggleHelperVideo(!helperVideoStatus)"
          :class="[toggleHelperVideoLoading ? 'pointer-events-none' : 'cursor-pointer']"
          class="h-7 w-7 bg-slate-100/80 flex items-center justify-center rounded-md hover:bg-white hover:shadow transition-colors"
        >
          <component :is="helperVideoStatus ? EyeIcon : EyeSlashIcon" class="h-4 w-4" />
        </div>
        <div
          @click="onClickMinimized"
          class="h-7 w-7 bg-slate-100/80 flex items-center justify-center rounded-md hover:bg-white hover:shadow transition-colors cursor-pointer"
        >
          <ArrowsPointingInIcon class="h-4 w-4" />
        </div>
        <div
          class="h-7 w-7 bg-red-800/80 text-white flex items-center justify-center rounded-md hover:bg-red-800 hover:shadow transition-colors cursor-pointer"
          @click="onRemoveHelper"
        >
          <XMarkIcon class="h-4 w-4" />
        </div>
      </template>
    </div>
    <NameTag :name="helperInfo?.name" :is-muted="!helperInfo?.audioEnabled" class="absolute bottom-2 left-3" />
  </div>
</template>

<script lang="ts" src="./helper-card.ts"></script>
