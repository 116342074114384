import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = ["tip"]
const _hoisted_2 = {
  key: 1,
  class: "container m-auto"
}
const _hoisted_3 = { class: "flex" }
const _hoisted_4 = { class: "flex-1 mt-1.5 text-slate-600" }
const _hoisted_5 = { class: "min-h-[60px] w-[350px]" }
const _hoisted_6 = {
  key: 0,
  class: "text-red-600"
}
const _hoisted_7 = { class: "flex" }
const _hoisted_8 = { class: "flex-1 text-slate-600" }
const _hoisted_9 = { class: "w-[350px]" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Spin = _resolveComponent("Spin")!
  const _component_Member = _resolveComponent("Member")!
  const _component_Draggable = _resolveComponent("Draggable")!
  const _component_Team = _resolveComponent("Team")!
  const _component_Input = _resolveComponent("Input")!
  const _component_ColorPicker = _resolveComponent("ColorPicker")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_ctx.isFetchingTeams)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "placeholder",
        tip: _ctx.MsgFetchingTeams
      }, [
        _createVNode(_component_Spin)
      ], 8, _hoisted_1))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createVNode(_component_Draggable, {
          class: "students border-2 border-dashed border-slate-200 mb-4 rounded-md",
          list: _ctx.nonTeamStudents,
          group: "people",
          itemKey: "name"
        }, {
          item: _withCtx(({ element: student }) => [
            _createVNode(_component_Member, { member: student }, null, 8, ["member"])
          ]),
          _: 1
        }, 8, ["list"]),
        _createVNode(_component_Draggable, {
          class: "grid grid-flow-col gap-2 auto-cols-fr",
          list: _ctx.teams,
          group: "teams",
          itemKey: "name"
        }, {
          item: _withCtx(({ element: team }) => [
            _createVNode(_component_Team, {
              team: team,
              onHandleDel: _ctx.handleDel,
              onHandleEdit: _ctx.handleEdit,
              onHandleMute: _ctx.handleMute
            }, null, 8, ["team", "onHandleDel", "onHandleEdit", "onHandleMute"])
          ]),
          _: 1
        }, 8, ["list"]),
        _createVNode(_component_Modal, {
          visible: _ctx.createModalOpened,
          title: _ctx.createTeamState.isEdit ? _ctx.MsgEditTeam : _ctx.MsgNewTeam,
          onOk: _ctx.handleSubmit,
          onCancel: _cache[2] || (_cache[2] = ($event: any) => (_ctx.toggleCreateTeamModal(false))),
          zIndex: 999
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.MsgTeamName), 1),
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_Input, {
                  ref: "teamNameInputEl",
                  value: _ctx.createTeamState.name,
                  "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.createTeamState.name) = $event)),
                  maxlength: 20,
                  onPressEnter: _withModifiers(_ctx.handleSubmit, ["prevent"])
                }, null, 8, ["value", "onPressEnter"]),
                (_ctx.errors.name && !_ctx.createTeamState.name.trim())
                  ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.errors.name), 1))
                  : _createCommentVNode("", true)
              ])
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.MsgTeamColor), 1),
              _createElementVNode("div", _hoisted_9, [
                _createVNode(_component_ColorPicker, {
                  "is-widget": "",
                  format: "rgb",
                  shape: 'circle',
                  "disable-history": "",
                  "disable-alpha": "",
                  pureColor: _ctx.createTeamState.color,
                  "onUpdate:pureColor": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.createTeamState.color) = $event))
                }, null, 8, ["pureColor"])
              ])
            ])
          ]),
          _: 1
        }, 8, ["visible", "title", "onOk"])
      ]))
}