import { AgoraClient, AgoraEventHandler } from "@/agora";
import { store } from "@/store";
import { VCPlatform } from "@/store/app/state";
import { TeacherWSClient } from "@/ws";
import { BaseRoomManager, RoomOptions } from "./base.manager";
import { RoomModel } from "@/models";
import { LoginInfo, RoleName } from "vue-glcommonui";
import { HelperState, TeacherState } from "@/store/room/interface";

export class TeacherRoomManager extends BaseRoomManager<TeacherWSClient> {
  constructor(options: RoomOptions) {
    super();
    this.options = options;
    if (options.agora) {
      this.agoraClient = new AgoraClient(options.agora);
    }
    this.WSClient = new TeacherWSClient({
      url: `${process.env.VUE_APP_REMOTE_TEACHING_SERVICE}/${process.env.VUE_APP_REMOTE_TEACHING_HUB}`,
      reConnectedCallback: async (newConnectionId: string) => {
        await this.handleSuccessfulReconnection(newConnectionId);
      },
    });
    this.WSClient.init();
  }

  async handleSuccessfulReconnection(newConnectionId: string) {
    await store.dispatch("teacherRoom/setSignalRConnectionId", newConnectionId);
    await store.dispatch("teacherRoom/joinWSRoom", true);
    const refreshSessionData = async () => {
      const { getters, dispatch } = store;
      const room = getters["teacherRoom/info"] as RoomModel;
      const { groupId, deviceId } = getters["teacherRoom/getParamsToJoinCurSession"];
      const loginInfo: LoginInfo = getters["auth/getLoginInfo"];
      const isHelper = store.getters["teacher/isHelper"];
      await dispatch("teacherRoom/initClassRoom", {
        classId: room.classInfo.classId,
        userId: loginInfo.profile.sub,
        userName: loginInfo.profile.name,
        role: RoleName.teacher,
        deviceId,
        isHelper,
        groupId,
        callFirstTime: false,
      });
    };
    await refreshSessionData();
  }

  async join(options: {
    classId?: string;
    studentId?: string;
    teacherId?: string;
    camera?: boolean;
    microphone?: boolean;
    idOne?: string;
    isMirror?: boolean;
    isRemoteMirror?: boolean;
    callingEventHandlers: AgoraEventHandler | null;
  }) {
    if (!options.teacherId || !options.classId) throw new Error("Missing Params");
    await this.WSClient.connect();
    if (store.getters.platform === VCPlatform.Agora) {
      await this.agoraClient.joinRTCRoom({ ...options, videoEncoderConfigurationPreset: "480p" }, false, async () => {
        await this.reconnectAgoraRoom();
      });
    }
  }

  async reconnectAgoraRoom() {
    if (!this.agoraClient.joinRoomOptions) return;
    const joinAsHelper = store.getters["teacher/isHelper"];
    const deviceStatus = {
      camera: false,
      microphone: false,
    };
    if (joinAsHelper) {
      const helper: HelperState | undefined = store.getters["teacherRoom/helperInfo"];
      deviceStatus.camera = helper?.videoEnabled ?? false;
      deviceStatus.microphone = helper?.audioEnabled ?? false;
    } else {
      const teacher: TeacherState | undefined = store.getters["teacherRoom/teacher"];
      deviceStatus.camera = teacher?.videoEnabled ?? false;
      deviceStatus.microphone = teacher?.audioEnabled ?? false;
    }
    const options = { ...this.agoraClient.joinRoomOptions, camera: deviceStatus.camera, microphone: deviceStatus.microphone };
    await this.agoraClient.joinRTCRoom(options, true);
  }

  async manualReconnectAgoraRoom() {
    await this.reconnectAgoraRoom();
  }

  async close(end?: boolean) {
    await this.WSClient.disconnect();
    if (store.getters.platform === VCPlatform.Agora) {
      if (end) {
        await this.agoraClient.reset();
      } else {
        await this.agoraClient.leaveChannel();
      }
    }
  }

  // TODO: Remove in v12.3
  async adjustRenderedVideoPosition(userId?: string) {
    //
  }

  // TODO: Remove in v12.3
  async removeParticipantVideo(userId: string) {
    //
  }
  // TODO: Remove in v12.3
  async renderExpandedVideo(userId: string, isExpanded: boolean) {
    //
  }
}
