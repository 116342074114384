import { computed } from "vue";
import { useStore } from "vuex";
import { useClientSide } from "@/hooks/use-client-side";
import { ClassView } from "@/store/room/interface";

export const useClassView = () => {
  const { getters } = useStore();
  const { isTeacherSide } = useClientSide();
  const isLpSidebarShrink = computed<boolean>(
    () => getters[`${isTeacherSide.value ? "teacherRoom" : "studentRoom"}/classView`] === ClassView.GALLERY,
  );
  return {
    isLpSidebarShrink,
  };
};
