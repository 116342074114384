import { MutationTree } from "vuex";
import { StudentTeachingState } from "./state";

const mutations: MutationTree<StudentTeachingState> = {
  setCurrentIndependentItemId(state: StudentTeachingState, payload: string) {
    state.currentIndependentItemId = payload;
  },
};

export default mutations;
