import { SessionReplacementLocales, TeacherClassError } from "@/locales/localeid";
import { notification } from "ant-design-vue";
import { fmtMsg } from "vue-glcommonui";

export const notifySameUserJoined = () => {
  notification.destroy();
  notification.info({
    message: fmtMsg(SessionReplacementLocales.NotificationOldTeacherDesc),
  });
};

export const notifyConnectAgoraServerFailed = () => {
  notification.error({
    message: fmtMsg(TeacherClassError.ConnectAgoraServersErrorTitle),
    description: fmtMsg(TeacherClassError.ConnectAgoraServersError),
    duration: 4,
  });
};
